

import { defineComponent, defineAsyncComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@/customValidators'
import { ErpNextActions } from '@/store/modules/erp-next/actions'
import { OrderActions } from '@/store/modules/orders/actions'
import { useToast } from 'vue-toastification'
import BulkOrderToastMessage from '@/views/account/orders/components/BulkOrderToastMessage.vue'
import { ClientActions } from '@/store/modules/clients/actions'

export default defineComponent({
  name: 'BulkCreateOrderModal',
  components: {
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    Multiselect: defineAsyncComponent(() => import('@vueform/multiselect')),
    DarkstoreSelect: defineAsyncComponent(() => import('@/views/account/orders/components/DarkstoreSelect.vue')),
    // eslint-disable-next-line vue/no-unused-components
    BulkOrderToastMessage: defineAsyncComponent(() => import('@/views/account/orders/components/BulkOrderToastMessage.vue'))
  },
  data () {
    return {
      form: {
        dark_store: undefined as any,
        erpnext_merchant: '',
        file: undefined as any,
        order_status: '' as any
      },
      statuses: [
        {
          value: 'ready_for_pick_up',
          label: 'Ready for pickup'
        },
        {
          value: 'preparing',
          label: 'Preparing'
        }
      ]
    }
  },
  validations: function () {
    return {
      form: {
        dark_store: { required: requiredIf(() => !this.isClientAdmin) },
        erpnext_merchant: { required: requiredIf(() => !this.isClientAdmin) },
        file: { required },
        order_status: { required }
      }
    }
  },
  computed: {
    vehicleTypes (): any[] {
      return this.$store.getters['vehicles/getVehicleTypes']
        .map((el: any) => {
          return {
            id: el.id,
            label: el.value
          }
        })
    },
    merchants (): any[] {
      return this.$store.getters['erpNext/getMerchants']
    },
    isClientAdmin (): boolean {
      return this.$store.getters.isClientAdmin
    }
  },
  setup (_, { emit }) {
    const closeModal = ($event: any, out = true) => {
      if (out && !$event.inner) {
        emit('close', false)
      } else $event.inner = true
    }
    return {
      closeModal,
      v$: useVuelidate()
    }
  },
  methods: {
    onFileSelected (e: any) {
      this.form.file = e.target.files[0]
      this.v$.$touch()
    },
    async submitForm (validator: any): Promise<any> {
      validator.$touch()

      if (validator.$invalid) return

      if (!validator.$invalid) {
        const payload = {
          file: this.form.file,
          darkstoreId: this.isClientAdmin ? undefined : this.form.dark_store.id,
          erpNextMerchant: this.isClientAdmin ? undefined : this.form.erpnext_merchant,
          orderStatus: this.form.order_status?.value ?? ''
        }
        if (this.isClientAdmin) {
          this.$store.dispatch(`clients/${[ClientActions.IMPORT_GENERAL_ORDER]}`, payload).then((res: any) => {
            const toast = useToast()
            let content = [res.data?.info?.message]
            if (res.data.data && Array.isArray(res.data.data) && res.data.data[0].result) {
              content = res.data.data.map((r: any) => (r.result))
            }
            toast.success({
              component: BulkOrderToastMessage,
              props: { messageArray: content },
              type: 'success'
            })
            this.$emit('onOrderCreated')
          })
        } else {
          this.$store.dispatch(`orders/${[OrderActions.IMPORT_GENERAL_ORDER]}`, payload).then((res: any) => {
            const toast = useToast()
            let content = [res.data?.info?.message]
            if (res.data.data && Array.isArray(res.data.data) && res.data.data[0].result) {
              content = res.data.data.map((r: any) => (r.result))
            }
            toast.success({
              component: BulkOrderToastMessage,
              props: { messageArray: content },
              type: 'success'
            })
            this.$emit('onOrderCreated')
          })
        }
      }
    }
  },
  mounted () {
    this.$store.dispatch(`erpNext/${[ErpNextActions.FETCH_ERP_NEXT_MERCHANTS]}`)
  }
})
