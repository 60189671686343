import { Order } from '@/shared/interfaces/order.interface'
import { CommonModel } from '@/shared/models/common.model'
import { formatDate } from '@/shared/utils/formatDate'
const detailed = [
  {
    created_at: '2023-08-04T12:37:26',
    priority: false,
    orders_drivers_assignment: [
      {
        sequence: [
          3242,
          3243
        ],
        first_assignment_datetime: '2023-08-04T12:37:26'
      }
    ],
    order_total: '36',
    trip_details_from_darkstore_outward: {
      end_address: '89G5+QXX، بغداد،، Baghdad, Iraq',
      end_location: {
        lat: 33.3271014,
        lng: 44.3602367
      },
      start_address: 'شارع 60، بغداد،، Baghdad, Iraq',
      start_location: {
        lat: 33.2301051,
        lng: 44.3980062
      },
      travel_distance: 16294,
      travel_duration: 1456,
      waypoints_order: [
        0
      ],
      overview_polyline: {
        points: 'egyiEqn~mGiClAC@_ChAE@{Bx@oFxAuLzCKBm@Rc@Cy@F}Ad@kDjAmDt@iFdAqB`@uDt@kCh@??EAYE]QQMIM[q@wDsVYkB?AGa@aAsGyEu[oDyU{CcRaB_LcBiLq@kF??E_A?]@OF[DSJULOPKHCFABAHAPAPBF@D@FBFDFBFFFFPZBD@DBP?H@LARGZCNINKNEBEDKFQFMFMDOBe@Bk@Ai@?o@Am@A??gAEa@Ce@EE?a@EK?CAUAW?o@Gc@Ea@CuBY{@Ma@GqASoCm@{G_BSCYE}Ca@}BUi@EyCEoCAS@sCP_D^{Dx@eBd@g@NmBr@??g@NyC`Ak@XkB|@oXpMqEvBoFfCoQdIe@Tc@RuDdBUJULgJdEaBr@aHhDcGtC_@Ra@PsDbBcGrCy@`@aA`@oAZ]HC@C@KBC@C?]JMDE@KBIBE@aO~DsCl@gAN??GEECICUGW@I@SFQJMLORGPAHAPGJMVMPOLQL]Nc@RcC~@kElB_Bt@eBp@a@NoAh@q@V{@^w@ZOHODQDQBG@EAGA??A?AAA?A??AA?A?AAMAOAM@A@KBMDKHKJGJUPGDMFG@[JuBTIBOJ??O@M@_@BoCPgAFc@DiBNkBPeAJ_@CmAFe@D_BHU@K@sDTaFn@sATs@Bc@?]C[Eu@O??IEEAC?C?E?C?C@C@E@CBA@CBA@?@A@A@?@A@?BA@?BAD?D?D?B?D@D@B@D@BBB@BBBBBFBD@B??@RjCPdCPfB\\vDj@pG??VlDT`DfAfMN`BbB`S??iEfLu@jB??Yl@wAvBeA`BgA~AyBdDw@hAiA~A{AvBSXe@h@sAjBU\\uB~CIL_@j@yApBMRORgA~AiAdB{E`HIH[f@aJ~MyLvQcCnDKHgBjCmAfBo@`Ag@lA??CCEECCECEAEAGAE?E@G@E@CBEBEDCD?@CBADAFAFAF?F@F@F@FEFEHALA`@EvAClCG~BGlASbDa@jC??ILEDGDEBG@G?O@YCe@m@qCiDe@k@qImJ_B_C[_@c@e@k@q@???A?ACCCCCAE?E?A@A??@A??@A??@A??@?@A??@?@?@A??@o@z@MLUTk@^sAb@YJkC`AA@kCnAA?wBhAwBpAYZCBWXq@`AMPwA~BABkBlCq@~@ILYf@o@lAGL]t@Wj@OZ??s@s@'
      },
      entire_polyline_route: 'egyiEqn~mGsGzC{Bx@oFxAaM~Cm@Rc@Cy@F}Ad@kDjAmDt@{IfBaI~A_@Go@_@e@_AyEc[{Gid@oDyU{CcReEiYq@kFE_A@m@Lo@Xe@ZOf@Gf@J^XT`@DV@VIn@M^QRq@\\]HqA@oEIuCSmCQsEo@qASoCm@oHcBwDg@}BUi@EyCEcD?sCP_D^{Dx@eBd@uCbAaEpAyc@`Ti[vNiQdIcK|EcHhDsOjHaA`@oAZa@JODoA\\gO`EsCl@gANMI_@Ka@Be@R]`@IZAPGJ[h@a@ZaAb@cC~@kElBeEfB_FpBgAd@a@JYDMCGAEC]COBYHWT]\\ULc@L_CXOJO@M@{Gb@uE`@eAJ_@CsBLuBJ_EVuHdAwABy@I_AUIAQ@OHGHGZ@THPPNH@RlClBvTl@nIvAhPbB`SiEfLoAxC_J~MqFzHyBtCkC|DqC~D}JzNaYhb@oCxDuDrFo@`Ag@lAIIUKS?WJMPET@^@FEFGVGxBKlG[pFa@jCILMJMDW@YCe@m@wDuEqImJ_B_C_AeAk@s@KKM@EBCD?Bq@~@c@b@k@^sAb@eDlAmCpAyBhAwBpAYZ[\\_ArAyAbC}ClE{ApCu@`BOZs@s@r@r@N[t@aBzAqC|CmExAcC~@sAZ]X[vBqAxBiAlCqAdDmArAc@h@CPKl@q@TKPEP?d@Nj@TZ^~A~BpIlJvDtE|@fAVR\\Jn@Ez@sQDkBNg@Lg@JQLM^I^HdAd@jNfJdJbGp\\rTdD~Bb@Nv@d@~C~BbAt@\\^`@n@T^JH@JBLDHXZXJ\\DDAj@NtAr@|DdCtFnDnGdExDlBjB`AvCzAtClA`FdCbClApFlCzEdCdE|BV\\j@ZbD|AxIfEpAb@zBhAz@XjANj@?`AExAUbAQhA]dAi@nAy@t@o@jAcAt@e@fFoEjC}Bv@w@nC{ChBsBlDaDhYqVbG_FjJoHv@OdCm@tFcAxAWz@Gb@H^VVTRl@j@jCrF`ZtA`IrElVzBhMp@|CX|@Nr@FtACr@[fAIGyEqCeFuCiI_F',
      total_travel_duration: 0
    },
    order_status: {
      name: 'canceled',
      value: 'Canceled',
      id: 12
    },
    destination_longitude: 44.36023950576783,
    order_id: 'SO-23-BJ-QMQF-00162-3434',
    zone_id: 32,
    id: 3242,
    user_address: 'address-Personal',
    destination_latitude: 33.32709941316224,
    trip_details_to_darkstore_inward: null,
    driver_last_location: {}
  }
]
export class OrderDetailsModel extends CommonModel {
  public data!: Order;

  constructor (data: any) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.data = this.setData(this.baseData)
  }

  private setData (data: any): Order {
    return {
      id: data.id,
      createdAt: data.created_at,
      estimateTime: this.calculateEstimateTime(data),
      customerAddressDetails: data.customer_address_details,
      orderId: data.order_id,
      delayTime: data.delay_time ? Math.ceil(data.delay_time / 60) : null,
      orderStatus: {
        id: data.order_status.id,
        name: data.order_status.name,
        value: data.order_status.value
      },
      orderStatusTime: data?.order_status_time,
      orderType: data.order_type, // remove later
      userAddress: data.user_address,
      orderTotal: data.order_total,
      destinationLatitude: data.destination_latitude,
      destinationLongitude: data.destination_longitude,
      darkStore: {
        latitude: data.dark_store?.latitude,
        longitude: data.dark_store?.longitude,
        id: data.dark_store?.id,
        name: data.dark_store?.name
      },
      driver: data.driver ? {
        driverInfo: {
          id: data.driver.driver_info.id,
          firstName: data.driver.driver_info.first_name,
          lastName: data.driver.driver_info.last_name,
          userName: data.driver.driver_info.username,
          phoneNumber: data.driver.driver_info.user_phone_number
        },
        lastLocation: {
          latitude: data?.driver?.last_location?.latitude ?? 0,
          longitude: data?.driver?.last_location?.longitude ?? 0
        },
        id: data.driver.id
      } : null,
      tripDetailsFromDarkstoreOutward: data.trip_details_from_darkstore_outward
        ? this.setTripOutwardInfo(data.trip_details_from_darkstore_outward, data.linked_orders ? data.linked_orders : [])
        : null,
      tripDetailsToDarkstoreInward: data.trip_details_to_darkstore_inward ? {
        endAddress: data.trip_details_to_darkstore_inward.end_address,
        endLocation: {
          lat: data.trip_details_to_darkstore_inward.end_location.lat,
          lng: data.trip_details_to_darkstore_inward.end_location.lng
        },
        startAddress: data.trip_details_to_darkstore_inward.start_address,
        startLocation: {
          lat: data.trip_details_to_darkstore_inward.start_location.lat,
          lng: data.trip_details_to_darkstore_inward.start_location.lng
        },
        totalTravelDuration: data.trip_details_to_darkstore_inward.total_travel_duration,
        travelDistance: data.trip_details_to_darkstore_inward.travel_distance,
        travelDuration: data.trip_details_to_darkstore_inward.travel_duration,
        overviewPolyline: data.trip_details_to_darkstore_inward.overview_polyline
      } : null,
      linkedOrders: data.linked_orders,
      priority: data?.priority,
      failedDeliveryReason: data.failed_delivery_reason, // ?
      customerCode: data.customer_code,
      customerPhoneNumber: data.customer_phone_number,
      orderBinNumber: data.order_bin_number,
      vehicleType: data.vehicle_type ? {
        id: data.vehicle_type.id,
        name: data.vehicle_type.name,
        value: data.vehicle_type.value
      } : null,
      driverLastLocation: data.driver_last_location,
      zone: data?.zone ?? null,
      isManual: data.is_manual,
      traveledDistance: data?.traveled_distance ?? 0,
      settlementStatus: data?.settlement_status ?? undefined,
      deliveredErpSyncStatus: data?.delivered_erp_sync_status ?? undefined,
      pickupErpSyncStatus: data?.pickup_erp_sync_status ?? undefined,
      canceledErpSyncStatus: data?.canceled_erp_sync_status ?? undefined,
      erpnextMerchant: data?.erpnext_merchant ?? 'Taza'
    }
  }

  private calculateEstimateTime (data: any): number {
    let totalDuration = 0
    if (data.trip_details_to_darkstore_inward) {
      totalDuration = data.trip_details_to_darkstore_inward.travel_duration
    }
    if (data.trip_details_from_darkstore_outward) {
      if (data.trip_details_from_darkstore_outward.length) {
        data.trip_details_from_darkstore_outward.forEach((el: any) => {
          totalDuration += el.travel_duration
        })
      } else {
        totalDuration += data.trip_details_from_darkstore_outward.travel_duration
      }
    }
    return Math.floor(totalDuration / 60)
  }

  private setTripOutwardInfo (data: any, linkedOrders = []) {
    if (data.length) {
      const mainOrderData = data.map((el: any) => {
        return {
          endAddress: el?.end_address ?? null,
          endLocation: el.end_location ? {
            lat: el.end_location.lat,
            lng: el.end_location.lng
          } : null,
          startAddress: el?.start_address ?? null,
          startLocation: el.start_location ? {
            lat: el.start_location.lat,
            lng: el.start_location.lng
          } : null,
          totalTravelDuration: el?.total_travel_duration ?? null,
          travelDistance: el?.travel_distance ?? null,
          travelDuration: el?.travel_duration ?? null,
          overviewPolyline: el?.overview_polyline ?? null
        }
      })
      // add linked orders data
      const linkedOrderData = linkedOrders.map((el: any) => {
        return el.trip_details_from_darkstore_outward ? {
          endAddress: el.end_address,
          endLocation: {
            lat: el.end_location.lat,
            lng: el.end_location.lng
          },
          startAddress: el.start_address,
          startLocation: {
            lat: el.start_location.lat,
            lng: el.start_location.lng
          },
          totalTravelDuration: el.total_travel_duration,
          travelDistance: el.travel_distance,
          travelDuration: el.travel_duration,
          overviewPolyline: el.overview_polyline
        } : {
          endAddress: null,
          endLocation: null,
          startAddress: null,
          startLocation: null,
          totalTravelDuration: null,
          travelDistance: null,
          travelDuration: null,
          overviewPolyline: null
        }
      })
      return [...mainOrderData, ...linkedOrderData]
    } else {
      const mainOrderData = {
        endAddress: data.end_address,
        endLocation: {
          lat: data.end_location.lat,
          lng: data.end_location.lng
        },
        startAddress: data.start_address,
        startLocation: {
          lat: data.start_location.lat,
          lng: data.start_location.lng
        },
        totalTravelDuration: data.total_travel_duration,
        travelDistance: data.travel_distance,
        travelDuration: data.travel_duration,
        overviewPolyline: data.overview_polyline
      }
      const linkedOrderData = linkedOrders.map((el: any) => {
        return el.trip_details_from_darkstore_outward ? {
          endAddress: el.trip_details_from_darkstore_outward.end_address,
          endLocation: {
            lat: el.trip_details_from_darkstore_outward.end_location.lat,
            lng: el.trip_details_from_darkstore_outward.end_location.lng
          },
          startAddress: el.trip_details_from_darkstore_outward.start_address,
          startLocation: {
            lat: el.trip_details_from_darkstore_outward.start_location.lat,
            lng: el.trip_details_from_darkstore_outward.start_location.lng
          },
          totalTravelDuration: el.trip_details_from_darkstore_outward.total_travel_duration,
          travelDistance: el.trip_details_from_darkstore_outward.travel_distance,
          travelDuration: el.trip_details_from_darkstore_outward.travel_duration,
          overviewPolyline: el.trip_details_from_darkstore_outward.overview_polyline
        } : {
          endAddress: null,
          endLocation: null,
          startAddress: null,
          startLocation: null,
          totalTravelDuration: null,
          travelDistance: null,
          travelDuration: null,
          overviewPolyline: null
        }
      })
      return [mainOrderData, ...linkedOrderData]
    }
  }
}
