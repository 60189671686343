import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f7ca6ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "locale-switcher inline-block text-start",
  ref: "localeswitcher"
}
const _hoisted_2 = { class: "rounded-md shadow-sm" }
const _hoisted_3 = {
  class: "primary inline-flex justify-center h-11 w-11 px-2 py-3 text-sm font-bold leading-5 transition duration-150 ease-in-out rounded-md",
  type: "button",
  "aria-haspopup": "true",
  "aria-expanded": "true",
  "aria-controls": "headlessui-menu-items-117"
}
const _hoisted_4 = { class: "opacity-0 invisible locale-switcher-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95" }
const _hoisted_5 = {
  class: "absolute end-0 w-40 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none",
  "aria-labelledby": "headlessui-menu-button-1",
  id: "headlessui-menu-items-117",
  role: "menu"
}
const _hoisted_6 = { class: "py-1" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("button", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$i18n.locale.toUpperCase()), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (locale) => {
            return (_openBlock(), _createElementBlock("a", {
              key: locale.code,
              href: "javascript:void(0)",
              onClick: _withModifiers(($event: any) => (_ctx.setLocale(locale.code)), ["prevent"]),
              class: _normalizeClass([(locale.code == _ctx.$i18n.locale) && 'bg-primary text-black font-bold', "text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-start hover:bg-primary hover:text-black"]),
              role: "menuitem"
            }, _toDisplayString(locale.nativeName), 11, _hoisted_7))
          }), 128))
        ])
      ])
    ])
  ], 512))
}