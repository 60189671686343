import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pt-14 pb-5 px-5" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "px-5" }
const _hoisted_4 = { class: "rounded-lg p-4 border flex flex-col items-start mb-4" }
const _hoisted_5 = { class: "flex w-full justify-between" }
const _hoisted_6 = { class: "font-bold text-black" }
const _hoisted_7 = { class: "flex w-full justify-between gap-x-6 mt-4" }
const _hoisted_8 = { class: "font-bold text-black" }
const _hoisted_9 = {
  key: 0,
  class: "bg-gray-200 animate-pulse w-full h-3 rounded-full"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "font-bold text-black" }
const _hoisted_12 = {
  key: 0,
  class: "bg-gray-200 animate-pulse w-full h-3 rounded-full"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "font-bold text-black" }
const _hoisted_15 = {
  key: 0,
  class: "bg-gray-200 animate-pulse w-full h-3 rounded-full"
}
const _hoisted_16 = { class: "font-bold text-black" }
const _hoisted_17 = {
  key: 0,
  class: "bg-gray-200 animate-pulse w-full h-3 rounded-full"
}
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "font-bold text-black" }
const _hoisted_20 = {
  key: 0,
  class: "bg-gray-200 animate-pulse w-full h-3 rounded-full"
}
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { class: "font-bold text-black" }
const _hoisted_23 = {
  key: 0,
  class: "bg-gray-200 animate-pulse w-full h-3 rounded-full"
}
const _hoisted_24 = { key: 1 }
const _hoisted_25 = { class: "font-bold text-black" }
const _hoisted_26 = {
  key: 0,
  class: "bg-gray-200 animate-pulse w-full h-3 rounded-full"
}
const _hoisted_27 = { key: 1 }
const _hoisted_28 = { class: "font-bold text-black" }
const _hoisted_29 = {
  key: 0,
  class: "bg-gray-200 animate-pulse w-full h-3 rounded-full"
}
const _hoisted_30 = { key: 1 }
const _hoisted_31 = { class: "text-textColor font-bold text-md mb-" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_DynamicTableCacheSettlementsAction = _resolveComponent("DynamicTableCacheSettlementsAction")!
  const _component_DynamicTableCacheSettlementStatus = _resolveComponent("DynamicTableCacheSettlementStatus")!
  const _component_DynamicTableErpSyncStatus = _resolveComponent("DynamicTableErpSyncStatus")!
  const _component_DynamicTable = _resolveComponent("DynamicTable")!
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_PageTitle, { title: "cacheSettlements.detail.title" }),
        _createElementVNode("div", {
          role: "button",
          class: "text-md text-black cursor-pointer font-bold hover:text-gray-600",
          onClick: _cache[0] || (_cache[0] = ()=> _ctx.$router.push(`/cache-settlements/${_ctx.type}`))
        }, " ← " + _toDisplayString(_ctx.$t('buttons.back')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('cacheSettlements.detail.info_title')), 1),
          _createElementVNode("div", null, [
            (_ctx.cacheSettlementsData)
              ? (_openBlock(), _createBlock(_component_DynamicTableCacheSettlementsAction, {
                  key: 0,
                  data: _ctx.cacheSettlementsData,
                  onReject: _ctx.onReject,
                  onApprove: _ctx.onApprove,
                  onRetryErpSync: _ctx.onRetryErpSync
                }, null, 8, ["data", "onReject", "onApprove", "onRetryErpSync"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('cacheSettlements.fields.settlement_no')), 1),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9))
              : _createCommentVNode("", true),
            (_ctx.cacheSettlementsData && !_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.cacheSettlementsData.settlementNo), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('cacheSettlements.fields.total_amount')), 1),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12))
              : _createCommentVNode("", true),
            (_ctx.cacheSettlementsData && !_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.cacheSettlementsData.totalAmount), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('cacheSettlements.fields.status')), 1),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15))
              : _createCommentVNode("", true),
            (_ctx.cacheSettlementsData && !_ctx.loading)
              ? (_openBlock(), _createBlock(_component_DynamicTableCacheSettlementStatus, {
                  key: 1,
                  data: _ctx.cacheSettlementsData
                }, null, 8, ["data"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('cacheSettlements.fields.driver')), 1),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17))
              : _createCommentVNode("", true),
            (_ctx.cacheSettlementsData && !_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.cacheSettlementsData.driver.fullName), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('cacheSettlements.fields.date')), 1),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20))
              : _createCommentVNode("", true),
            (_ctx.cacheSettlementsData && !_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_ctx.cacheSettlementsData.createdAt), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('cacheSettlements.fields.admin_action_datetime')), 1),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23))
              : _createCommentVNode("", true),
            (_ctx.cacheSettlementsData && !_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_24, _toDisplayString(_ctx.cacheSettlementsData.adminActionDatetime), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t('cacheSettlements.fields.admin')), 1),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_26))
              : _createCommentVNode("", true),
            (_ctx.cacheSettlementsData && !_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(_ctx.cacheSettlementsData.admin), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t('cacheSettlements.fields.erp_next_status')), 1),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_29))
              : _createCommentVNode("", true),
            (_ctx.cacheSettlementsData && !_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                  _createVNode(_component_DynamicTableErpSyncStatus, { data: _ctx.cacheSettlementsData }, null, 8, ["data"])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createVNode(_component_DynamicTable, {
        tableData: _ctx.records,
        loading: _ctx.loading,
        tableFields: _ctx.tableFields,
        scrollable: true,
        buttons: { edit: true, delete: true, export: false }
      }, null, 8, ["tableData", "loading", "tableFields"])
    ]),
    (_ctx.confirmObject)
      ? (_openBlock(), _createBlock(_component_ConfirmationModal, {
          key: 0,
          header: _ctx.confirmObject.title,
          hasIcon: false,
          cancelText: _ctx.$t('buttons.no'),
          confirmText: _ctx.$t('buttons.yes'),
          onConfirm: _ctx.confirmObject?.handler,
          onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmObject = null))
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.confirmObject.message), 1)
          ]),
          _: 1
        }, 8, ["header", "cancelText", "confirmText", "onConfirm"]))
      : _createCommentVNode("", true)
  ]))
}