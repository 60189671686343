import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { apiEndpoints } from '@/shared/endpoints/api'
import { UsersState } from '@/store/modules/users/types'
import { UserModel } from '@/shared/models/user.model'
import { UsersMutations } from '@/store/modules/users/mutations'
import moment from 'moment'
import axiosInstance from '@/shared/utils/axiosInstance'

export enum UsersActions {
  FETCH_USERS = 'FETCH_USERS',
  ACTIVATE_USER = 'LOCK_USER',
  DEACTIVATE_USER = 'UNLOCK_USER',
  UPDATE_USER = 'UPDATE_USER',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
}

export const actions: ActionTree<UsersState, RootState> = {
  [UsersActions.FETCH_USERS]: async (context, payload) => {
    const isExport = payload?.export ?? false
    if (!isExport) {
      context.commit(UsersMutations.TRIGGER_LOADING, true)
    }
    const params = new URLSearchParams()
    params.append('page_number', payload.pageNumber)
    params.append('per_page', payload.pageSize)
    if (payload.role) {
      params.append(payload.role, '1')
    }
    if (payload.search && payload.search !== '') {
      params.append('search', payload.search)
    }
    if (payload.orderBy && payload.sort) {
      params.append('order_by', `${payload.orderBy}_${payload.sort}`)
    } else {
      params.append('order_by', 'created_at_desc')
    }
    if (payload.firstName && payload.firstName !== '') {
      params.append('first_name', payload.firstName)
    }
    if (payload.lastName && payload.lastName !== '') {
      params.append('last_name', payload.lastName)
    }
    if (payload.joiningDate_from) {
      params.append('from_date', moment(payload.joiningDate_from).format('YYYY-MM-DD hh:mm:ss'))
      params.append('to_date', moment(payload.joiningDate_to).format('YYYY-MM-DD hh:mm:ss'))
    }
    if (payload.status) {
      params.append('status', payload.status)
    }
    if (payload.active) {
      params.append('is_active', payload.active === 'true' ? '1' : '0')
    }
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.users.list.method,
        url: apiEndpoints.users.list.url,
        params
      }).then(resp => {
        context.commit(UsersMutations.TRIGGER_LOADING, false)
        const user = new UserModel(resp.data)
        const payload = {
          data: user.data,
          pagination: user.paginationData
        }
        if (isExport) {
          context.commit(UsersMutations.EXPORT_USERS, payload)
        } else {
          context.commit(UsersMutations.INSERT_USERS, payload)
        }
        resolve(payload)
      }).catch(err => {
        context.commit(UsersMutations.TRIGGER_LOADING, false)
        reject(err)
        throw new Error(err)
      })
    })
  },

  [UsersActions.ACTIVATE_USER]: async (context, payload) => {
    await axiosInstance({
      method: apiEndpoints.users.activate.method,
      url: apiEndpoints.users.activate.url + payload.userId
    }).then(resp => {
      context.commit(UsersMutations.UPDATE_USERS_ACTIVATED, { userId: payload.userId, isActivate: true })
    }).catch(err => {
      throw new Error(err)
    })
  },

  [UsersActions.DEACTIVATE_USER]: async (context, payload) => {
    await axiosInstance({
      method: apiEndpoints.users.deactivate.method,
      url: apiEndpoints.users.deactivate.url + payload.userId
    }).then(resp => {
      context.commit(UsersMutations.UPDATE_USERS_ACTIVATED, { userId: payload.userId, isActivate: false })
    }).catch(err => {
      throw new Error(err)
    })
  },

  [UsersActions.UPDATE_USER]: async (context, payload) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.users.update.method,
        url: apiEndpoints.users.update.url + payload.userId,
        data: payload.data
      }).then(resp => {
        resolve(resp)
      }).catch(err => {
        reject(err)
        throw new Error(err)
      })
    })
  },

  [UsersActions.CHANGE_PASSWORD]: async (context, payload) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.users.changePass.method,
        url: apiEndpoints.users.changePass.url + payload.userId,
        data: payload.data
      }).then(resp => {
        resolve(resp)
      }).catch(err => {
        reject(err)
        throw new Error(err)
      })
    })
  }
}
