import { PredefinedReasonInterface } from '@/shared/interfaces/predefined-reasons.interface'
import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'

export class PredefinedReasonModel extends CommonModel {
  public data: PredefinedReasonInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): PredefinedReasonInterface {
    return {
      id: data.id,
      description: data.description,
      feedbackType: data.feedback_type,
      isActive: data.is_active,
      descriptionArabic: data?.description_ar ?? data.description
    }
  }
}
