import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { AdminWalletInterface } from '@/shared/interfaces/reports/admin-wallet.interface'
import moment from 'moment'

export class AdminWalletModel extends CommonModel {
  public data: AdminWalletInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): AdminWalletInterface {
    return {
      adminUsername: data.admin_username,
      totalCashSettlements: data.total_cash_settlements,
      settledOrders: data.settled_orders,
      totalCashSettlementsAmount: data.total_cash_settlements_amount,
      cashSettlementItems: data.cash_settlement_items.map((item: any) => {
        return {
          cashSettlementId: item.cash_settlement_id,
          settlementNo: item.settlement_no,
          date: moment(item?.created_at).format('DD-MM-YYYY HH:mm') ?? '',
          adminActionDate: moment(item.admin_action_datetime).format('DD-MM-YYYY HH:mm'),
          amount: item.total_amount,
          status: item.status,
          erpNextSyncStatus: item.erp_sync_status,
          driverUsername: item.driver_username
        }
      })
    }
  }
}
