
import { defineComponent } from 'vue'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'

export default defineComponent({
  name: 'DarkstoreSettingsInput',
  components: { BaseIcon },
  props: {
    modelValue: {
      type: [Number, String],
      default: 0
    },
    lockValue: {
      type: [Boolean],
      default: false
    },
    step: {
      type: [String, Number],
      default: '0.5'
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      localValue: null as any,
      disabled: false
    }
  },
  methods: {
    clockHandler () {
      this.disabled = !this.disabled
      this.$emit('update:lockValue', this.disabled)
    }
  },
  mounted () {
    this.localValue = this.modelValue
  },
  watch: {
    localValue (newValue) {
      this.$emit('update:modelValue', newValue)
    },
    modelValue (newVal) {
      this.localValue = newVal
    }
  }
})
