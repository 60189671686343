
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BulkOrderSelectButton',
  emits: ['upload'],
  data () {
    return {
      showBulkSelect: false
    }
  },
  methods: {
    onUpload () {
      this.showBulkSelect = false
      this.$emit('upload')
    },
    onDownload (e: any) {
      e.stopPropagation()
      const button = document.getElementById('bulk-order-template-export')
      if (button) {
        setTimeout(() => {
          button.click()
          this.showBulkSelect = false
        }, 200)
      }
    }
  }
})
