import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { VehiclesState } from '@/store/modules/vehicles/types'

const state: VehiclesState = {
  vehicleList: [],
  availableVehicleList: [],
  vehicleTypes: [],
  pagination: null,
  loading: false,
  vehicleModels: [],
  vehicleModelLoading: false,
  vehiclesMake: [],
  vehiclesMakeLoading: false
}

export const vehicles: Module<VehiclesState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
