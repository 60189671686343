import { Method as apiMethod } from 'axios'

export const authEndpoints = {
  me: {
    method: 'get',
    url: '/me'
  },
  login: {
    method: 'post' as apiMethod,
    url: '/auth/login'
  },
  refresh: {
    method: 'post' as apiMethod,
    url: '/auth/refresh_token'
  },
  logout: {
    method: 'post',
    url: '/auth/logout'
  },
  forgot: {
    method: 'post',
    url: '/users/reset_password'
  },
  reset: {
    method: 'post',
    url: '/users/reset_password/check'
  },
  roles: {
    method: 'get' as apiMethod,
    url: '/roles/page'
  },
  firebase: {
    method: 'post' as apiMethod,
    url: '/users/fcm_token/'
  }
}
