<template>
  <div class="border bg-gray-50 px-20 pt-4 rounded mx-12 overflow-hidden">
    <div class="font-bold text-lg pb-8 text-gray-600">{{ $t('reports.darkStoreOrderPeakHours.noChartData') }}</div>
    <div class="flex gap-12 justify-center">
      <div
        v-for="i in 5"
        :key="i"
        class="flex gap-3 items-end"
      >
        <div class="w-8 bg-gray-200 rounded-t-2xl" style="height: 180px"></div>
        <div class="w-8 bg-gray-200 rounded-t-2xl" style="height: 90px"></div>
        <div class="w-8 bg-gray-200 rounded-t-2xl" style="height: 120px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'NoChartDataPlaceholder'
})
</script>
