import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { ProductivityDayInterface } from '@/shared/interfaces/reports/productivity-day.interface'
import moment from 'moment'

export class ReportProductivityDayModel extends CommonModel {
  public data: ProductivityDayInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): ProductivityDayInterface {
    return {
      date: moment({ year: data.Y, month: data.M - 1, day: data.D }).format('DD.MM.yyyy'),
      driverId: data.driver_id,
      driverFullName: data.driver_full_name,
      ordersCount: data.orders_count,
      orderStatus: data.order_status,
      orderStatusId: data.order_status_id,
      username: data.username,
      trackingId: data?.tracking_id ?? undefined
    }
  }
}
