import { RouteRecordRaw } from 'vue-router'

const profileRoutes: Array<RouteRecordRaw> = [
  {
    path: 'current-status',
    name: 'CurrentStatus',
    component: () => import('./components/CurrentStatus.vue')
  }, {
    path: 'order-history',
    name: 'OrderHistory',
    component: () => import('./components/OrderHistory.vue')
  }, {
    path: 'cash-settlement',
    name: 'CashSettlement',
    component: () => import('./components/CashSettlement.vue')
  },
  {
    path: 'assigned-orders',
    name: 'AssignedOrders',
    component: () => import('./components/AssignedOrders.vue')
  }
]

export default profileRoutes
