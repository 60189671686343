import { MutationTree } from 'vuex'
import { WorkShiftsState } from '@/store/modules/work-shifts/types'

export enum WorkShiftMutations {
  INSERT_WORK_SHIFTS= 'INSERT_WORK_SHIFTS',
  INSERT_USER_REQUESTS = 'INSERT_USER_REQUESTS',
  INSERT_USER_EXPORT_REQUESTS = 'INSERT_USER_EXPORT_REQUESTS',
  TRIGGER_LOADING = 'TRIGGER_LOADING',
  TRIGGER_REQUEST_LOADING = 'TRIGGER_REQUEST_LOADING'
}

export const mutations: MutationTree<WorkShiftsState> = {
  [WorkShiftMutations.INSERT_WORK_SHIFTS] (state, payload) {
    state.workShifts = payload.data
    state.pagination = payload.pagination
  },
  [WorkShiftMutations.INSERT_USER_REQUESTS] (state, payload) {
    state.userRequests = payload.data
    state.pagination = payload.pagination
  },
  [WorkShiftMutations.INSERT_USER_EXPORT_REQUESTS] (state, payload) {
    state.userExportRequests = payload.data
  },
  [WorkShiftMutations.TRIGGER_LOADING] (state, bool: boolean) {
    state.loading = bool
  },
  [WorkShiftMutations.TRIGGER_REQUEST_LOADING] (state, bool: boolean) {
    state.requestLoading = bool
  }
}
