import { Method as apiMethod } from 'axios'

export const clientEndpoints = {
  all: {
    method: 'get' as apiMethod,
    url: '/clients/page'
  },
  create: {
    method: 'post' as apiMethod,
    url: '/clients/create'
  },
  update (clientId: number) {
    return {
      method: 'put' as apiMethod,
      url: `/clients/${clientId}`
    }
  },
  delete (clientId: number) {
    return {
      method: 'delete' as apiMethod,
      url: `/clients/${clientId}`
    }
  },
  orders: {
    method: 'get' as apiMethod,
    url: '/clients/order/page'
  },
  createBulkOrder: {
    method: 'post' as apiMethod,
    url: '/clients/order/bulk'
  },
  createOrder: {
    method: 'post' as apiMethod,
    url: '/clients/order'
  }
}
