import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { CacheSettlementsState } from '@/store/modules/cache-settlements/types'

export const getters: GetterTree<CacheSettlementsState, RootState> = {
  getCacheSettlements (state): any[] {
    return state.cacheSettlements
  },
  getCacheSettlementOrders (state) {
    return state.cacheSettlementOrders
  },
  getPagination (state): any {
    return state.pagination
  },
  getLoading (state): boolean {
    return state.loading
  }
}
