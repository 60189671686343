import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "px-6.5 py-11" }
const _hoisted_2 = { class: "flex justify-between mb-7" }
const _hoisted_3 = { class: "flex justify-start" }
const _hoisted_4 = { class: "flex justify-between" }
const _hoisted_5 = { class: "relative h-42 w-288 me-3" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "absolute end-2 top-2.5" }
const _hoisted_8 = { class: "flex items-center justify-start me-8 ms-8" }
const _hoisted_9 = { class: "font-normal text-base pe-4" }
const _hoisted_10 = {
  key: 0,
  class: "flex justify-center mt-2"
}
const _hoisted_11 = {
  key: 1,
  class: "text-center text-darkText text-lg px-4 py-8 font-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_DateRange = _resolveComponent("DateRange")!
  const _component_OrderDetails = _resolveComponent("OrderDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: _ctx.$t('placeholders.orderHistorySearch'),
              class: "absolute py-3.5 ps-3.5 pe-9 top-0 start-0 w-full h-full block appearance-none bg-transparent",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event))
            }, null, 8, _hoisted_6), [
              [_vModelText, _ctx.searchValue]
            ]),
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_BaseIcon, { name: "search" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('drivers.date')), 1),
          _createVNode(_component_DateRange, {
            modelValue: _ctx.date,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.date) = $event))
          }, null, 8, ["modelValue"])
        ])
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order) => {
      return (_openBlock(), _createBlock(_component_OrderDetails, {
        key: order.id,
        collapsible: true,
        order: order
      }, null, 8, ["order"]))
    }), 128)),
    (_ctx.orders.length && _ctx.orders.length < _ctx.pagination.totalItems)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("button", {
            class: "secondary p-3 w-40",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadMore()))
          }, "Show More")
        ]))
      : _createCommentVNode("", true),
    (!_ctx.orders.length)
      ? (_openBlock(), _createElementBlock("p", _hoisted_11, "No items to show"))
      : _createCommentVNode("", true)
  ]))
}