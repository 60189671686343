import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { DriverPerformanceInterface } from '@/shared/interfaces/reports/driver-performance.interface'
import { convertToKM, formatToFix } from '@/shared/utils/utils'

export class DriverPerformanceModel extends CommonModel {
  public data: DriverPerformanceInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): DriverPerformanceInterface {
    return {
      driverFullName: data.driver_full_name,
      driverId: data.driver_id,
      coveredDistance: convertToKM(data.covered_distance),
      avgSpeed: formatToFix(data.avg_speed),
      totalStops: data.total_stops
    }
  }
}
