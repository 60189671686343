
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DriverBalance',
  props: {
    balance: {
      type: [String, Number],
      default: 0
    }
  }
})
