
import { defineComponent, defineAsyncComponent } from 'vue'
import { Order } from '@/shared/interfaces/order.interface'
import { Driver } from '@/shared/interfaces/driver.interface'
import { DriverActions } from '@/store/modules/drivers/actions'
import { OrderActions } from '@/store/modules/orders/actions'
import { ReturnedOrder } from '@/shared/interfaces/returned-order.interface'

export default defineComponent({
  name: 'ReturnedOrderModal',
  components: {
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    OrdersMap: defineAsyncComponent(() => import('@/views/account/orders/components/OrdersMap.vue'))
  },
  data () {
    return {
      searchValue: '',
      selectedDriver: null as any
    }
  },
  props: {
    orderData: {
      type: Object as () => ReturnedOrder,
      required: true
    }
  },
  computed: {
    drivers (): Driver[] {
      return this.$store.getters['drivers/getDriversAsDictation']
    },
    distance (): number {
      let outwardDistance = 0
      let inwardDuration = 0
      if (this.orderData.tripDetailsFromDarkstoreOutward) {
        this.orderData.tripDetailsFromDarkstoreOutward.forEach(el => {
          outwardDistance += el.travelDistance
        })
      }
      if (this.orderData.tripDetailsToDarkstoreInward) {
        inwardDuration = this.orderData.tripDetailsToDarkstoreInward.travelDistance
      }
      return (outwardDistance + inwardDuration) / 1000
    },
    direction () {
      return {
        from: this.orderData.driver ? this.orderData.driver.lastLocation : null,
        to: { latitude: this.orderData.originalOrder.destinationLatitude, longitude: this.orderData.originalOrder.destinationLongitude }
      }
    },
    tripDetails () {
      if (this.orderData.tripDetailsToDarkstoreInward && this.orderData.tripDetailsFromDarkstoreOutward) {
        return {
          toDarkstore: this.orderData.tripDetailsToDarkstoreInward,
          fromDarkstore: this.orderData.tripDetailsFromDarkstoreOutward,
          status: this.orderData.orderStatus.name,
          driverLocation: this.orderData.driver ? this.orderData.driver.lastLocation : null
        }
      } else {
        return null
      }
    }
  },
  mounted () {
    this.getDriversList()
  },
  setup (_, { emit }) {
    const closeModal = ($event: any, out = true) => {
      if (out && !$event.inner) {
        emit('close', false)
      } else $event.inner = true
    }
    return {
      closeModal
    }
  },
  methods: {
    getDriversList (pageNumber = 1, pageSize = 10000): void {
      const params = { pageNumber, pageSize, isActive: true, search: this.searchValue }
      this.$store.dispatch(`drivers/${DriverActions.FETCH_DRIVERS}`, params)
    },
    assignOrder () {
      this.$store.dispatch(`orders/${[OrderActions.ASSIGN_ORDER]}`, { orderId: this.orderData.id, driver: { driver_id: this.selectedDriver } }).then(() => {
        this.$emit('close', true)
      })
    },
    cancelOrder () {
      this.$store.dispatch(`orders/${[OrderActions.CANCEL_ORDER]}`, { orderId: this.orderData.id }).then(() => {
        this.$emit('close', true)
      })
    }
  },
  watch: {
    orderData (val: Order) {
      // if (val && val.driver) {
      //   this.selectedDriver = val.driver.id
      // }
    }
  }
})
