import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["px-2 py-1 rounded text-xs capitalize", [
    {'bg-gray-200 border border-gray-400 text-gray-800' : _ctx.data.status === 'requested'},
    {'bg-green-200 border border-green-500 text-green-800' : _ctx.data.status === 'approved'},
    {'bg-red-200 border border-red-400 text-red-800' : _ctx.data.status === 'rejected'}
  ]])
  }, _toDisplayString(_ctx.data.status), 3))
}