
import { defineComponent } from 'vue'
import LocaleSwitcher from '@/shared/components/LocaleSwitcher.vue'

export default defineComponent({
  name: 'Auth',
  components: {
    LocaleSwitcher
  },
  data () {
    return {
      showLogo: false
    }
  },
  mounted () {
    const animation = this.$refs.animation as any
    if (animation) {
      animation.addEventListener('animationend', () => {
        this.showLogo = true
      })
    }
  }
})
