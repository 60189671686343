<template>
  <div>
    <div
      role="button"
      class="rounded-lg bg-gray-200 h-6 w-12 flex items-center"
      :class="{'bg-green-500 justify-end' : checked}"
    >
      <div class="w-4 h-4 bg-white rounded mx-1 shadow-md" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderManualAssignmentToggle',
  props: {
    checked: {
      type: Boolean,
      default: false
    }
  }
}
</script>
