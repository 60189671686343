import { MutationTree } from 'vuex'
import { UsersState } from '@/store/modules/users/types'

export enum UsersMutations {
  INSERT_USERS = 'INSERT_USERS',
  UPDATE_USERS_ACTIVATED = 'UPDATE_USERS_ACTIVATED',
  TRIGGER_LOADING = 'TRIGGER_LOADING',
  EXPORT_USERS = 'EXPORT_USERS'
}

export const mutations: MutationTree<UsersState> = {
  [UsersMutations.INSERT_USERS] (state, payload) {
    state.userList = payload.data
    state.pagination = payload.pagination
  },
  [UsersMutations.TRIGGER_LOADING] (state, bool: boolean) {
    state.loading = bool
  },
  [UsersMutations.UPDATE_USERS_ACTIVATED] (state, payload) {
    state.userList = state.userList.map(user => {
      if (user.id === payload.userId) {
        user.activated = payload.isActivate
      }
      return user
    })
  },
  [UsersMutations.EXPORT_USERS] (state, payload) {
    state.exportUserList = payload.data
  }
}
