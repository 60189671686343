import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { DarkStoresState } from '@/store/modules/darkstores/types'

const state: DarkStoresState = {
  darkstores: [],
  pagination: null,
  loading: false
}

export const darkstores: Module<DarkStoresState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
