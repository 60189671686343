
import { defineComponent } from 'vue'
import { Order } from '@/shared/interfaces/order.interface'
import moment from 'moment'

export default defineComponent({
  name: 'OrderCard',
  props: {
    order: {
      type: Object as () => Order,
      required: true
    }
  },
  components: {
    // BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue'))
  },
  data () {
    return {
      moment,
      isExtended: false,
      distance: null as any
    }
  },
  watch: {
    order: {
      immediate: true,
      handler: function (val: Order): void {
        if (val) {
          let outwardDistance = 0
          let inwardDuration = 0
          if (val.tripDetailsFromDarkstoreOutward) {
            val.tripDetailsFromDarkstoreOutward.forEach(el => {
              outwardDistance += el.travelDistance
            })
          }
          if (val.tripDetailsToDarkstoreInward) {
            inwardDuration = val.tripDetailsToDarkstoreInward.travelDistance
          }
          this.distance = (outwardDistance + inwardDuration) / 1000
        }
      }
    }
  }
})
