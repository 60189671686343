import { Method as apiMethod } from 'axios'

export const zoneEndpoints = {
  list: {
    method: 'get' as apiMethod,
    url: '/zones/page'
  },
  create: {
    method: 'post' as apiMethod,
    url: '/zones/'
  },
  update: (zoneId:string) => {
    return {
      method: 'put' as apiMethod,
      url: `/zones/${zoneId}`
    }
  },
  get_zone_by_id: (zoneId:string) => {
    return {
      method: 'get' as apiMethod,
      url: `/zones/${zoneId}`
    }
  },
  delete_zone_by_id: (zoneId:string) => {
    return {
      method: 'delete' as apiMethod,
      url: `/zones/${zoneId}`
    }
  },
  assign_zone_to_darkstore: (zoneId:string) => {
    return {
      method: 'post' as apiMethod,
      url: `/zones/assign/${zoneId}`
    }
  },
  un_assign_zone_to_darkstore: (zoneId:string) => {
    return {
      method: 'post' as apiMethod,
      url: `/zones/un_assign/${zoneId}`
    }
  }
}
