<template>
  <div class="flex pt-4 pt-md-0 md:h-screen items-center px-4">
    <div class="w-550 m-auto">
      <div class="flex flex-col bg-white rounded-lg">
        <h1 class="font-bold text-xl md:text-3xl text-gray-600 text-start">{{ $t('auth.signin_header') }}</h1>
        <form v-on:submit.prevent="login" class="mt-8">

          <div class="relative h-42 mb-7">
            <input
              v-bind:class="{error: v$.username.$error && username}"
              type="text"
              placeholder=""
              class="h-42 absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent" v-model="username" @blur="v$.username.$touch" :disabled="isLoading"/>
            <label v-bind:class="{error: v$.username.$error && username}" class="absolute text-placeholder top-2 start-4 text-base bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text">{{$t('auth.username')}}</label>
<!--            <span v-if="v$.username.$error && username" class="error-message flex items-center absolute start-0 -bottom-6 text-10px text-textError">-->
<!--              <BaseIcon class="me-1" name="alert"/>-->
<!--              {{ $t('validation.required') }}-->
<!--            </span>-->
          </div>

          <div class="relative h-42">
            <input v-model="password"  @blur="v$.password.$touch" :disabled="isLoading" :type="this.showPassword ? 'text' : 'password'" placeholder=" " class="h-42 absolute py-3.5 ps-3.5 pe-9 top-0 left-0 w-full h-full block appearance-none bg-transparent"/>
            <label class="absolute text-placeholder top-2 start-4 text-base bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text">{{$t('auth.password')}}</label>
            <BaseIcon v-if="showPassword" @click="togglePassword" class="input-icon absolute end-3.5 top-3 cursor-pointer z-30" name="eye"/>
            <BaseIcon v-if="!showPassword" @click="togglePassword" class="input-icon absolute end-3.5 top-3 cursor-pointer z-30" name="eyeoff"/>
<!--            <span v-if="v$.password.$error && password" class="error-message flex items-center absolute start-0 -bottom-6 text-10px text-textError">-->
<!--              <BaseIcon class="me-1" name="alert"/>-->
<!--              {{ v$.password?.minLength.$invalid ? $t('validation.min_length', {length: 8}) : $t('validation.required') }}-->
<!--            </span>-->
          </div>

          <div class="flex sm:flex-row sm:justify-end justify-end mt-2">
            <router-link to="/auth/forgot" class="text-md inline-block text-blue-500 font-bold hover:underline">{{ $t('auth.forget_pass') }}</router-link>
          </div>
          <div class="flex justify-center mt-12 mb-12">
            <button type="submit" class="primary px-20 rounded-full py-2 text-base" :disabled="v$.$invalid || isLoading">
              {{ $t('auth.signin') }}
            </button>
          </div>
          <Alert v-if="isError" type="danger" title="error"><p>{{ $t('auth.signin_error') }}</p></Alert>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, minLength, email } from '@/customValidators'
import Alert from '@/shared/components/Alert.vue'
import BaseIcon from '../../shared/components/base-icon/BaseIcon'

export default defineComponent({
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      username: '',
      password: '',
      remember: false,
      isError: false,
      isLoading: false,
      showPassword: false
    }
  },
  validations: function () {
    return {
      username: { required }, // { required, email },
      password: { required, minLength: minLength(8) } // { required, minLength: minLength(8) }
    }
  },
  components: {
    BaseIcon,
    Alert
  },
  methods: {
    login: async function () {
      // check form validation
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.isLoading = true
      await this.$store.dispatch('login', { username: this.username, password: this.password }).then(() => {
        this.reset()
        this.$router.push(this.$route.query.redirect || '/')
      }).catch(err => {
        this.isError = true
        throw new Error(err)
      }).finally(() => {
        this.isLoading = false
      })
    },
    togglePassword () {
      this.showPassword = !this.showPassword
    },
    reset () {
      this.isError = false
      this.v$.$reset()
      this.username = ''
      this.password = ''
    }
  }
})
</script>
<style lang="scss" scoped>
input[type='text'], input[type='password'] {
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-radius: 0px;
}
</style>
