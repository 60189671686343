
import { defineComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@/customValidators'
import { DriverActions } from '@/store/modules/drivers/actions'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import { WorkShiftActions } from '@/store/modules/work-shifts/actions'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'
import Multiselect from '@vueform/multiselect'

export default defineComponent({
  components: { Multiselect },
  props: {
    vehicle: {
      type: Object,
      required: true
    },
    driverIds: {
      type: Array,
      required: true
    }
  },
  emits: ['close', 'fetchVehicles'],
  setup: () => ({ v$: useVuelidate() }),
  mounted () {
    this.$store.dispatch(`darkstores/${[DarkStoresActions.FETCH_DARKSTORES]}`, { pageNumber: null, pageSize: 200 })
  },
  data () {
    return {
      driver: null as any,
      current: 1,
      searchValue: null as any,
      shift: null as any,
      darkStore: null as any
    }
  },
  validations: function () {
    return {
      driver: { required }
    }
  },
  methods: {
    assignDriver: async function (): Promise<any> {
      const payload = {
        vehicleId: this.vehicle.id,
        driverId: this.driver.id
      }
      await this.$store.dispatch(`drivers/${[DriverActions.ASSIGN_VEHICLE]}`, payload).then(() => {
        this.$emit('fetchVehicles')
        this.$emit('close')
      }).catch(() => {
        // const reference = this.$refs['driver' + driverId] as any
        // reference.checked = false
      })
    },
    unassignDiver: async function (driverId: number): Promise<any> {
      const payload = {
        vehicleId: this.vehicle.id,
        driverId: driverId
      }
      await this.$store.dispatch(`drivers/${[DriverActions.UNASSIGN_VEHICLE]}`, payload).then(() => {
        this.$emit('fetchVehicles')
      }).catch(() => {
        // const reference = this.$refs['driver' + driverId] as any
        // reference.checked = true
      })
    },
    // checkDriver (driver: any, e: any): void {
    //   if (e.target.checked) {
    //     this.assignDriver(driver.id)
    //   } else {
    //     this.unassignDiver(driver.id)
    //   }
    // },
    getDrivers (): void {
      // const shiftIds = this.vehicle.driverInfo.map((el: any) => {
      //   return el.shift.id
      // })
      // this.$store.dispatch(`drivers/${DriverActions.FETCH_DRIVERS}`, { pageNumber: 1, pageSize: 100, search: this.searchValue, notInShiftIds: shiftIds })
      this.$store.dispatch(`drivers/${DriverActions.FETCH_DRIVERS}`, { pageNumber: 1, pageSize: 100, shiftId: this.shift ? this.shift?.id : null, hasVehicle: 0 })
    }
  },
  computed: {
    drivers (): any[] {
      return this.$store.getters['drivers/getDriversAsDictation']
    },
    pagination (): PaginationInterface {
      return this.$store.getters['drivers/getPagination']
    },
    shifts (): any[] {
      return this.$store.getters['workShifts/getWorkShiftsForSelect']
    },
    darkstores (): any[] {
      return this.$store.getters['darkstores/getDarkStoresAsDictionary']
    }
  },
  watch: {
    searchValue (val) {
      this.getDrivers()
    },
    darkStore (val) {
      this.shift = null
      this.driver = null
      if (val) {
        this.$store.dispatch(`workShifts/${[WorkShiftActions.FETCH_WORK_SHIFTS]}`, { darkstoreId: val.id })
      }
    },
    shift (val) {
      this.driver = null
      if (val) {
        this.getDrivers()
      }
    }
  }
})
