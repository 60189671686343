import { MutationTree } from 'vuex'
import { PredefinedReasonsState } from '@/store/modules/predefined-reasons/types'

export enum PredefinedReasonMutations {
  SET_PREDEFINED_REASONS= 'SET_PREDEFINED_REASONS',
  REQUEST_PREDEFINED_REASONS= 'REQUEST_PREDEFINED_REASONS',
}

export const mutations: MutationTree<PredefinedReasonsState> = {
  [PredefinedReasonMutations.SET_PREDEFINED_REASONS] (state, payload) {
    state.predefinedReasons = payload
  },
  [PredefinedReasonMutations.REQUEST_PREDEFINED_REASONS] (state, status = false) {
    state.loading = status
  }
}
