
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LTSSummaryView',
  props: {
    summary: {
      type: Object,
      required: true
    }
  },
  computed: {
    totalDistance () {
      if (this.summary && this.summary.total_distance) {
        return this.summary.total_distance >= 1000
          ? Number(this.summary.total_distance / 1000).toFixed(1) + ' km'
          : Number(this.summary.total_distance).toFixed(1) + ' m'
      }
      return '-'
    }
  },
  methods: {
    formatTime (seconds: number) {
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      seconds = seconds % 60

      if (hours > 0) {
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
      } else {
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
      }
    }
  }
})
