import { Method as apiMethod } from 'axios'

export const manualChangesEndpoints = {
  list: {
    method: 'get' as apiMethod,
    url: '/manual_changes_log/'
  },
  types: {
    method: 'get' as apiMethod,
    url: '/manual_changes_log/entity_types'
  }
}
