import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { DriverWalletInterface } from '@/shared/interfaces/reports/driver-wallet.interface'
import moment from 'moment'

export class DriverWalletModel extends CommonModel {
  public data: DriverWalletInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): DriverWalletInterface {
    return {
      driverFullName: data.driver_full_name,
      driverId: data.driver_id,
      financialLimitExceed: data.financial_limit_exceed,
      pendingSettlementOrders: data.pending_settlement_orders,
      pendingSettlementOrdersAmount: data.pending_settlement_orders_amount,
      settledOrders: data.settled_orders,
      settledOrdersAmount: data.settled_orders_amount,
      totalBalance: data.total_balance,
      unpaidOrders: data.unpaid_orders,
      unpaidOrdersAmount: data.unpaid_orders_amount
    }
  }
}
