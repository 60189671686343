import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { DriverRevenueInterface } from '@/shared/interfaces/reports/driver-revenue.interface'
import moment from 'moment'

export class DriverRevenueModel extends CommonModel {
  public data: DriverRevenueInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): DriverRevenueInterface {
    return {
      driverFullName: data?.driver_full_name,
      username: data?.username,
      orderId: data.order_id,
      orderStatus: data.order_status,
      orderTotal: data.order_total,
      orderCreatedAt: moment(data.order_created_at).format('YYYY-MM-DD HH:mm'),
      orderDeliveredAt: data.order_delivered_at ? moment(data.order_delivered_at).format('YYYY-MM-DD HH:mm') : '',
      customerPhoneNumber: data.customer_phone_number,
      addressAddressLine1: data.customer_address_details.address_address_line1,
      addressBuilding: data.customer_address_details.address_building,
      addressDistrict: data.customer_address_details.address_district,
      addressFlat: data.customer_address_details.address_flat,
      addressFloor: data.customer_address_details.address_floor,
      addressNearestLandmark: data.customer_address_details.address_nearest_landmark,
      addressNeighbourhood: data.customer_address_details.address_neighbourhood,
      addressNickname: data.customer_address_details.address_nickname,
      addressPhone: data.customer_address_details.address_phone,
      trackingId: data.tracking_id,
      settlementDate: data.settlement_date,
      settlementNo: data.settlement_no,
      settlementStatus: data.settlement_status,
      adminUsername: data?.admin_username ?? ''
    }
  }
}
