
import { defineComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, minLength, sameAs, maxLength } from '@/customValidators'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
import InputError from '@/shared/components/InputError.vue'
import moment from 'moment'
import isValidUsername from '@/shared/utils/usernameRegex'

export default defineComponent({
  name: 'UserInvitation',
  components: {
    InputError,
    BaseIcon
  },
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      step: 1,
      name: '',
      surname: '',
      username: '',
      birthdate: '',
      mobile: '',
      password: '',
      confirmPassword: '',
      street: '',
      driverLicenseNo: '',
      city: 'Bagdad',
      isError: false,
      isLoading: false,
      showPassword: false,
      showConfPassword: false,
      boxNumber: '',
      email: '',
      code: '',
      isDriver: false,

      district: '',
      building: '',
      flat: '',
      floor: '',
      landmark: '',
      neighborhood: ''
    }
  },
  mounted () {
    if (this.$route.query?.redirect_url) {
      const url = this.$route.fullPath.toString()
      this.isDriver = this.$route.query.user_role === 'driver'
      this.email = url.split('?')[2].split('&')[0].split('=')[1]
    }
    if (this.$route.query?.code) {
      this.code = this.$route.query.code.toString()
    }
  },
  validations: function () {
    return {
      name: { required },
      surname: { required },
      birthdate: { required },
      mobile: { required, minLength: minLength(13) },
      username: { required },
      street: { required },
      // driverLicenseNo: { },
      // city: { required },
      password: {
        required,
        maxLength: maxLength(10),
        goodPassword: (password: string) => {
          return password.length >= 8 &&
            /[0-9]/.test(password) &&
            /[a-z]/.test(password) &&
            /[A-Z]/.test(password) &&
            /[#?!@$%^&*-]/.test(password)
        }
      },
      confirmPassword: { required, sameAs: sameAs(this.password) }
    }
  },
  watch: {
    username (val) {
      if (!isValidUsername(val)) {
        const strArry = this.username.split('')
        strArry.pop()
        this.username = strArry.join('')
      }
    }
  },
  methods: {
    submitData () {
      const body = {
        first_name: this.name,
        last_name: this.surname,
        username: this.username,
        birthdate: moment(this.birthdate).format('YYYY-MM-DDThh:mm:ss'),
        user_phone_number: this.mobile,
        password: this.password,
        street: this.street,
        driver_license_no: this.driverLicenseNo,
        city_id: 1,
        user_email: this.email,
        box_number: this.boxNumber,
        address_building: this.building,
        address_district: this.district,
        address_flat: this.flat,
        address_floor: this.floor,
        address_nearest_landmark: this.landmark,
        address_neighborhood: this.neighborhood
      }
      const params = {
        user_email: this.email,
        code: this.code
      }
      this.$store.dispatch('storeInvitation', { body, params }).then(() => {
        this.$router.push('/')
      })
    },
    togglePassword () {
      this.showPassword = !this.showPassword
    },
    toggleConfPassword () {
      this.showConfPassword = !this.showConfPassword
    }
  }
})
