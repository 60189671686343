<template>
  <div v-for="item in [1,2,3,4,5]" :key="'panelGhost' + item" class="order-ghost w-full h-5 rounded rounded-sm mb-2"></div>
</template>
<style lang="scss" scoped>

@import "../../../styles/_ghost.scss";
.order-ghost{
  @include animate-ghost-line-3
}
</style>
