import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { CityInterface } from '@/shared/interfaces/city.interface'

export class CitiesModel extends CommonModel {
  public data: CityInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): CityInterface {
    return {
      id: data.id,
      name: data.name,
      countryName: data.country_name
    }
  }
}
