
import { defineComponent } from 'vue'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'

export default defineComponent({
  components: { BaseIcon },
  data () {
    return {
      order: null as any
    }
  },
  props: {
    sortField: {
      type: Object as () => any,
      required: true
    }
  }
})
