import { MutationTree } from 'vuex'
import { NotificationsState } from '@/store/modules/notifications/types'

export enum NotificationsMutations {
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  TRIGGER_LOADING = 'TRIGGER_LOADING'
}

export const mutations: MutationTree<NotificationsState> = {
  [NotificationsMutations.SET_NOTIFICATIONS] (state, payload) {
    state.notifications = payload.data
    state.pagination = payload.pagination
  },
  [NotificationsMutations.TRIGGER_LOADING] (state, bool: boolean) {
    state.loading = bool
  }
}
