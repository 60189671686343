
import { defineComponent, defineAsyncComponent } from 'vue'
import router from '@/router'
import { VehiclesActions } from '@/store/modules/vehicles/actions'

export default defineComponent({
  components: {
    SearchInput: defineAsyncComponent(() => import('@/shared/components/SearchInput.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    DynamicTable: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTable.vue'))
  },
  data () {
    return {
      showFilterModal: false,
      tableFields: [
        { id: 'driverFullName', name: 'Driver', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'driver_full_name' },
        { id: 'coveredDistance', name: 'Covered Distance', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'order_status' },
        { id: 'avgSpeed', name: 'Average Speed', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'orders_count' },
        { id: 'totalStops', name: 'Total Stops', width: '20%', sortable: false, currentSort: false, sort: null }
      ],
      filters: [
        { name: this.$t('filters.createdAt'), model: 'locationDate', type: 'date', calendarType: 'range' }
      ],
      selectedReport: this.reportType,
      searchValue: null as any
    }
  },
  methods: {
    onExpand (event: any): void {
      if (event === 'vehicleTypes') {
        this.$store.dispatch(`vehicles/${[VehiclesActions.FETCH_VEHICLE_TYPES]}`)
      }
    },
    menuEvent (e: any): void {
      console.log(e)
    },
    clickSort (field: any): void {
      this.tableFields = this.tableFields?.map((el: any) => {
        if (el.id === field.id) {
          el.currentSort = true
          if (el.sort === null) {
            el.sort = 'asc'
          } else if (el.sort === 'asc') {
            el.sort = 'desc'
          } else if (el.sort === 'desc') {
            el.sort = null
          }
        } else {
          el.currentSort = false
          el.sort = null
        }
        return el
      })
      router.push({ query: { ...this.$route.query, orderBy: field.sortName, sort: field.sort } })
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (val: any) {
        if (val.query.orderBy) {
          this.tableFields = this.tableFields.map((el: any) => {
            if (el.id === val.query.orderBy) {
              el.currentSort = true
              el.sort = val.query.sort
            }
            return el
          })
        }
      }
    },
    selectedReport (val) {
      if (val) {
        router.push({ query: { reportName: val.name } })
      }
    },
    searchValue () {
      const queries = this.$route.query
      queries.pageNumber = '1'
      router.push({ query: { ...queries, username: this.searchValue } })
    }
  },
  props: {
    reportType: {
      type: String
    }
  },
  computed: {
    reports () {
      return this.$store.getters['reports/getPerformanceReports']
    },
    exportReports () {
      return this.$store.getters['reports/getPerformanceExportReports']
    },
    pagination () {
      return this.$store.getters['reports/getPerformancePagination']
    },
    loading () {
      return this.$store.getters['reports/getLoading']
    }
  }
})
