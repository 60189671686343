<template>
  <div>
    <input type="text" :placeholder="placeholder ? placeholder : ''"
           class="h-42 absolute py-3.5 ps-3.5 pe-9 top-0 start-0 w-full h-full block appearance-none bg-transparent"
           :value="searchValue" @input.stop="onSearchTextChange($event.target.value)"  />
    <span class="absolute end-2 top-2.5">
      <BaseIcon name="search"/>
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { debounce } from 'lodash'
import BaseIcon from '@/shared/components/base-icon/BaseIcon'
export default defineComponent({
  name: 'SearchInput',
  props: ['modelValue', 'placeholder'],
  emits: ['update:modelValue'],
  components: {
    BaseIcon
  },
  data () {
    return {
      searchValue: ''
    }
  },
  methods: {
    onSearchTextChange: debounce(function (searchTerm) {
      this.searchValue = searchTerm
      this.$emit('update:modelValue', this.searchValue)
    }, 500)
  },
  mounted () {
    this.searchValue = this.modelValue
  }
})
</script>

<style scoped>

</style>
