import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { LocationState } from '@/store/modules/locations/types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

const state: LocationState = {
  locations: []
}

export const locations: Module<LocationState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
