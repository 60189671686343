import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { DarkStoreInterface } from '@/shared/interfaces/darkstore.interface'

export class DarkstoreModel extends CommonModel {
  public data: DarkStoreInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): DarkStoreInterface {
    return {
      closeTime: data.close_time,
      id: data.id,
      name: data.name,
      latitude: data.latitude,
      longitude: data.longitude,
      openTime: data.open_time,
      city: {
        id: data.city.id,
        name: data.city.name,
        countryName: data.city.country_name
      },
      darkstoreSetting: data?.darkstore_setting.map((s:any) => {
        return {
          ...s,
          max_threshold_waiting_time: s.max_threshold_waiting_time / 60,
          interval_time: s.interval_time / 60
        }
      })
    }
  }
}
