import { RouteRecordRaw } from 'vue-router'

const routesAuth: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'Login',
    component: () => import('./Login.vue')
  }, {
    path: 'forgot',
    name: 'Forgot',
    component: () => import('./ForgotPassword.vue')
  }, {
    path: 'reset',
    name: 'Reset',
    component: () => import('./ResetPassword.vue')
  }, {
    path: 'verification',
    name: 'Verification',
    component: () => import('./Verification.vue')
  }, {
    path: 'logout',
    name: 'Logout',
    component: () => import('./Login.vue')
  }
]

export default routesAuth
