import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "orders-map relative" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.mapRedirection)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('redirectToGoogleMap'))),
          class: "absolute start-1 top-1 bg-white p-2 z-50 secondary"
        }, "Open Google Maps"))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      id: _ctx.id,
      class: "w-full h-full overflow-auto"
    }, null, 8, _hoisted_2)
  ]))
}