import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-8" }
const _hoisted_2 = { class: "mt-4" }
const _hoisted_3 = { class: "mt-4" }
const _hoisted_4 = { class: "mt-4" }
const _hoisted_5 = { class: "flex flex-wrap items-center justify-end w-full mt-8" }
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Multiselect, {
        modelValue: _ctx.darkStore,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.darkStore) = $event)),
        options: _ctx.darkstores,
        label: "value",
        valueProp: "id",
        object: "true",
        noOptionsText: _ctx.$t('validation.noItemsToShow'),
        placeholder: _ctx.$t('placeholders.darkstore')
      }, null, 8, ["modelValue", "options", "noOptionsText", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Multiselect, {
        modelValue: _ctx.shift,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.shift) = $event)),
        options: _ctx.shifts,
        label: "value",
        valueProp: "id",
        object: "true",
        noOptionsText: _ctx.$t('validation.noItemsToShow'),
        placeholder: _ctx.$t('placeholders.shift')
      }, null, 8, ["modelValue", "options", "noOptionsText", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Multiselect, {
        modelValue: _ctx.driver,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.driver) = $event)),
        options: _ctx.drivers,
        label: "value",
        valueProp: "id",
        object: "true",
        noOptionsText: _ctx.$t('validation.noItemsToShow'),
        placeholder: _ctx.$t('placeholders.driver')
      }, null, 8, ["modelValue", "options", "noOptionsText", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close'))),
        class: "secondary m-1 w-32 px-4 py-2"
      }, _toDisplayString(_ctx.$t('buttons.close')), 1),
      _createElementVNode("button", {
        type: "submit",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.assignDriver())),
        class: "primary m-1 w-32 px-4 py-2",
        disabled: _ctx.v$.$invalid
      }, _toDisplayString(_ctx.$t('buttons.addDriver')), 9, _hoisted_6)
    ])
  ]))
}