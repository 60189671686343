import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { ClientInterface } from '@/shared/interfaces/clients.interface'

export class ClientsModel extends CommonModel {
  public data: ClientInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): ClientInterface {
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      erpnextMerchant: data.erpnext_merchant,
      status: data.status,
      secretKey: data.secret_key,
      darkstore: {
        name: data?.darkstore?.name ?? '',
        id: data?.darkstore?.id ?? 0
      }
    }
  }
}
