import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { ErpNextState } from '@/store/modules/erp-next/types'

const state: ErpNextState = {
  merchants: [],
  pagination: null,
  loading: false
}

export const erpNext: Module<ErpNextState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
