import { Method as apiMethod } from 'axios'

export const vehicleEndpoints = {
  list: {
    method: 'get' as apiMethod,
    url: '/vehicles/page'
  },
  byId: {
    method: 'get' as apiMethod,
    url: '/vehicles/'
  },
  add: {
    method: 'post' as apiMethod,
    url: '/vehicles/'
  },
  update: {
    method: 'put' as apiMethod,
    url: '/vehicles/'
  },
  types: {
    method: 'get' as apiMethod,
    url: '/vehicles/vehicle_types/all'
  },
  unassignVehicle: {
    method: 'post' as apiMethod,
    url: '/vehicles/unassign_vehicle/'
  },
  activateDeactivate: {
    method: 'put' as apiMethod,
    url: '/vehicles/activate_deactivate/'
  },
  delete: {
    method: 'delete' as apiMethod,
    url: '/vehicles/'
  },
  models: {
    method: 'get' as apiMethod,
    url: '/vehicles/models'
  }
}
