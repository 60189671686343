import Vuex, { StoreOptions } from 'vuex'
import { VueCookieNext } from 'vue-cookie-next'
import { apiEndpoints } from '@/shared/endpoints/api'
import { User } from '@/shared/interfaces/user.interface'
import { RootState } from '@/store/types'
import { drivers } from '@/store/modules/drivers'
import { orders } from '@/store/modules/orders'
import { users } from '@/store/modules/users'
import { vehicles } from '@/store/modules/vehicles'
import { workShifts } from '@/store/modules/work-shifts'
import { darkstores } from '@/store/modules/darkstores'
import { reports } from '@/store/modules/reports'
import { zones } from '@/store/modules/zones'
import jwtDecode from 'jwt-decode'
import { useToast } from 'vue-toastification'
import axiosInstance from '@/shared/utils/axiosInstance'
import { manualChanges } from '@/store/modules/manual-changes'
import { notifications } from '@/store/modules/notifications'
import { predefinedReasons } from '@/store/modules/predefined-reasons'
import { cacheSettlements } from '@/store/modules/cache-settlements'
import { locations } from '@/store/modules/locations'
import { settings } from '@/store/modules/settings'
import { cities } from '@/store/modules/cities'
import { erpNext } from '@/store/modules/erp-next'
import { clients } from '@/store/modules/clients'
import VuexPersistence from 'vuex-persist'
const config = window?._runtimeConfig ?? {}

axiosInstance.defaults.baseURL = config.VUE_APP_API_HOST + config.VUE_APP_API_BASE
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json'

axiosInstance.interceptors.request.use(async function (config) {
  const token = VueCookieNext.getCookie('tasleem_token')
  config.headers = {
    ...config.headers,
    'Accept-Language': 'en'
  }
  if (token && token.length > 0) {
    config.headers = {
      ...config.headers,
      Authorization: 'Bearer ' + token
    }
  }
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

const vueLocalStorage = new VuexPersistence({
  key: 'TASLEEM_VUEX_STATE',
  storage: localStorage || window.localStorage,
  reducer: (state:any) => ({
    drivers: {
      driversMarkers: state.drivers.driversMarkers
    }
  })
})

const store: StoreOptions<RootState> = {
  state: {
    lang: '',
    loggedUser: null,
    roles: [],
    userRole: null,
    loading: false,
    userCredentials: null,
    authorizedUser: null,
    refresfRequested: false,
    newOrderCreated: false
    // failedRequests: []
  },
  mutations: {
    setLang: (state, newLang) => { state.lang = newLang },
    setLoggedUser: (state, loggedUser: User) => { state.loggedUser = loggedUser },
    setRoles: (state, payload) => { state.roles = payload },
    setUserRole: (state, payload) => { state.userRole = payload },
    triggerLoading: (state, bool: boolean) => { state.loading = bool },
    setUserCredentials: (state, payload) => {
      state.userCredentials = payload
    },
    setAuthorizedUser: (state, payload) => {
      state.authorizedUser = payload
    }
  },
  getters: {
    isAuthenticated: () => !!VueCookieNext.getCookie('tasleem_token'),
    getRoles: (state) => state.roles,
    isSuperAdmin: () => {
      const decoded: any = jwtDecode(VueCookieNext.getCookie('tasleem_token'))
      return decoded.user_role_id === 1
    },
    isAdmin: () => {
      const decoded: any = jwtDecode(VueCookieNext.getCookie('tasleem_token'))
      return decoded.user_role_id === 2
    },
    isClientAdmin: () => {
      try {
        const decoded: any = jwtDecode(VueCookieNext.getCookie('tasleem_token'))
        return decoded.user_role_id === 5
      } catch (e) {
        return false
      }
    },
    getAuthorized: (state) => {
      return state.authorizedUser
    },
    getUserCredentials: (state) => {
      return state.userCredentials
    },
    getNewOrderCreated: (state) => {
      return state.newOrderCreated
    }
  },
  actions: {
    newOrderCreated: (context, val: boolean) => {
      context.state.newOrderCreated = val
      setTimeout(() => { context.state.newOrderCreated = false }, 1000)
    },
    storeInvitation: async (context, payload) => {
      context.commit('triggerLoading', true)
      await axiosInstance({
        method: apiEndpoints.invitation.create.method,
        url: apiEndpoints.invitation.create.url,
        params: payload.params,
        data: payload.body
      }).then(resp => {
        context.commit('triggerLoading', false)
        context.commit('setRoles', resp.data.data)
      }).catch(err => {
        context.commit('triggerLoading', false)
        throw new Error(err)
      })
    },
    getUserRoles: async (context) => {
      await axiosInstance({
        method: apiEndpoints.roles.method,
        url: apiEndpoints.roles.url
      }).then(resp => {
        context.commit('setRoles', resp.data.data)
      }).catch(err => {
        throw new Error(err)
      })
    },
    sendFirebaseToken: async (context, payload) => {
      await axiosInstance({
        method: apiEndpoints.firebase.method,
        url: apiEndpoints.firebase.url + payload.userId,
        data: {
          fcm_token: payload.token
        }
      })
    },
    setToken: (context, token: string) => {
      VueCookieNext.setCookie('tasleem_token', token, { path: '/', domain: location.hostname })
    },
    setRefreshToken: (context, refreshToken: string) => {
      VueCookieNext.setCookie('tasleem_refresh_token', refreshToken, { path: '/', domain: location.hostname })
    },
    removeToken: (context, refresh: boolean) => {
      VueCookieNext.removeCookie('tasleem_token', { path: '/', domain: location.hostname })
      VueCookieNext.removeCookie('tasleem_refresh_token', { path: '/', domain: location.hostname })
      context.commit('setLoggedUser', null)
      if (refresh) {
        window.location.href = '/'
      }
    },
    getLoggedUserDetail: async (context) => {
      const decoded: any = jwtDecode(VueCookieNext.getCookie('tasleem_token'))
      await axiosInstance({
        method: apiEndpoints.users.getById.method,
        url: apiEndpoints.users.getById.url + decoded.id
      }).then(resp => {
        if (resp.data?.data) {
          context.commit('setAuthorizedUser', resp.data.data)
        }
      }).catch(err => {
        throw err
      })
    },
    removeRefreshToken: () => {
      VueCookieNext.removeCookie('tasleem_refresh_token', { path: '/', domain: location.hostname })
    },
    login: async (context, { username, password, remember }) => {
      const data = {
        username: username,
        password: password
      }
      await axiosInstance({
        method: apiEndpoints.login.method,
        url: apiEndpoints.login.url,
        data: data
      }).then(resp => {
        const token = resp.data.data.access_token
        const decoded: any = jwtDecode(token)
        if ([1, 2, 4, 5].includes(decoded.user_role_id)) {
          const refreshToken = resp.data.data.refresh_token
          context.dispatch('setToken', token)
          context.dispatch('setRefreshToken', refreshToken)
        } else {
          const toast = useToast()
          toast.error('You dont have permission!', {
            timeout: 15000
          })
        }
      }).catch(err => {
        // context.dispatch('removeToken', true)
        // REMOVE: just for development
        if (username !== 'admin' || password !== 'admin') throw err
        else {
          context.dispatch('setToken', 'hashhashtoken')
        }
        // throw err
      })
    },
    logout: async (context, refresh = true) => {
      await axiosInstance.post(apiEndpoints.logout.url).then(() => {
        context.dispatch('removeToken', refresh)
        context.dispatch('removeRefreshToken')
      }).catch(() => {
        // TO DO: remove these lines after integrate with backend, they are for mock
        context.dispatch('removeToken', refresh)
      })
    },
    getLoggedUser: async (context, token) => {
      await axiosInstance.get(apiEndpoints.me.url).then(response => {
        context.commit('setLoggedUser', response.data)
      })
    }
  },
  modules: {
    drivers,
    orders,
    vehicles,
    users,
    workShifts,
    darkstores,
    reports,
    manualChanges,
    zones,
    notifications,
    predefinedReasons,
    locations,
    cacheSettlements,
    settings,
    cities,
    erpNext,
    clients
  },
  plugins: [vueLocalStorage.plugin]
}

export default new Vuex.Store<RootState>(store)
