import { MutationTree } from 'vuex'
import { SettingsState } from '@/store/modules/settings/types'

export enum SettingMutations {
  SET_SETTINGS = 'SET_SETTINGS',
  TRIGGER_LOADING = 'TRIGGER_LOADING'
}

export const mutations: MutationTree<SettingsState> = {
  [SettingMutations.SET_SETTINGS] (state, payload) {
    state.settings = payload.data
    state.loading = false
  },
  [SettingMutations.TRIGGER_LOADING] (state, bool: boolean) {
    state.loading = bool
  }
}
