import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-199c7d88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-6" }
const _hoisted_2 = { class: "flex justify-between stepper" }
const _hoisted_3 = { class: "flex flex-col items-center px-2" }
const _hoisted_4 = { class: "flex flex-col items-center px-2" }
const _hoisted_5 = { class: "flex flex-col items-center px-2" }
const _hoisted_6 = { class: "mt-4" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "flex gap-8" }
const _hoisted_9 = { class: "mt-4 w-full" }
const _hoisted_10 = { class: "pb-2 font-bold" }
const _hoisted_11 = {
  key: 0,
  class: "mt-4 w-full"
}
const _hoisted_12 = { class: "pb-2 font-bold" }
const _hoisted_13 = { class: "multiselect-single-label" }
const _hoisted_14 = { class: "flex justify-between items-center w-full" }
const _hoisted_15 = { class: "text-warningColor mt-2" }
const _hoisted_16 = { class: "mt-4" }
const _hoisted_17 = { class: "mt-4" }
const _hoisted_18 = { class: "mb-4" }
const _hoisted_19 = { class: "flex gap-8" }
const _hoisted_20 = { class: "w-full" }
const _hoisted_21 = { class: "flex border-b pb-3 mt-6" }
const _hoisted_22 = { class: "font-bold w-full" }
const _hoisted_23 = { class: "sort-item flex items-center justify-between border-b my-0 p-3 related-order-item" }
const _hoisted_24 = { class: "text-black flex items-center" }
const _hoisted_25 = { class: "rounded px-2 border inline-flex mx-2 text-gray" }
const _hoisted_26 = { class: "w-full" }
const _hoisted_27 = { class: "flex justify-end mt-6" }
const _hoisted_28 = ["disabled"]
const _hoisted_29 = ["disabled"]
const _hoisted_30 = { class: "text-textColor font-bold text-md mb-" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_draggableComponent = _resolveComponent("draggableComponent")!
  const _component_AssignmentMap = _resolveComponent("AssignmentMap")!
  const _component_ReasonForm = _resolveComponent("ReasonForm")!
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["rounded-full border bg-white border-red-400 w-9 h-9 flex items-center justify-center", [
            {'text-white bg-red-600 font-bold': _ctx.step == 1},
            {'text-white bg-red-300 font-bold': _ctx.step > 1}
          ]])
        }, "1", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["bg-white mt-1", {'text-red-600':_ctx.step === 1}])
        }, "Driver", 2)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass(["rounded-full bg-white border border-red-400 w-9 h-9 flex items-center justify-center", {'text-white bg-red-600 font-bold': _ctx.step === 2}])
        }, "2", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["bg-white mt-1", {'text-red-600':_ctx.step === 2}])
        }, "Related Orders", 2)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: _normalizeClass(["rounded-full border bg-white border-red-400 border w-9 h-9 flex items-center justify-center text-green-600 text-lg", {'text-white bg-red-600 font-bold': _ctx.step === 3}])
        }, "✓", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["bg-white mt-1", {'text-red-600':_ctx.step === 3}])
        }, "Finish", 2)
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_6, [
      (!this.orderData.driver)
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t('orders.manualAssignmentDesc')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('placeholders.drivers')), 1),
          _createVNode(_component_Multiselect, {
            canClear: false,
            modelValue: _ctx.selectedDriver,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDriver) = $event)),
            options: _ctx.drivers,
            object: "true",
            label: "value",
            valueProp: "id",
            noOptionsText: _ctx.$t('validation.noItemsToShow'),
            placeholder: _ctx.$t('placeholders.driver')
          }, null, 8, ["modelValue", "options", "noOptionsText", "placeholder"])
        ]),
        (!this.orderData.driver)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('placeholders.nearbyOrders')), 1),
              _createVNode(_component_Multiselect, {
                modelValue: _ctx.selectedNearbyDriver,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedNearbyDriver) = $event)),
                options: _ctx.nearbyOrders,
                label: "username",
                valueProp: "id",
                object: "true",
                noOptionsText: _ctx.$t('validation.noItemsToShow'),
                placeholder: _ctx.$t('placeholders.vehicle')
              }, {
                singlelabel: _withCtx(() => [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.selectedNearbyDriver.username), 1)
                  ])
                ]),
                option: _withCtx(({ option }) => [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", null, [
                      _createElementVNode("p", null, _toDisplayString(option.username), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("p", null, _toDisplayString(option.orderId), 1),
                      _createElementVNode("p", _hoisted_15, _toDisplayString(option.orderTravelDuration) + " " + _toDisplayString(option.orderTravelDuration === 1 ? _ctx.$t('orders.minute') : _ctx.$t('orders.minutes')), 1)
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["modelValue", "options", "noOptionsText", "placeholder"])
            ]))
          : _createCommentVNode("", true)
      ])
    ], 512), [
      [_vShow, _ctx.step === 1]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t('orders.relatedOrdersDesc')), 1),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            (_ctx.relatedOrders)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('orders.orderID')), 1)
                  ]),
                  _createVNode(_component_draggableComponent, {
                    modelValue: _ctx.relatedOrders,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.relatedOrders) = $event)),
                    group: "people",
                    "ghost-class": "ghost"
                  }, {
                    item: _withCtx(({element}) => [
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("div", _hoisted_24, [
                          _createTextVNode(_toDisplayString(element.order_id) + " ", 1),
                          (element.order_id === _ctx.orderData.orderId)
                            ? (_openBlock(), _createBlock(_component_BaseIcon, {
                                key: 0,
                                name: "eye",
                                class: "ml-1 w-3"
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t(`orders.statuses.${element.order_status.name}`)), 1)
                      ])
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createVNode(_component_AssignmentMap, {
              coordinates: _ctx.coordinates,
              "store-coordinates": _ctx.storeCoordinates,
              style: {"height":"300px","width":"100%"}
            }, null, 8, ["coordinates", "store-coordinates"])
          ])
        ])
      ])
    ], 512), [
      [_vShow, _ctx.step === 2]
    ]),
    _createElementVNode("div", _hoisted_27, [
      _createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close'))),
        class: "tertiary px-6 py-3 me-2"
      }, _toDisplayString(_ctx.$t('buttons.close')), 1),
      (_ctx.step == 2)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.step = 1)),
            class: "tertiary px-6 py-3 me-2"
          }, _toDisplayString(_ctx.$t('buttons.back')), 1))
        : _createCommentVNode("", true),
      (_ctx.step == 1)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            disabled: _ctx.isManualAssignmentDisabled,
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.step = 2)),
            class: "primary px-6 py-3"
          }, _toDisplayString(_ctx.$t('buttons.next')), 9, _hoisted_28))
        : _createCommentVNode("", true),
      (_ctx.step == 2)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            disabled: _ctx.isManualAssignmentDisabled,
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showConfirmation = true)),
            class: "primary px-6 py-3"
          }, _toDisplayString(_ctx.$t('buttons.saveChanges')), 9, _hoisted_29))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showConfirmation)
      ? (_openBlock(), _createBlock(_component_ConfirmationModal, {
          key: 0,
          header: _ctx.$t('orders.assignConfirmation.header'),
          hasIcon: false,
          cancelText: _ctx.$t('buttons.no'),
          confirmText: _ctx.$t('buttons.yesAssign'),
          isValid: _ctx.assignReason && _ctx.assignReason.reason_id && _ctx.assignReason.note.length,
          onConfirm: _cache[8] || (_cache[8] = ($event: any) => (_ctx.confirmAssignDriver($event))),
          onCancel: _cache[9] || (_cache[9] = ($event: any) => (_ctx.rejectAssignDriver()))
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.confirmationTitle), 1),
            _createVNode(_component_ReasonForm, {
              class: "mt-5",
              "feedback-type": _ctx.PredefinedFeedbackTypes.MANUAL_ASSIGNMENT,
              onReason: _cache[7] || (_cache[7] = (r) => _ctx.assignReason = r)
            }, null, 8, ["feedback-type"])
          ]),
          _: 1
        }, 8, ["header", "cancelText", "confirmText", "isValid"]))
      : _createCommentVNode("", true)
  ]))
}