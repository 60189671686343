
import { defineComponent } from 'vue'
import { debounce } from 'lodash'

export default defineComponent({
  name: 'GreaterLessThenCondition',
  emits: ['update:modelValue'],
  props: ['modelValue'],
  data () {
    return {
      localValue: { condition: 'lowerThan', value: 0 },
      onSetItem: debounce((input) => {
        this.inputHandler(input)
      }, 500)
    }
  },
  methods: {
    inputHandler (text: number) {
      this.localValue = { ...this.localValue, value: text }
    }
  },
  watch: {
    modelValue (newVal) {
      this.localValue = newVal
    },
    localValue: {
      handler (newVal) {
        this.$emit('update:modelValue', newVal)
      },
      deep: true
    }
  },
  mounted () {
    if (this.modelValue && this.modelValue !== null) {
      this.localValue = this.modelValue
    }
  }
})
