import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fa1e9df"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "step"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["border rounded-md flex items-center gap-1", _ctx.total === 100 ? 'border-green-600' : 'border-red-600'])
  }, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
      disabled: _ctx.disabled,
      type: "number",
      placeholder: "1",
      class: "bg-white input px-2 w-18 border-none",
      min: "0",
      step: _ctx.step
    }, null, 8, _hoisted_1), [
      [_vModelText, _ctx.localValue]
    ]),
    (_openBlock(), _createBlock(_component_BaseIcon, {
      key: _ctx.disabled,
      role: "button",
      name: _ctx.disabled ? 'locked' : 'unlocked',
      class: _normalizeClass('mr-2'),
      onClick: _ctx.clockHandler
    }, null, 8, ["name", "onClick"]))
  ], 2))
}