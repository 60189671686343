import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import { ZoneState } from '@/store/modules/zones/types'

export const getters: GetterTree<ZoneState, RootState> = {
  getPagination (state): PaginationInterface | null {
    return state.pagination
  },
  getLoading (state): boolean {
    return state.loading
  },
  getZones (state) {
    return state.zones
  }
}
