
import { defineAsyncComponent, defineComponent } from 'vue'
import router from '@/router'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import useVuelidate from '@vuelidate/core'
import { ManualChangesActions } from '@/store/modules/manual-changes/actions'
import { ManualChangesInterface } from '@/shared/interfaces/manual-changes.interface'

export default defineComponent({
  components: {
    SearchInput: defineAsyncComponent(() => import('@/shared/components/SearchInput.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    TableGhost: defineAsyncComponent(() => import('@/shared/components/ghosts/TableGhost.vue')),
    Pagination: defineAsyncComponent(() => import('@/shared/components/Pagination.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue'))
  },
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      data: [],
      columns: [
        {
          label: this.$t('table.userName'),
          field: 'userName'
        },
        {
          label: this.$t('table.entityName'),
          field: 'entityName'
        },
        {
          label: this.$t('table.createdAt'),
          field: 'createdAt'
        },
        {
          label: this.$t('table.entityType'),
          field: 'entityType'
        },
        {
          label: this.$t('table.fieldName'),
          field: 'fieldName'
        },
        {
          label: this.$t('table.oldValue'),
          field: 'oldValue'
        },
        {
          label: this.$t('table.newValue'),
          field: 'newValue'
        }
      ],
      exportLoading: false,
      showFilter: false,
      sortFields: [
        { id: 'created_at', currentSort: false, sort: null },
        { id: 'last_login', currentSort: false, sort: null }
      ],
      height: 400,
      current: 1,
      searchValue: null as any,
      filters: [
        {
          name: this.$t('filters.entityTypes'),
          model: 'entityType',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'fetchEntityTypes',
          type: 'radio',
          data: []
        },
        { name: this.$t('filters.entityId'), model: 'entityId', type: 'number' },
        { name: this.$t('filters.date'), model: 'date', type: 'date', calendarType: 'range' }
      ]
    }
  },
  mounted () {
    this.height = document.documentElement.clientHeight - 280
    addEventListener('resize', () => {
      this.height = document.documentElement.clientHeight - 280
    })
  },
  computed: {
    manualChanges (): ManualChangesInterface[] {
      return this.$store.getters['manualChanges/getManualChanges']
    },
    pagination (): PaginationInterface {
      return this.$store.getters['manualChanges/getPagination']
    },
    loading (): boolean {
      return this.$store.getters['manualChanges/getLoading']
    },
    entityTypes (): any {
      return this.$store.getters['manualChanges/getEntityTypes']
    }
  },
  methods: {
    exportTableToExcel (event: any) {
      event.stopPropagation()
      this.exportLoading = true
      const params = { pageSize: 10, pageNumber: this.$route.query.pageNumber || 100, export: true }
      this.current = this.$route.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
      if (this.searchValue) {
        Object.assign(params, { search: this.searchValue })
      }
      this.$store.dispatch(`manualChanges/${ManualChangesActions.FETCH_MANUAL_CHANGES}`, params).then((res: any) => {
        const button = document.getElementById('excel-export')
        this.data = res.data.map((request: any) => {
          return {
            userName: request?.owner?.username ?? '',
            entityName: request.entityName,
            createdAt: request.createdAtFormatted,
            entityType: request.entityType,
            fieldName: request.fieldName,
            oldValue: request.oldValue,
            newValue: request.newValue
          }
        })
        this.exportLoading = false
        if (button) {
          setTimeout(() => {
            button.click()
          }, 200)
        }
      })
    },
    clickSort (field: any, currentSortName: string): void {
      this.sortFields.map((el: any) => {
        if (el.id === currentSortName) {
          el.currentSort = true
          if (el.sort === null) {
            el.sort = 'asc'
          } else if (el.sort === 'asc') {
            el.sort = 'desc'
          } else if (el.sort === 'desc') {
            el.sort = null
          }
        } else {
          el.currentSort = false
          el.sort = null
        }
        return el
      })
      const queries = this.$route.query
      router.push({ query: { ...queries, orderBy: field.id, sort: field.sort } })
    },
    onExpandPanelClick (eventName: string): void {
      if (eventName === 'fetchEntityTypes') {
        this.$store.dispatch(`manualChanges/${ManualChangesActions.FETCH_ENTITY_TYPES}`)
      }
    }
  },
  watch: {
    current (val) {
      router.push({ query: { ...this.$route.query, pageNumber: val } })
    },
    searchValue (val) {
      const queries = this.$route.query
      queries.pageNumber = '1'
      router.push({ query: { ...queries, search: this.searchValue } })
    },
    $route: {
      immediate: true,
      handler (val) {
        if (val.name === 'ManualChanges') {
          this.current = this.$route.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
          if (val.query.search) {
            this.searchValue = val.query.search
          }
          let params = JSON.parse(JSON.stringify(val.query))
          params = { pageSize: 10, pageNumber: val.query.pageNumber ? val.query.pageNumber : 1, ...params }
          this.$store.dispatch(`manualChanges/${ManualChangesActions.FETCH_MANUAL_CHANGES}`, params)
        }
      }
    },
    entityTypes (val: any) {
      if (!val?.length) return
      const filterItems = val.map((el: any) => {
        return {
          id: el.name,
          label: el.value
        }
      })
      const target = this.filters[0]
      target.data = filterItems
      target.dataRequested = true
      target.dataLoading = false
    }
  }
})
