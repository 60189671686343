import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { ViewOrdersTimingInterface } from '@/shared/interfaces/reports/view-orders-timing.interface'

export class ViewOrdersTimingModel extends CommonModel {
  public data: ViewOrdersTimingInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...item }]
    })
  }
}
