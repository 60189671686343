import { Method as apiMethod } from 'axios'

export const userEndpoints = {
  invite: {
    method: 'post' as apiMethod,
    url: '/users/invite'
  },
  getById: {
    method: 'get' as apiMethod,
    url: '/users/'
  },
  list: {
    method: 'get' as apiMethod,
    url: '/users/page'
  },
  activate: {
    method: 'post' as apiMethod,
    url: '/users/activate/'
  },
  deactivate: {
    method: 'post' as apiMethod,
    url: '/users/deactivate/'
  },
  update: {
    method: 'put' as apiMethod,
    url: '/users/'
  },
  changePass: {
    method: 'post' as apiMethod,
    url: '/users/change_password/'
  }
}
