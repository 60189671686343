<template>
  <div class="flex h-screen items-center">
    <div class="w-550 m-auto">
      <div v-if="token && token.length>0" class="reset flex flex-col bg-white px-104 py-10 rounded-lg shadow space-y-3 border">
        <h1 class="font-bold text-3xl text-center mb-14">{{ $t('auth.reset_header') }}</h1>
        <Alert v-if="isError" type="danger" title="error"><p>{{ errorMessage }}</p></Alert>
        <form action="">
          <div class="relative h-42 mb-8">
            <input v-bind:class="{error: v$.pass.$error}" type="email" placeholder=" " class="h-42 absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent" v-model="pass" @blur="v$.pass.$touch"/>
            <label v-bind:class="{error: v$.pass.$error}" class="absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text">Password</label>
            <BaseIcon v-if="showPassword" @click="togglePassword" class="input-icon absolute end-3.5 top-3 cursor-pointer z-30" name="eye"/>
            <BaseIcon v-if="!showPassword" @click="togglePassword" class="input-icon absolute end-3.5 top-3 cursor-pointer z-30" name="eyeoff"/>
            <span v-if="v$.pass.$error" class="flex items-center absolute start-0 -bottom-6 text-10px text-textError">
              <BaseIcon class="me-1" name="alert"/>
              {{ $t('validation.required') }}
          </span>
          </div>
          <div class="relative h-42 mb-7">
            <input v-bind:class="{error: v$.confirm_pass.$error}" type="email" placeholder=" " class="h-42 absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent" v-model="confirm_pass" @blur="v$.confirm_pass.$touch"/>
            <label v-bind:class="{error: v$.confirm_pass.$error}" class="absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text">Password</label>
            <span v-if="v$.confirm_pass.$error" class="flex items-center absolute start-0 -bottom-6 text-10px text-textError">
              <BaseIcon class="me-1" name="alert"/>
              {{ $t('validation.same_as') }}
          </span>
          </div>
        </form>
        <button :disabled="v$.$invalid" class="primary w-full mt-8 p-3" @click="send">{{ $t('auth.reset') }}</button>
         <div class="relative">
          <router-link to="/auth" class="inline-block text-black font-bold hover:underline mt-6">{{ $t('auth.signin_header') }}</router-link>
        </div>
      </div>
      <div v-else class="reset-expier flex flex-col bg-white p-9 rounded-lg shadow space-y-3 border">
        <h1 class="font-normal text-lg text-center">{{ $t('auth.reset_expired') }}</h1>
        <div class="relative">
          <router-link to="/auth" class="inline-block text-black font-bold hover:underline mt-6">{{ $t('auth.signin_header') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, minLength, sameAs } from '@/customValidators'
import client from 'axios'
import { apiEndpoints } from '@/shared/endpoints/api'
import Alert from '@/shared/components/Alert.vue'
import BaseIcon from '../../shared/components/base-icon/BaseIcon'
import axiosInstance from '../../shared/utils/axiosInstance'

export default defineComponent({
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      token: '',
      pass: '',
      confirm_pass: '',
      isError: false,
      errorMessage: ''
    }
  },
  validations: function () {
    return {
      pass: { required, minLength: minLength(8) },
      confirm_pass: { required, sameAs: sameAs(this.pass, this.$i18n.t('auth.password')) }
    }
  },
  components: {
    BaseIcon,
    Alert
  },
  beforeMount () {
    this.token = this.$route.query.token
    // Just for Testing
    this.token = 'UITestToken'
  },
  methods: {
    send: async function () {
      // check form validation
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      await axiosInstance[apiEndpoints.reset.method](apiEndpoints.reset.url, { body: { pass: this.pass.trim() } }).then(() => {
        this.pass = ''
        this.confirm_pass = ''
        this.isError = false
        this.v$.$reset()
        this.errorMessage = ''
        this.$router.push('/auth')
      }, (err) => {
        this.isError = true
        this.errorMessage = err
      })
    }
  }
})
</script>
