import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["px-2 py-1 rounded text-xs capitalize border", [
    {'text-blue-600 bg-blue-200 border-blue-300' : _ctx.data.erpSyncStatus === 'init'},
    {'text-orange bg-yellow-100 border-orange' : _ctx.data.erpSyncStatus === 'pending'},
    {'text-green-600 bg-green-100 border-green-600' : _ctx.data.erpSyncStatus === 'synced'},
    {'text-red-600 bg-red-100 border-red-600' : _ctx.data.erpSyncStatus === 'failed'}
  ]])
  }, _toDisplayString(_ctx.$t(`orders.erpSyncStatus.${_ctx.data.erpSyncStatus ?? 'not_set' }`)), 3))
}