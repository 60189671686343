
import { defineComponent, PropType, toRaw } from 'vue'
import { DarkStoreInterface } from '@/shared/interfaces/darkstore.interface'
import { ZoneActions } from '@/store/modules/zones/actions'
import { ZoneInterface } from '@/shared/interfaces/zone.interface'
import { ZoneModel } from '@/shared/models/zone.model'

const google = window.google

export default defineComponent({
  name: 'ZoneManagement',
  props: {
    darkstore: {
      type: Object as PropType<DarkStoreInterface>,
      required: true
    }
  },
  data () {
    return {
      assignedZones: [] as any,
      unAssignedZones: [] as any,
      map: {} as any,
      drawingManager: {} as any,
      polygons: [] as any,
      textOverlays: [] as any[],
      confirm: null as any,
      loading: false,
      darkstoreMarker: null as any
    }
  },
  methods: {
    async fetch () {
      await this.$store.dispatch(`zones/${ZoneActions.FETCH_ZONES}`, { pageSize: 9999, pageNumber: 1 })
      this.assignedZones = this.zones.filter((z:ZoneInterface) => z.darkstores
        .some((darkstore:DarkStoreInterface) => darkstore.id === this.darkstore.id))
        .map((z: ZoneInterface) => {
          return {
            polygons: ZoneModel.convertToMapPolygonFormat(z.polygon),
            zone: z
          }
        })

      this.unAssignedZones = this.zones.filter((z:ZoneInterface) => z.darkstores
        .every((darkstore:DarkStoreInterface) => darkstore.id !== this.darkstore.id))
        .map((z: ZoneInterface) => {
          return {
            polygons: ZoneModel.convertToMapPolygonFormat(z.polygon),
            zone: z
          }
        })
    },
    async init () {
      await this.fetch()
      if (Object.keys(this.map).length) {
        this.clearTextOverlays()
        this.clearPolygons()
        this.drawPolygons(this.assignedZones, true)
        this.drawPolygons(this.unAssignedZones)
      } else {
        this.initMap()
        this.$nextTick(() => {
          this.clearTextOverlays()
          this.clearPolygons()
          this.drawPolygons(this.assignedZones, true)
          this.drawPolygons(this.unAssignedZones)
          // this.centerMap() // center of all of the zones
        })
      }
    },
    initMap () {
      // const _center = this.allZoneCoordinates.length ? this.getCoordinatesCenter() : null
      const _center = { lat: this.darkstore.latitude, lng: this.darkstore.longitude } // center is darkstore
      // create a map
      this.map = new google.maps.Map(document.getElementById('darkstore-zone-management-map')!, {
        center: _center || { lat: 33.312805, lng: 44.361488 },
        zoom: 12,
        fullscreenControl: false,
        mapTypeControl: false
      })

      // init darkstore marker
      if (this.darkstoreMarker == null) {
        const icon = {
          url: require('../../../../assets/markers/darkstore-dark-marker.png'),
          size: new google.maps.Size(55, 70),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(22, 67)
        }
        this.darkstoreMarker = new google.maps.Marker({
          position: new google.maps.LatLng(this.darkstore.latitude, this.darkstore.longitude),
          map: this.map,
          icon,
          zIndex: 9999
        })
      }
    },
    drawPolygons (coordinatesGroup:any, isAssigned = false) {
      for (const coordinates of coordinatesGroup) {
        const polygon = new google.maps.Polygon({
          paths: coordinates.polygons,
          editable: false,
          draggable: false,
          map: this.map,
          zIndex: 100,
          fillColor: isAssigned ? '#13fb03' : '#000',
          fillOpacity: isAssigned ? 0.5 : 0.35,
          strokeWeight: 1.5
        })
        this.addTextOverlay(polygon, coordinates.zone.name)
        this.addListeners(polygon, isAssigned, coordinates.zone)
        this.polygons.push(polygon)
      }
    },
    updatePolygon (polygon: any, isAssigned: boolean, zone: ZoneInterface) {
      polygon.setOptions({
        fillColor: isAssigned ? '#13fb03' : '#000',
        fillOpacity: isAssigned ? 0.5 : 0.35
      })
      this.removeListeners(polygon)
      this.addListeners(polygon, isAssigned, zone)
    },
    addTextOverlay (polygon: any, text: string) {
      const path = polygon.getPath().getArray()
      const bounds = new google.maps.LatLngBounds()
      for (const point of path) {
        bounds.extend(point)
      }

      const center = bounds.getCenter()

      // 1p x 1px empty transparent image
      const emptyImage = 'data:image/png;base64,' + 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
      const icon = {
        url: emptyImage,
        scaledSize: new google.maps.Size(50, 50), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(0, 0) // anchor
      }

      const marker = new google.maps.Marker({
        position: center,
        map: this.map,
        icon,
        label: {
          text,
          color: 'black',
          className: 'google-map-zone-label',
          fontSize: '11px',
          fontWeight: 'bold'
        },
        zIndex: 9999
      })
      this.textOverlays.push(marker)
    },
    clearPolygons () {
      // remove all polygons
      for (const polygon of this.polygons) {
        this.removeListeners(polygon)
        toRaw(polygon).setMap(null)
        polygon.setMap(null)
      }
      this.polygons = []
    },
    clearTextOverlays () {
      // remove all overlays
      for (const overlay of this.textOverlays) {
        toRaw(overlay).setMap(null)
        overlay.setMap(null)
      }
      this.textOverlays = []
    },
    getCoordinatesCenter () {
      if (!this.allZoneCoordinates.length) return false
      const bounds = new google.maps.LatLngBounds()
      for (const coordinates of this.allZoneCoordinates) {
        for (const coordinate of coordinates) {
          const latLng = new google.maps.LatLng(coordinate.lat, coordinate.lng)
          bounds.extend(latLng)
        }
      }
      return bounds.getCenter()
    },
    centerMap () {
      const center = this.getCoordinatesCenter()
      this.map.setCenter(center)
    },
    addListeners (polygon:any, isAssigned = true, zone: ZoneInterface) {
      google.maps.event.addListener(polygon, 'click', () => {
        this.showConfirm(polygon, zone, isAssigned)
      })
    },
    removeListeners (polygon:any) {
      google.maps.event.clearListeners(polygon, 'click')
    },
    showConfirm (polygon: any, zone: ZoneInterface, isAssign: boolean) {
      this.confirm = {
        polygon,
        zone,
        isAssign
      }
    },
    addAssignUnAssignHandler (polygon: any, zone: ZoneInterface, isAssign: boolean) {
      this.loading = true
      const payload = {
        zone_id: zone.id,
        dark_store_id: this.darkstore.id
      }
      const action = !isAssign ? ZoneActions.ASSIGN_ZONE_TO_DARKSTORE : ZoneActions.UN_ASSIGN_ZONE_TO_DARKSTORE
      this.$store.dispatch(`zones/${action}`, payload)
        .then(() => {
          this.updatePolygon(polygon, !isAssign, zone)
          this.confirm = null
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    zones (): ZoneInterface[] {
      return this.$store.getters['zones/getZones']
    },
    allZoneCoordinates () {
      return this.zones.map((z: ZoneInterface) => {
        return [
          ...ZoneModel.convertToMapPolygonFormat(z.polygon)
        ]
      })
    }
  },
  created () {
    this.init()
  }
})
