import { MutationTree } from 'vuex'
import { DriversState } from '@/store/modules/drivers/types'

export enum DriversMutations {
  INSERT_DRIVERS= 'INSERT_DRIVERS',
  INSERT_DRIVERS_AUTOCOMPLETE = 'INSERT_DRIVERS_AUTOCOMPLETE',
  INSERT_AVAILABLE_DRIVERS = 'INSERT_AVAILABLE_DRIVERS',
  INSERT_DRIVERS_LOCATIONS= 'INSERT_DRIVERS_LOCATIONS',
  SET_DRIVER= 'SET_DRIVER',
  LOADING_EVENT = 'LOADING_EVENT',
  SET_CURRENT_ORDER = 'SET_CURRENT_ORDER',
  SET_TODAYS_ORDERS = 'SET_TODAYS_ORDERS',
  SET_STATUSES = 'SET_STATUSES',
  LAST_LOCATION_LOADING = 'LAST_LOCATION_LOADING',
  SET_LAST_LOCATION = 'SET_LAST_LOCATION',
  UPDATE_DRIVER_DETAILS = 'UPDATE_DRIVER_DETAILS',
  SET_CLOCKED_IN_LOCATIONS = 'SET_CLOCKED_IN_LOCATIONS',
  TRIP_LOCATION_LOADING = 'TRIP_LOCATION_LOADING',
  SET_TRIP_LOCATION = 'SET_TRIP_LOCATION',
  SET_DRIVERS_MARKERS_CACHE = 'SET_DRIVERS_MARKERS_CACHE',
}

export const mutations: MutationTree<DriversState> = {
  [DriversMutations.INSERT_DRIVERS] (state, payload) {
    state.driversList = payload.data
    state.pagination = payload.pagination
  },
  [DriversMutations.INSERT_DRIVERS_AUTOCOMPLETE] (state, payload) {
    state.driversListAutocomplete = payload.data
    state.paginationAutocomplete = payload.pagination
  },
  [DriversMutations.SET_CLOCKED_IN_LOCATIONS] (state, payload) {
    state.clockedInLocations = payload
  },
  [DriversMutations.INSERT_AVAILABLE_DRIVERS] (state, payload) {
    state.availableDrivers = payload.data
    state.availableDriverPagination = payload.pagination
  },
  [DriversMutations.SET_DRIVER] (state, payload) {
    state.driver = payload
  },
  [DriversMutations.LOADING_EVENT] (state, bool: boolean) {
    state.loading = bool
  },
  [DriversMutations.LAST_LOCATION_LOADING] (state, bool: boolean) {
    state.lastLocationLoading = bool
  },
  [DriversMutations.SET_CURRENT_ORDER] (state, payload) {
    state.currentOrder = payload.order
  },
  [DriversMutations.SET_STATUSES] (state, payload) {
    state.statuses = payload
  },
  [DriversMutations.SET_LAST_LOCATION] (state, payload) {
    state.lastLocation = payload
  },

  [DriversMutations.SET_TRIP_LOCATION] (state, payload) {
    state.tripLocations = payload
  },
  [DriversMutations.TRIP_LOCATION_LOADING] (state, bool: boolean) {
    state.tripLocationLoading = bool
  },
  [DriversMutations.UPDATE_DRIVER_DETAILS] (state, payload) {
    // eslint-disable-next-line no-prototype-builtins
    if (payload.hasOwnProperty('address_building')) {
      state.driver.driverAddress.addressBuilding = payload.address_building
      state.driver.driverAddress.addressDistrict = payload.address_district
      state.driver.driverAddress.addressFlat = payload.address_flat
      state.driver.driverAddress.addressFloor = payload.address_floor
      state.driver.driverAddress.addressNearestLandmark = payload.address_nearest_landmark
      state.driver.driverAddress.addressNeighborhood = payload.address_neighborhood
    } else {
      state.driver.boxNumber = payload.box_number
    }
  },
  [DriversMutations.SET_DRIVERS_MARKERS_CACHE] (state, markers: any) {
    state.driversMarkers = markers
  }
}
