import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'

export class OrderAutocompleteModel extends CommonModel {
  public data: any[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): any {
    return {
      dateRangeFrom: data.date_range_from,
      dateRangeTo: data.date_range_to,
      driverId: data.driver_id,
      orderId: data.order_id
    }
  }
}
