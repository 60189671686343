import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { DriversState } from '@/store/modules/drivers/types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

const state: DriversState = {
  driversList: [],
  driversListAutocomplete: [],
  driver: null,
  availableDrivers: [],
  availableDriverPagination: null,
  pagination: null,
  paginationAutocomplete: null,
  loading: false,
  currentOrder: [],
  statuses: [],
  lastLocation: null,
  lastLocationLoading: false,
  clockedInLocations: [],
  tripLocations: null,
  tripLocationLoading: false,
  driversMarkers: {}
}

export const drivers: Module<DriversState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
