import { apiBase, apiEndpoints } from '@/shared/endpoints/api'
import axios from 'axios'
import { VueCookieNext } from 'vue-cookie-next'

let isRefreshing = false
let refreshSubscribers: any[] = []

const axiosInstance = axios.create({
  baseURL: apiBase
})

axiosInstance.interceptors.response.use(response => {
  return response
}, error => {
  const { config, response: { status } } = error
  const originalRequest = config

  if (status === 401) {
    if (!isRefreshing) {
      isRefreshing = true
      refreshAccessToken()
        .then((newToken: any) => {
          isRefreshing = false
          onRefreshed(newToken)
        })
    }

    const retryOrigReq = new Promise((resolve, reject) => {
      subscribeTokenRefresh((token: any) => {
        // replace the expired token and retry
        originalRequest.headers.Authorization = 'Bearer ' + token
        resolve(axios(originalRequest))
      })
    })
    return retryOrigReq
  } else {
    return Promise.reject(error)
  }
})

function subscribeTokenRefresh (cb: any) {
  refreshSubscribers.push(cb)
}

function onRefreshed (token: any) {
  refreshSubscribers.map(cb => cb(token))
  refreshSubscribers = []
}

function refreshAccessToken (): Promise<any> {
  return new Promise((resolve, reject) => {
    axios.post(apiEndpoints.refresh.url, {}, {
      baseURL: apiBase,
      headers: {
        Authorization: 'Bearer ' + VueCookieNext.getCookie('tasleem_refresh_token')
      }
    }).then((res) => {
      const token = res.data.data.access_token
      const refresh = res.data.data.refresh_token
      VueCookieNext.setCookie('tasleem_token', token, { path: '/', domain: location.hostname })
      VueCookieNext.setCookie('tasleem_refresh_token', refresh, { path: '/', domain: location.hostname })
      resolve(token)
    })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 409) {
          VueCookieNext.removeCookie('tasleem_token', { path: '/', domain: location.hostname })
          VueCookieNext.removeCookie('tasleem_refresh_token', { path: '/', domain: location.hostname })
          location.href = '/'
        }
        reject(err)
      })
  })
}

export default axiosInstance
