import { MutationTree } from 'vuex'
import { OrdersState } from '@/store/modules/orders/types'
import { Order } from '@/shared/interfaces/order.interface'
import { ReturnedOrder } from '@/shared/interfaces/returned-order.interface'

export enum OrderMutations {
  SET_ORDERS= 'SET_ORDERS',
  SET_ORDERS_AUTOCOMPLETE = 'SET_ORDERS_AUTOCOMPLETE',
  SET_ORDER_DETAILS = 'SET_ORDER_DETAILS',
  SET_ORDERS_HISTORIES = 'SET_ORDERS_HISTORIES',
  LOADING_EVENT = 'LOADING_EVENT',
  SET_RETURNED_ORDERS = 'SET_RETURNED_ORDERS',
  SET_RETURNED_ORDER_DETAILS = 'SET_RETURNED_ORDER_DETAILS',
  SET_ORDER_STATUSES = 'SET_ORDER_STATUSES',
  UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS',
  UPDATE_ORDER_VEHICLE_TYPE = 'UPDATE_ORDER_VEHICLE_TYPE',
  SET_NEARBY_ORDERS = 'SET_NEARBY_ORDERS',
  SET_ACCEPTED_ORDERS = 'SET_ACCEPTED_ORDERS',
  SET_ORDER_DRIVER_LOCATION = 'SET_ORDER_DRIVER_LOCATION',
  NAVIGATE_TO_NEXT_PAGE = 'NAVIGATE_TO_NEXT_PAGE'
}

export const mutations: MutationTree<OrdersState> = {
  [OrderMutations.SET_ORDERS] (state, payload) {
    if (payload.reset) {
      state.orderList = payload.data
    } else {
      state.orderList = [...state.orderList, ...payload.data]
    }
    state.pagination = payload.paginationData
  },

  [OrderMutations.SET_ORDERS_AUTOCOMPLETE] (state, payload) {
    state.orderAutocompleteList = payload.data
    state.paginationAutocomplete = payload.paginationData
  },

  [OrderMutations.SET_RETURNED_ORDERS] (state, payload) {
    if (payload.reset) {
      state.returnedItems = payload.data
    } else {
      state.returnedItems = [...state.returnedItems, ...payload.data]
    }
    state.pagination = payload.paginationData
  },

  [OrderMutations.SET_ORDER_DETAILS] (state, payload: Order) {
    state.detailedOrder = payload
  },

  [OrderMutations.SET_ACCEPTED_ORDERS] (state, payload: number) {
    state.acceptedOrdersCount = payload
  },

  [OrderMutations.SET_RETURNED_ORDER_DETAILS] (state, payload: ReturnedOrder) {
    state.detailedReturnedOrder = payload
  },

  [OrderMutations.SET_ORDERS_HISTORIES] (state, payload: any) {
    state.orderHistories = payload.refresh ? payload.orders.data : [...state.orderHistories, ...payload.orders.data]
    state.orderHistoryPagination = payload.orders.paginationData
  },
  [OrderMutations.LOADING_EVENT] (state, bool: boolean) {
    state.loading = bool
  },
  [OrderMutations.SET_ORDER_STATUSES] (state, payload) {
    state.statuses = payload
  },
  [OrderMutations.UPDATE_ORDER_STATUS] (state, { orderId, status, orderStatusTime }) {
    state.orderList = state.orderList.map((el: Order) => {
      if (el.id === orderId) {
        el.orderStatus = status
        el.orderStatusTime = orderStatusTime
      }
      return el
    })
    state.detailedOrder.orderStatus = status
  },
  [OrderMutations.UPDATE_ORDER_VEHICLE_TYPE] (state, vehicleType) {
    state.detailedOrder.vehicleType = vehicleType
  },
  [OrderMutations.SET_NEARBY_ORDERS] (state, data) {
    state.nearbyOrders = data
  },
  [OrderMutations.SET_ORDER_DRIVER_LOCATION] (state, data) {
    state.detailedOrder.driver.lastLocation = {
      latitude: data.latitude,
      longitude: data.longitude
    }
  },
  storeRequest (state, req) {
    state.request = req
  },
  [OrderMutations.NAVIGATE_TO_NEXT_PAGE] (state, bool: boolean) {
    state.navigateToNextPage = bool
  }
}
