import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import client from 'axios'
import { apiEndpoints } from '@/shared/endpoints/api'
import { VehiclesState } from '@/store/modules/vehicles/types'
import { VehiclesModel } from '@/shared/models/vehicles.model'
import { VehiclesMutations } from '@/store/modules/vehicles/mutations'
import { VehicleTypesModel } from '@/shared/models/vehicle-types.model'
import axiosInstance from '@/shared/utils/axiosInstance'

export enum VehiclesActions {
  FETCH_VEHICLES = 'FETCH_VEHICLES',
  ADD_VEHICLE = 'ADD_VEHICLE',
  FETCH_VEHICLE_TYPES = 'FETCH_VEHICLE_TYPES',
  UPDATE_VEHICLE = 'UPDATE_VEHICLE',
  ACTIVATE_VEHICLE = 'ACTIVATE_VEHICLE',
  DEACTIVATE_VEHICLE = 'DEACTIVATE_VEHICLE',
  FETCH_VEHICLES_AVAILABLE = 'FETCH_VEHICLES_AVAILABLE',
  DELETE_VEHICLE = 'DELETE_VEHICLE',
  FETCH_VEHICLE_MODELS = 'FETCH_VEHICLE_MODELS',
  FETCH_VEHICLES_MAKE = 'FETCH_VEHICLES_MAKE'
}

export const actions: ActionTree<VehiclesState, RootState> = {
  [VehiclesActions.FETCH_VEHICLES]: async (context, payload) => {
    context.commit(VehiclesMutations.TRIGGER_LOADING, true)
    const params = new URLSearchParams()
    params.append('page_number', payload.pageNumber)
    params.append('per_page', payload.pageSize)
    if (payload.isFree) {
      params.append('is_free', payload.isFree)
    }
    if (payload.owner) {
      params.append('owner', payload.owner)
    }
    if (payload.search) {
      params.append('search', payload.search)
    }
    if (payload.isActivated) {
      params.append('is_activated', payload.isActivated)
    }
    if (payload.shiftId) {
      params.append('shift_id', payload.shiftId)
    }
    if (payload.vehicleTypeId) {
      params.append('vehicle_type_id', payload.vehicleTypeId)
    }
    await axiosInstance({
      method: apiEndpoints.vehicle.list.method,
      url: apiEndpoints.vehicle.list.url,
      params
    }).then(resp => {
      context.commit(VehiclesMutations.TRIGGER_LOADING, false)
      const vehicleModel = new VehiclesModel(resp.data)
      const payload = {
        data: vehicleModel.data,
        pagination: vehicleModel.paginationData
      }
      context.commit(VehiclesMutations.INSERT_VEHICLES, payload)
    }).catch(err => {
      context.commit(VehiclesMutations.TRIGGER_LOADING, false)
      throw new Error(err)
    })
  },

  [VehiclesActions.FETCH_VEHICLES_AVAILABLE]: async (context, payload) => {
    const params = { page_number: payload.pageNumber, per_page: payload.pageSize }
    if (payload.shiftId) {
      Object.assign(params, { shift_id: payload.shiftId })
    }
    Object.assign(params, { is_free: payload.isFree })
    await axiosInstance({
      method: apiEndpoints.vehicle.list.method,
      url: apiEndpoints.vehicle.list.url,
      params
    }).then(resp => {
      const vehicleModel = new VehiclesModel(resp.data)
      const payload = {
        data: vehicleModel.data,
        pagination: vehicleModel.paginationData
      }
      context.commit(VehiclesMutations.INSERT_AVAILABLE_VEHICLES, payload)
    }).catch(err => {
      throw new Error(err)
    })
  },

  [VehiclesActions.FETCH_VEHICLE_TYPES]: async (context) => {
    await axiosInstance({
      method: apiEndpoints.vehicle.types.method,
      url: apiEndpoints.vehicle.types.url,
      headers: { 'secret-key': 'test' }
    }).then(resp => {
      const vehicleTypesModel = new VehicleTypesModel(resp.data)
      const payload = {
        data: vehicleTypesModel.data,
        pagination: vehicleTypesModel.paginationData
      }
      context.commit(VehiclesMutations.INSERT_VEHICLE_TYPES, payload)
    }).catch(err => {
      throw new Error(err)
    })
  },

  [VehiclesActions.ADD_VEHICLE]: (context, data) => {
    context.commit(VehiclesMutations.TRIGGER_LOADING, true)
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.vehicle.add.method,
        url: apiEndpoints.vehicle.add.url,
        data: data.payload
      }).then((res) => {
        resolve(res)
        context.commit(VehiclesMutations.TRIGGER_LOADING, false)
      }).catch(err => {
        context.commit(VehiclesMutations.TRIGGER_LOADING, false)
        throw new Error(err)
      })
    })
  },

  [VehiclesActions.UPDATE_VEHICLE]: (context, data) => {
    context.commit(VehiclesMutations.TRIGGER_LOADING, true)
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        axiosInstance({
          method: apiEndpoints.vehicle.update.method,
          url: `${apiEndpoints.vehicle.update.url}${data.vehicleId}`,
          data: data.payload
        }).then((res) => {
          resolve(res)
          context.commit(VehiclesMutations.TRIGGER_LOADING, false)
        }).catch(err => {
          context.commit(VehiclesMutations.TRIGGER_LOADING, false)
          throw new Error(err)
        })
      }, 2000)
    })
  },

  [VehiclesActions.ACTIVATE_VEHICLE]: async (context, payload) => {
    await axiosInstance({
      method: apiEndpoints.vehicle.activateDeactivate.method,
      url: apiEndpoints.vehicle.activateDeactivate.url + payload.vehicleId,
      data: { activate: 1 }
    }).then(resp => {
      context.commit(VehiclesMutations.UPDATE_VEHICLES_ACTIVATED, { vehicleId: payload.vehicleId, isActivate: true })
    }).catch(err => {
      throw new Error(err)
    })
  },

  [VehiclesActions.DEACTIVATE_VEHICLE]: async (context, payload) => {
    await axiosInstance({
      method: apiEndpoints.vehicle.activateDeactivate.method,
      url: apiEndpoints.vehicle.activateDeactivate.url + payload.vehicleId,
      data: { activate: 0 }
    }).then(resp => {
      context.commit(VehiclesMutations.UPDATE_VEHICLES_ACTIVATED, { vehicleId: payload.vehicleId, isActivate: false })
    }).catch(err => {
      throw new Error(err)
    })
  },

  [VehiclesActions.DELETE_VEHICLE]: async (context, payload) => {
    await axiosInstance({
      method: apiEndpoints.vehicle.delete.method,
      url: apiEndpoints.vehicle.delete.url + payload.vehicleId,
      data: { activate: 0 }
    }).catch(err => {
      throw new Error(err)
    })
  },

  [VehiclesActions.FETCH_VEHICLE_MODELS]: async (context) => {
    context.commit(VehiclesMutations.TRIGGER_VEHICLE_MODELS_LOADING, true)
    await axiosInstance({
      method: apiEndpoints.vehicle.models.method,
      url: apiEndpoints.vehicle.models.url
    }).then((res: any) => {
      context.commit(VehiclesMutations.TRIGGER_VEHICLE_MODELS_LOADING, false)
      context.commit(VehiclesMutations.INSERT_VEHICLE_MODELS, res.data.data)
    }).catch(err => {
      context.commit(VehiclesMutations.TRIGGER_VEHICLE_MODELS_LOADING, false)
      throw new Error(err)
    })
  },

  [VehiclesActions.FETCH_VEHICLES_MAKE]: async (context, modelName: string) => {
    context.commit(VehiclesMutations.TRIGGER_VEHICLE_MAKE_LOADING, true)
    await axiosInstance({
      method: apiEndpoints.vehicle.models.method,
      url: apiEndpoints.vehicle.models.url + '/' + modelName + '/make'
    }).then((res: any) => {
      context.commit(VehiclesMutations.TRIGGER_VEHICLE_MAKE_LOADING, false)
      context.commit(VehiclesMutations.INSERT_VEHICLES_MAKE, res.data.data)
    }).catch(err => {
      context.commit(VehiclesMutations.TRIGGER_VEHICLE_MAKE_LOADING, false)
      throw new Error(err)
    })
  }
}
