
import { defineAsyncComponent, defineComponent } from 'vue'
import router from '@/router'
import DriverSelect from '@/views/account/cache-settlements/component/DriverSelect.vue'
import { OrderActions } from '@/store/modules/orders/actions'

export default defineComponent({
  components: {
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    DynamicTable: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTable.vue')),
    DriverSelect
  },
  data () {
    return {
      showFilterModal: false,
      tableFields: [
        { id: 'orderId', name: 'Order ID & No', width: '18%', sortable: false, currentSort: false, sort: null, sortName: '' },
        { id: 'createdAt', name: 'Date', width: '18%', sortable: false, currentSort: false, sort: null, sortName: '' },
        { id: 'orderStatus', name: 'Order Status', width: '18%', sortable: false, currentSort: false, sort: null, sortName: '' },
        { id: 'orderTotal', name: 'Order Amount', width: '18%', sortable: false, currentSort: false, sort: null, sortName: '' },
        { id: 'settlementId', name: 'Settlement ID', width: '18%', sortable: false, currentSort: false, sort: null, sortName: '' },
        { id: 'settlementNo', name: 'Smart ID', width: '18%', sortable: false, currentSort: false, sort: null, sortName: '' },
        { id: 'settlementStatus', name: 'Settled Status', width: '18%', sortable: false, currentSort: false, sort: null, sortName: '' },
        { id: 'settlementDate', name: 'Settled Date', width: '18%', sortable: false, currentSort: false, sort: null, sortName: '' },
        { id: 'driverUsername', name: 'Driver', width: '18%', sortable: false, currentSort: false, sort: null, sortName: '' },
        { id: 'adminUsername', name: 'Admin Username', width: '18%', sortable: false, currentSort: false, sort: null, sortName: '' }
      ],
      filters: [
        { name: this.$t('filters.createdAt'), model: 'locationDate', type: 'date', calendarType: 'range' },
        { name: this.$t('filters.settlementDate'), model: 'settlement_date', type: 'date', calendarType: 'range' },
        {
          name: this.$t('filters.orderId'),
          model: 'orderId',
          type: 'text'
        },
        {
          name: this.$t('filters.smartId'),
          model: 'settlementNo',
          type: 'text'
        },
        {
          name: this.$t('filters.orderStatus'),
          model: 'orderStatusId',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'orderStatus',
          type: 'radio',
          data: []
        },
        {
          name: this.$t('filters.settlementStatus'),
          model: 'settlementStatus',
          type: 'radio',
          data: [
            { id: undefined, label: this.$t('filters.all') },
            { id: 'init', label: this.$t('orders.settlementStatus.init') },
            { id: 'requested', label: this.$t('orders.settlementStatus.requested') },
            { id: 'approved', label: this.$t('orders.settlementStatus.approved') },
            { id: 'rejected', label: this.$t('orders.settlementStatus.rejected') }
          ]
        },
        {
          name: this.$t('filters.erpSyncStatus'),
          model: 'erpSyncStatus',
          type: 'radio',
          data: [
            { id: undefined, label: this.$t('filters.all') },
            { id: 'init', label: this.$t('orders.erpSyncStatus.init') },
            { id: 'pending', label: this.$t('orders.erpSyncStatus.pending') },
            { id: 'failed', label: this.$t('orders.erpSyncStatus.failed') }
          ]
        }
      ],
      selectedDriver: null as any,
      selectedReport: this.reportType
    }
  },
  methods: {
    menuEvent (e: any): void {
      console.log(e)
    },
    clickSort (field: any): void {
      this.tableFields = this.tableFields?.map((el: any) => {
        if (el.id === field.id) {
          el.currentSort = true
          if (el.sort === null) {
            el.sort = 'asc'
          } else if (el.sort === 'asc') {
            el.sort = 'desc'
          } else if (el.sort === 'desc') {
            el.sort = null
          }
        } else {
          el.currentSort = false
          el.sort = null
        }
        return el
      })
      router.push({ query: { ...this.$route.query, orderBy: field.sortName, sort: field.sort } })
    },
    onExpand (eventName: string): void {
      if (eventName === 'orderStatus') {
        this.$store.dispatch(`orders/${OrderActions.FETCH_ORDER_STATUSES}`)
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (val: any) {
        if (val.query.orderBy) {
          this.tableFields = this.tableFields.map((el: any) => {
            if (el.id === val.query.orderBy) {
              el.currentSort = true
              el.sort = val.query.sort
            }
            return el
          })
        }
      }
    },
    selectedDriver (newVal) {
      const queries = this.$route.query
      if (newVal) {
        router.push({ query: { ...queries, driverId: newVal.id } })
      } else {
        router.push({ query: { ...queries, driverId: undefined } })
      }
    },
    selectedReport (val) {
      if (val) {
        router.push({ query: { reportName: val.name } })
      }
    },
    statuses (val: any) {
      if (!val.length) return
      const filterItems = val.map((el: any) => {
        return {
          id: el.id,
          label: el.value
        }
      })
      const target = this.filters[4]
      target.dataRequested = true
      target.data = filterItems
      target.dataLoading = false
    }
  },
  props: {
    reportType: {
      type: String
    }
  },
  computed: {
    reports () {
      return this.$store.getters['reports/getOrdersSettlementReports'].map((item : any) => {
        return {
          ...item,
          orderTotal: item.orderTotal + ' IQD'
        }
      })
    },
    exportReports () {
      return this.$store.getters['reports/getOrdersSettlementReportsExport'].map((item : any) => {
        return {
          ...item,
          orderTotal: item.orderTotal + ' IQD'
        }
      })
    },
    pagination () {
      return this.$store.getters['reports/getOrdersSettlementPagination']
    },
    loading () {
      return this.$store.getters['reports/getLoading']
    },
    statuses (): any {
      return this.$store.getters['orders/getOrderStatuses']
    }
  }
})
