import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { apiEndpoints } from '@/shared/endpoints/api'
import { ZoneMutations } from '@/store/modules/zones/mutations'
import { ZoneState } from '@/store/modules/zones/types'
import axiosInstance from '@/shared/utils/axiosInstance'
import { ZoneModel } from '@/shared/models/zone.model'
import { useToast } from 'vue-toastification'
const toast = useToast()

export enum ZoneActions {
  FETCH_ZONES = 'FETCH_ZONES',
  CREATE_ZONE = 'CREATE_ZONE',
  UPDATE_ZONE = 'UPDATE_ZONE',
  FETCH_ZONE_BY_ID = 'FETCH_ZONE_BY_ID',
  DELETE_ZONE = 'DELETE_ZONE',
  ASSIGN_ZONE_TO_DARKSTORE = 'ASSIGN_ZONE_TO_DARKSTORE',
  UN_ASSIGN_ZONE_TO_DARKSTORE = 'UN_ASSIGN_ZONE_TO_DARKSTORE',
}

export const actions: ActionTree<ZoneState, RootState> = {
  [ZoneActions.FETCH_ZONES]: async (context, payload) => {
    context.commit(ZoneMutations.REQUEST_ZONES, true)
    const params = new URLSearchParams()
    if (payload.search && payload.search !== '') {
      params.append('search', payload.search)
    }
    params.append('page_number', payload.pageNumber)
    params.append('per_page', payload.pageSize)
    await axiosInstance({
      ...apiEndpoints.zones.list,
      params
    }).then(resp => {
      const zoneModel = new ZoneModel(resp.data)
      const payload = {
        data: zoneModel.data,
        pagination: zoneModel.paginationData
      }
      context.commit(ZoneMutations.RECEIVE_ZONES, payload)
    }).catch(err => {
      context.commit(ZoneMutations.ERROR_ZONES, err)
      throw new Error(err)
    })
  },

  [ZoneActions.CREATE_ZONE]: async (context, payload): Promise<any> => {
    context.commit(ZoneMutations.REQUEST_ZONES)
    return await axiosInstance({
      ...apiEndpoints.zones.create,
      data: payload
    }).then(resp => {
      toast.success('Zone has been created successfully', {
        timeout: 3000
      })
      context.commit(ZoneMutations.REQUEST_ZONES, false)
      return resp
    }).catch(err => {
      context.commit(ZoneMutations.ERROR_ZONES, err)
      throw new Error(err)
    })
  },

  [ZoneActions.UPDATE_ZONE]: async (context, payload): Promise<any> => {
    context.commit(ZoneMutations.REQUEST_ZONES)
    return await axiosInstance({
      ...apiEndpoints.zones.update(payload?.id),
      data: payload
    }).then(resp => {
      toast.info('Zone has been updated successfully', {
        timeout: 3000
      })
      context.commit(ZoneMutations.REQUEST_ZONES, false)
      return resp
    }).catch(err => {
      context.commit(ZoneMutations.ERROR_ZONES, err)
      throw new Error(err)
    })
  },

  [ZoneActions.FETCH_ZONE_BY_ID]: async (context, zoneId): Promise<any> => {
    context.commit(ZoneMutations.REQUEST_ZONES, true)
    return await axiosInstance({
      ...apiEndpoints.zones.get_zone_by_id(zoneId)
    }).then(resp => {
      context.commit(ZoneMutations.REQUEST_ZONES, false)
      return resp.data
    }).catch(err => {
      toast.error('Zone not found', {
        timeout: 3000
      })
      context.commit(ZoneMutations.ERROR_ZONES, err)
      throw new Error(err)
    })
  },

  [ZoneActions.DELETE_ZONE]: async (context, zoneId): Promise<any> => {
    context.commit(ZoneMutations.REQUEST_ZONES, true)
    return await axiosInstance({
      ...apiEndpoints.zones.delete_zone_by_id(zoneId)
    }).then(resp => {
      toast.success('Zone has been deleted successfully', {
        timeout: 3000
      })
      context.commit(ZoneMutations.REQUEST_ZONES, false)
      return resp
    }).catch(err => {
      context.commit(ZoneMutations.ERROR_ZONES, err)
      throw new Error(err)
    })
  },

  [ZoneActions.ASSIGN_ZONE_TO_DARKSTORE]: async (context, payload): Promise<any> => {
    context.commit(ZoneMutations.REQUEST_ZONES)
    return await axiosInstance({
      ...apiEndpoints.zones.assign_zone_to_darkstore(payload.zone_id),
      data: payload
    }).then(resp => {
      toast.success('The zone has been assigned to the darkstore successfully', {
        timeout: 3000
      })
      context.commit(ZoneMutations.REQUEST_ZONES, false)
      return resp
    }).catch(err => {
      context.commit(ZoneMutations.ERROR_ZONES, err)
      throw new Error(err)
    })
  },

  [ZoneActions.UN_ASSIGN_ZONE_TO_DARKSTORE]: async (context, payload): Promise<any> => {
    context.commit(ZoneMutations.REQUEST_ZONES)
    return await axiosInstance({
      ...apiEndpoints.zones.un_assign_zone_to_darkstore(payload.zone_id),
      data: payload
    }).then(resp => {
      toast.success('The zone has been unassigned from the darkstore successfully', {
        timeout: 3000
      })
      context.commit(ZoneMutations.REQUEST_ZONES, false)
      return resp
    }).catch(err => {
      context.commit(ZoneMutations.ERROR_ZONES, err)
      throw new Error(err)
    })
  }
}
