
import { defineComponent, defineAsyncComponent } from 'vue'
import { Driver } from '@/shared/interfaces/driver.interface'
import { DriverActions } from '@/store/modules/drivers/actions'
import ConfirmationModal from '@/shared/components/modals/ConfirmationModal.vue'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
import BaseModal from '@/shared/components/modals/BaseModal.vue'
import { useToast } from 'vue-toastification'
import { LocationActions } from '@/store/modules/locations/actions'
import DriverMap from '@/views/account/drivers/components/DriverMap.vue'
import moment from 'moment'
import Toggle from '@/shared/components/Toggle.vue'
import DriverBalance from '@/views/account/drivers/components/DriverBalance.vue'

export default defineComponent({
  data () {
    return {
      tableItems: [
        { name: this.$t('drivers.currentStatus'), path: 'current-status', isActive: false },
        { name: this.$t('drivers.orderHistory'), path: 'order-history', isActive: false },
        // { name: this.$t('drivers.cashSettlement'), path: 'cash-settlement', isActive: false },
        { name: this.$t('drivers.assignedOrders'), path: 'assigned-orders', isActive: false }
      ],
      status: null as any,
      showUpdateStatusConfirmation: false,
      showBoxNumberModal: false,
      trackingIdData: '' as any,
      showTrackingIdModal: false,
      showAddressModal: false,
      boxNumberData: '',
      district: '',
      building: '',
      flat: '',
      floor: '',
      landmark: '',
      neighborhood: '',
      driverLastLocation: null,
      checkUnpaidOrdersFromThePreviousDay: true,
      moment
    }
  },
  async mounted () {
    const segmentArry = this.$route.fullPath.split('/')
    const lastSegment = segmentArry[segmentArry.length - 1]
    this.setActiveTab(lastSegment)
    this.$store.dispatch(`drivers/${DriverActions.FETCH_SINGLE_DRIVER}`, this.$route.params.id)
    if (this.isSuperAdmin) {
      this.$store.dispatch(`drivers/${DriverActions.FETCH_DRIVER_STATUSES}`)
    }
    this.driverLastLocation = await this.$store.dispatch(`locations/${LocationActions.FETCH_DRIVER_LAST_LOCATION}`, this.$route.params.id)
  },
  components: {
    DriverBalance,
    Toggle,
    DriverMap,
    BaseModal,
    BaseIcon,
    ConfirmationModal,
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    Tab: defineAsyncComponent(() => import('@/shared/components/Tab.vue')),
    Multiselect: defineAsyncComponent(() => import('@vueform/multiselect')),
    Popper: defineAsyncComponent(() => import('vue3-popper'))
  },
  computed: {
    isSuperAdmin (): boolean {
      return this.$store.getters.isSuperAdmin
    },
    driver (): Driver {
      return this.$store.getters['drivers/getDriver']
    },
    statuses (): any {
      return this.$store.getters['drivers/getDriverStatuses']
    }
  },
  methods: {
    updateBoxNumber (): void {
      const body = {
        box_number: this.boxNumberData
      }
      this.$store.dispatch(`drivers/${DriverActions.UPDATE_DRIVER}`, { data: body, driverId: this.driver.id }).then((res: any) => {
        this.showBoxNumberModal = false
        if (res.data?.info) {
          const toast = useToast()
          toast.success(res.data.info.message, {
            timeout: 3000
          })
          this.$store.dispatch(`drivers/${DriverActions.FETCH_SINGLE_DRIVER}`, this.$route.params.id)
        }
      })
    },
    updateTrackingId (): void {
      const body = {
        box_number: this.boxNumberData,
        tracking_id: typeof this.trackingIdData === 'number' ? this.trackingIdData : null
      }
      this.$store.dispatch(`drivers/${DriverActions.UPDATE_DRIVER}`, { data: body, driverId: this.driver.id }).then((res: any) => {
        this.showTrackingIdModal = false
        if (res.data?.info) {
          const toast = useToast()
          toast.success(res.data.info.message, {
            timeout: 3000
          })
          this.$store.dispatch(`drivers/${DriverActions.FETCH_SINGLE_DRIVER}`, this.$route.params.id)
        }
      })
    },
    deleteTrackingId (): void {
      const body = {
        box_number: this.boxNumberData,
        tracking_id: null
      }
      this.$store.dispatch(`drivers/${DriverActions.UPDATE_DRIVER}`, { data: body, driverId: this.driver.id }).then((res: any) => {
        if (res.data?.info) {
          const toast = useToast()
          toast.success(res.data.info.message, {
            timeout: 3000
          })
          this.$store.dispatch(`drivers/${DriverActions.FETCH_SINGLE_DRIVER}`, this.$route.params.id)
        }
      })
    },
    updateAddress (): void {
      const body = {
        address_building: this.building,
        address_district: this.district,
        address_flat: this.flat,
        address_floor: this.floor,
        address_nearest_landmark: this.landmark,
        address_neighborhood: this.neighborhood,
        box_number: this.boxNumberData || ''
      }
      this.$store.dispatch(`drivers/${DriverActions.UPDATE_DRIVER}`, { data: body, driverId: this.driver.id }).then((res: any) => {
        this.showAddressModal = false
        if (res.data?.info) {
          const toast = useToast()
          toast.success(res.data.info.message, {
            timeout: 3000
          })
        }
      })
    },
    onChangeTab (value: any): void {
      this.$router.push(value.path)
      this.setActiveTab(value.path)
    },
    setActiveTab (path: string): void {
      this.tableItems = this.tableItems.map((el: any) => {
        el.isActive = el.path === path
        return el
      })
    },
    downloadFile (url: any) {
      window.open(url, '_blank')
      // const a = document.createElement('a')
      // a.href = url
      // a.download = url.split('/').pop()
      // document.body.appendChild(a)
      // a.click()
      // document.body.removeChild(a)
    },
    updateDriverStatus (): void {
      this.$store.dispatch(`drivers/${DriverActions.UPDATE_STATUS}`, { driverId: this.driver.id, statusId: this.status.id })
    },
    onUpdateCheckUnpaidOrders (): void {
      const body = {
        check_unpaid_orders_from_the_previous_day: typeof this.checkUnpaidOrdersFromThePreviousDay === 'boolean' ? this.checkUnpaidOrdersFromThePreviousDay : undefined,
        box_number: this.boxNumberData
      }
      this.$store.dispatch(`drivers/${DriverActions.UPDATE_DRIVER}`, { data: body, driverId: this.driver.id }).then((res: any) => {
        if (res.data?.info) {
          const toast = useToast()
          toast.success(res.data.info.message, {
            timeout: 3000
          })
          this.$store.dispatch(`drivers/${DriverActions.FETCH_SINGLE_DRIVER}`, this.$route.params.id)
        }
      })
    },
    reTryErpSync (): void {
      this.$store.dispatch(`drivers/${DriverActions.RE_TRY_ERP_SYNC}`, { driverId: this.driver.id })
        .then(() => {
          this.$store.dispatch(`drivers/${DriverActions.FETCH_SINGLE_DRIVER}`, this.driver.id)
        })
    }
  },
  watch: {
    driver (val: Driver) {
      this.status = val.driverStatus
      this.boxNumberData = val.boxNumber
      this.building = val.driverAddress.addressBuilding
      this.district = val.driverAddress.addressDistrict
      this.flat = val.driverAddress.addressFlat
      this.floor = val.driverAddress.addressFloor
      this.landmark = val.driverAddress.addressNearestLandmark
      this.neighborhood = val.driverAddress.addressNeighborhood
      this.trackingIdData = val?.trackingId ?? ''
      this.checkUnpaidOrdersFromThePreviousDay = val?.checkUnpaidOrdersFromThePreviousDay ?? false
    }
  }
})
