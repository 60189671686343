
import { defineAsyncComponent, defineComponent } from 'vue'
import { OrderActions } from '@/store/modules/orders/actions'
import router from '@/router'

export default defineComponent({
  components: {
    OrderDetails: defineAsyncComponent(() => import('@/views/account/drivers/components/OrderDetails.vue')),
    OrdersGhost: defineAsyncComponent(() => import('@/shared/components/ghosts/OrdersGhost.vue')),
    Pagination: defineAsyncComponent(() => import('@/shared/components/Pagination.vue'))
  },
  data () {
    return {
      current: 1
    }
  },
  watch: {
    current (val) {
      router.push({ query: { ...this.$route.query, pageNumber: val } })
    },
    $route: {
      immediate: true,
      handler (val) {
        if (val.name === 'AssignedOrders') {
          this.current = this.$route.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
          const driverId = val.params.id
          this.$store.dispatch(`orders/${OrderActions.FETCH_ASSIGNED_DRIVER_ORDERS}`, { driverId, pageNumber: this.current })
        }
      }
    }
  },
  computed: {
    orders () {
      return this.$store.getters['orders/getOrders']
    },
    loading () {
      return this.$store.getters['orders/getLoading']
    },
    pagination () {
      return this.$store.getters['orders/getPagination']
    }
  },
  async mounted () {
    const driverId = this.$route.params.id
    this.$store.dispatch(`orders/${OrderActions.FETCH_ASSIGNED_DRIVER_ORDERS}`, { driverId, pageNumber: 1 })
  }
})
