import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { apiEndpoints } from '@/shared/endpoints/api'
import axiosInstance from '@/shared/utils/axiosInstance'
import { ClientsState } from '@/store/modules/clients/types'
import { ClientsMutations } from '@/store/modules/clients/mutations'
import { ClientsModel } from '@/shared/models/clients.model'
import { useToast } from 'vue-toastification'
import { OrderModel } from '@/shared/models/order.model'
import { OrderStatusesEnum } from '@/shared/enums/order-statuses.enum'
import moment from 'moment'
const toast = useToast()

export enum ClientActions {
  FETCH_CLIENTS = 'FETCH_CLIENTS',
  TRIGGER_LOADING = 'TRIGGER_LOADING',
  CREATE_CLIENT = 'CREATE_CLIENT',
  UPDATE_CLIENT = 'UPDATE_CLIENT',
  DELETE_CLIENT = 'DELETE_CLIENT',
  FETCH_CLIENT_ORDERS = 'FETCH_CLIENT_ORDERS',
  IMPORT_GENERAL_ORDER = 'IMPORT_GENERAL_ORDER',
  CREATE_GENERAL_ORDER = 'CREATE_GENERAL_ORDER',
}

export const actions: ActionTree<ClientsState, RootState> = {
  [ClientActions.FETCH_CLIENTS]: async (context, payload) => {
    context.commit(ClientsMutations.TRIGGER_LOADING, true)
    const params = new URLSearchParams()
    if (payload.pageNumber) {
      params.append('page_number', payload.pageNumber)
    }
    if (payload.pageSize) {
      params.append('per_page', payload.pageSize)
    }
    if (payload.search && payload.search !== '') {
      params.append('search', payload.search)
    }
    await axiosInstance({
      ...apiEndpoints.clients.all,
      params
    }).then((resp: any) => {
      context.commit(ClientsMutations.TRIGGER_LOADING, false)
      const clientModel = new ClientsModel(resp.data)
      const payload = {
        data: clientModel.data,
        pagination: clientModel.paginationData
      }
      context.commit(ClientsMutations.SET_CLIENTS, payload)
    }).catch((err: any) => {
      context.commit(ClientsMutations.TRIGGER_LOADING, false)
      throw new Error(err)
    })
  },
  [ClientActions.CREATE_CLIENT]: async (context, payload) => {
    context.commit(ClientsMutations.TRIGGER_LOADING, true)
    return axiosInstance({
      ...apiEndpoints.clients.create,
      data: payload
    }).then((resp: any) => {
      toast.success('Client has been created successfully', {
        timeout: 3000
      })
    }).catch((err: any) => {
      throw new Error(err)
    }).finally(() => context.commit(ClientsMutations.TRIGGER_LOADING, false))
  },
  [ClientActions.UPDATE_CLIENT]: async (context, payload) => {
    context.commit(ClientsMutations.TRIGGER_LOADING, true)
    return axiosInstance({
      ...apiEndpoints.clients.update(payload.id),
      data: {
        ...payload,
        id: undefined
      }
    }).then((resp: any) => {
      toast.success('Client has been updated successfully', {
        timeout: 3000
      })
    }).catch((err: any) => {
      throw new Error(err)
    }).finally(() => context.commit(ClientsMutations.TRIGGER_LOADING, false))
  },
  [ClientActions.DELETE_CLIENT]: async (context, id) => {
    context.commit(ClientsMutations.TRIGGER_LOADING, true)
    return axiosInstance({
      ...apiEndpoints.clients.delete(id)
    }).then((resp: any) => {
      toast.success('Client has been removed successfully', {
        timeout: 3000
      })
    }).catch((err: any) => {
      throw new Error(err)
    }).finally(() => context.commit(ClientsMutations.TRIGGER_LOADING, false))
  },
  [ClientActions.FETCH_CLIENT_ORDERS]: async (context, payload) => {
    context.commit(ClientsMutations.TRIGGER_LOADING, true)
    const params = new URLSearchParams()
    params.append('per_page', payload.pageSize)
    params.append('page_number', payload.pageNumber)
    if (payload.priority && payload.priority !== 'all') {
      params.append('is_prime', payload.priority === 'prime' ? '1' : '0')
    }
    if (payload.orderStatus) {
      if (payload.orderStatus === 'prime') {
        params.append('is_prime', '1')
      }
      if (payload.orderStatus === 'delivered-not-settled') {
        params.append('settlement_status', 'init')
        params.append('order_status_ids', '7') // delivered
      }
      if (OrderStatusesEnum[payload.orderStatus]) {
        if (OrderStatusesEnum[payload.orderStatus] === 'not_assigned') {
          params.append('no_driver', '1')
          params.append('order_status_ids', '1')
          params.append('order_status_ids', '2')
        } else {
          params.append('order_status_ids', OrderStatusesEnum[payload.orderStatus])
        }
      }
    }
    if (payload.createdAt_from) {
      params.append('from_date', moment(payload.createdAt_from).format('YYYY-MM-DD hh:mm:ss'))
      params.append('to_date', moment(payload.createdAt_to).format('YYYY-MM-DD hh:mm:ss'))
    } else {
      params.append('from_date', moment().add(-24, 'h').format('YYYY-MM-DD HH:mm:ss'))
      params.append('to_date', moment().add(1, 'h').format('YYYY-MM-DD HH:mm:ss'))
    }
    if (payload.search && payload.search !== '') {
      params.append('search', payload.search)
    }
    if (payload.currentAssigned) {
      params.append('current_assigned', payload.currentAssigned)
    }
    if (payload.isDelayed) {
      params.append('is_delayed', payload.isDelayed)
    }
    if (payload.darkstoreIds && payload.darkstoreIds.split(':')[1] !== '') {
      payload.darkstoreIds.split(':')[1].split(',').forEach((el: any) => {
        params.append('dark_store_ids', el)
      })
    } else {
      delete payload.darkstoreIds
    }
    if (payload.orderBy && payload.sort) {
      params.append('order_by', `${payload.orderBy}_${payload.sort === 'asc' ? 'asc' : 'desc'}`)
    }
    if (payload.assigmentType && ['manual', 'auto'].includes(payload.assigmentType)) {
      const assigmentTypeVal = payload.assigmentType === 'manual' ? 1 : 0
      params.append('is_manual', assigmentTypeVal.toString())
    }
    if (payload.driverId) {
      params.append('driver_ids', payload.driverId)
    }
    if (payload.settlementStatus) {
      params.append('settlement_status', payload.settlementStatus)
    }
    if (payload.pickupErpSyncStatus) {
      params.append('pickup_erp_sync_status', payload.pickupErpSyncStatus)
    }
    if (payload.deliveredErpSyncStatus) {
      params.append('delivered_erp_sync_status', payload.deliveredErpSyncStatus)
    }
    if (payload.canceledErpSyncStatus) {
      params.append('canceled_erp_sync_status', payload.canceledErpSyncStatus)
    }
    await axiosInstance({
      ...apiEndpoints.clients.orders,
      params
    }).then((resp: any) => {
      context.commit(ClientsMutations.TRIGGER_LOADING, false)

      const orderModel = new OrderModel(resp.data)
      const data = {
        data: orderModel.data,
        pagination: orderModel.paginationData
      }
      context.commit(ClientsMutations.SET_ORDERS, data)
    }).catch((err: any) => {
      context.commit(ClientsMutations.TRIGGER_LOADING, false)
      throw new Error(err)
    })
  },
  [ClientActions.IMPORT_GENERAL_ORDER]: (context, payload) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('file', payload.file)
      formData.append('order_status', payload.orderStatus)
      axiosInstance({
        ...apiEndpoints.clients.createBulkOrder,
        data: formData
      }).then((res: any) => {
        resolve(res)
      }).catch(err => {
        const toast = useToast()
        const errorText = err.response.data.data[0].result
        toast.error(errorText, {
          timeout: 5000
        })
        reject(err)
        throw new Error(err)
      })
    })
  },
  [ClientActions.CREATE_GENERAL_ORDER]: (context, payload) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        ...apiEndpoints.clients.createOrder,
        data: payload
      }).then((res: any) => {
        const toast = useToast()
        toast.success(res.data?.info?.message, {
          timeout: 3000
        })
        resolve(res)
      }).catch(err => {
        throw new Error(err)
        reject(err)
      })
    })
  }
}
