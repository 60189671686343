
import { defineAsyncComponent, defineComponent } from 'vue'
import { OrderActions } from '@/store/modules/orders/actions'
import { VehiclesActions } from '@/store/modules/vehicles/actions'
import router from '@/router'
import { DarkStoreInterface } from '@/shared/interfaces/darkstore.interface'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'
import { OrdersListInterface } from '@//shared/interfaces/orders-list.interface'
import { OrderMutations } from '@/store/modules/orders/mutations'

export default defineComponent({
  name: 'orders',
  components: {
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    OrdersGhost: defineAsyncComponent(() => import('@/shared/components/ghosts/OrdersGhost.vue')),
    OrdersMap: defineAsyncComponent(() => import('@/views/account/orders/components/OrdersMap.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    OrderModal: defineAsyncComponent(() => import('@/views/account/orders/components/OrderModalTabView.vue')),
    Order: defineAsyncComponent(() => import('@/views/account/orders/components/Order.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    CreateOrderModal: defineAsyncComponent(() => import('@/views/account/orders/components/OrderCreateModal.vue')),
    BulkOrderCreateModal: defineAsyncComponent(() => import('@/views/account/orders/components/BulkOrderCreateModal.vue')),
    BulkOrderSelectButton: defineAsyncComponent(() => import('@/views/account/orders/components/BulkOrderSelectButton.vue'))

  },
  data () {
    return {
      showModal: false,
      selectedOrder: null,
      searchValue: '',
      orderType: 'all',
      pageNumber: 1,
      timeout: null as any,
      reset: false,
      showFilterModal: false,
      ordersIds: [] as any,
      interval: null as any,
      statusesNeedToChecked: ['assigned', 'not_assigned', 'ready_for_pick_up', 'preparing', 'picked_up', 'on_the_way', 'at_the_address', 'to_return', 'to_return_assigned'],
      openedOrderId: null as any,
      filters: [
        {
          name: this.$t('filters.orderBy'),
          model: '',
          type: 'order',
          data: [
            { id: 'created_at', value: this.$t('filters.createdAt') },
            { id: 'delay_time', value: this.$t('filters.delayTime') }
          ]
        },
        {
          name: this.$t('filters.isDelayed'),
          model: 'isDelayed',
          type: 'radio',
          data: [
            { id: 1, label: this.$t('filters.yes') },
            { id: 0, label: this.$t('filters.no') }
          ]
        },
        {
          name: this.$t('filters.currentAssigned'),
          model: 'currentAssigned',
          type: 'radio',
          data: [
            { id: 1, label: this.$t('filters.yes') },
            { id: 0, label: this.$t('filters.no') }
          ]
        },
        {
          name: this.$t('filters.darkstores'),
          model: 'darkstoreIds',
          type: 'checkbox',
          data: []
        },
        { name: this.$t('filters.createdAt'), model: 'createdAt', type: 'date', calendarType: 'range' },
        {
          name: this.$t('filters.assigmentType'),
          model: 'assigmentType',
          type: 'radio',
          data: [
            { id: 'manual', label: this.$t('filters.manual') },
            { id: 'auto', label: this.$t('filters.auto') },
            { id: 'all', label: this.$t('filters.all') }
          ]
        },
        {
          name: this.$t('filters.orderPriority'),
          model: 'priority',
          type: 'radio',
          data: [
            { id: 'all', label: this.$t('filters.all') },
            { id: 'normal', label: this.$t('filters.normal') },
            { id: 'prime', label: this.$t('filters.prime') }
          ]
        },
        {
          name: this.$t('filters.settlementStatus'),
          model: 'settlementStatus',
          type: 'radio',
          data: [
            { id: undefined, label: this.$t('filters.all') },
            { id: 'init', label: this.$t('orders.settlementStatus.init') },
            { id: 'requested', label: this.$t('orders.settlementStatus.requested') },
            { id: 'approved', label: this.$t('orders.settlementStatus.approved') },
            { id: 'rejected', label: this.$t('orders.settlementStatus.rejected') }
          ]
        }
      ],
      initialDetailedRequested: false,
      linkedOrderIds: [],
      orderDetail: null as any,
      orderSyncRequested: false,
      showCreateOrderModal: false,
      showBulkCreateOrderModal: false
    }
  },
  mounted () {
    this.$store.dispatch(`orders/${OrderActions.FETCH_ORDER_STATUSES}`)
    this.$store.dispatch(`vehicles/${VehiclesActions.FETCH_VEHICLE_TYPES}`)
    this.getDarkStores()
    this.interval = setInterval(async () => {
      const response = await this.$store.dispatch(`orders/${OrderActions.FETCH_ORDERS_STATUSES}`, {
        ordersIds: [...this.ordersIds, ...this.linkedOrderIds], orderType: this.$route.params.orderType
      })
      if (response?.data?.data && this.orderDetail && this.orderDetail.linkedOrders && this.orderDetail.linkedOrders.length) {
        this.orderDetail.linkedOrders.forEach((o:any, i:number) => {
          const index = response.data.data.findIndex((x:any) => x.order_id === o.order_id)
          if (index > -1) {
            if (this.orderDetail.linkedOrders[i].order_status.name !== response.data.data[index].order_status.name) {
              this.orderDetail.linkedOrders[i].order_status = response.data.data[index].order_status
            }
          }
        })
      }
    }, 10000)
  },
  methods: {
    editModal (event: any) {
      this.showModal = false
      this.showBulkCreateOrderModal = false
      this.showCreateOrderModal = false
      if (this.statusesNeedToChecked.includes(event.statusName)) {
        this.openedOrderId = event.orderStringId
      } else {
        this.openedOrderId = null
      }
      this.$store.dispatch(`orders/${OrderActions.FETCH_ORDER_BY_ID}`, { orderId: event.orderId }).then(() => {
        this.orderDetail = { ...this.detailedOrder }
        this.showModal = true
        this.orderSyncRequested = false
      })
    },
    loadMore (): void {
      this.reset = false
      this.pageNumber++
      this.getOrders(this.$route, false)
    },
    getOrders (route: any, resetPage = true): void {
      if (resetPage) {
        this.pageNumber = 1
      }
      if (route.name === 'Orders') {
        if (route.query.search) {
          this.searchValue = route.query.search
        }
        let params = JSON.parse(JSON.stringify(route.query))
        Object.assign(params, { orderStatus: route.params.orderType })
        params = { pageNumber: this.pageNumber ? this.pageNumber : 1, pageSize: 10, ...params, reset: this.reset }
        this.$store.dispatch(`orders/${OrderActions.FETCH_ORDERS}`, params)
      }
    },
    resetOrderData (data: any): void {
      this.$store.dispatch(`orders/${OrderActions.FETCH_ORDER_BY_ID}`, { orderId: data.orderId }).then(() => {
        this.orderDetail = { ...this.detailedOrder }
      })
    },
    getDarkStores (pageNumber = 1, pageSize = 100): void {
      this.$store.dispatch(`darkstores/${DarkStoresActions.FETCH_DARKSTORES}`, { pageNumber, pageSize })
    },
    checkNextPageAndNavigate (): void {
      const currentPage = this.$route.params.orderType
      let nextPage: string | undefined
      switch (currentPage) {
        case 'preparing':
          nextPage = 'assigned'
          break
        case 'assigned':
          nextPage = 'ready_for_pick_up'
          break
        case 'ready_for_pick_up':
          nextPage = 'picked_up'
          break
        case 'picked_up':
          nextPage = 'on_the_way'
          break
        case 'on_the_way':
          nextPage = 'at_the_address'
          break
        case 'at_the_address':
          nextPage = 'delivered'
          break
        case 'not_assigned':
          nextPage = 'assigned'
          break
        default: nextPage = undefined
      }

      if (nextPage) {
        router.replace({ params: { orderType: nextPage }, replace: true })
        this.$store.commit(`orders/${OrderMutations.NAVIGATE_TO_NEXT_PAGE}`, false)
      }
    },
    openRelatedOrderModal (orderId: string) {
      this.showModal = false
      this.showBulkCreateOrderModal = false
      this.showCreateOrderModal = false
      this.$store.dispatch(`orders/${OrderActions.FETCH_ORDER_BY_ID}`, { orderId }).then(() => {
        this.orderDetail = { ...this.detailedOrder }
        this.showModal = true
      })
    },
    reTryErpSync (data: any): void {
      const payload = { status_to_retry: '' }
      if (data?.pickupErpSyncStatus === 'failed') {
        payload.status_to_retry = 'pickup'
      } else if (data?.deliveredErpSyncStatus === 'failed') {
        payload.status_to_retry = 'delivered'
      } else if (data?.canceledErpSyncStatus === 'failed') {
        payload.status_to_retry = 'canceled'
      }
      this.$store.dispatch(`orders/${OrderActions.RE_TRY_ERP_SYNC}`, { orderId: data.id, payload })
        .then(() => {
          this.orderSyncRequested = true
          this.resetOrderData(data)
        })
    },
    onOrderCreatedHandler () {
      this.showBulkCreateOrderModal = false
      this.showCreateOrderModal = false
      this.getOrders(this.$route)
    }
  },
  computed: {
    orders () {
      return this.$store.getters['orders/getOrders']
    },
    navigateToNextPage () {
      return this.$store.getters['orders/getNavigateToNextPage']
    },
    detailedOrder () {
      return this.$store.getters['orders/getDetailedOrder']
    },
    loading () {
      return this.$store.getters['orders/getLoading']
    },
    paginations () {
      return this.$store.getters['orders/getPagination']
    },
    darkstores (): DarkStoreInterface[] {
      return this.$store.getters['darkstores/getDarkStoresAsDictionary']
    },
    getNewOrderCreated (): boolean {
      return this.$store.getters.getNewOrderCreated
    },
    isPrimeOrders (vm) {
      return !!(vm.$route?.params?.orderType && vm.$route?.params?.orderType === 'prime')
    },
    isSuperAdmin (): boolean {
      return this.$store.getters.isSuperAdmin
    },
    isAdmin (): boolean {
      return this.$store.getters.isAdmin
    },
    isClientAdmin (): boolean {
      return this.$store.getters.isClientAdmin
    }
  },
  watch: {
    getNewOrderCreated (val) {
      if (val) {
        this.getOrders(this.$route)
      }
    },
    $route: {
      immediate: true,
      handler (val) {
        if (val.name === 'Orders') {
          const params = val
          if (!val.query.orderBy) {
            Object.assign(params.query, { orderBy: 'created_at', sort: 'desc' })
          }
          if (val.query.id && !this.initialDetailedRequested) {
            this.editModal({ orderId: val.query.id, statusName: '' })
            this.initialDetailedRequested = true
          } else {
            val.query.id = undefined
          }
          this.reset = true
          this.getOrders(params)
        }
      }
    },
    navigateToNextPage (val) {
      if (val) {
        this.checkNextPageAndNavigate()
      }
    },
    darkstores: {
      immediate: true,
      handler (val) {
        this.filters[3].data = val
      }
    },
    searchValue () {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        this.reset = true
        this.pageNumber = 1
        router.push({ query: { ...this.$route.query, search: this.searchValue } })
      }, 500)
    },
    orders (orders: OrdersListInterface[]) {
      this.ordersIds = orders.filter((order: OrdersListInterface) => this.statusesNeedToChecked.includes(order.orderStatus.name)).map((order: OrdersListInterface) => {
        return order.orderId
      })
      if (this.openedOrderId && !this.ordersIds.includes(this.openedOrderId)) {
        this.ordersIds.push(this.openedOrderId)
      }
    }
  },
  beforeUnmount () {
    clearInterval(this.interval)
  }
})
