
import { defineAsyncComponent, defineComponent } from 'vue'
import { ZoneActions } from '@/store/modules/zones/actions'
import router from '@/router'
import { ZoneInterface } from '@/shared/interfaces/zone.interface'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import { ZoneModel } from '@/shared/models/zone.model'

export default defineComponent({
  components: {
    SearchInput: defineAsyncComponent(() => import('@/shared/components/SearchInput.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    Pagination: defineAsyncComponent(() => import('@/shared/components/Pagination.vue')),
    TableGhost: defineAsyncComponent(() => import('@/shared/components/ghosts/TableGhost.vue')),
    BaseModal: defineAsyncComponent(() => import('@/shared/components/modals/BaseModal.vue')),
    ZoneMap: defineAsyncComponent(() => import('@/views/account/zones/components/ZoneMap.vue')),
    ConfirmationModal: defineAsyncComponent(() => import('@/shared/components/modals/ConfirmationModal.vue')),
    ZoneDarkStoreSettings: defineAsyncComponent(() => import('@/views/account/zones/components/ZoneDarkStoreSettings.vue'))
  },
  data () {
    return {
      searchValue: '',
      height: 400,
      current: 1,
      highlightIndex: -1,
      shortTable: true,
      showZoneDetails: null,
      selectedZone: null as any,
      selectedZoneToDelete: null as any
    }
  },
  methods: {
    showDetails (zone:ZoneInterface) {
      this.showZoneDetails = {
        ...zone,
        polygon: [ZoneModel.convertToMapPolygonFormat(zone.polygon)]
      } as any
    },
    async deleteZone () {
      if (this.selectedZoneToDelete) {
        await this.$store.dispatch(`zones/${ZoneActions.DELETE_ZONE}`, this.selectedZoneToDelete?.id)
        this.current = 1
        this.selectedZoneToDelete = null
        this.$store.dispatch(`zones/${ZoneActions.FETCH_ZONES}`, { pageSize: 10, pageNumber: 1 })
      }
    },
    startAnimation (): void {
      const container: any = this.$refs.zonesContent
      const map: any = this.$refs.zonesMap
      if (this.shortTable) {
        container.classList.add('animate-to-end')
        map.classList.add('w-0')
        map.classList.remove('calculated-width')
      } else {
        container.classList.remove('animate-to-end')
        map.classList.add('calculated-width')
        map.classList.remove('w-0')
      }
      this.shortTable = !this.shortTable
    },
    async onSync () {
      const val = this.$route
      this.current = this.$route.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
      if (val.query.search) {
        this.searchValue = val.query.search as any
      }
      let params = JSON.parse(JSON.stringify(val.query))
      params = { pageSize: 10, pageNumber: val.query.pageNumber ? val.query.pageNumber : 1, ...params }
      await this.$store.dispatch(`zones/${ZoneActions.FETCH_ZONES}`, params)

      if (this.selectedZone) {
        const latest = this.zones.filter(z => z.id === this.selectedZone.id)
        if (latest.length) {
          this.selectedZone = latest[0]
        }
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (val) {
        if (val.name === 'Zones') {
          this.highlightIndex = -1
          this.current = this.$route.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
          if (val.query.search) {
            this.searchValue = val.query.search
          }
          let params = JSON.parse(JSON.stringify(val.query))
          params = { pageSize: 10, pageNumber: val.query.pageNumber ? val.query.pageNumber : 1, ...params }
          this.$store.dispatch(`zones/${ZoneActions.FETCH_ZONES}`, params)
        }
      }
    },
    current (val) {
      router.push({ query: { ...this.$route.query, pageNumber: val } })
    },
    searchValue () {
      const queries = this.$route.query
      queries.pageNumber = '1'
      router.push({ query: { ...queries, search: this.searchValue } })
    }
  },
  mounted () {
    this.height = document.documentElement.clientHeight - 260
    addEventListener('resize', () => {
      this.height = document.documentElement.clientHeight - 260
    })
  },
  computed: {
    zones (): ZoneInterface[] {
      return this.$store.getters['zones/getZones']
    },
    pagination (): PaginationInterface {
      return this.$store.getters['zones/getPagination']
    },
    loading (): boolean {
      return this.$store.getters['zones/getLoading']
    },
    allZoneCoordinates (vm) {
      return vm.zones.map((z: ZoneInterface) => {
        return [
          ...ZoneModel.convertToMapPolygonFormat(z.polygon)
        ]
      })
    }
  }
})
