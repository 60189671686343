

import { defineComponent, defineAsyncComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@/customValidators'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'
import Datepicker from '@vuepic/vue-datepicker'

export default defineComponent({
  name: 'CreateOrderDarkstore',
  components: {
    Datepicker,
    CreateOrderLocationSelectMap: defineAsyncComponent(() => import('@/views/account/orders/components/CreateOrderLocationSelectMap.vue')),
    CitySelect: defineAsyncComponent(() => import('@/views/account/darkstores/components/CitySelect.vue'))
  },
  data () {
    return {
      form: {
        name: '',
        city_id: undefined as any,
        latitude: 1,
        longitude: 1,
        open_time: { hours: 9, minutes: 0, seconds: 0 } as any,
        close_time: { hours: 18, minutes: 0, seconds: 0 } as any
      }
    }
  },
  validations: function () {
    return {
      form: {
        name: { required },
        city_id: { required },
        latitude: { required },
        longitude: { required },
        open_time: { required },
        close_time: { required }
      }
    }
  },
  setup (_, { emit }) {
    const closeModal = ($event: any, out = true) => {
      if (out && !$event.inner) {
        emit('close', false)
      } else $event.inner = true
    }
    return {
      closeModal,
      v$: useVuelidate()
    }
  },
  methods: {
    getHoursStr (hoursObject: any) {
      let str = ''
      str += (hoursObject.hours > 9 ? hoursObject.hours : `0${hoursObject.hours}`) + ':'
      str += hoursObject.minutes > 9 ? hoursObject.minutes : `0${hoursObject.minutes}`
      return str
    },
    async submitForm (validator: any): Promise<any> {
      validator.$touch()

      if (validator.$invalid) return
      if (!validator.$invalid) {
        this.$store.dispatch(`darkstores/${[DarkStoresActions.CREATE_DARKSTORE]}`, {
          name: this.form.name,
          latitude: this.form.latitude,
          longitude: this.form.longitude,
          city_id: this.form.city_id.id,
          open_time: this.getHoursStr(this.form.open_time),
          close_time: this.getHoursStr(this.form.close_time)
        }).then(() => {
          this.$emit('onDarkstoreCreated')
        })
      }
    },
    onLocationSelected (data: { lat: number, lng: number }) {
      this.form.latitude = data.lat as any
      this.form.longitude = data.lng as any
    }
  }
})
