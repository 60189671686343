import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import { VehiclesState } from '@/store/modules/vehicles/types'
import { VehicleInterface, VehicleTypeInterface } from '@/shared/interfaces/vehicle.interface'

export const getters: GetterTree<VehiclesState, RootState> = {
  getVehicles (state): VehicleInterface[] {
    return state.vehicleList
  },
  getVehiclesWithShiftId (state): any {
    return state.availableVehicleList.map(el => {
      return { id: el.id, value: `${el.name} ${el.model}` }
    })
  },
  getPagination (state): PaginationInterface | null {
    return state.pagination
  },
  getVehicleTypes (state): VehicleTypeInterface[] {
    return state.vehicleTypes
  },
  getVehiclesForInvitation (state): any[] {
    return state.vehicleList.map(el => {
      return { id: el.id, value: `${el.name} ${el.model}` }
    })
  },
  getLoading (state): boolean {
    return state.loading
  },
  getVehicleModels (state): any[] {
    return state.vehicleModels
  },
  vehicleModelsLoading (state): boolean {
    return state.vehicleModelLoading
  },
  getVehiclesMake (state): any[] {
    return state.vehiclesMake
  },
  vehiclesMakeLoading (state): boolean {
    return state.vehiclesMakeLoading
  }
}
