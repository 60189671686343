import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pt-14 pb-5 px-5" }
const _hoisted_2 = { class: "flex justify-between mb-7" }
const _hoisted_3 = { class: "flex justify-start w-full" }
const _hoisted_4 = { class: "flex gap-4 w-full" }
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "px-5" }
const _hoisted_8 = {
  class: "absolute top-1 flex justify-center w-full mx-0",
  style: {"z-index":"9999"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_BulkOrderSelectButton = _resolveComponent("BulkOrderSelectButton")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_FilterPanel = _resolveComponent("FilterPanel")!
  const _component_DynamicTable = _resolveComponent("DynamicTable")!
  const _component_BulkOrderCreateModal = _resolveComponent("BulkOrderCreateModal")!
  const _component_CreateOrderModal = _resolveComponent("CreateOrderModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PageTitle, { title: "nav.clientOrders" }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_BulkOrderSelectButton, {
              onUpload: _cache[0] || (_cache[0] = () => {
              _ctx.showBulkCreateOrderModal = true;
              _ctx.showCreateOrderModal = false
              _ctx.showFilterModal = false
            })
            }),
            _createElementVNode("button", {
              class: "primary py-2 px-2",
              onClick: _cache[1] || (_cache[1] = () => {
              _ctx.showBulkCreateOrderModal = false;
              _ctx.showCreateOrderModal = true;
              _ctx.showFilterModal = false
            })
            }, _toDisplayString(_ctx.$t('orderCreateModal.title')), 1),
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showFilterModal = true)),
              class: "border border-inputBorder w-12 h-42"
            }, [
              _createElementVNode("span", _hoisted_5, [
                _createVNode(_component_BaseIcon, { name: "filter" })
              ])
            ]),
            (_ctx.showFilterModal)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_FilterPanel, {
                    filters: _ctx.filters,
                    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showFilterModal = false))
                  }, null, 8, ["filters"])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_DynamicTable, {
        tableData: _ctx.orders,
        loading: _ctx.loading,
        pagination: _ctx.pagination,
        tableFields: _ctx.tableFields,
        scrollable: true,
        buttons: { edit: false, delete: false, export: false},
        onSort: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clickSort($event))),
        onMenuEvent: _cache[5] || (_cache[5] = ($event: any) => (_ctx.menuEvent($event)))
      }, null, 8, ["tableData", "loading", "pagination", "tableFields"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.showBulkCreateOrderModal)
        ? (_openBlock(), _createBlock(_component_BulkOrderCreateModal, {
            key: 0,
            onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showBulkCreateOrderModal = false)),
            onOnOrderCreated: _ctx.onOrderCreatedHandler
          }, null, 8, ["onOnOrderCreated"]))
        : _createCommentVNode("", true),
      (_ctx.showCreateOrderModal)
        ? (_openBlock(), _createBlock(_component_CreateOrderModal, {
            key: 1,
            onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showCreateOrderModal = false)),
            onOnOrderCreated: _ctx.onOrderCreatedHandler
          }, null, 8, ["onOnOrderCreated"]))
        : _createCommentVNode("", true)
    ])
  ]))
}