<template>
  <div class="flex h-screen items-center px-4">
    <div class="w-550 m-auto">
      <div v-if="!sent" class="forgot flex flex-col bg-white py-10">
        <Alert v-if="isError" type="danger" title="error"><p>{{ errorMessage }}</p></Alert>
        <h1 class="font-bold text-gray-600 text-3xl text-start mb-8">{{ $t('auth.forget_header') }}</h1>
        <p class="text-base text-start text-gray-600 mb-2">{{ $t('auth.forget_description') }}</p>
        <p class="text-base text-start  text-gray-600">{{ $t('auth.forget_message') }}</p>
        <div class="relative h-42 mt-10">
          <input v-bind:class="{error: v$.email.$error}" type="email" placeholder=" " class="h-42 absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent" v-model="email" @blur="v$.email.$touch"/>
          <label v-bind:class="{error: v$.email.$error}" class="absolute text-placeholder top-2 start-4 text-base bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text">Email</label>
          <span v-if="v$.email.$error" class="flex items-center absolute start-0 -bottom-6 text-10px text-textError">
              <BaseIcon class="me-1" name="alert"/>
              {{ $t('validation.email') }}
          </span>
        </div>
        <div class="mt-8 flex justify-center">
          <button
            :disabled="v$.$invalid"
            class="primary px-20 rounded-full py-2 text-base" @click="send">
            {{ $t('auth.send') }}
          </button>
        </div>
         <div class="relative">
          <router-link to="/auth" class="inline-block text-blue-500 font-bold hover:underline mt-6">{{ $t('auth.back') }}</router-link>
        </div>
      </div>
      <div v-else class="forgot-sent flex flex-col bg-white p-9 rounded-lg shadow border">
        <h1 class="font-normal text-lg text-center">{{ $t('auth.forget_message') }}</h1>
        <div class="relative">
          <router-link to="/auth" class="inline-block text-blue-500 font-bold hover:underline mt-6">{{ $t('auth.back') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@/customValidators'
import Alert from '@/shared/components/Alert.vue'
import BaseIcon from '../../shared/components/base-icon/BaseIcon'
import client from 'axios'
import { apiEndpoints } from '../../shared/endpoints/api'
import axiosInstance from '../../shared/utils/axiosInstance'

export default defineComponent({
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      email: '',
      isError: false,
      errorMessage: '',
      sent: false
    }
  },
  validations: function () {
    return {
      email: { required, email }
    }
  },
  components: {
    BaseIcon,
    Alert
  },
  methods: {
    send: async function () {
      // check form validation
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      await axiosInstance[apiEndpoints.forgot.method](apiEndpoints.forgot.url, { user_email: this.email }).then(response => {
        this.$store.commit('setUserCredentials', { user_email: this.email })
        this.email = ''
        this.sent = true
        this.isError = false
        this.v$.$reset()
        this.errorMessage = ''
        this.$router.push('/auth/verification')
      }, (err) => {
        this.isError = true
        this.errorMessage = err
      })
    }
  }
})
</script>
<style lang="scss" scoped>
input[type='email'] {
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-radius: 0px;
}
</style>
