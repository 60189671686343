import { Method as apiMethod } from 'axios'

export const workShiftEndpoints = {
  list: {
    method: 'get' as apiMethod,
    url: '/shifts/'
  },
  requests: {
    method: 'get' as apiMethod,
    url: '/shifts/requests/'
  },
  approve: {
    method: 'put' as apiMethod,
    url: '/shifts/requests/'
  },
  approveClockout: {
    method: 'post' as apiMethod,
    url: '/drivers/clock_out_approval/'
  },
  create: {
    method: 'post' as apiMethod,
    url: '/shifts/'
  },
  update: {
    method: 'put' as apiMethod,
    url: '/shifts/'
  }
}
