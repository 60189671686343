import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center absolute start-0 top-42 text-textError text-start leading-3 pt-1" }
const _hoisted_2 = { class: "text-10px leading-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_BaseIcon, {
        class: "me-1 w-4 h-4",
        name: "alert"
      })
    ]),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.error), 1)
  ]))
}