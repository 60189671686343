
import { defineAsyncComponent, defineComponent } from 'vue'
import { debounce } from 'lodash'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'

export default defineComponent({
  name: 'DarkstoreSelect',
  props: ['modelValue'],
  components: {
    Multiselect: defineAsyncComponent(() => import('@vueform/multiselect'))
  },
  data () {
    return {
      selectedDarkstore: null as any,
      handleSearch: debounce((searchText: string) => {
        if (!searchText.length) return
        this.$store.dispatch(`darkstores/${DarkStoresActions.SEARCH_DARKSTORE_AUTOCOMPLETE}`,
          {
            pageNumber: 1,
            pageSize: 1000, // we need to get all the data
            query: searchText
          }
        )
      }, 1000),
      searchable: false
    }
  },
  computed: {
    darkstores (vm: any) {
      const darkstores = this.$store.getters['darkstores/getDarkStores']
        .map((ds: any) => ({ name: ds.name, id: ds.id }))
      const selected = vm.selectedDarkstore as any
      if (selected) {
        const hasSelected = darkstores.findIndex((d:any) => d.id === selected.id)
        return hasSelected > -1 ? darkstores : [selected, ...darkstores]
      }
      return darkstores
    },
    darkstoreLoading (): boolean {
      return this.$store.getters['darkstores/getLoading']
    }
  },
  watch: {
    selectedDarkstore (newValue) {
      this.$emit('update:modelValue', newValue)
    },
    modelValue (newValue) {
      this.selectedDarkstore = newValue
    }
  }
})
