import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-6" }
const _hoisted_2 = { class: "flex gap-4" }
const _hoisted_3 = { class: "w-1/2 flex flex-col gap-5" }
const _hoisted_4 = { class: "flex flex-col gap-4" }
const _hoisted_5 = { class: "relative" }
const _hoisted_6 = {
  key: 0,
  class: "form-error"
}
const _hoisted_7 = { class: "relative" }
const _hoisted_8 = {
  key: 0,
  class: "form-error"
}
const _hoisted_9 = { class: "w-1/2 flex flex-col gap-5" }
const _hoisted_10 = { class: "relative w-full" }
const _hoisted_11 = {
  key: 0,
  class: "form-error"
}
const _hoisted_12 = { class: "relative w-full" }
const _hoisted_13 = {
  key: 0,
  class: "form-error"
}
const _hoisted_14 = { class: "relative w-full" }
const _hoisted_15 = {
  key: 0,
  class: "form-error"
}
const _hoisted_16 = { class: "relative w-full" }
const _hoisted_17 = {
  key: 0,
  class: "form-error"
}
const _hoisted_18 = { class: "flex justify-end items-center gap-x-6 mt-4" }
const _hoisted_19 = {
  type: "submit",
  class: "primary py-3 px-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateOrderLocationSelectMap = _resolveComponent("CreateOrderLocationSelectMap")!
  const _component_CitySelect = _resolveComponent("CitySelect")!
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.submitForm(_ctx.v$)), ["prevent"])),
      class: "w-full mt-3"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_CreateOrderLocationSelectMap, {
            style: {"width":"100%","height":"305px"},
            onOnLocationSelect: _ctx.onLocationSelected
          }, null, 8, ["onOnLocationSelect"]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_4, [
              _withDirectives(_createElementVNode("div", _hoisted_5, [
                _createElementVNode("label", {
                  class: _normalizeClass([{error: _ctx.v$.form.latitude.$error}, "text-xs mb-1"])
                }, _toDisplayString(_ctx.$t('Destination latitude')), 3),
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass([{error: _ctx.v$.form.latitude.$error}, "h-42 px-3"]),
                  type: "text",
                  placeholder: " ",
                  disabled: "",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.latitude) = $event)),
                  onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.v$.form.latitude.$touch && _ctx.v$.form.latitude.$touch(...args)))
                }, null, 34), [
                  [_vModelText, _ctx.form.latitude]
                ]),
                (_ctx.v$.form.latitude.$error)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('validation.required')), 1))
                  : _createCommentVNode("", true)
              ], 512), [
                [_vShow, false]
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_7, [
                _createElementVNode("label", {
                  class: _normalizeClass([{error: _ctx.v$.form.longitude.$error}, "text-xs mb-1"])
                }, _toDisplayString(_ctx.$t('Destination longitude')), 3),
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass([{error: _ctx.v$.form.longitude.$error}, "h-42 px-3"]),
                  type: "text",
                  placeholder: " ",
                  disabled: "",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.longitude) = $event)),
                  onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.v$.form.longitude.$touch && _ctx.v$.form.longitude.$touch(...args)))
                }, null, 34), [
                  [_vModelText, _ctx.form.longitude]
                ]),
                (_ctx.v$.form.longitude.$error)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('validation.required')), 1))
                  : _createCommentVNode("", true)
              ], 512), [
                [_vShow, false]
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("label", {
              class: _normalizeClass([{error: _ctx.v$.form.name.$error}, "text-xs mb-1"])
            }, _toDisplayString(_ctx.$t('darkstores.createModal.name')), 3),
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass([{error: _ctx.v$.form.name.$error}, "h-42 px-3 w-full"]),
              type: "text",
              placeholder: "",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.name) = $event)),
              onBlur: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.v$.form.name.$touch && _ctx.v$.form.name.$touch(...args)))
            }, null, 34), [
              [_vModelText, _ctx.form.name]
            ]),
            (_ctx.v$.form.name.$error)
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t('validation.required')), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("label", {
              class: _normalizeClass([{error: _ctx.v$.form.city_id.$error}, "text-xs mb-1"])
            }, _toDisplayString(_ctx.$t('darkstores.createModal.city')), 3),
            _createVNode(_component_CitySelect, {
              modelValue: _ctx.form.city_id,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.city_id) = $event)),
              class: _normalizeClass({error: _ctx.v$.form.city_id.$error})
            }, null, 8, ["modelValue", "class"]),
            (_ctx.v$.form.city_id.$error)
              ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t('validation.required')), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("label", {
              class: _normalizeClass([{error: _ctx.v$.form.open_time.$error}, "text-xs mb-1"])
            }, _toDisplayString(_ctx.$t('darkstores.createModal.openTime')), 3),
            _createVNode(_component_Datepicker, {
              timePicker: "",
              modelValue: _ctx.form.open_time,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.open_time) = $event)),
              class: _normalizeClass([{error: _ctx.v$.form.open_time.$error}, "w-full"]),
              "calendar-cell-class-name": "border border-red-600"
            }, null, 8, ["modelValue", "class"]),
            (_ctx.v$.form.open_time.$error)
              ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.$t('validation.required')), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("label", {
              class: _normalizeClass([{error: _ctx.v$.form.close_time.$error}, "text-xs mb-1"])
            }, _toDisplayString(_ctx.$t('darkstores.createModal.closeTime')), 3),
            _createVNode(_component_Datepicker, {
              timePicker: "",
              modelValue: _ctx.form.close_time,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.close_time) = $event)),
              class: _normalizeClass([{error: _ctx.v$.form.close_time.$error}, "w-full"]),
              "calendar-cell-class-name": "border border-red-600"
            }, null, 8, ["modelValue", "class"]),
            (_ctx.v$.form.close_time.$error)
              ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t('validation.required')), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('close'))),
          class: "danger-btn py-3 px-6"
        }, _toDisplayString(_ctx.$t('buttons.close')), 1),
        _createElementVNode("button", _hoisted_19, _toDisplayString(_ctx.$t('create')), 1)
      ])
    ], 32)
  ]))
}