import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { ClientsState } from '@/store/modules/clients/types'
import { ClientInterface } from '@/shared/interfaces/clients.interface'
import { Order } from '@/shared/interfaces/order.interface'

export const getters: GetterTree<ClientsState, RootState> = {
  getClients (state): ClientInterface[] {
    return state.clients
  },
  getPagination (state): any {
    return state.pagination
  },
  getLoading (state): boolean {
    return state.loading
  },
  getOrders (state): Order[] {
    return state.orderList
  },
  getOrderPagination (state): any {
    return state.orderPagination
  }
}
