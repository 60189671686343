import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { User } from '@/shared/interfaces/user.interface'
import { formatDate } from '@/shared/utils/formatDate'

export class UserModel extends CommonModel {
  public data: User[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): User {
    return {
      firstName: data.first_name,
      lastName: data.last_name,
      birthdate: data.birthdate,
      birthDateFormatted: formatDate(data.birthdate),
      street: data.street,
      username: data.username,
      activated: data.activated,
      createdAt: data.created_at,
      createdAtFormatted: formatDate(data.created_at),
      id: data.id,
      isAdmin: data.is_admin,
      isLocked: data.is_locked,
      isLoggedOut: data.is_logged_out,
      lastLogin: data.last_login,
      lastLoginFormatted: formatDate(data.last_login),
      modifiedAt: data.modified_at,
      role: {
        id: data.role.id,
        name: data.role.name,
        value: data.role.value
      },
      userEmail: data.user_email,
      userPhoneNumber: data.user_phone_number
    }
  }
}
