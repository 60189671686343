
import { defineComponent, onMounted, ref } from 'vue'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
import BaseModal from '@/shared/components/modals/BaseModal.vue'
import { ModalType } from '@/shared/components/modals/const'

export default defineComponent({
  components: { BaseModal, BaseIcon },
  props: {
    hasIcon: Boolean,
    header: String,
    title: String,
    description: String,
    type: String,
    cancelText: String,
    confirmText: String,
    confirmOnClose: Boolean,
    isValid: {
      type: Boolean,
      default: true
    }
  },
  emits: ['cancel', 'confirm'],
  setup (props, { emit }) {
    const iconName = ref<string>(props.type || ModalType.SUCCESS)
    const classColor = ref<string>('text-successColor')

    onMounted(() => {
      classColor.value = iconName.value === ModalType.WARNING ? 'text-errorColor' : 'text-successColor'
    })

    const handleCancel = () : void => {
      emit('cancel', false)
    }

    const handleConfirm = () : void => {
      emit('confirm', true)
    }

    return {
      iconName,
      classColor,
      handleCancel,
      handleConfirm
    }
  }
})
