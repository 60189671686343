import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import { WorkShiftsState } from '@/store/modules/work-shifts/types'
import { WorkShiftInterface } from '@/shared/interfaces/work-shift.interface'
import { UserRequestsInterface } from '@/shared/interfaces/user-requests.interface'

export const getters: GetterTree<WorkShiftsState, RootState> = {
  getWorkShifts (state): WorkShiftInterface[] {
    return state.workShifts
  },
  getWorkShiftsForSelect (state): any[] {
    return state.workShifts.map((el: WorkShiftInterface) => {
      return {
        id: el.id,
        value: `${el.shiftStart}-${el.shiftEnd}`
      }
    })
  },
  getUserRequests (state): UserRequestsInterface[] {
    return state.userRequests
  },
  getUserExportRequests (state): UserRequestsInterface[] {
    return state.userExportRequests
  },
  getPagination (state): PaginationInterface | null {
    return state.pagination
  },
  getLoading (state): boolean {
    return state.loading
  },
  getRequestLoading (state): boolean {
    return state.requestLoading
  }
}
