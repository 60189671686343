
import { defineComponent } from 'vue'
import _ from 'lodash'
import { DarkstoreOrderPeakHoursInterface } from '@/shared/interfaces/reports/darkstore-order-peak-hours.interface'
import { VueEcharts } from 'vue3-echarts'
export default defineComponent({
  name: 'PerHourChart',
  components: {
    VueEcharts
  },
  data () {
    return {
      optionTemplate: {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: []
        },
        xAxis: {
          name: this.$t('reports.darkStoreOrderPeakHours.hours'),
          type: 'category',
          data: []
        },
        yAxis: {
          name: this.$t('reports.darkStoreOrderPeakHours.totalOrders'),
          nameRotate: 0,
          type: 'value'
        },
        series: [] as any
      }
    }
  },
  computed: {
    loading () {
      return this.$store.getters['reports/getLoading']
    },
    reports () {
      return this.$store.getters['reports/getDarkstorePeakHoursReports']
    },
    chartOption (vm) {
      const options = _.cloneDeep(vm.optionTemplate)
      options.legend.data = [...new Set(vm.reports.map((r: DarkstoreOrderPeakHoursInterface) => r.darkstoreName))] as any
      options.xAxis.data = [...new Set(vm.reports.map((r: DarkstoreOrderPeakHoursInterface) => r.orderHour))] as any
      const darkStoreIds = [...new Set(vm.reports.map((r: DarkstoreOrderPeakHoursInterface) => r.darkstoreId))] as number[]
      darkStoreIds.forEach((darkstoreId: number) => {
        const darkstore = this.reports
          .filter((r: DarkstoreOrderPeakHoursInterface) => (r.darkstoreId === darkstoreId))
        const dsObject = {
          name: darkstore.length ? darkstore[0].darkstoreName : 'N/A',
          data: [],
          type: 'bar',
          barGap: '0',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          },
          itemStyle: {
            normal: {
              barBorderRadius: [50, 50, 0, 0]
            }
          }
        } as any
        options.xAxis.data.forEach((hour: number) => {
          const item = vm.reports
            .filter((r:DarkstoreOrderPeakHoursInterface) => r.orderHour === hour && r.darkstoreId === darkstoreId) as DarkstoreOrderPeakHoursInterface[]
          if (item.length > 0) {
            dsObject.data.push(item[0].totalOrders)
          } else {
            dsObject.data.push(0)
          }
        })
        options.series.push(dsObject)
      })
      options.xAxis.data = options.xAxis.data.map((value: number) => (value < 10 ? `0${value}:00` : `${value}:00`)) as any
      return options
    }
  }
})
