
import { defineComponent, PropType } from 'vue'
import { NavigationItem } from '@/shared/interfaces/navigation.model'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'

export default defineComponent({
  components: {
    BaseIcon
  },
  props: {
    companyTitle: {
      type: String,
      required: true
    },
    navItems: {
      type: Object as PropType<NavigationItem[]>,
      required: true
    }
  },
  data () {
    return {
      selectedRoute: null as any
    }
  },
  mounted () {
    this.$store.dispatch('getLoggedUserDetail')
  },
  computed: {
    companyShortTitle () : string {
      const matches = this.companyTitle.match(/\b(\w)/g)
      const shortTitle = matches?.slice(0, 3).join('') || 'CAT'
      return shortTitle
    },
    authorized (): any {
      return this.$store.getters.getAuthorized
    }
  },
  methods: {
    toggleSubMenus (route: string): void {
      if (this.selectedRoute !== route) {
        this.selectedRoute = route
      }
    },
    logout (): void {
      this.$store.dispatch('logout')
    },
    subIsActive (input: string) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0 // current path starts with this path string
      })
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (val) {
        // console.log('dddd', val)
      }
    }
  }
})
