
import { defineAsyncComponent, defineComponent } from 'vue'
import { ZoneActions } from '@/store/modules/zones/actions'
export default defineComponent({
  components: {
    ZoneMap: defineAsyncComponent(() => import('@/views/account/zones/components/ZoneMap.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue'))
  },
  data () {
    return {
      form: {
        name: '',
        polygon: {}
      },
      showAllZones: true
    }
  },
  methods: {
    setPolygon (loc:any) {
      this.form.polygon = loc
    },
    async onCreate () {
      const response = await this.$store.dispatch(`zones/${ZoneActions.CREATE_ZONE}`, this.form)
      if (response) {
        this.$router.push('/zones')
      }
    }
  },
  computed: {
    isValid (vm) {
      return Object.keys(vm.form.polygon).length > 0 && vm.form.name.length > 0
    }
  }
})
