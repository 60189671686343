<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_490_1980)">
      <path d="M8.25 3.53333C8.82361 3.39907 9.41089 3.33195 10 3.33333C15.8333 3.33333 19.1667 10 19.1667 10C18.6608 10.9463 18.0575 11.8373 17.3667 12.6583M11.7667 11.7667C11.5378 12.0123 11.2618 12.2093 10.9551 12.3459C10.6485 12.4826 10.3174 12.5561 9.98174 12.562C9.64607 12.5679 9.31264 12.5061 9.00134 12.3804C8.69005 12.2547 8.40727 12.0675 8.16987 11.8301C7.93248 11.5927 7.74533 11.31 7.61959 10.9987C7.49385 10.6874 7.43211 10.3539 7.43803 10.0183C7.44395 9.68258 7.51742 9.35154 7.65406 9.04487C7.7907 8.73821 7.98771 8.46221 8.23333 8.23333M14.95 14.95C13.5255 16.0358 11.7909 16.6374 10 16.6667C4.16667 16.6667 0.833334 10 0.833334 10C1.86991 8.06825 3.30761 6.38051 5.05 5.05L14.95 14.95Z" stroke="#5f5f5f" stroke-width="1.68" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M0.833334 0.833332L19.1667 19.1667" stroke="#5f5f5f" stroke-width="1.68" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_490_1980">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
