
import { getMessaging, getToken } from 'firebase/messaging'
import * as Firebase from 'firebase/app'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyC7d2lL58PZ8LSZpMFaPjZ7A-1eYCJM2s4',
  authDomain: 'tasleem-9ba2d.firebaseapp.com',
  projectId: 'tasleem-9ba2d',
  storageBucket: 'tasleem-9ba2d.appspot.com',
  messagingSenderId: '106208593985',
  appId: '1:106208593985:web:b8f64855c1c2d766921ec0',
  measurementId: 'G-VWV5TDV914'
}
// Initialize Firebase
const app = Firebase.initializeApp(firebaseConfig)

export function connectToFirebase (): any {
  return new Promise((resolve: any, reject: any) => {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        return getToken(getMessaging(app)).then((token: any) => {
          resolve(token)
        }).catch((err: any) => {
          reject(err)
        })
      }
    })
  })
}

export const messaging = getMessaging(app)
