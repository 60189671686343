import { MutationTree } from 'vuex'
import { CitiesState } from '@/store/modules/cities/types'

export enum CitiesMutations {
  SET_CITIES = 'SET_CITIES',
  TRIGGER_LOADING = 'TRIGGER_LOADING'
}

export const mutations: MutationTree<CitiesState> = {
  [CitiesMutations.SET_CITIES] (state, payload) {
    state.cities = payload.data
    state.pagination = payload.pagination
  },
  [CitiesMutations.TRIGGER_LOADING] (state, bool: boolean) {
    state.loading = bool
  }
}
