import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, resolveDirective as _resolveDirective, vShow as _vShow, vModelDynamic as _vModelDynamic, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "auth bg-login-track bg-big-logo bg-black bg-no-repeat bg-bottom-4" }
const _hoisted_2 = { class: "invitation-container flex h-screen items-center py-8 overflow-y-scroll" }
const _hoisted_3 = { class: "md:w-860 sm:w-488 w-288 m-auto" }
const _hoisted_4 = { class: "bg-white lg:px-36 md:px-20 sm:px-10 px-8 sm:pt-14 pt-8 pb-9 rounded-lg shadow space-y-3 border" }
const _hoisted_5 = { class: "font-bold text-3xl text-black text-center mb-2" }
const _hoisted_6 = { class: "sm:text-lg text-sm text-darkText text-center pb-4" }
const _hoisted_7 = {
  key: 0,
  class: "text-lg text-darkText mb-4 text-start"
}
const _hoisted_8 = { class: "grid md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-2.5 flex-wrap" }
const _hoisted_9 = { class: "relative h-42 mb-3 sm:mb-7" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "relative h-42 mb-3 sm:mb-7" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { class: "relative h-42 mb-3 sm:mb-7" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "relative h-42 mb-3 sm:mb-7" }
const _hoisted_16 = { class: "relative h-42 mb-3 sm:mb-7" }
const _hoisted_17 = ["disabled"]
const _hoisted_18 = {
  key: 0,
  class: "relative h-42 mb-7"
}
const _hoisted_19 = ["disabled"]
const _hoisted_20 = { class: "absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text" }
const _hoisted_21 = {
  key: 1,
  class: "relative h-42 mb-7"
}
const _hoisted_22 = ["disabled"]
const _hoisted_23 = { class: "absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text" }
const _hoisted_24 = {
  key: 1,
  class: "text-lg text-darkText mb-4 text-start"
}
const _hoisted_25 = { class: "grid md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-2.5 flex-wrap" }
const _hoisted_26 = { class: "relative h-42 mb-7" }
const _hoisted_27 = { class: "absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text" }
const _hoisted_28 = { class: "relative h-42 mb-3 sm:mb-7" }
const _hoisted_29 = ["disabled"]
const _hoisted_30 = {
  key: 0,
  class: "relative h-42 mb-7"
}
const _hoisted_31 = ["disabled"]
const _hoisted_32 = { class: "absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text" }
const _hoisted_33 = {
  key: 1,
  class: "relative h-42 mb-7"
}
const _hoisted_34 = ["disabled"]
const _hoisted_35 = { class: "absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text" }
const _hoisted_36 = {
  key: 2,
  class: "relative h-42 mb-7"
}
const _hoisted_37 = ["disabled"]
const _hoisted_38 = { class: "absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text" }
const _hoisted_39 = {
  key: 3,
  class: "relative h-42 mb-7"
}
const _hoisted_40 = ["disabled"]
const _hoisted_41 = { class: "absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text" }
const _hoisted_42 = {
  key: 4,
  class: "relative h-42 mb-7"
}
const _hoisted_43 = ["disabled"]
const _hoisted_44 = { class: "absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text" }
const _hoisted_45 = {
  key: 5,
  class: "relative h-42 mb-7"
}
const _hoisted_46 = ["disabled"]
const _hoisted_47 = { class: "absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text" }
const _hoisted_48 = {
  key: 2,
  class: "text-lg text-darkText mb-4 text-start"
}
const _hoisted_49 = { class: "grid md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-2.5 flex-wrap" }
const _hoisted_50 = ["disabled", "type"]
const _hoisted_51 = { class: "relative h-42 mb-3 sm:mb-7" }
const _hoisted_52 = ["disabled", "type"]
const _hoisted_53 = {
  key: 1,
  class: "flex justify-between"
}
const _hoisted_54 = {
  key: 2,
  class: "flex justify-between"
}
const _hoisted_55 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputError = _resolveComponent("InputError")!
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('auth.signin_header')), 1),
          _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.$t('userInvitation.description')), 1),
          _createElementVNode("form", {
            onSubmit: _cache[28] || (_cache[28] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitData && _ctx.submitData(...args)), ["prevent"])),
            autocomplete: "off"
          }, [
            (_ctx.step === 1)
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t('userInvitation.steps.step1')), 1))
              : _createCommentVNode("", true),
            _withDirectives(_createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass([{error: _ctx.v$.name.$error}, "h-42 absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent"]),
                  type: "text",
                  placeholder: " ",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                  onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.v$.name.$touch && _ctx.v$.name.$touch(...args))),
                  disabled: _ctx.isLoading
                }, null, 42, _hoisted_10), [
                  [_vModelText, _ctx.name]
                ]),
                _createElementVNode("label", {
                  class: _normalizeClass([{error: _ctx.v$.name.$error}, "absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text"])
                }, _toDisplayString(_ctx.$t('userInvitation.name')), 3),
                (_ctx.v$.name.$error)
                  ? (_openBlock(), _createBlock(_component_InputError, {
                      key: 0,
                      error: _ctx.$t('validation.required')
                    }, null, 8, ["error"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass([{error: _ctx.v$.surname.$error}, "h-42 absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent"]),
                  type: "text",
                  placeholder: " ",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.surname) = $event)),
                  onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.v$.surname.$touch && _ctx.v$.surname.$touch(...args))),
                  disabled: _ctx.isLoading
                }, null, 42, _hoisted_12), [
                  [_vModelText, _ctx.surname]
                ]),
                _createElementVNode("label", {
                  class: _normalizeClass([{error: _ctx.v$.surname.$error}, "absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text"])
                }, _toDisplayString(_ctx.$t('userInvitation.surname')), 3),
                (_ctx.v$.surname.$error)
                  ? (_openBlock(), _createBlock(_component_InputError, {
                      key: 0,
                      error: _ctx.$t('validation.required')
                    }, null, 8, ["error"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass([{error: _ctx.v$.username.$error}, "h-42 absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent"]),
                  type: "text",
                  placeholder: " ",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.username) = $event)),
                  onBlur: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.v$.username.$touch && _ctx.v$.username.$touch(...args))),
                  disabled: _ctx.isLoading
                }, null, 42, _hoisted_14), [
                  [_vModelText, _ctx.username]
                ]),
                _createElementVNode("label", {
                  class: _normalizeClass([{error: _ctx.v$.username.$error}, "absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text"])
                }, _toDisplayString(_ctx.$t('userInvitation.username')), 3),
                (_ctx.v$.username.$error)
                  ? (_openBlock(), _createBlock(_component_InputError, {
                      key: 0,
                      error: _ctx.$t('validation.required')
                    }, null, 8, ["error"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_Datepicker, {
                  class: _normalizeClass({error: _ctx.v$.birthdate.$error}),
                  modelValue: _ctx.birthdate,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.birthdate) = $event)),
                  format: "dd.MM.yyyy",
                  placeholder: _ctx.$t('userInvitation.birthdate'),
                  onBlur: _ctx.v$.birthdate.$touch
                }, null, 8, ["class", "modelValue", "placeholder", "onBlur"]),
                (_ctx.v$.birthdate.$error)
                  ? (_openBlock(), _createBlock(_component_InputError, {
                      key: 0,
                      error: _ctx.$t('validation.required')
                    }, null, 8, ["error"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass([{error: _ctx.v$.mobile.$error}, "absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent"]),
                  type: "text",
                  placeholder: " ",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.mobile) = $event)),
                  onBlur: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.v$.mobile.$touch && _ctx.v$.mobile.$touch(...args))),
                  disabled: _ctx.isLoading
                }, null, 42, _hoisted_17), [
                  [_directive_maska, { mask: '07H# ### ####', tokens: { 'H': { pattern: /[3-9]/ }}}],
                  [_vModelText, _ctx.mobile]
                ]),
                _createElementVNode("label", {
                  class: _normalizeClass([{error: _ctx.v$.mobile.$error}, "absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text"])
                }, _toDisplayString(_ctx.$t('userInvitation.mobile')), 3),
                (_ctx.v$.mobile.$error)
                  ? (_openBlock(), _createBlock(_component_InputError, {
                      key: 0,
                      error: _ctx.v$.mobile.minLength.$invalid ? _ctx.$t('validation.min_length', {length: 11}) : _ctx.$t('validation.required')
                    }, null, 8, ["error"]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isDriver)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      placeholder: " ",
                      class: "absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent",
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.driverLicenseNo) = $event)),
                      disabled: _ctx.isLoading
                    }, null, 8, _hoisted_19), [
                      [_vModelText, _ctx.driverLicenseNo]
                    ]),
                    _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t('userInvitation.licenseNo')), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isDriver)
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      placeholder: " ",
                      class: "absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent",
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.boxNumber) = $event)),
                      disabled: _ctx.isLoading
                    }, null, 8, _hoisted_22), [
                      [_vModelText, _ctx.boxNumber]
                    ]),
                    _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t('userInvitation.boxNumber')), 1)
                  ]))
                : _createCommentVNode("", true)
            ], 512), [
              [_vShow, _ctx.step === 1]
            ]),
            (_ctx.step === 2)
              ? (_openBlock(), _createElementBlock("p", _hoisted_24, _toDisplayString(_ctx.$t('userInvitation.steps.step2')), 1))
              : _createCommentVNode("", true),
            _withDirectives(_createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  placeholder: " ",
                  class: "absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent",
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.city) = $event)),
                  disabled: true
                }, null, 512), [
                  [_vModelText, _ctx.city]
                ]),
                _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t('userInvitation.city')), 1)
              ]),
              _createElementVNode("div", _hoisted_28, [
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass([{error: _ctx.v$.street.$error}, "h-42 absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent"]),
                  type: "text",
                  placeholder: " ",
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.street) = $event)),
                  onBlur: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.v$.street.$touch && _ctx.v$.street.$touch(...args))),
                  disabled: _ctx.isLoading
                }, null, 42, _hoisted_29), [
                  [_vModelText, _ctx.street]
                ]),
                _createElementVNode("label", {
                  class: _normalizeClass([{error: _ctx.v$.street.$error}, "absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text"])
                }, _toDisplayString(_ctx.$t('userInvitation.street')), 3),
                (_ctx.v$.street.$error)
                  ? (_openBlock(), _createBlock(_component_InputError, {
                      key: 0,
                      error: _ctx.$t('validation.required')
                    }, null, 8, ["error"]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isDriver)
                ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      placeholder: " ",
                      class: "absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent",
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.district) = $event)),
                      disabled: _ctx.isLoading
                    }, null, 8, _hoisted_31), [
                      [_vModelText, _ctx.district]
                    ]),
                    _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t('drivers.district')), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isDriver)
                ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      placeholder: " ",
                      class: "absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent",
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.building) = $event)),
                      disabled: _ctx.isLoading
                    }, null, 8, _hoisted_34), [
                      [_vModelText, _ctx.building]
                    ]),
                    _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t('drivers.building')), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isDriver)
                ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      placeholder: " ",
                      class: "absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent",
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.flat) = $event)),
                      disabled: _ctx.isLoading
                    }, null, 8, _hoisted_37), [
                      [_vModelText, _ctx.flat]
                    ]),
                    _createElementVNode("label", _hoisted_38, _toDisplayString(_ctx.$t('drivers.flat')), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isDriver)
                ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      placeholder: " ",
                      class: "absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent",
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.floor) = $event)),
                      disabled: _ctx.isLoading
                    }, null, 8, _hoisted_40), [
                      [_vModelText, _ctx.floor]
                    ]),
                    _createElementVNode("label", _hoisted_41, _toDisplayString(_ctx.$t('drivers.floor')), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isDriver)
                ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      placeholder: " ",
                      class: "absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent",
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.landmark) = $event)),
                      disabled: _ctx.isLoading
                    }, null, 8, _hoisted_43), [
                      [_vModelText, _ctx.landmark]
                    ]),
                    _createElementVNode("label", _hoisted_44, _toDisplayString(_ctx.$t('drivers.landmark')), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isDriver)
                ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      placeholder: " ",
                      class: "absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent",
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.neighborhood) = $event)),
                      disabled: _ctx.isLoading
                    }, null, 8, _hoisted_46), [
                      [_vModelText, _ctx.neighborhood]
                    ]),
                    _createElementVNode("label", _hoisted_47, _toDisplayString(_ctx.$t('drivers.neighborhood')), 1)
                  ]))
                : _createCommentVNode("", true)
            ], 512), [
              [_vShow, _ctx.step === 2]
            ]),
            (_ctx.step === 3)
              ? (_openBlock(), _createElementBlock("p", _hoisted_48, _toDisplayString(_ctx.$t('userInvitation.steps.step3')), 1))
              : _createCommentVNode("", true),
            _withDirectives(_createElementVNode("div", _hoisted_49, [
              _createElementVNode("div", {
                class: _normalizeClass(["relative h-42", _ctx.v$.password.$error && _ctx.v$.password.goodPassword.$invalid ? 'mb-8' : 'mb-3 sm:mb-7'])
              }, [
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass([{error: _ctx.v$.password.$error}, "h-42 absolute py-3.5 ps-3.5 pe-9 top-0 left-0 w-full h-full block appearance-none bg-transparent"]),
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.password) = $event)),
                  onBlur: _cache[21] || (_cache[21] = 
//@ts-ignore
(...args) => (_ctx.v$.password.$touch && _ctx.v$.password.$touch(...args))),
                  disabled: _ctx.isLoading,
                  type: this.showPassword ? 'text' : 'password',
                  placeholder: " "
                }, null, 42, _hoisted_50), [
                  [_vModelDynamic, _ctx.password]
                ]),
                _createElementVNode("label", {
                  class: _normalizeClass([{error: _ctx.v$.password.$error}, "absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text"])
                }, _toDisplayString(_ctx.$t('userInvitation.password')), 3),
                (_ctx.showPassword)
                  ? (_openBlock(), _createBlock(_component_BaseIcon, {
                      key: 0,
                      onClick: _ctx.togglePassword,
                      class: "input-icon absolute end-3.5 top-3 cursor-pointer z-30",
                      name: "eye"
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (!_ctx.showPassword)
                  ? (_openBlock(), _createBlock(_component_BaseIcon, {
                      key: 1,
                      onClick: _ctx.togglePassword,
                      class: "input-icon absolute end-3.5 top-3 cursor-pointer z-30",
                      name: "eyeoff"
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.v$.password.$error)
                  ? (_openBlock(), _createBlock(_component_InputError, {
                      key: 2,
                      error: _ctx.v$.password.goodPassword.$invalid ?
                  _ctx.$t('validation.strongPassword') : _ctx.v$.password.maxLength.$invalid ? _ctx.$t('validation.max_length', {length: 10}) : _ctx.$t('validation.required')
                    }, null, 8, ["error"]))
                  : _createCommentVNode("", true)
              ], 2),
              _createElementVNode("div", _hoisted_51, [
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass([{error: _ctx.v$.confirmPassword.$error}, "h-42 absolute py-3.5 ps-3.5 pe-9 top-0 left-0 w-full h-full block appearance-none bg-transparent"]),
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.confirmPassword) = $event)),
                  onBlur: _cache[23] || (_cache[23] = 
//@ts-ignore
(...args) => (_ctx.v$.confirmPassword.$touch && _ctx.v$.confirmPassword.$touch(...args))),
                  disabled: _ctx.isLoading,
                  type: this.showConfPassword ? 'text' : 'password',
                  placeholder: " "
                }, null, 42, _hoisted_52), [
                  [_vModelDynamic, _ctx.confirmPassword]
                ]),
                _createElementVNode("label", {
                  class: _normalizeClass([{error: _ctx.v$.confirmPassword.$error}, "absolute text-placeholder top-3 start-4 text-xs bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text"])
                }, _toDisplayString(_ctx.$t('userInvitation.confirmPass')), 3),
                (_ctx.showConfPassword)
                  ? (_openBlock(), _createBlock(_component_BaseIcon, {
                      key: 0,
                      onClick: _ctx.toggleConfPassword,
                      class: "input-icon absolute end-3.5 top-3 cursor-pointer z-30",
                      name: "eye"
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (!_ctx.showConfPassword)
                  ? (_openBlock(), _createBlock(_component_BaseIcon, {
                      key: 1,
                      onClick: _ctx.toggleConfPassword,
                      class: "input-icon absolute end-3.5 top-3 cursor-pointer z-30",
                      name: "eyeoff"
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.v$.confirmPassword.$error)
                  ? (_openBlock(), _createBlock(_component_InputError, {
                      key: 2,
                      error: _ctx.v$.confirmPassword.sameAs.$invalid ? _ctx.$t('validation.same_as', { other: 'password' }) : _ctx.$t('validation.required')
                    }, null, 8, ["error"]))
                  : _createCommentVNode("", true)
              ])
            ], 512), [
              [_vShow, _ctx.step === 3]
            ]),
            _createElementVNode("div", null, [
              (_ctx.step === 1)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.step = 2)),
                    type: "button",
                    class: "secondary w-full flex items-center justify-center mt-10 rounded-r py-3 me-2"
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('buttons.next')), 1),
                    _createVNode(_component_BaseIcon, {
                      class: "w-4 ms-2 cursor-pointer",
                      name: "arrow-dark"
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.step === 2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
                    _createElementVNode("button", {
                      onClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.step = 1)),
                      type: "button",
                      class: "secondary w-full me-2 mt-10 rounded-r py-3 flex items-center justify-center"
                    }, [
                      _createVNode(_component_BaseIcon, {
                        class: "w-4 me-2 cursor-pointer transform rotate-180",
                        name: "arrow-dark"
                      }),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('buttons.back')), 1)
                    ]),
                    _createElementVNode("button", {
                      onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.step = 3)),
                      type: "button",
                      class: "secondary w-full flex items-center justify-center mt-10 rounded-r py-3 me-2"
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('buttons.next')), 1),
                      _createVNode(_component_BaseIcon, {
                        class: "w-4 ms-2 cursor-pointer",
                        name: "arrow-dark"
                      })
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.step === 3)
                ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                    _createElementVNode("button", {
                      onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.step = 2)),
                      type: "button",
                      class: "secondary w-full mt-10 rounded-r py-3 flex items-center justify-center"
                    }, [
                      _createVNode(_component_BaseIcon, {
                        class: "w-4 me-2 cursor-pointer transform rotate-180",
                        name: "arrow-dark"
                      }),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('buttons.back')), 1)
                    ]),
                    _createElementVNode("button", {
                      type: "submit",
                      class: "primary w-full mt-10 rounded-r py-3 ms-2",
                      disabled: _ctx.v$.$invalid || _ctx.isLoading
                    }, _toDisplayString(_ctx.$t('buttons.submit')), 9, _hoisted_55)
                  ]))
                : _createCommentVNode("", true)
            ])
          ], 32)
        ])
      ])
    ])
  ]))
}