
import { defineAsyncComponent, defineComponent } from 'vue'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import { WorkShiftActions } from '@/store/modules/work-shifts/actions'
import { WorkShiftInterface } from '@/shared/interfaces/work-shift.interface'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'
import router from '@/router'

export default defineComponent({
  components: {
    SearchInput: defineAsyncComponent(() => import('@/shared/components/SearchInput.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    TableGhost: defineAsyncComponent(() => import('@/shared/components/ghosts/TableGhost.vue')),
    AddShift: defineAsyncComponent(() => import('@/views/account/workshifts/components/AddShift.vue')),
    Pagination: defineAsyncComponent(() => import('@/shared/components/Pagination.vue')),
    BaseModal: defineAsyncComponent(() => import('@/shared/components/modals/BaseModal.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue'))
  },
  data () {
    return {
      showModal: false,
      current: 1,
      height: 450,
      showFilterModal: false,
      searchValue: '',
      selectedShift: {} as WorkShiftInterface,
      isEdit: false,
      filters: [
        {
          name: 'Darkstores',
          model: 'darkstoreIds',
          type: 'checkbox',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'darkstores',
          data: []
        }
      ]
    }
  },
  computed: {
    workShifts (): WorkShiftInterface[] {
      return this.$store.getters['workShifts/getWorkShifts']
    },
    pagination (): PaginationInterface {
      return this.$store.getters['workShifts/getPagination']
    },
    loading (): boolean {
      return this.$store.getters['workShifts/getLoading']
    },
    darkstores (): any {
      return this.$store.getters['darkstores/getDarkStoresAsDictionary']
    }
  },
  mounted () {
    this.height = document.documentElement.clientHeight - 280
    addEventListener('resize', () => {
      this.height = document.documentElement.clientHeight - 280
    })
  },
  watch: {
    current (val) {
      router.push({ query: { ...this.$route.query, pageNumber: val } })
    },
    searchValue () {
      const queries = this.$route.query
      queries.pageNumber = '1'
      router.push({ query: { ...queries, search: this.searchValue ? this.searchValue : undefined } })
    },
    darkstores: {
      immediate: true,
      handler (val) {
        const target = this.filters[0]
        target.data = val
        target.dataLoading = false
      }
    },
    $route: {
      immediate: true,
      handler (val) {
        if (val.name === 'WorkShifts') {
          this.current = val.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
          if (val.query.search) {
            this.searchValue = val.query.search
          }
          let params = JSON.parse(JSON.stringify(val.query))
          params = { pageSize: 10, pageNumber: val.query.pageNumber ? val.query.pageNumber : 1, ...params }
          this.$store.dispatch(`workShifts/${WorkShiftActions.FETCH_WORK_SHIFTS}`, params)
        }
      }
    }
  },
  methods: {
    editShift (shift: WorkShiftInterface): void {
      this.selectedShift = shift
      this.showModal = true
      this.isEdit = true
    },
    onExpand (event: any): void {
      if (event === 'darkstores') {
        if (!this.darkstores.length) {
          this.$store.dispatch(`darkstores/${DarkStoresActions.FETCH_DARKSTORES}`, { pageNumber: 1, pageSize: 100 })
        }
      }
    }
  }
})
