import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'

export class CommonModel {
  public baseData: any
  public paginationData: PaginationInterface = {
    pageNumber: null,
    totalPage: null,
    totalItems: null,
    pageSize: null
  };

  constructor (data: CommonGetResponseInterface) {
    this._hasException(data)
  }

  protected clearBaseData (): void {
    this.baseData = []
  }

  private _hasException (response: any): void {
    if (response.error) {
      // Handle exception
    } else {
      if (response.data) {
        this._setResData(response)
      }
    }
  }

  static _hasPagination (response: any): boolean {
    return !!response.data.num_pages
  }

  private _setResData (response: any): void {
    if (response.data) {
      this.baseData = response.data.page_records ? response.data.page_records : response.data

      if (CommonModel._hasPagination(response)) {
        this.paginationData.pageNumber = response.data.current_page
        this.paginationData.totalItems = response.data.num_records
        this.paginationData.totalPage = response.data.num_pages
        this.paginationData.pageSize = response.data.per_page
      }
    }
  }
}
