import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b366e584"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-1 gap-4 lts-container scrollable-content" }
const _hoisted_2 = { class: "w-620 text-left form-wrapper" }
const _hoisted_3 = { class: "text-black font-bold text-lg mb-6" }
const _hoisted_4 = { class: "flex justify-between items-center rounded-lg shadow p-2 gap-2" }
const _hoisted_5 = {
  class: "dp__action_row",
  openontop: "false"
}
const _hoisted_6 = { class: "dp__selection_preview" }
const _hoisted_7 = { class: "dp__action_buttons" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "text-xs" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "text-xs" }
const _hoisted_13 = {
  key: 0,
  class: "mt-6 px-1 py-2"
}
const _hoisted_14 = {
  key: 1,
  class: "mt-6 flex flex-col gap-4 px-1 py-2 overflow-y-scroll order-list"
}
const _hoisted_15 = {
  key: 0,
  class: "mb-3 font-bold text-gray-800"
}
const _hoisted_16 = { key: 2 }
const _hoisted_17 = { class: "font-bold text-center pt-20" }
const _hoisted_18 = { class: "w-full rounded map-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_next_select = _resolveComponent("vue-next-select")!
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_Popper = _resolveComponent("Popper")!
  const _component_OrdersGhost = _resolveComponent("OrdersGhost")!
  const _component_OrderCard = _resolveComponent("OrderCard")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_LTSMap = _resolveComponent("LTSMap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('lts.playback')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_vue_next_select, {
          searchable: "",
          options: _ctx.driverOptions,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSearch($event))),
          onSelected: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSelect($event))),
          "label-by": "name",
          "value-by": "id",
          placeholder: _ctx.$t('lts.searchADriver'),
          "close-on-select": "",
          "clear-on-select": ""
        }, null, 8, ["options", "placeholder"]),
        _createVNode(_component_vue_next_select, {
          searchable: "",
          options: _ctx.orderOptions,
          "label-by": "name",
          "value-by": "id",
          "close-on-select": "",
          "clear-on-select": "",
          placeholder: _ctx.$t('lts.searchAnOrder'),
          disabled: _ctx.driver == null,
          onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSearchOrder($event))),
          onSelected: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSelectOrder($event)))
        }, null, 8, ["options", "placeholder", "disabled"]),
        _createElementVNode("div", {
          class: _normalizeClass(["absolute top-20 z-20", !_ctx.showCalendar && 'hidden'])
        }, [
          _createVNode(_component_Datepicker, {
            "onUpdate:modelValue": [
              _cache[5] || (_cache[5] = ($event: any) => (_ctx.dateChange($event))),
              _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dateRange) = $event))
            ],
            inline: "",
            modelValue: _ctx.dateRange,
            format: 'dd.MM.yyyy HH:mm - dd.MM.yyyy HH:mm',
            previewFormat: 'dd.MM.yyyy HH:mm - dd.MM.yyyy HH:mm',
            range: "",
            multiCalendars: "",
            class: "w-full"
          }, {
            "action-row": _withCtx(({ internalModelValue, selectDate }) => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formatDate(internalModelValue)), 1),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", {
                    class: "dp__action dp__select",
                    tabindex: "0",
                    "data-test": "select-button",
                    onClick: selectDate
                  }, "Select", 8, _hoisted_8),
                  _createElementVNode("span", {
                    class: "dp__action dp__close",
                    tabindex: "0",
                    "data-test": "close-button",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showCalendar = false))
                  }, "Close")
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "format", "previewFormat"])
        ], 2),
        _createVNode(_component_Popper, {
          arrow: "",
          hover: "",
          placement: "auto"
        }, {
          content: _withCtx(() => [
            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.dateRange ? _ctx.formatDate(_ctx.dateRange) : _ctx.$t('lts.selectDateRange')), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("button", {
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showCalendar = true)),
              class: "border border-menuColor w-10 flex-shrink-0 h-10 text-menuColor cursor-pointer rounded-full",
              disabled: _ctx.driver == null
            }, [
              _createVNode(_component_BaseIcon, { name: "time" })
            ], 8, _hoisted_9)
          ]),
          _: 1
        }),
        _createVNode(_component_Popper, {
          arrow: "",
          hover: "",
          placement: "auto"
        }, {
          content: _withCtx(() => [
            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('search')), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("button", {
              onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.getLocationData && _ctx.getLocationData(...args))),
              disabled: !_ctx.dateRange || _ctx.dateRange.length < 2 || !_ctx.driver,
              class: "disabled:cursor-not-allowed cursor-pointer border border-menuColor w-10 flex-shrink-0 h-10 text-menuColor rounded-full"
            }, [
              _createVNode(_component_BaseIcon, { name: "find" })
            ], 8, _hoisted_11)
          ]),
          _: 1
        })
      ]),
      (_ctx.orderLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_OrdersGhost)
          ]))
        : _createCommentVNode("", true),
      (_ctx.ordersData?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            (Array.isArray(_ctx.ordersData) && Array.isArray(_ctx.dateRange) && _ctx.dateRange.length >= 2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.$t('lts.order_found_for_date_range', [_ctx.ordersData?.length,  _ctx.moment(this.dateRange[0]).format('DD-MM-YYYY HH:mm'), _ctx.moment(_ctx.dateRange[1]).format('DD-MM-YYYY HH:mm')])), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ordersData, (item) => {
              return (_openBlock(), _createBlock(_component_OrderCard, {
                key: item.orderId,
                order: item
              }, null, 8, ["order"]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t('lts.no_orders')), 1)
          ])),
      (_ctx.pagination && _ctx.pagination.totalItems)
        ? (_openBlock(), _createBlock(_component_Pagination, {
            key: 3,
            current: _ctx.pageNumber,
            total: _ctx.pagination.totalItems,
            "page-range": 2,
            basicPagination: true,
            "per-page": _ctx.pagination.pageSize,
            onPageChanged: _cache[9] || (_cache[9] = ($event: any) => (_ctx.pageNumber = $event)),
            "text-before-input": _ctx.$t('lts.goto_page')
          }, null, 8, ["current", "total", "per-page", "text-before-input"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createVNode(_component_LTSMap, {
        locations: _ctx.locations,
        stopPoints: _ctx.stopPoints,
        summary: _ctx.summary,
        orders: _ctx.ordersData,
        class: "w-100",
        style: {"height":"77%"}
      }, null, 8, ["locations", "stopPoints", "summary", "orders"])
    ])
  ]))
}