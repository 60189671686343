import { MutationTree } from 'vuex'
import { ClientsState } from '@/store/modules/clients/types'

export enum ClientsMutations {
  SET_CLIENTS = 'SET_CLIENTS',
  TRIGGER_LOADING = 'TRIGGER_LOADING',
  SET_ORDERS = 'SET_ORDERS',
}

export const mutations: MutationTree<ClientsState> = {
  [ClientsMutations.SET_CLIENTS] (state, payload) {
    state.clients = payload.data
    state.pagination = payload.pagination
  },
  [ClientsMutations.TRIGGER_LOADING] (state, bool: boolean) {
    state.loading = bool
  },
  [ClientsMutations.SET_ORDERS] (state, payload) {
    state.orderList = payload.data
    state.orderPagination = payload.pagination
  }
}
