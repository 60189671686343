import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { PredefinedReasonsState } from '@/store/modules/predefined-reasons/types'
import { PredefinedReasonInterface } from '@/shared/interfaces/predefined-reasons.interface'

export const getters: GetterTree<PredefinedReasonsState, RootState> = {
  getPredefinedReasons (state): PredefinedReasonInterface[] {
    return state.predefinedReasons
  }
}
