import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { ReportsState } from '@/store/modules/reports/types'

const state: ReportsState = {
  reportTypes: [],
  driverDelivery: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  driverActivity: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  driverPerformance: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  driverProductivity: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  productivity: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  productivityDay: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  viewOrderDriver: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  ordersByStatus: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  ordersByStatusMonthly: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  ordersTiming: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  driversTimingDaily: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  driversTimingMonthly: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  darkStoreDeliveryMetrics: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  zoneDeliveryMetrics: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  darkStoreOrderPeakHours: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  hourlyDeliveryTimeAnalysis: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  driverRevenue: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  darkstoreDriverRevenue: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  aggregateDarkstoreRevenue: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  driverWallet: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  ordersSettlement: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  adminWallet: {
    reports: [],
    pagination: null,
    exportReports: []
  },
  loading: false,
  exportLoading: false
}

export const reports: Module<ReportsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
