
import { defineAsyncComponent, defineComponent } from 'vue'
import router from '@/router'
import { VehiclesActions } from '@/store/modules/vehicles/actions'

export default defineComponent({
  components: {
    SearchInput: defineAsyncComponent(() => import('@/shared/components/SearchInput.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    DynamicTable: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTable.vue'))
  },
  data () {
    return {
      showFilterModal: false,
      filters: [
        { name: this.$t('filters.date'), model: 'dates', type: 'date', calendarType: 'month' },
        { name: this.$t('filters.ordersCount'), model: 'ordersCount', type: 'number' },
        {
          name: this.$t('filters.vehicleType'),
          model: 'vehicleTypeId',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'vehicleTypes',
          type: 'radio',
          data: []
        },
        { name: this.$t('filters.trackingId'), model: 'trackingId', type: 'number' }
      ],
      tableFields: [
        { id: 'date', name: 'Created Date', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'created_at' },
        { id: 'username', name: 'Username', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'username' },
        { id: 'driver_full_name', name: 'Driver Full Name', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'driver_full_name' },
        { id: 'orders_count', name: 'Orders Count', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'orders_count' },
        { id: 'ready_for_pickup_average', name: 'Ready For Pickup Average', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'ready_for_pickup_average' },
        { id: 'ready_for_pickup_max', name: 'Ready For Pickup Time Max', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'ready_for_pickup_max' },
        { id: 'ready_for_pickup_min', name: 'Ready For Pickup Time Min', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'ready_for_pickup_min' },
        { id: 'ready_for_pickup_total', name: 'Ready For Pickup Time Total', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'ready_for_pickup_total' },
        { id: 'picked_up_time_average', name: 'Picked Up Time Average', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'picked_up_time_average' },
        { id: 'picked_up_time_max', name: 'Picked Up Time Max', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'picked_up_time_max' },
        { id: 'picked_up_time_min', name: 'Picked Up Time Min', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'picked_up_time_min' },
        { id: 'picked_up_time_total', name: 'Picked Up Time Total', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'picked_up_time_total' },
        { id: 'on_the_way_time_average', name: 'On The Way Time Average', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'on_the_way_time_average' },
        { id: 'on_the_way_time_max', name: 'On The Way Time Max', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'on_the_way_time_max' },
        { id: 'on_the_way_time_min', name: 'On The Way Time Min', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'on_the_way_time_min' },
        { id: 'on_the_way_time_total', name: 'On The Way Time Total', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'on_the_way_time_total' },
        { id: 'at_the_address_time_average', name: 'At The Address Time Average', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'at_the_address_time_average' },
        { id: 'at_the_address_time_max', name: 'At The Address Time Max', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'at_the_address_time_max' },
        { id: 'at_the_address_time_min', name: 'At The Address Time Min', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'at_the_address_time_min' },
        { id: 'at_the_address_time_total', name: 'At The Address Time Total', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'at_the_address_time_total' },
        { id: 'tracking_id', name: 'Tracking Id', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'tracking_id' }
      ],
      selectedReport: this.reportType,
      searchValue: null as any
    }
  },
  methods: {
    onExpand (event: any): void {
      if (event === 'vehicleTypes') {
        this.$store.dispatch(`vehicles/${[VehiclesActions.FETCH_VEHICLE_TYPES]}`)
      }
    },
    menuEvent (e: any): void {
      console.log(e)
    },
    clickSort (field: any): void {
      this.tableFields = this.tableFields?.map((el: any) => {
        if (el.id === field.id) {
          el.currentSort = true
          if (el.sort === null) {
            el.sort = 'asc'
          } else if (el.sort === 'asc') {
            el.sort = 'desc'
          } else if (el.sort === 'desc') {
            el.sort = null
          }
        } else {
          el.currentSort = false
          el.sort = null
        }
        return el
      })
      router.push({ query: { ...this.$route.query, orderBy: field.sortName, sort: field.sort } })
    }
  },
  watch: {
    vehicleTypes: {
      immediate: true,
      handler (val) {
        if (!val.length) return
        const target = this.filters[2]
        target.dataRequested = true
        target.data = val
        target.dataLoading = false
      }
    },
    $route: {
      immediate: true,
      handler (val: any) {
        if (val.query.orderBy) {
          this.tableFields = this.tableFields.map((el: any) => {
            if (el.id === val.query.orderBy) {
              el.currentSort = true
              el.sort = val.query.sort
            }
            return el
          })
        }
      }
    },
    searchValue () {
      const queries = this.$route.query
      queries.pageNumber = '1'
      router.push({ query: { ...queries, username: this.searchValue } })
    },
    selectedReport (val) {
      if (val) {
        router.push({ query: { reportName: val.name } })
      }
    }
  },
  props: {
    reportType: {
      type: String
    }
  },
  computed: {
    vehicleTypes (): any[] {
      return this.$store.getters['vehicles/getVehicleTypes']
        .map((el: any) => {
          return {
            id: el.id,
            label: el.value
          }
        })
    },
    reports () {
      return this.$store.getters['reports/getDriversTimingMonthlyReports']
    },
    exportReports () {
      return this.$store.getters['reports/getDriversTimingMonthlyExportReports']
    },
    pagination () {
      return this.$store.getters['reports/getDriversTimingMonthlyPagination']
    },
    loading () {
      return this.$store.getters['reports/getLoading']
    },
    statuses (): any {
      return this.$store.getters['orders/getOrderStatuses']
    }
  }
})
