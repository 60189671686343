
import { defineComponent, defineAsyncComponent } from 'vue'
import { ReportActions } from '@/store/modules/reports/actions'
import { ReportTypesInterface } from '@/shared/interfaces/reports/report-types.interface'
import router from '@/router'

export default defineComponent({
  components: {
    ViewDriversTimingPerMonth: defineAsyncComponent(() => import('@/views/account/reports/components/ViewDriversTimingPerMonth.vue')),
    ViewDriversTimingPerDay: defineAsyncComponent(() => import('@/views/account/reports/components/ViewDriversTimingPerDay.vue')),
    VIewOrdersTiming: defineAsyncComponent(() => import('@/views/account/reports/components/VIewOrdersTiming.vue')),
    OrdersByStatusMonthly: defineAsyncComponent(() => import('@/views/account/reports/components/OrdersByStatusMonthly.vue')),
    ProductivityPerDay: defineAsyncComponent(() => import('@/views/account/reports/components/ProductivityPerDay.vue')),
    OrdersByStatusReport: defineAsyncComponent(() => import('@/views/account/reports/components/OrdersByStatusReport.vue')),
    ViewOrderDriverReport: defineAsyncComponent(() => import('@/views/account/reports/components/ViewOrderDriverReport.vue')),
    ProductivityReport: defineAsyncComponent(() => import('@/views/account/reports/components/ProductivityReport.vue')),
    DriverActivityReport: defineAsyncComponent(() => import('@/views/account/reports/components/DriverActivity.vue')),
    DriverProductivityReport: defineAsyncComponent(() => import('@/views/account/reports/components/DriverProductivity.vue')),
    DriverDeliveryReport: defineAsyncComponent(() => import('@/views/account/reports/components/DriverDelivery.vue')),
    DarkStoreDeliveryMetricsReport: defineAsyncComponent(() => import('@/views/account/reports/components/DarkStoreDeliveryMetrics.vue')),
    ZoneDeliveryMetricsReport: defineAsyncComponent(() => import('@/views/account/reports/components/ZoneDeliveryMetrics.vue')),
    DriverPerformance: defineAsyncComponent(() => import('@/views/account/reports/components/DriverPerformance.vue')),
    HourlyDeliveryTimeAnalysis: defineAsyncComponent(() => import('@/views/account/reports/components/HourlyDeliveryTimeAnalysis.vue')),
    DriverRevenueReport: defineAsyncComponent(() => import('@/views/account/reports/components/DriverRevenueReport.vue')),
    DarkstoreDriverRevenue: defineAsyncComponent(() => import('@/views/account/reports/components/DarkstoreDriverRevenue.vue')),
    AggregateDarkstoreRevenue: defineAsyncComponent(() => import('@/views/account/reports/components/AggregateDarkstoreRevenue.vue')),
    DriverWallet: defineAsyncComponent(() => import('@/views/account/reports/components/DriverWallet.vue')),
    OrdersSettlement: defineAsyncComponent(() => import('@/views/account/reports/components/OrdersSettlement.vue')),
    AdminWallet: defineAsyncComponent(() => import('@/views/account/reports/components/AdminWallet.vue'))
  },
  data () {
    return {
      reportType: null as any,
      pageNumber: 1
    }
  },
  methods: {
    async getReports (reportName: any) {
      if (reportName === 'Darkstore_delivery_metrics') {
        this.reportType = {
          id: reportName,
          name: reportName
        }
      } else if (reportName === 'Zone_delivery_metrics') {
        this.reportType = {
          id: reportName,
          name: reportName
        }
      } else if (reportName === 'Driver_performance') {
        this.reportType = {
          id: reportName,
          name: reportName
        }
      } else {
        this.reportType = this.reportTypes.filter((el: ReportTypesInterface) => el.name === reportName)[0]
      }

      if (this.reportType.name === 'Driver_revenue' && this.$route.query.driverId == null) return
      if (['Darkstore_driver_revenue', 'Aggregate_darkstore_revenue'].includes(this.reportType.name) && this.$route.query.darkstoreId == null) return
      const queryParams = { reportType: this.reportType, pageNumber: 1, pageSize: 10, ...this.$route.query }
      this.$store.dispatch(`reports/${[ReportActions.FETCH_REPORTS]}`, queryParams)
    }
  },
  computed: {
    reportTypes (): ReportTypesInterface[] {
      return this.$store.getters['reports/getReportTypes']
    }
  },
  watch: {
    $route: {
      handler: function (val: any) {
        if (val.name === 'Reports') {
          if (val.params.reportName && this.$store.state.reports.reportTypes.length) {
            this.getReports(val.params.reportName)
          }
        }
      },
      deep: true,
      immediate: true
    },
    '$store.state.reports.reportTypes': {
      deep: true,
      immediate: false,
      handler (v) {
        if (v.length) {
          if (this.$route.params.reportName) {
            this.getReports(this.$route.params.reportName)
          } else {
            router.push({ query: { ...this.$route.query, reportName: v[0].name } })
          }
        }
      }
    }
  }
})
