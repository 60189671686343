export const isInt = (n: number) => {
  return n % 1 === 0
}

export const convertToKM = (val: number) => {
  return isInt(val / 1000) ? val / 1000 : (val / 1000).toFixed(2) + 'km'
}

export const formatToFix = (val: number, formatter = 2) => {
  return isInt(val) ? val : Number(val).toFixed(formatter)
}
