import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import moment from 'moment'
import { OrderCacheSettlementInterface } from '@/shared/interfaces/cache-order-settlement.interface'
export class OrderCacheSettlementsModel extends CommonModel {
  public data: OrderCacheSettlementInterface | null = null;

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.setData(this.baseData)
    this.clearBaseData()
  }

  private setData (data: any) {
    this.data = {
      id: data.id,
      driver: {
        driverId: data?.driver?.driver_info?.id ?? null,
        fullName: (data?.driver?.driver_info?.first_name ?? '') + ' ' + (data?.driver?.driver_info?.last_name ?? '')
      },
      adminActionDatetime: data.admin_action_datetime ? moment(data.admin_action_datetime).format('YYYY-MM-DD HH:mm') : undefined,
      adminId: data?.admin_id ?? undefined,
      createdAt: moment(data.created_at).format('YYYY-MM-DD HH:mm'),
      status: data.status,
      totalAmount: data.total_amount,
      erpSyncStatus: data.erp_sync_status,
      cacheSettlementsOrder: data.cache_settlement_orders.map((i: any) => {
        return {
          amount: i.amount,
          createdAt: moment(i.order.created_at).format('YYYY-MM-DD HH:mm'),
          driverId: i.driver_id,
          isConfirmed: i.is_confirmed,
          orderId: i.order.order_id,
          id: i.id,
          customerCode: i.order.customer_code
        }
      }),
      admin: data?.admin?.username ?? '',
      settlementNo: data?.settlement_no ?? ''
    }
  }
}
