import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40db185e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-left text-xs" }
const _hoisted_2 = { class: "flex items-center justify-between mb-1 text-black" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "font-bold px-1" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "bg-gray-100 rounded-lg p-2 text-gray-500" }
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "w-1/3 px-1 font-bold" }
const _hoisted_9 = { class: "w-1/3 text-center font-bold" }
const _hoisted_10 = { class: "w-1/3 text-right px-3 font-bold" }
const _hoisted_11 = { class: "content scrollable-content" }
const _hoisted_12 = { class: "p-1" }
const _hoisted_13 = { class: "flex w-full" }
const _hoisted_14 = { class: "w-1/3" }
const _hoisted_15 = { class: "w-1/3 text-center" }
const _hoisted_16 = { class: "w-1/3 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BaseIcon, { name: "time" }),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('lts.duration_info')), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('lts.total_duration')) + ": ", 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('lts.total_duration_info', [_ctx.totalDuration, _ctx.stopPoints.length])), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('lts.start')), 1),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('lts.end')), 1),
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('lts.duration')), 1)
        ]),
        _createElementVNode("div", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stopPoints, (sp, i) => {
            return (_openBlock(), _createElementBlock("div", { key: i }, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.moment(sp.stop_datetime).format('DD/MM/YYYY HH:mm A')), 1),
                  _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.moment(sp.stop_datetime).add(sp.duration, 'second').format('DD/MM/YYYY HH:mm A')), 1),
                  _createElementVNode("div", _hoisted_16, _toDisplayString(sp.duration) + " sec.", 1)
                ])
              ])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}