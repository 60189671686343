import { createApp } from 'vue'
import { VueCookieNext } from 'vue-cookie-next'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import Datepicker from '@vuepic/vue-datepicker'
import VueNextSelect from 'vue-next-select'
import Maska from 'maska'
import VueExcelXlsx from 'vue-excel-xlsx'
import Toast, { PluginOptions, POSITION } from 'vue-toastification'
import VueCollapsiblePanel from '@dafcoe/vue-collapsible-panel'
import { injectComponents } from '@/component-injector'
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'
import VueClickAway from 'vue3-click-away'
import '@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css'
import '@vuepic/vue-datepicker/dist/main.css'
const options: PluginOptions = {
  // You can set your default options here
  position: POSITION.TOP_RIGHT
}
export const app = createApp(App)
injectComponents(app)
app
  .component('Datepicker', Datepicker)
  .component('v-select', VueNextSelect)
  .use(store)
  .use(VueCollapsiblePanel)
  .use(Maska)
  .use(VueExcelXlsx)
  .use(VueClickAway)
  .mixin({
    methods: {
      createDriverFullName (driver: any): string {
        if (driver.firstName && driver.lastName) {
          return `${driver.userName} - ${driver.firstName} ${driver.lastName}`
        } else {
          return `${driver.userName}`
        }
      }
    }
  })
  .use(Toast, options)
  .use(router)
  .use(VueCookieNext)
  .use(i18n)
  .mount('#app')
