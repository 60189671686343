import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { ClientsState } from '@/store/modules/clients/types'

const state: ClientsState = {
  clients: [],
  pagination: null,
  loading: false,
  orderList: [],
  orderPagination: null
}

export const clients: Module<ClientsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
