import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { OrdersState } from '@/store/modules/orders/types'
import { getters } from '@/store/modules/orders/getters'
import { mutations } from '@/store/modules/orders/mutations'
import { actions } from '@/store/modules/orders/actions'

const state: OrdersState = {
  orderList: [],
  orderAutocompleteList: [],
  paginationAutocomplete: null,
  returnedItems: [],
  detailedOrder: null,
  orderHistories: [],
  orderHistoryPagination: {
    pageSize: 1,
    pageNumber: 10,
    totalItems: null,
    totalPage: null
  },
  pagination: {
    pageSize: 1,
    pageNumber: 10,
    totalItems: null,
    totalPage: null
  },
  loading: false,
  detailedReturnedOrder: null,
  statuses: [],
  nearbyOrders: [],
  request: null,
  acceptedOrdersCount: 0,
  navigateToNextPage: false
}

export const orders: Module<OrdersState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
