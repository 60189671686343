import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { AggregateDarkstoreRevenueInterface } from '@/shared/interfaces/reports/aggregate-darkstore-revenue.interface'

export class AggregateDarkstoreRevenue extends CommonModel {
  public data: AggregateDarkstoreRevenueInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): AggregateDarkstoreRevenueInterface {
    return {
      darkStoreId: data.dark_store_id,
      name: data.name,
      orderStatusName: data.order_status_name,
      orderStatusId: data.order_status_id,
      totalCollectedOrderAmount: data.total_collected_order_amount,
      totalOrders: data.total_orders,
      settledOrders: data?.settled_orders ?? 0,
      settledOrdersAmount: data?.settled_orders_amount ?? 0,
      notSettledOrders: data?.not_settled_orders ?? 0,
      notSettledOrdersAmount: data?.not_settled_orders_amount ?? 0,
      pendingSettledOrders: data?.pending_settlement_orders ?? 0,
      pendingSettledOrdersAmount: data?.pending_settlement_orders_amount ?? 0
    }
  }
}
