
import { defineComponent } from 'vue'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
import { ReturnedOrder } from '@/shared/interfaces/returned-order.interface'

export default defineComponent({
  name: 'ReturnedOrder',
  props: {
    order: {
      type: Object as () => ReturnedOrder,
      required: true
    }
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    toggleCollapse (): void {
      this.show = !this.show
    },
    editOrder (order: ReturnedOrder): void {
      this.$emit('editOrder', order)
    }
  },
  components: {
    BaseIcon
  }
})
