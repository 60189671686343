import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { apiEndpoints } from '@/shared/endpoints/api'
import moment from 'moment'
import axiosInstance from '@/shared/utils/axiosInstance'
import { ManualChangesState } from '@/store/modules/manual-changes/types'
import { ManualChangesMutations } from '@/store/modules/manual-changes/mutations'
import { ManualChangesModel } from '@/shared/models/manual-changes.model'

export enum ManualChangesActions {
  FETCH_MANUAL_CHANGES = 'FETCH_MANUAL_CHANGES',
  FETCH_ENTITY_TYPES = 'FETCH_ENTITY_TYPES'
}

export const actions: ActionTree<ManualChangesState, RootState> = {
  [ManualChangesActions.FETCH_MANUAL_CHANGES]: async (context, payload) => {
    if (!payload.export) {
      context.commit(ManualChangesMutations.TRIGGER_LOADING_MANUAL_CHANGES, true)
    }
    const params = new URLSearchParams()
    params.append('page_number', payload.pageNumber)
    params.append('per_page', payload.pageSize)
    if (payload.export) {
      params.append('export', '1')
    }
    if (payload.entityId && payload.entityId !== '') {
      params.append('entity_id', payload.entityId)
    }
    if (payload.search && payload.search !== '') {
      params.append('search', payload.search)
    }
    if (payload.entityType) {
      params.append('entity_type', payload.entityType)
    }
    if (payload.date_from) {
      params.append('from_date', moment(payload.date_from).format('YYYY-MM-DD hh:mm:ss'))
      params.append('to_date', moment(payload.date_to).format('YYYY-MM-DD hh:mm:ss'))
    }
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.manualChanges.list.method,
        url: apiEndpoints.manualChanges.list.url,
        params
      }).then(resp => {
        context.commit(ManualChangesMutations.TRIGGER_LOADING_MANUAL_CHANGES, false)
        const manualChangesResponse = new ManualChangesModel(resp.data)
        const dat = {
          data: manualChangesResponse.data,
          pagination: manualChangesResponse.paginationData
        }
        if (payload.export) {
          context.commit(ManualChangesMutations.INSERT_MANUAL_CHANGES_EXPORT, dat)
        } else {
          context.commit(ManualChangesMutations.INSERT_MANUAL_CHANGES, dat)
        }
        resolve(dat)
      }).catch(err => {
        context.commit(ManualChangesMutations.TRIGGER_LOADING_MANUAL_CHANGES, false)
        throw new Error(err)
      })
    })
  },

  [ManualChangesActions.FETCH_ENTITY_TYPES]: async (context, payload) => {
    context.commit(ManualChangesMutations.TRIGGER_LOADING_ENTITIES, true)
    const params = new URLSearchParams()
    await axiosInstance({
      method: apiEndpoints.manualChanges.types.method,
      url: apiEndpoints.manualChanges.types.url,
      params
    }).then(resp => {
      context.commit(ManualChangesMutations.TRIGGER_LOADING_ENTITIES, false)
      if (resp.data?.data) {
        context.commit(ManualChangesMutations.INSERT_ENTITY_TYPES, resp.data.data)
      }
    }).catch(err => {
      context.commit(ManualChangesMutations.TRIGGER_LOADING_ENTITIES, false)
      throw new Error(err)
    })
  }
}
