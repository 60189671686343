import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pt-14 pb-5 px-5" }
const _hoisted_2 = { class: "flex justify-between mb-7" }
const _hoisted_3 = { class: "flex justify-start w-full" }
const _hoisted_4 = { class: "flex justify-between w-full" }
const _hoisted_5 = { class: "relative h-42 w-288 me-3" }
const _hoisted_6 = { class: "px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_DynamicTable = _resolveComponent("DynamicTable")!
  const _component_DarkstoreSettings = _resolveComponent("DarkstoreSettings")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _component_ZoneManagement = _resolveComponent("ZoneManagement")!
  const _component_CreateDarkstore = _resolveComponent("CreateDarkstore")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PageTitle, { title: "darkstores.title" }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_SearchInput, {
                placeholder: _ctx.$t('darkstores.search'),
                modelValue: _ctx.searchValue,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event))
              }, null, 8, ["placeholder", "modelValue"])
            ]),
            (_ctx.isSuperAdmin)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "primary flex items-center rounded-md px-3",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showCreateDarkstorePopup = true))
                }, _toDisplayString(_ctx.$t('darkstores.createModal.title')), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_DynamicTable, {
        tableData: _ctx.darkstores,
        loading: _ctx.loading,
        pagination: _ctx.pagination,
        tableFields: _ctx.tableFields,
        scrollable: true,
        buttons: { edit: true, delete: false, export: false},
        onEditDarkstoreSettings: _ctx.onEditDarkStoreSettings,
        onAssignStore: _ctx.onEditDarkStoreSettings,
        onSort: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clickSort($event))),
        onMenuEvent: _cache[3] || (_cache[3] = ($event: any) => (_ctx.menuEvent($event)))
      }, null, 8, ["tableData", "loading", "pagination", "tableFields", "onEditDarkstoreSettings", "onAssignStore"])
    ]),
    (_ctx.selectedDarkstore && _ctx.showDarkstoreSettingsPopup)
      ? (_openBlock(), _createBlock(_component_BaseModal, {
          key: 0,
          title: _ctx.$t('darkstores.settings_update'),
          width: '675',
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showDarkstoreSettingsPopup = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DarkstoreSettings, {
              darkstore: _ctx.selectedDarkstore,
              onUpdated: _ctx.settingsUpdated
            }, null, 8, ["darkstore", "onUpdated"])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    (_ctx.selectedDarkstore && _ctx.showDarkstoreZoneManagementPopup)
      ? (_openBlock(), _createBlock(_component_BaseModal, {
          key: 1,
          title: _ctx.selectedDarkstore.name,
          width: '1200',
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showDarkstoreZoneManagementPopup = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ZoneManagement, {
              darkstore: _ctx.selectedDarkstore,
              style: {"height":"550px"}
            }, null, 8, ["darkstore"])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    (_ctx.showCreateDarkstorePopup)
      ? (_openBlock(), _createBlock(_component_BaseModal, {
          key: 2,
          title: _ctx.$t('darkstores.createModal.title'),
          width: '675',
          onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showCreateDarkstorePopup = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CreateDarkstore, {
              onOnDarkstoreCreated: _ctx.onDarkstoreCreatedHandler,
              onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showCreateDarkstorePopup = false))
            }, null, 8, ["onOnDarkstoreCreated"])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true)
  ], 64))
}