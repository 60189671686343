
import { defineComponent } from 'vue'
// import SettlementItem from '@/views/account/drivers/components/SettlementItem.vue'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
import DateRange from '@/shared/components/datepicker/DateRange.vue'
import { OrderActions } from '@/store/modules/orders/actions'

export default defineComponent({
  name: 'CashSettlement',
  components: {
    DateRange,
    BaseIcon
    // SettlementItem
  },
  mounted () {
    this.$store.dispatch(`orders/${[OrderActions.FETCH_CASH_SETTLEMENTS]}`, { driverId: this.$route.params.id })
  }
})
