
import { defineComponent } from 'vue'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
export default defineComponent({
  components: { BaseIcon },
  data () {
    return {
      isOpen: false
    }
  },
  props: {
    open: {
      type: Boolean
    },
    item: {
      type: Object
    },
    buttons: {
      type: Object as () => { edit?: boolean, delete?: boolean, export?: boolean }
    }
  },
  watch: {
    open (val) {
      this.isOpen = val
    }
  },
  methods: {
    onClickAway () {
      this.isOpen = false
    }
  }
})
