
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    row: {
      type: Number,
      required: true
    },
    col: {
      type: Number,
      required: true
    },
    colMargin: {
      type: String,
      default: 'py-0'
    }
  }
})
