import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { ManualChangesInterface } from '@/shared/interfaces/manual-changes.interface'
import moment from 'moment'

export class ManualChangesModel extends CommonModel {
  public data: ManualChangesInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): ManualChangesInterface {
    return {
      entityName: data.entity_name,
      createdAt: data.created_at,
      createdAtFormatted: moment(data.created_at).format('DD.MM.yyyy hh:mm:ss'),
      entityType: data.entity_type,
      fieldName: data.field_name,
      newValue: data.new_value,
      oldValue: data.old_value,
      owner: data.owner
    }
  }
}
