import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { CitiesState } from '@/store/modules/cities/types'

const state: CitiesState = {
  cities: [],
  pagination: null,
  loading: false
}

export const cities: Module<CitiesState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
