
import { defineAsyncComponent, defineComponent } from 'vue'
import { CacheSettlementsActions } from '@/store/modules/cache-settlements/actions'
import router from '@/router'
import DriverSelect from '@/views/account/cache-settlements/component/DriverSelect.vue'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
import moment from 'moment'

export default defineComponent({
  name: 'CacheSettlements',
  components: {
    DriverSelect,
    BaseIcon,
    DynamicTable: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTable.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    ConfirmationModal: defineAsyncComponent(() => import('@/shared/components/modals/ConfirmationModal.vue')),
    Pagination: defineAsyncComponent(() => import('@/shared/components/Pagination.vue'))
  },
  data () {
    return {
      confirmObject: null as any,
      tableFields: [
        { id: 'settlementNo', name: this.$t('cacheSettlements.fields.settlement_no'), width: '18%', sortable: false },
        { id: 'totalAmount', name: this.$t('cacheSettlements.fields.total_amount'), width: '18%', sortable: false },
        {
          id: 'status',
          name: this.$t('cacheSettlements.fields.status'),
          width: '18%',
          sortable: false,
          type: 'component',
          componentName: 'DynamicTableCacheSettlementStatus',
          props: {
            data: 'rowData' // rowData is all data of row
          }
        },
        { id: 'driver', name: this.$t('cacheSettlements.fields.driver'), width: '18%', sortable: false },
        { id: 'createdAt', name: this.$t('cacheSettlements.fields.date'), width: '18%', sortable: false },
        { id: 'adminActionDatetime', name: this.$t('cacheSettlements.fields.admin_action_datetime'), width: '18%', sortable: false },
        { id: 'admin', name: this.$t('cacheSettlements.fields.admin'), width: '18%', sortable: false },
        {
          id: 'erp_sync_status',
          name: this.$t('cacheSettlements.fields.erp_next_status'),
          width: '18%',
          sortable: false,
          type: 'component',
          componentName: 'DynamicTableErpSyncStatus',
          props: {
            data: 'rowData' // rowData is all data of row
          }
        },
        {
          id: 'actions',
          type: 'component',
          name: 'Actions',
          componentName: 'DynamicTableCacheSettlementAction',
          props: {
            data: 'rowData' // rowData is all data of row
          },
          on: {
            reject: this.onReject,
            approve: this.onApprove,
            retryErpSync: this.onRetryErpSync
          }
        }
      ],
      current: 1,
      dateRange: undefined as any,
      selectedDriver: null as any,
      adminUsername: null as any,
      exportLoading: false,
      exportFields: [
        { field: 'settlementNo', label: 'Smart ID' },
        { field: 'totalAmount', label: 'Total Amount' },
        { field: 'status', label: 'Status' },
        { field: 'driver', label: 'Driver' },
        { field: 'adminActionDatetime', label: 'Admin Action Date' },
        { field: 'admin', label: 'Admin' },
        { field: 'erpSyncStatus', label: 'ERP Sync Status' },
        { field: 'createdAt', label: 'Create Date' }
      ],
      exportData: []
    }
  },
  methods: {
    onReject (data: any) {
      this.confirmObject = {
        title: this.$t('cacheSettlements.confirms.reject.title'),
        message: this.$t('cacheSettlements.confirms.reject.message', [data.settlementNo]),
        handler: async () => {
          await this.$store.dispatch(`cacheSettlements/${[CacheSettlementsActions.REJECT_CACHE_SETTLEMENT]}`, data.id)
          this.init()
          this.confirmObject = null
        }
      }
    },
    onApprove (data: any) {
      this.confirmObject = {
        title: this.$t('cacheSettlements.confirms.approve.title'),
        message: this.$t('cacheSettlements.confirms.approve.message', [data.settlementNo]),
        handler: async () => {
          await this.$store.dispatch(`cacheSettlements/${[CacheSettlementsActions.APPROVE_CACHE_SETTLEMENT]}`, data.id)
          this.init()
          this.confirmObject = null
        }
      }
    },
    onRetryErpSync (data: any) {
      this.confirmObject = {
        title: this.$t('cacheSettlements.confirms.retryErpSync.title'),
        message: this.$t('cacheSettlements.confirms.retryErpSync.message', [data.settlementNo]),
        handler: async () => {
          await this.$store.dispatch(`cacheSettlements/${[CacheSettlementsActions.RETRY_ERP_SYNC]}`, data.id)
          this.init()
          this.confirmObject = null
        }
      }
    },
    gotoDetail (data: any) {
      this.$router.push(`/cache-settlements/${this.type}/${data.id}`)
    },
    init (route = null) {
      const val = route || this.$route
      let params = JSON.parse(JSON.stringify(val.query))
      params = { pageSize: 10, pageNumber: val.query.pageNumber ? val.query.pageNumber : 1, ...params, status: val.params.type ?? undefined }
      this.$store.dispatch(`cacheSettlements/${[CacheSettlementsActions.FETCH_CACHE_SETTLEMENTS]}`, params)
    },
    onFilter () {
      router.push({
        query: {
          page: 1,
          createdAt_from: this.dateRange && Array.isArray(this.dateRange) && this.dateRange.length > 1 ? moment(this.dateRange[0]).format('YYYY-MM-DD HH:mm:ss') : undefined,
          createdAt_to: this.dateRange && Array.isArray(this.dateRange) && this.dateRange.length > 1 ? moment(this.dateRange[1]).format('YYYY-MM-DD HH:mm:ss') : undefined,
          driverId: this.selectedDriver?.id ?? undefined,
          adminUsername: this.adminUsername && this.adminUsername.length ? this.adminUsername : undefined
        }
      })
    },
    exportTableToExcel (event: any) {
      event.stopPropagation()
      this.exportLoading = true
      const button = document.getElementById('excel-export')
      this.$store.dispatch(`cacheSettlements/${[CacheSettlementsActions.FETCH_CACHE_SETTLEMENTS]}`, { export: true, pageNumber: 1, pageSize: this.pagination?.totalItems || 100, ...this.$route.query, status: this.$route.params.type }).then((res: any) => {
        // eslint-disable-next-line no-unused-expressions
        this.exportData = res
          .map((item: any) => ({ ...item, driver: item.driver.fullName }))
          .map((item: any) => {
            const obj: any = {}
            // eslint-disable-next-line no-unused-expressions
            this.exportFields.forEach((f: any) => {
              obj[f.field] = item[f.field]
            })
            return obj
          })
        if (button) {
          setTimeout(() => {
            button.click()
            this.exportLoading = false
          }, 200)
        }
      })
    }
  },
  computed: {
    type () {
      return this.$route.params?.type ?? 'all'
    },
    records () {
      return this.$store.getters['cacheSettlements/getCacheSettlements'].map((item: any) => ({ ...item, driver: item.driver.fullName }))
    },
    loading () {
      return this.$store.getters['cacheSettlements/getLoading']
    },
    pagination () {
      return this.$store.getters['cacheSettlements/getPagination']
    }
  },
  watch: {
    current (val) {
      router.push({
        query: {
          ...this.$route.query,
          pageNumber: val
        }
      })
    },
    $route: {
      immediate: true,
      handler (val) {
        if (val.name === 'CacheSettlements') {
          this.init(val)
        }
      }
    }
  }
})
