import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex flex-wrap p-8" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = {
  key: 0,
  class: "form-error"
}
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = {
  key: 0,
  class: "form-error"
}
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = {
  key: 0,
  class: "form-error"
}
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = {
  key: 0,
  class: "form-error"
}
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = {
  key: 0,
  class: "form-error"
}
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = {
  key: 0,
  class: "form-error"
}
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = {
  key: 0,
  class: "form-error"
}
const _hoisted_16 = { class: "flex flex-wrap items-center justify-end w-full" }
const _hoisted_17 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[12] || (_cache[12] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
      class: "w-full"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass([{error: _ctx.v$.name.$error}, "h-42 form-control"]),
          type: "text",
          placeholder: " ",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
          onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.v$.name.$touch && _ctx.v$.name.$touch(...args)))
        }, null, 34), [
          [_vModelText, _ctx.name]
        ]),
        _createElementVNode("label", {
          class: _normalizeClass([{error: _ctx.v$.name.$error}, "floating-label"])
        }, _toDisplayString(_ctx.$t('addVehicle.name')), 3),
        (_ctx.v$.name.$error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _createVNode(_component_BaseIcon, {
                class: "me-1",
                name: "alert"
              }),
              _createTextVNode(_toDisplayString(_ctx.$t('validation.required')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass([{error: _ctx.v$.model.$error}, "h-42 form-control"]),
          type: "text",
          placeholder: " ",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model) = $event)),
          onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.v$.model.$touch && _ctx.v$.model.$touch(...args)))
        }, null, 34), [
          [_vModelText, _ctx.model]
        ]),
        _createElementVNode("label", {
          class: _normalizeClass([{error: _ctx.v$.model.$error}, "floating-label"])
        }, _toDisplayString(_ctx.$t('addVehicle.model')), 3),
        (_ctx.v$.model.$error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _createVNode(_component_BaseIcon, {
                class: "me-1",
                name: "alert"
              }),
              _createTextVNode(_toDisplayString(_ctx.$t('validation.required')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass([{error: _ctx.v$.make.$error}, "h-42 form-control"]),
          type: "text",
          placeholder: " ",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.make) = $event)),
          onBlur: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.v$.make.$touch && _ctx.v$.make.$touch(...args)))
        }, null, 34), [
          [_vModelText, _ctx.make]
        ]),
        _createElementVNode("label", {
          class: _normalizeClass([{error: _ctx.v$.make.$error}, "floating-label"])
        }, _toDisplayString(_ctx.$t('addVehicle.make')), 3),
        (_ctx.v$.make.$error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
              _createVNode(_component_BaseIcon, {
                class: "me-1",
                name: "alert"
              }),
              _createTextVNode(_toDisplayString(_ctx.$t('validation.required')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_Multiselect, {
          modelValue: _ctx.type,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.type) = $event)),
          options: _ctx.vehicleTypes,
          label: "value",
          valueProp: "id",
          noOptionsText: _ctx.$t('validation.noItemsToShow'),
          placeholder: _ctx.$t('placeholders.vehicleType')
        }, null, 8, ["modelValue", "options", "noOptionsText", "placeholder"]),
        (_ctx.v$.type.$error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
              _createVNode(_component_BaseIcon, {
                class: "me-1",
                name: "alert"
              }),
              _createTextVNode(_toDisplayString(_ctx.$t('validation.required')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_Multiselect, {
          modelValue: _ctx.owner,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.owner) = $event)),
          options: _ctx.vehicleOwners,
          label: "value",
          valueProp: "id",
          noOptionsText: _ctx.$t('validation.noItemsToShow'),
          placeholder: _ctx.$t('placeholders.vehicleOwner')
        }, null, 8, ["modelValue", "options", "noOptionsText", "placeholder"]),
        (_ctx.v$.owner.$error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
              _createVNode(_component_BaseIcon, {
                class: "me-1",
                name: "alert"
              }),
              _createTextVNode(_toDisplayString(_ctx.$t('validation.required')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass([{error: _ctx.v$.licenseNo.$error}, "h-42 form-control"]),
          type: "text",
          placeholder: " ",
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.licenseNo) = $event)),
          onBlur: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.v$.licenseNo.$touch && _ctx.v$.licenseNo.$touch(...args)))
        }, null, 34), [
          [_vModelText, _ctx.licenseNo]
        ]),
        _createElementVNode("label", {
          class: _normalizeClass([{error: _ctx.v$.licenseNo.$error}, "floating-label"])
        }, _toDisplayString(_ctx.$t('addVehicle.licenseNo')), 3),
        (_ctx.v$.licenseNo.$error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
              _createVNode(_component_BaseIcon, {
                class: "me-1",
                name: "alert"
              }),
              _createTextVNode(_toDisplayString(_ctx.$t('validation.required')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_Multiselect, {
          modelValue: _ctx.year,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.year) = $event)),
          options: _ctx.years,
          label: "value",
          valueProp: "id",
          noOptionsText: _ctx.$t('validation.noItemsToShow'),
          placeholder: _ctx.$t('placeholders.buildYear')
        }, null, 8, ["modelValue", "options", "noOptionsText", "placeholder"]),
        (_ctx.v$.year.$error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
              _createVNode(_component_BaseIcon, {
                class: "me-1",
                name: "alert"
              }),
              _createTextVNode(_toDisplayString(_ctx.$t('validation.required')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[11] || (_cache[11] = ($event: any) => (this.$emit('close'))),
          class: "secondary m-1 w-32 px-4 py-2"
        }, _toDisplayString(_ctx.$t('buttons.close')), 1),
        _createElementVNode("button", {
          disabled: _ctx.v$.$invalid,
          type: "submit",
          class: "primary m-1 w-32 px-4 py-2"
        }, _toDisplayString(_ctx.isEdit ? _ctx.$t('buttons.updateVehicle') : _ctx.$t('buttons.addVehicle')), 9, _hoisted_17)
      ])
    ], 32)
  ]))
}