import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { CacheSettlementsState } from '@/store/modules/cache-settlements/types'

const state: CacheSettlementsState = {
  cacheSettlements: [],
  cacheSettlementOrders: null,
  pagination: null,
  loading: false
}

export const cacheSettlements: Module<CacheSettlementsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
