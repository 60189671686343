import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { NotificationsState } from '@/store/modules/notifications/types'

const state: NotificationsState = {
  notifications: [
    {
      id: 1,
      message: 'Just picked up the order, direction to the customer address',
      createdAt: '01:00 AM'
    },
    {
      id: 2,
      message: 'test',
      createdAt: '00:01 AM'
    },
    {
      id: 3,
      message: 'test',
      createdAt: '02:00 AM'
    },
    {
      id: 3,
      message: 'test',
      createdAt: '02:00 AM'
    }
  ],
  pagination: {
    pageNumber: 3,
    pageSize: 6,
    totalItems: 23,
    totalPage: 4
  },
  loading: false
}

export const notifications: Module<NotificationsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
