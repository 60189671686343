import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pt-14 pb-5 px-5" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "flex justify-start" }
const _hoisted_4 = { class: "relative h-42 w-288 me-3" }
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_FilterPanel = _resolveComponent("FilterPanel")!
  const _component_DynamicTable = _resolveComponent("DynamicTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_PageTitle, { title: "reports.viewDriversTimingMonth" })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_SearchInput, {
            placeholder: _ctx.$t('placeholders.username'),
            modelValue: _ctx.searchValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event))
          }, null, 8, ["placeholder", "modelValue"])
        ]),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showFilterModal = true)),
          class: "border border-inputBorder w-42 h-42"
        }, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_BaseIcon, { name: "filter" })
          ])
        ]),
        (_ctx.showFilterModal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_FilterPanel, {
                filters: _ctx.filters,
                onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showFilterModal = false)),
                onFetchData: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onExpand($event)))
              }, null, 8, ["filters"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_DynamicTable, {
        tableData: _ctx.reports,
        exportData: _ctx.exportReports,
        loading: _ctx.loading,
        reportType: _ctx.reportType,
        pagination: _ctx.pagination,
        tableFields: _ctx.tableFields,
        scrollable: true,
        exportFields: [
        { id: 'date', name: 'Created Date', width: '16%' },
        { id: 'username', name: 'Username', width: '16%' },
        { id: 'driver_full_name', name: 'Driver Full Name', width: '16%' },
        { id: 'orders_count', name: 'Orders Count', width: '14%' },
        { id: 'ready_for_pickup_average', name: 'Ready For Pickup Average', width: '18%' },
        { id: 'ready_for_pickup_max', name: 'Ready For Pickup Time Max', width: '18%' },
        { id: 'ready_for_pickup_min', name: 'Ready For Pickup Time Min', width: '18%' },
        { id: 'ready_for_pickup_total', name: 'Ready For Pickup Time Total', width: '18%' },
        { id: 'picked_up_time_average', name: 'Picked Up Time Average', width: '18%' },
        { id: 'picked_up_time_max', name: 'Picked Up Time Max', width: '18%' },
        { id: 'picked_up_time_min', name: 'Picked Up Time Min', width: '18%' },
        { id: 'picked_up_time_total', name: 'Picked Up Time Total', width: '18%' },
        { id: 'on_the_way_time_average', name: 'On The Way Time Average', width: '18%' },
        { id: 'on_the_way_time_max', name: 'On The Way Time Max', width: '18%' },
        { id: 'on_the_way_time_min', name: 'On The Way Time Min', width: '18%' },
        { id: 'on_the_way_time_total', name: 'On The Way Time Total', width: '18%' },
        { id: 'at_the_address_time_average', name: 'At The Address Time Average', width: '18%' },
        { id: 'at_the_address_time_max', name: 'At The Address Time Max', width: '18%' },
        { id: 'at_the_address_time_min', name: 'At The Address Time Min', width: '18%' },
        { id: 'at_the_address_time_total', name: 'At The Address Time Total', width: '18%' },
        { id: 'tracking_id', name: 'Tracking Id', width: '18%' }
      ],
        buttons: { edit: true, delete: true, export: true},
        onSort: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clickSort($event))),
        onMenuEvent: _cache[5] || (_cache[5] = ($event: any) => (_ctx.menuEvent($event)))
      }, null, 8, ["tableData", "exportData", "loading", "reportType", "pagination", "tableFields"])
    ])
  ], 64))
}