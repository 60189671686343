import { NearbyOrderInterface } from '@/shared/interfaces/nearby-order.interface'
import { CommonModel } from '@/shared/models/common.model'
import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'

export class NearbyOrderModel extends CommonModel {
  public data: NearbyOrderInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    if (Array.isArray(this.baseData)) {
      this.baseData.forEach((item: any) => {
        this.data = [...this.data, { ...this.setData(item) }]
      })
    }
  }

  private setData (data: any): NearbyOrderInterface {
    return {
      id: data.assigned_driver.id,
      username: data.assigned_driver.username,
      orderId: data.order_id,
      orderTravelDuration: Math.ceil(data.order_travel_duration / 60)
    }
  }
}
