
import draggableComponent from 'vuedraggable'
import { defineAsyncComponent, defineComponent } from 'vue'
import { Order } from '@/shared/interfaces/order.interface'
import { cloneDeep } from 'lodash'
import { NearbyOrderInterface } from '@/shared/interfaces/nearby-order.interface'
import { OrderActions } from '@/store/modules/orders/actions'
import { Driver } from '@/shared/interfaces/driver.interface'
import AssignmentMap from '@/views/account/orders/components/AssignmentMap.vue'
import { DriverActions } from '@/store/modules/drivers/actions'
import { PredefinedFeedbackTypes } from '@/shared/interfaces/predefined-reasons.interface'

export default defineComponent({
  name: 'OrderManuelAssignModal',
  components: {
    AssignmentMap,
    draggableComponent,
    ConfirmationModal: defineAsyncComponent(() => import('@/shared/components/modals/ConfirmationModal.vue')),
    Multiselect: defineAsyncComponent(() => import('@vueform/multiselect')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    ReasonForm: defineAsyncComponent(() => import('@/views/account/orders/components/ReasonForm.vue'))
  },
  props: {
    orderData: {
      type: Object as () => Order,
      required: true
    }
  },
  data () {
    return {
      relatedOrders: [],
      selectedDriver: null as any,
      selectedNearbyDriver: null as any,
      showConfirmation: false,
      step: 1,
      selectedDriverOrders: null as any,
      assignReason: null as any,
      PredefinedFeedbackTypes
    }
  },
  methods: {
    assignOrder () {
      const orderId = this.orderData.id
      const payload = {
        order_sequence: this.relatedOrders.map((o:Order) => o.id),
        ...this.assignReason
      }
      if (this.selectedDriver) {
        Object.assign(payload, { driver_id: this.selectedDriver.id })
      } else if (this.selectedNearbyDriver) {
        Object.assign(payload, { driver_id: this.selectedNearbyDriver.id, taza_order_id: this.selectedNearbyDriver.orderId })
      }
      this.$store.dispatch(`orders/${[OrderActions.MANUEL_ORDER_ASSIGN]}`, { orderId, payload }).then(() => {
        this.$emit('resetDriverData', { orderId: this.orderData.id })
        this.$emit('close')
      }).finally(() => {
        this.showConfirmation = false
      })
    },
    rejectAssignDriver (val: boolean): void {
      this.showConfirmation = false
    },
    confirmAssignDriver (val: any): void {
      this.assignOrder()
    }
  },
  watch: {
    async selectedDriver (val: any) {
      if (val) {
        this.selectedNearbyDriver = null
        if (val) {
          const orders = await this.$store.dispatch(`drivers/${DriverActions.DRIVER_ORDERS}`, val.id)
          if (orders && orders.order_records) {
            if (this.orderData.linkedOrders) {
              this.relatedOrders = cloneDeep(this.orderData.linkedOrders)
            }
            this.relatedOrders = [
              ...orders.order_records,
              {
                id: this.orderData.id,
                order_id: this.orderData.orderId,
                destination_latitude: this.orderData.destinationLatitude,
                destination_longitude: this.orderData.destinationLongitude,
                order_status: this.orderData.orderStatus
              }
            ] as any
          }
        }
      }
    },
    selectedNearbyDriver (val: string) {
      if (val) {
        this.selectedDriver = null
      }
    }
  },
  computed: {
    activateSubmitButton (): boolean {
      return this.selectedDriver && (this.selectedDriver !== this.orderData.driver?.driverInfo.id)
    },
    drivers (): Driver[] {
      return this.$store.getters['drivers/getDriversWithCount']
    },
    nearbyOrders (): NearbyOrderInterface[] {
      return this.$store.getters['orders/getNearbyOrders']
    },
    isManualAssignmentDisabled (): boolean {
      if (!this.selectedDriver && !this.selectedNearbyDriver) {
        return true
      } else if (this.orderData && this.orderData.driver && this.orderData.driver.driverInfo.id === this.selectedDriver?.id) {
        return true
      } else {
        return false
      }
    },
    confirmationTitle (): string {
      let driverName = ''
      if (this.selectedDriver) {
        driverName = this.selectedDriver.value
      } else if (this.selectedNearbyDriver) {
        driverName = this.selectedDriver.username
      }
      return this.$t('orders.assignConfirmation.title', { driver: driverName, darkstore: this.orderData.darkStore.name, orderNumber: this.orderData.orderId })
    },
    coordinates () {
      return this.relatedOrders.map((o: any) => ({ lng: o.destination_longitude, lat: o.destination_latitude }))
    },
    storeCoordinates () {
      return { lat: this.orderData.darkStore.latitude, lng: this.orderData.darkStore.longitude }
    }
  },
  mounted () {
    if (this.orderData.linkedOrders) {
      this.relatedOrders = cloneDeep(this.orderData.linkedOrders)
    }
  }
})
