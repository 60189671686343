import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { DarkstoreOrderPeakHoursInterface } from '@/shared/interfaces/reports/darkstore-order-peak-hours.interface'

export class DarkstoreOrderPeakHoursModel extends CommonModel {
  public data: DarkstoreOrderPeakHoursInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    if (!Array.isArray(this.baseData)) {
      this.data = []
    } else {
      this.baseData.forEach((item: any) => {
        this.data = [...this.data, { ...this.setData(item) }]
      })
    }
  }

  private setData (data: any): DarkstoreOrderPeakHoursInterface {
    return {
      darkstoreId: data.darkstore_id,
      darkstoreName: data.darkstore_name,
      orderHour: data.order_hour,
      totalOrders: data.total_orders
    }
  }
}
