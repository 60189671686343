import { MutationTree } from 'vuex'
import { ReportsState } from '@/store/modules/reports/types'

export enum ReportsMutations {
  SET_REPORT_TYPES = 'SET_REPORT_TYPES',
  SET_PRODUCTIVITY_REPORTS = 'SET_PRODUCTIVITY_REPORTS',
  SET_VIEW_ORDER_DRIVER_REPORTS = 'SET_VIEW_ORDER_DRIVER_REPORTS',
  SET_ORDERS_BY_STATUS_REPORTS = 'SET_ORDERS_BY_STATUS_REPORTS',
  SET_PRODUCTIVITY_EXPORT_REPORTS = 'SET_PRODUCTIVITY_EXPORT_REPORTS',
  SET_VIEW_ORDER_DRIVER_EXPORT_REPORTS = 'SET_VIEW_ORDER_DRIVER_EXPORT_REPORTS',
  SET_ORDERS_BY_STATUS_EXPORT_REPORTS = 'SET_ORDERS_BY_STATUS_EXPORT_REPORTS',
  EXPORT_REPORTS_LOADING_EVENT = 'EXPORT_REPORTS_LOADING_EVENT',
  REPORTS_LOADING_EVENT = 'REPORTS_LOADING_EVENT',
  SET_PRODUCTIVITY_DAY_EXPORT_REPORTS = 'SET_PRODUCTIVITY_DAY_EXPORT_REPORTS',
  SET_PRODUCTIVITY_DAY_REPORTS = 'SET_PRODUCTIVITY_DAY_REPORTS',
  SET_ORDERS_BY_STATUS_MONTHLY_EXPORT_REPORTS = 'SET_ORDERS_BY_STATUS_MONTHLY_EXPORT_REPORTS',
  SET_ORDERS_BY_STATUS_MONTHLY_REPORTS ='SET_ORDERS_BY_STATUS_MONTHLY_REPORTS',
  SET_ORDERS_TIMING_EXPORT_REPORTS = 'SET_ORDERS_TIMING_EXPORT_REPORTS',
  SET_ORDERS_TIMING_REPORTS = 'SET_ORDERS_TIMING_REPORTS',
  SET_DRIVERS_TIMING_PER_DAY_EXPORT_REPORTS = 'SET_DRIVERS_TIMING_PER_DAY_EXPORT_REPORTS',
  SET_DRIVERS_TIMING_PER_DAY_REPORTS = 'SET_DRIVERS_TIMING_PER_DAY_REPORTS',
  SET_DRIVERS_TIMING_PER_MONTH_EXPORT_REPORTS = 'SET_DRIVERS_TIMING_PER_MONTH_EXPORT_REPORTS',
  SET_DRIVERS_TIMING_PER_MONTH_REPORTS = 'SET_DRIVERS_TIMING_PER_MONTH_REPORTS',
  SET_DRIVER_ACTIVITY_EXPORT = 'SET_DRIVER_ACTIVITY_EXPORT',
  SET_DRIVER_ACTIVITY = 'SET_DRIVER_ACTIVITY',
  SET_DRIVER_PRODUCTIVITY_EXPORT = 'SET_DRIVER_PRODUCTIVITY_EXPORT',
  SET_DRIVER_PRODUCTIVITY = 'SET_DRIVER_PRODUCTIVITY',
  SET_DRIVER_DELIVERY = 'SET_DRIVER_DELIVERY',
  SET_DRIVER_DELIVERY_EXPORT = 'SET_DRIVER_DELIVERY_EXPORT',
  SET_DARKSTORE_DELIVERY_METRICS = 'SET_DARKSTORE_DELIVERY_METRICS',
  SET_DARKSTORE_DELIVERY_METRICS_EXPORT = 'SET_DARKSTORE_DELIVERY_METRICS_EXPORT',
  SET_ZONE_DELIVERY_METRICS = 'SET_ZONE_DELIVERY_METRICS',
  SET_ZONE_DELIVERY_METRICS_EXPORT = 'SET_ZONE_DELIVERY_METRICS_EXPORT',
  SET_DRIVER_PERFORMANCE = 'SET_DRIVER_PERFORMANCE',
  SET_DRIVER_PERFORMANCE_EXPORT = 'SET_DRIVER_PERFORMANCE_EXPORT',
  SET_DARKSTORE_ORDER_PEAK_HOURS_EXPORT = 'SET_DARKSTORE_ORDER_PEAK_HOURS_EXPORT',
  SET_DARKSTORE_ORDER_PEAK_HOURS = 'SET_DARKSTORE_ORDER_PEAK_HOURS',
  SET_HOURLY_DELIVERY_TIME_ANALYSIS_EXPORT = 'SET_HOURLY_DELIVERY_TIME_ANALYSIS_EXPORT',
  SET_HOURLY_DELIVERY_TIME_ANALYSIS = 'SET_HOURLY_DELIVERY_TIME_ANALYSIS',
  SET_DRIVER_REVENUE = 'SET_DRIVER_REVENUE',
  SET_DRIVER_REVENUE_EXPORT = 'SET_DRIVER_REVENUE_EXPORT',
  DARKSTORE_DRIVER_REVENUE= 'DARKSTORE_DRIVER_REVENUE',
  DARKSTORE_DRIVER_REVENUE_EXPORT= 'DARKSTORE_DRIVER_REVENUE_EXPORT',
  DARKSTORE_AGGREGATE_REVENUE= 'DARKSTORE_AGGREGATE_REVENUE',
  DARKSTORE_AGGREGATE_REVENUE_EXPORT= 'DARKSTORE_AGGREGATE_REVENUE_EXPORT',
  DRIVER_WALLET = 'DRIVER_WALLET',
  DRIVER_WALLET_EXPORT = 'DRIVER_WALLET_EXPORT',
  ORDERS_SETTLEMENTS = 'ORDERS_SETTLEMENTS',
  ORDERS_SETTLEMENTS_EXPORT = 'ORDERS_SETTLEMENTS_EXPORT',
  ADMIN_WALLET_EXPORT = 'ADMIN_WALLET_EXPORT',
  ADMIN_WALLET = 'ADMIN_WALLET'
}

export const mutations: MutationTree<ReportsState> = {
  [ReportsMutations.SET_REPORT_TYPES] (state, payload) {
    state.reportTypes = payload
  },
  [ReportsMutations.SET_DRIVER_DELIVERY] (state, payload) {
    state.driverDelivery.reports = payload.data
    state.driverDelivery.pagination = payload.pagination
  },
  [ReportsMutations.SET_DARKSTORE_DELIVERY_METRICS] (state, payload) {
    state.darkStoreDeliveryMetrics.reports = payload.data
    state.darkStoreDeliveryMetrics.pagination = payload.pagination
  },
  [ReportsMutations.SET_DRIVER_DELIVERY_EXPORT] (state, payload) {
    state.driverDelivery.exportReports = payload.data
  },
  [ReportsMutations.SET_DRIVER_ACTIVITY] (state, payload) {
    state.driverActivity.reports = payload.data
    state.driverActivity.pagination = payload.pagination
  },
  [ReportsMutations.SET_DRIVER_ACTIVITY_EXPORT] (state, payload) {
    state.driverActivity.exportReports = payload.data
  },
  [ReportsMutations.SET_DRIVER_PRODUCTIVITY] (state, payload) {
    state.driverProductivity.reports = payload.data
    state.driverProductivity.pagination = payload.pagination
  },
  [ReportsMutations.SET_DRIVER_PRODUCTIVITY_EXPORT] (state, payload) {
    state.driverProductivity.exportReports = payload.data
  },
  [ReportsMutations.SET_PRODUCTIVITY_REPORTS] (state, payload) {
    state.productivity.reports = payload.data
    state.productivity.pagination = payload.pagination
  },
  [ReportsMutations.SET_VIEW_ORDER_DRIVER_REPORTS] (state, payload) {
    state.viewOrderDriver.reports = payload.data
    state.viewOrderDriver.pagination = payload.pagination
  },
  [ReportsMutations.SET_ORDERS_BY_STATUS_REPORTS] (state, payload) {
    state.ordersByStatus.reports = payload.data
    state.ordersByStatus.pagination = payload.pagination
  },
  [ReportsMutations.SET_PRODUCTIVITY_DAY_REPORTS] (state, payload) {
    state.productivityDay.reports = payload.data
    state.productivityDay.pagination = payload.pagination
  },
  [ReportsMutations.SET_PRODUCTIVITY_EXPORT_REPORTS] (state, payload) {
    state.productivity.exportReports = payload.data
  },
  [ReportsMutations.SET_VIEW_ORDER_DRIVER_EXPORT_REPORTS] (state, payload) {
    state.viewOrderDriver.exportReports = payload.data
  },
  [ReportsMutations.SET_ORDERS_BY_STATUS_EXPORT_REPORTS] (state, payload) {
    state.ordersByStatus.exportReports = payload.data
  },
  [ReportsMutations.SET_ORDERS_BY_STATUS_MONTHLY_REPORTS] (state, payload) {
    state.ordersByStatusMonthly.reports = payload.data
    state.ordersByStatusMonthly.pagination = payload.pagination
  },
  [ReportsMutations.SET_ORDERS_BY_STATUS_MONTHLY_EXPORT_REPORTS] (state, payload) {
    state.ordersByStatusMonthly.exportReports = payload.data
  },
  [ReportsMutations.SET_PRODUCTIVITY_DAY_EXPORT_REPORTS] (state, payload) {
    state.productivityDay.exportReports = payload.data
  },
  [ReportsMutations.SET_ORDERS_TIMING_REPORTS] (state, payload) {
    state.ordersTiming.reports = payload.data
    state.ordersTiming.pagination = payload.pagination
  },
  [ReportsMutations.SET_DARKSTORE_DELIVERY_METRICS_EXPORT] (state, payload) {
    state.darkStoreDeliveryMetrics.exportReports = payload.data
  },
  [ReportsMutations.SET_ZONE_DELIVERY_METRICS] (state, payload) {
    state.zoneDeliveryMetrics.reports = payload.data
    state.zoneDeliveryMetrics.pagination = payload.pagination
  },
  [ReportsMutations.SET_ZONE_DELIVERY_METRICS_EXPORT] (state, payload) {
    state.zoneDeliveryMetrics.exportReports = payload.data
  },
  [ReportsMutations.SET_ORDERS_TIMING_EXPORT_REPORTS] (state, payload) {
    state.ordersTiming.exportReports = payload.data
  },
  [ReportsMutations.SET_DRIVERS_TIMING_PER_DAY_REPORTS] (state, payload) {
    state.driversTimingDaily.reports = payload.data
    state.driversTimingDaily.pagination = payload.pagination
  },
  [ReportsMutations.SET_DRIVERS_TIMING_PER_DAY_EXPORT_REPORTS] (state, payload) {
    state.driversTimingDaily.exportReports = payload.data
  },
  [ReportsMutations.SET_DRIVERS_TIMING_PER_MONTH_REPORTS] (state, payload) {
    state.driversTimingMonthly.reports = payload.data
    state.driversTimingMonthly.pagination = payload.pagination
  },
  [ReportsMutations.SET_DRIVERS_TIMING_PER_MONTH_EXPORT_REPORTS] (state, payload) {
    state.driversTimingMonthly.exportReports = payload.data
  },
  [ReportsMutations.EXPORT_REPORTS_LOADING_EVENT] (state, bool: boolean) {
    state.exportLoading = bool
  },
  [ReportsMutations.REPORTS_LOADING_EVENT] (state, bool: boolean) {
    state.loading = bool
  },
  [ReportsMutations.SET_DRIVER_PERFORMANCE] (state, payload) {
    state.driverPerformance.reports = payload.data
    state.driverPerformance.pagination = payload.pagination
  },
  [ReportsMutations.SET_DRIVER_PERFORMANCE_EXPORT] (state, payload) {
    state.driverPerformance.exportReports = payload.data
  },
  [ReportsMutations.SET_DARKSTORE_ORDER_PEAK_HOURS] (state, payload) {
    state.darkStoreOrderPeakHours.reports = payload.data
    state.darkStoreOrderPeakHours.pagination = payload.pagination
  },
  [ReportsMutations.SET_DARKSTORE_ORDER_PEAK_HOURS_EXPORT] (state, payload) {
    state.darkStoreOrderPeakHours.exportReports = payload.data
  },
  [ReportsMutations.SET_HOURLY_DELIVERY_TIME_ANALYSIS_EXPORT] (state, payload) {
    state.hourlyDeliveryTimeAnalysis.reports = payload.data
    state.hourlyDeliveryTimeAnalysis.pagination = payload.pagination
  },
  [ReportsMutations.SET_HOURLY_DELIVERY_TIME_ANALYSIS] (state, payload) {
    state.hourlyDeliveryTimeAnalysis.exportReports = payload.data
  },
  [ReportsMutations.SET_DRIVER_REVENUE] (state, payload) {
    state.driverRevenue.reports = payload.data
    state.driverRevenue.pagination = payload.pagination
  },
  [ReportsMutations.SET_DRIVER_REVENUE_EXPORT] (state, payload) {
    state.driverRevenue.exportReports = payload.data
  },
  [ReportsMutations.DARKSTORE_DRIVER_REVENUE] (state, payload) {
    state.darkstoreDriverRevenue.reports = payload.data
    state.darkstoreDriverRevenue.pagination = payload.pagination
  },
  [ReportsMutations.DARKSTORE_AGGREGATE_REVENUE] (state, payload) {
    state.aggregateDarkstoreRevenue.reports = payload.data
    state.aggregateDarkstoreRevenue.pagination = payload.pagination
  },
  [ReportsMutations.DARKSTORE_AGGREGATE_REVENUE_EXPORT] (state, payload) {
    state.aggregateDarkstoreRevenue.exportReports = payload.data
  },
  [ReportsMutations.DARKSTORE_DRIVER_REVENUE_EXPORT] (state, payload) {
    state.darkstoreDriverRevenue.exportReports = payload.data
  },
  [ReportsMutations.DRIVER_WALLET] (state, payload) {
    state.driverWallet.reports = payload.data
    state.driverWallet.pagination = payload.pagination
  },
  [ReportsMutations.DRIVER_WALLET_EXPORT] (state, payload) {
    state.driverWallet.exportReports = payload.data
  },
  [ReportsMutations.ORDERS_SETTLEMENTS] (state, payload) {
    state.ordersSettlement.reports = payload.data
    state.ordersSettlement.pagination = payload.pagination
  },
  [ReportsMutations.ORDERS_SETTLEMENTS_EXPORT] (state, payload) {
    state.ordersSettlement.exportReports = payload.data
  },
  [ReportsMutations.ADMIN_WALLET] (state, payload) {
    state.adminWallet.reports = payload.data
    state.adminWallet.pagination = payload.pagination
  },
  [ReportsMutations.ADMIN_WALLET_EXPORT] (state, payload) {
    state.adminWallet.exportReports = payload.data
  }
}
