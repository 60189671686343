import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { WorkShiftInterface } from '@/shared/interfaces/work-shift.interface'
import { formatDate } from '@/shared/utils/formatDate'

export class WorkShiftModel extends CommonModel {
  public data: WorkShiftInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...WorkShiftModel.setData(item) }]
    })
  }

  private static setData (data: any): WorkShiftInterface {
    return {
      darkstoreId: {
        latitude: data.darkstore_id.latitude,
        longitude: data.darkstore_id.longitude,
        id: data.darkstore_id.id,
        closeTime: data.darkstore_id.close_time,
        openTime: data.darkstore_id.open_time,
        name: data.darkstore_id.name,
        city: {
          id: data.darkstore_id.city.id,
          name: data.darkstore_id.city.name,
          countryName: data.darkstore_id.city.country_name
        }
      },
      id: data.id,
      shiftEnd: data.shift_end,
      description: data.description,
      shiftStart: data.shift_start,
      name: data.name,
      value: data.value
    }
  }
}
