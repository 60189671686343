import { MutationTree } from 'vuex'
import { ManualChangesState } from '@/store/modules/manual-changes/types'

export enum ManualChangesMutations {
  INSERT_MANUAL_CHANGES = 'INSERT_MANUAL_CHANGES',
  INSERT_MANUAL_CHANGES_EXPORT = 'INSERT_MANUAL_CHANGES_EXPORT',
  TRIGGER_LOADING_MANUAL_CHANGES = 'TRIGGER_LOADING_MANUAL_CHANGES',
  TRIGGER_LOADING_ENTITIES = 'TRIGGER_LOADING_ENTITIES',
  INSERT_ENTITY_TYPES = 'INSERT_ENTITY_TYPES'
}

export const mutations: MutationTree<ManualChangesState> = {
  [ManualChangesMutations.INSERT_MANUAL_CHANGES] (state, payload) {
    state.manualChanges = payload.data
    state.pagination = payload.pagination
  },
  [ManualChangesMutations.INSERT_MANUAL_CHANGES_EXPORT] (state, payload) {
    state.manualChangesExport = payload.data
  },
  [ManualChangesMutations.INSERT_ENTITY_TYPES] (state, payload) {
    state.entityTypes = payload
  },
  [ManualChangesMutations.TRIGGER_LOADING_MANUAL_CHANGES] (state, bool: boolean) {
    state.loading = bool
  },
  [ManualChangesMutations.TRIGGER_LOADING_ENTITIES] (state, bool: boolean) {
    state.entityLoading = bool
  }
}
