import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { OrdersState } from '@/store/modules/orders/types'
import { Order } from '@/shared/interfaces/order.interface'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import { ReturnedOrder } from '@/shared/interfaces/returned-order.interface'
import { NearbyOrderInterface } from '@/shared/interfaces/nearby-order.interface'

export const getters: GetterTree<OrdersState, RootState> = {
  getOrders (state): Order[] {
    return state.orderList
  },
  getOrdersAutocomplete (state): any[] {
    return state.orderAutocompleteList
  },
  getOrderStatuses (state): any {
    return state.statuses
  },
  getReturnedItems (state): ReturnedOrder[] {
    return state.returnedItems
  },
  getDetailedOrder (state): Order {
    return state.detailedOrder
  },
  getDetailedReturnedOrder (state): ReturnedOrder {
    return state.detailedReturnedOrder
  },
  getOrderHistories (state): Order[] {
    return state.orderHistories
  },
  getOrderHistoryPagination (state): PaginationInterface | null {
    return state.orderHistoryPagination
  },
  getPagination (state): PaginationInterface | null {
    return state.pagination
  },
  getLoading (state): boolean {
    return state.loading
  },
  getNearbyOrders (state): NearbyOrderInterface[] {
    return state.nearbyOrders
  },
  getReturnedItemsDriversLocations (state): any {
    return state.returnedItems.filter((el: ReturnedOrder) => {
      if (el.driver) {
        return el
      }
    }).map((el: ReturnedOrder) => {
      return {
        latitude: el.driver?.lastLocation.latitude,
        longitude: el.driver?.lastLocation.longitude,
        isToCustomer: el.orderStatus.name === 'picked_up' || el.orderStatus.name === 'at_the_address'
      }
    })
  },
  // getDriversLocations (state): any {
  //   return state.orderList.filter((el: Order) => {
  //     if (el.driver) {
  //       return el
  //     }
  //   }).map((el: Order) => {
  //     return {
  //       latitude: el.driver?.lastLocation.latitude,
  //       longitude: el.driver?.lastLocation.longitude,
  //       isToCustomer: el.orderStatus.name === 'picked_up' || el.orderStatus.name === 'at_the_address' ||
  //       el.orderStatus.name === 'assigned' || el.orderStatus.name === 'on_the_way',
  //       driver: el.driver ? el.driver : null
  //     }
  //   })
  // },
  getAcceptedOrdersCount (state): number {
    return state.acceptedOrdersCount
  },
  getNavigateToNextPage (state): boolean {
    return state.navigateToNextPage
  }
}
