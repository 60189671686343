
import router from '@/router'
import { defineComponent, defineAsyncComponent } from 'vue'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import { ClientActions } from '@/store/modules/clients/actions'
import { ClientInterface } from '@/shared/interfaces/clients.interface'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'
import { useToast } from 'vue-toastification'

const toast = useToast()

export default defineComponent({
  components: {
    SearchInput: defineAsyncComponent(() => import('@/shared/components/SearchInput.vue')),
    DynamicTable: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTable.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    BaseModal: defineAsyncComponent(() => import('@/shared/components/modals/BaseModal.vue')),
    ClientForm: defineAsyncComponent(() => import('@/views/account/clients/components/ClientForm.vue')),
    ConfirmationModal: defineAsyncComponent(() => import('@/shared/components/modals/ConfirmationModal.vue'))
  },
  data () {
    return {
      confirmObject: null as any,
      current: 1,
      searchValue: '',
      tableFields: [
        { id: 'name', name: this.$t('table.name'), width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'description', name: this.$t('table.description'), width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'status', name: this.$t('table.status'), width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'erpnextMerchant', name: this.$t('table.erpnextMerchant'), width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'darkstoreName', name: this.$t('table.darkstore'), width: '18%', sortable: false, currentSort: false, sort: null },
        {
          id: 'actions',
          type: 'component',
          name: '',
          width: '18%',
          contentWidth: 'w-18',
          sortable: false,
          currentSort: false,
          sort: null,
          componentName: 'DynamicTableClientActionsCell',
          props: {
            data: 'rowData' // rowData is all data of row
          },
          on: {
            edit: this.onEdit,
            delete: this.onDelete,
            show: this.onShowSecretKey
          }
        }
      ],
      selectedClient: null as any,
      showCreateClientPopup: false,
      showSecretKeyPopup: false
    }
  },
  methods: {
    onEdit (client: ClientInterface) {
      this.selectedClient = client
      this.showCreateClientPopup = true
    },
    onDelete (client: ClientInterface) {
      this.selectedClient = client
      this.confirmObject = {
        title: this.$t('clients.deleteConfirm.title'),
        message: this.$t('clients.deleteConfirm.message', [this.selectedClient.name]),
        handler: async () => {
          await this.$store.dispatch(`clients/${[ClientActions.DELETE_CLIENT]}`, this.selectedClient.id)
          this.init()
          this.onCancel()
        }
      }
    },
    onShowSecretKey (client: ClientInterface) {
      this.selectedClient = client
      this.showSecretKeyPopup = true
    },
    init (route = null) {
      const val = route || this.$route
      if (val.query.search) {
        this.searchValue = val.query.search as any
      }
      let params = JSON.parse(JSON.stringify(val.query))
      params = { pageSize: 10, pageNumber: val.query.pageNumber ? val.query.pageNumber : 1, ...params }
      this.$store.dispatch(`clients/${[ClientActions.FETCH_CLIENTS]}`, params)
    },
    onCompleteHandler () {
      this.onCancel()
      this.init()
    },
    onCancel () {
      this.selectedClient = null
      this.showCreateClientPopup = false
      this.showSecretKeyPopup = false
      this.confirmObject = null
    },
    async copySecretKey (key: string) {
      try {
        await navigator.clipboard.writeText(key)
        toast.success(this.$t('copied'), { timeout: 3000 })
        this.onCancel()
      } catch (e: any) {
        toast.error('Cannot copied', { timeout: 3000 })
      }
    }
  },
  computed: {
    clients (): ClientInterface[] {
      return this.$store.getters['clients/getClients'].map((c: ClientInterface) => {
        return {
          ...c,
          darkstoreName: c.darkstore.name
        }
      })
    },
    pagination (): PaginationInterface {
      return this.$store.getters['clients/getPagination']
    },
    loading (): boolean {
      return this.$store.getters['clients/getLoading']
    },
    isSuperAdmin (): boolean {
      return this.$store.getters.isSuperAdmin
    }
  },
  watch: {
    current (val) {
      router.push({ query: { ...this.$route.query, pageNumber: val } })
    },
    searchValue () {
      const queries = this.$route.query
      queries.pageNumber = '1'
      router.push({ query: { ...queries, search: this.searchValue } })
    },
    $route: {
      immediate: true,
      handler (val) {
        if (val.name === 'Clients') {
          this.init(val)
        }
      }
    }
  },
  mounted () {
    if (!this.isSuperAdmin) {
      this.$router.push('/')
    }
    this.$store.dispatch(`darkstores/${DarkStoresActions.FETCH_DARKSTORES}`, { page: 1, size: 100 })
  }
})
