
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UserWithAvatar',
  props: {
    driverInfo: {
      type: Object,
      required: true
    },
    indicatorClass: {
      type: String
    }
  }
})
