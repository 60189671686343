import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5843b4f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-4 pb-4" }
const _hoisted_2 = { class: "px-4 font-bold text-lg" }
const _hoisted_3 = { class: "store-list bordered m-4" }
const _hoisted_4 = {
  key: 0,
  class: "text-green-500 font-semibold"
}
const _hoisted_5 = {
  key: 1,
  class: "text-green-500 font-semibold"
}
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('darkstores.title')), 1),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.darkstores, (s, i) => {
        return (_openBlock(), _createElementBlock("li", {
          key: i,
          class: "pt-2 pb-1 px-3 border-b border-dashed flex justify-between"
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(["flex-1", {'font-semibold': s.selected}])
          }, [
            (s.selected)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "✓"))
              : (_openBlock(), _createElementBlock("span", _hoisted_5, "+")),
            _createTextVNode(" " + _toDisplayString(s.name), 1)
          ], 2),
          _createElementVNode("a", {
            role: "button",
            onClick: ($event: any) => (_ctx.addRemoveHandler(s, !s.selected)),
            class: _normalizeClass(s.selected ? 'text-red-500' : 'text-green-500')
          }, _toDisplayString(_ctx.$t( s.selected ? 'zones.remove_from_zone' : 'zones.add_to_zone')), 11, _hoisted_6)
        ]))
      }), 128))
    ])
  ]))
}