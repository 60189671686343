export enum OrderStatusesEnum {
  'all' = 0,
  'preparing' = 1,
  'ready_for_pick_up' = 2,
  'assigned' = 3,
  'not_assigned' = 'not_assigned',
  'picked_up' = 4,
  'on_the_way' = 5,
  'at_the_address' = 6,
  'delivered' = 7,
  'to_return' = 8,
  'to_return_assigned' = 9,
  'returned' = 10,
  'delayed' = 11,
  'canceled' = 12
}
