
import { defineComponent, PropType, toRaw } from 'vue'
import { ZoneInterface } from '@/shared/interfaces/zone.interface'
import { ZoneActions } from '@/store/modules/zones/actions'
import { ZoneModel } from '@/shared/models/zone.model'
const google = window.google

export default defineComponent({
  name: 'ZoneMap',
  emits: ['redirectToGoogleMap', 'drawCompleted', 'polygonDragged'],
  props: {
    id: {
      type: String,
      default: 'zone-map'
    },
    zoom: {
      type: Number,
      default: 15
    },
    coordinatesGroup: {
      type: Array as PropType<Array<Array<{ lat: number; lng: number }>>>,
      default: () => []
    },
    highlightIndex: {
      type: Number,
      default: -1
    },
    editablePolygon: {
      type: Boolean,
      default: true
    },
    draggablePolygon: {
      type: Boolean,
      default: true
    },
    drawEnabled: {
      type: Boolean,
      default: true
    },
    showAllZones: {
      type: Boolean,
      default: false
    },
    editZoneId: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      map: {} as any,
      drawingManager: {} as any,
      polygons: [] as any,
      zonePolygons: [] as any,
      textOverlays: [] as any
    }
  },
  async mounted () {
    this.initMap()
    if (this.showAllZones) {
      await this.$store.dispatch(`zones/${ZoneActions.FETCH_ZONES}`, { pageSize: 9999, pageNumber: 1 })
      this.drawZonesPolygons()
    }
  },
  methods: {
    initMap () {
      const _center = this.coordinatesGroup.length ? this.getCoordinatesCenter() : null
      // create a map
      this.map = new google.maps.Map(document.getElementById(this.id)!, {
        center: _center || { lat: 33.312805, lng: 44.361488 },
        zoom: this.zoom
      })

      // create DrawingManager to draw action if prop value is enable
      if (this.drawEnabled) {
        this.drawingManager = new google.maps.drawing.DrawingManager({
          drawingMode: google.maps.drawing.OverlayType.POLYGON,
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON]
          },
          polygonOptions: {
            editable: true,
            draggable: true
          }
        })

        // drawing completed
        google.maps.event.addListener(
          this.drawingManager,
          'overlaycomplete',
          (event: any) => {
            if (event.type === google.maps.drawing.OverlayType.POLYGON) {
              this.clearPolygons()
              // Set new polygon
              const newPolygon = event.overlay as any
              newPolygon.setEditable(true)
              newPolygon.setDraggable(true)
              newPolygon.setOptions({ fillColor: '#000000', fillOpacity: 0.35, zIndex: 100 })
              // listen to drag - drop event
              this.addListeners(newPolygon, true)

              // get coordinates of polygon
              const path = newPolygon.getPath().getArray()
              const coordinates = path.map((latLng: any) => ([
                latLng.lat(),
                latLng.lng()
              ]))

              this.polygons.push(newPolygon)
              this.$emit('drawCompleted', coordinates)
            }
          }
        )

        // start to draw action
        this.drawingManager.setMap(this.map)
      }
    },
    drawPolygons (coordinatesGroup:any) {
      this.clearPolygons()
      for (const coordinates of coordinatesGroup) {
        const polygon = new google.maps.Polygon({
          paths: coordinates,
          editable: this.editablePolygon,
          draggable: this.draggablePolygon,
          map: this.map,
          zIndex: 100
        })

        this.polygons.push(polygon)
        this.addListeners(polygon)
      }
    },
    drawZonesPolygons () {
      for (const coordinates of this.zones) {
        const polygon = new google.maps.Polygon({
          paths: coordinates.polygons,
          editable: false,
          draggable: false,
          map: this.map,
          zIndex: 99,
          fillColor: '#13fb03',
          fillOpacity: 0.5,
          strokeWeight: 1.5
        })
        this.addTextOverlay(polygon, coordinates.zone.name)
        this.zonePolygons.push(polygon)
      }
    },
    clearZonePolygons () {
      for (const polygon of this.zonePolygons) {
        toRaw(polygon).setMap(null)
        polygon.setMap(null)
      }
      this.zonePolygons = []
    },
    addTextOverlay (polygon: any, text: string) {
      const path = polygon.getPath().getArray()
      const bounds = new google.maps.LatLngBounds()
      for (const point of path) {
        bounds.extend(point)
      }
      const center = bounds.getCenter()
      // 1p x 1px empty transparent image
      const emptyImage = 'data:image/png;base64,' + 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
      const icon = {
        url: emptyImage,
        scaledSize: new google.maps.Size(50, 50), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(0, 0) // anchor
      }
      const marker = new google.maps.Marker({
        position: center,
        map: this.map,
        icon,
        label: {
          text,
          color: 'black',
          className: 'google-map-zone-label',
          fontSize: '11px',
          fontWeight: 'bold'
        },
        zIndex: 9999
      })
      this.textOverlays.push(marker)
    },
    clearTextOverlays () {
      // remove all overlays
      for (const overlay of this.textOverlays) {
        toRaw(overlay).setMap(null)
        overlay.setMap(null)
      }
      this.textOverlays = []
    },
    getCoordinatesCenter () {
      if (!this.coordinatesGroup.length) return false
      const bounds = new google.maps.LatLngBounds()
      for (const coordinates of this.coordinatesGroup) {
        for (const coordinate of coordinates) {
          const latLng = new google.maps.LatLng(coordinate.lat, coordinate.lng)
          bounds.extend(latLng)
        }
      }
      return bounds.getCenter()
    },
    centerMap () {
      const center = this.getCoordinatesCenter()
      this.map.setCenter(center)
    },
    highlightPolygon (index: number) {
      // remove all polygon highlight
      for (const polygon of this.polygons) {
        polygon.setOptions({ fillColor: '#000000', fillOpacity: 0.35 })
      }

      // when index is out of scope
      if (index === -1 || index >= this.polygons.length) {
        return
      }

      // highlight target polygon
      const highlightedPolygon = this.polygons[index]
      highlightedPolygon.setOptions({ fillColor: '#13fb03', fillOpacity: 0.5 })

      const highlightedBounds = new google.maps.LatLngBounds()
      for (const highlightedCoordinate of this.coordinatesGroup[this.highlightIndex]) {
        const latLng = new google.maps.LatLng(highlightedCoordinate.lat, highlightedCoordinate.lng)
        highlightedBounds.extend(latLng)
      }
      this.map.setCenter(highlightedBounds.getCenter())
    },
    clearPolygons () {
      // remove all polygons
      for (const polygon of this.polygons) {
        this.removeListeners(polygon)
        toRaw(polygon).setMap(null)
        polygon.setMap(null)
      }
      this.polygons = []
    },
    updatePolygonCoordinates (polygon: any) {
      // update polygon coordinates
      const path = polygon.getPath().getArray()
      const coordinates = path.map((latLng:any) => ([
        latLng.lat(),
        latLng.lng()
      ]))
      this.$emit('polygonDragged', coordinates)
    },
    addListeners (polygon:any, force = false) {
      if (this.draggablePolygon || force) {
        google.maps.event.addListener(polygon, 'dragend', () => {
          this.updatePolygonCoordinates(polygon)
        })
      }
      if (this.editablePolygon || force) {
        google.maps.event.addListener(polygon, 'set_at', () => {
          this.updatePolygonCoordinates(polygon)
        })

        google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
          this.updatePolygonCoordinates(polygon)
        })

        google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
          this.updatePolygonCoordinates(polygon)
        })
      }
    },
    removeListeners (polygon:any) {
      google.maps.event.clearListeners(polygon, 'dragend')
      google.maps.event.clearListeners(polygon, 'set_at')
      google.maps.event.clearListeners(polygon.getPath(), 'set_at')
      google.maps.event.clearListeners(polygon.getPath(), 'insert_at')
    }
  },
  watch: {
    coordinatesGroup: {
      immediate: true,
      handler (newVal) {
        this.$nextTick(() => {
          this.drawPolygons(newVal)
          if (newVal.length) {
            this.centerMap()
          }
        })
      }
    },
    highlightIndex (newVal) {
      this.highlightPolygon(newVal)
    },
    showAllZones (newVal) {
      if (newVal) {
        this.drawZonesPolygons()
      } else {
        this.clearZonePolygons()
        this.clearTextOverlays()
      }
    }
  },
  computed: {
    zones () {
      let zones = this.$store.getters['zones/getZones']
      if (this.editZoneId) {
        zones = this.$store.getters['zones/getZones'].filter((z: ZoneInterface) => z.id !== this.editZoneId)
      }
      return zones.map((z: ZoneInterface) => {
        return {
          polygons: ZoneModel.convertToMapPolygonFormat(z.polygon),
          zone: z
        }
      })
    }
  }
})
