
import { defineAsyncComponent, defineComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@/customValidators'
import { VehiclesActions } from '@/store/modules/vehicles/actions'
import { VehicleInterface, VehicleTypeInterface } from '@/shared/interfaces/vehicle.interface'

export default defineComponent({
  components: {
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    Multiselect: defineAsyncComponent(() => import('@vueform/multiselect'))
  },
  props: {
    vehicle: {
      type: Object as () => VehicleInterface,
      required: false,
      default: {} as () => VehicleInterface
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      name: '',
      model: null as any,
      make: null as any,
      year: null as any,
      type: null as any,
      licenseNo: '',
      models: null as any,
      makes: null as any,
      owner: null as any,
      vehicleOwners: [
        { id: 'driver', value: this.$t('vehicles.vehicleOwners.driver') },
        { id: 'Tasleem', value: this.$t('vehicles.vehicleOwners.tasleem') }
      ]
    }
  },
  validations: function () {
    return {
      name: { required },
      model: { required },
      make: { required },
      year: { required },
      type: { required },
      owner: { required },
      licenseNo: { required }
    }
  },
  mounted () {
    if (!this.vehicleTypes.length) {
      this.$store.dispatch(`vehicles/${VehiclesActions.FETCH_VEHICLE_TYPES}`)
    }
    if (Object.keys(this.vehicle).length) {
      this.name = this.vehicle.name
      this.model = this.vehicle.model
      this.make = this.vehicle.make
      this.owner = this.vehicle.owner
      this.year = this.vehicle.year
      this.type = this.vehicle.vehicleType.id
      this.licenseNo = this.vehicle.licenseNo
    }
  },
  methods: {
    submitForm: function (): void {
      const payload = {
        name: this.name,
        model: this.model,
        license_no: this.licenseNo,
        make: this.make,
        owner: this.owner,
        year: this.year,
        vehicle_type_id: this.type
      }
      const action = this.isEdit ? VehiclesActions.UPDATE_VEHICLE : VehiclesActions.ADD_VEHICLE
      this.$store.dispatch(`vehicles/${action}`, { payload, vehicleId: this.isEdit ? this.vehicle.id : null }).then((res: any) => {
        this.$emit('close')
        this.$emit('fetchVehicles')
      })
    },
    resetForm (): void {
      this.name = ''
      this.model = null as any
      this.make = ''
      this.year = null as any
      this.type = null as any
      this.licenseNo = ''
    }
  },
  watch: {
    vehicleModels (val: any[]) {
      this.models = val
    },
    vehiclesMake (val: any[]) {
      this.makes = val
    }
    // model (val: string) {
    //   this.$store.dispatch(`vehicles/${VehiclesActions.FETCH_VEHICLES_MAKE}`, val)
    // }
  },
  computed: {
    vehicleTypes (): VehicleTypeInterface[] {
      return this.$store.getters['vehicles/getVehicleTypes']
    },
    vehicleModels (): any[] {
      return this.$store.getters['vehicles/getVehicleModels']
    },
    vehicleModelsLoading (): boolean {
      return this.$store.getters['vehicles/vehicleModelsLoading']
    },
    vehicleMakesLoading (): boolean {
      return this.$store.getters['vehicles/vehiclesMakeLoading']
    },
    vehiclesMake (): any[] {
      return this.$store.getters['vehicles/getVehiclesMake']
    },
    isEdit (): boolean {
      return !!Object.keys(this.vehicle).length
    },
    years (): any[] {
      const currentYear = new Date().getFullYear()
      const years = []
      for (let i = 0; i <= currentYear - 1900; i++) {
        years.push((currentYear - i).toString())
      }
      return years
    }
  },
  beforeUnmount () {
    this.resetForm()
  }
})
