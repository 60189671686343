
import { defineComponent, PropType } from 'vue'
import { ZoneInterface } from '@/shared/interfaces/zone.interface'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'
import { DarkStoreInterface } from '@/shared/interfaces/darkstore.interface'
import { ZoneActions } from '@/store/modules/zones/actions'

export default defineComponent({
  name: 'ZoneDarkStoreSettings',
  props: {
    zone: {
      type: Object,
      default: Object as PropType<ZoneInterface>
    }
  },
  mounted () {
    this.getAllDarkstores()
  },
  methods: {
    getAllDarkstores () {
      this.$store.dispatch(`darkstores/${DarkStoresActions.FETCH_DARKSTORES}`, { pageNumber: 1, pageSize: 9999 })
    },
    addRemoveHandler (darkstore: DarkStoreInterface, isAssign: boolean) {
      const payload = {
        zone_id: this.zone.id,
        dark_store_id: darkstore.id
      }
      const action = isAssign ? ZoneActions.ASSIGN_ZONE_TO_DARKSTORE : ZoneActions.UN_ASSIGN_ZONE_TO_DARKSTORE
      this.$store.dispatch(`zones/${action}`, payload)
        .then(() => {
          this.$emit('sync')
        })
    }
  },
  computed: {
    existingDarkstores () {
      return this.zone?.darkstores ?? []
    },
    darkstores () {
      return this.$store.getters['darkstores/getDarkStores'].map((ds:DarkStoreInterface) => {
        const isExist = this.existingDarkstores.filter((es:DarkStoreInterface) => es.id === ds.id)
        return {
          selected: isExist.length > 0,
          ...ds
        }
      })
    }
  }
})
