import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "bulk-order-template-export" }
const _hoisted_2 = { class: "bg-gray-600 text-white px-2 h-10 flex items-center rounded-md hover:bg-gray-500 font-semibold text-xs" }
const _hoisted_3 = {
  key: 0,
  class: "absolute top-10 border border-gray-200 p-2 rounded-md bg-white shadow w-170",
  style: {"z-index":"100"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_excel_xlsx = _resolveComponent("vue-excel-xlsx")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_vue_excel_xlsx, {
      data: [{
        order_id:  '',
        order_total:  '',
        destination_latitude:  '',
        destination_longitude:  '',
        customer_phone_number: '',
        vehicle_type_id: '',
        notes: '',
        user_address: '',
        address_building: '',
        address_district: '',
        address_flat: '',
        address_floor: '',
        address_neighbourhood: '',
        settlement_status: '',
        address_nickname: ''
      }],
      columns: [
        { label: 'order_id', field: 'order_id' },
        { label: 'order_total', field: 'order_total' },
        { label: 'destination_latitude', field: 'destination_latitude' },
        { label: 'destination_longitude', field: 'destination_longitude' },
        { label: 'customer_phone_number', field: 'customer_phone_number' },
        { label: 'vehicle_type_id', field: 'vehicle_type_id' },
        { label: 'notes', field: 'notes' },
        { label: 'user_address', field: 'user_address' },
        { label: 'address_building', field: 'address_building' },
        { label: 'address_district', field: 'address_district' },
        { label: 'address_flat', field: 'address_flat' },
        { label: 'address_floor', field: 'address_floor' },
        { label: 'address_neighbourhood', field: 'address_neighbourhood' },
        { label: 'settlement_status', field: 'settlement_status' },
        { label: 'address_nickname', field: 'address_nickname' }
      ],
      "file-name": "Bulk-Order-Template",
      "file-type": 'xlsx',
      "sheet-name": 'sheetname'
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("button", _hoisted_1, null, 512), [
          [_vShow, false]
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      class: "relative",
      onMouseover: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showBulkSelect = true)),
      onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showBulkSelect = false))
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('bulkOrderCreateModal.bulkOrder')), 1),
      (_ctx.showBulkSelect)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "py-2 hover:bg-gray-200 cursor-pointer",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onUpload && _ctx.onUpload(...args)))
            }, _toDisplayString(_ctx.$t('bulkOrderCreateModal.excelUpload')), 1),
            _createElementVNode("div", {
              class: "py-2 hover:bg-gray-200 cursor-pointer",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onDownload && _ctx.onDownload(...args)))
            }, _toDisplayString(_ctx.$t('bulkOrderCreateModal.excelDownload')), 1)
          ]))
        : _createCommentVNode("", true)
    ], 32)
  ]))
}