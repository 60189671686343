import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-10 px-10" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrdersGhost = _resolveComponent("OrdersGhost")!
  const _component_OrderDetails = _resolveComponent("OrderDetails")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_OrdersGhost, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order, index) => {
            return (_openBlock(), _createBlock(_component_OrderDetails, {
              key: index,
              collapsible: true,
              order: order
            }, null, 8, ["order"]))
          }), 128))
        ])),
    (_ctx.pagination && _ctx.pagination.totalItems)
      ? (_openBlock(), _createBlock(_component_Pagination, {
          key: 2,
          current: _ctx.current,
          "hide-info-section": false,
          total: _ctx.pagination.totalItems,
          "page-range": 2,
          "per-page": _ctx.pagination.pageSize,
          onPageChanged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.current = $event)),
          "text-before-input": "Go to page"
        }, null, 8, ["current", "total", "per-page"]))
      : _createCommentVNode("", true)
  ]))
}