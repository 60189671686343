<template>
  <div>
    <Popper arrow hover placement="auto">
      <div class="rounded-full w-8 h-8 bg-gray-100 text-sm flex items-center justify-center font-medium border">
        {{title}}
      </div>
      <template #content>{{ name }} {{ surname }}</template>
    </Popper>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'DarkstoreInfoWindowDriver',
  components: {
    Popper: defineAsyncComponent(() => import('vue3-popper'))
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    surname: {
      type: String,
      default: ''
    }
  },
  computed: {
    title () {
      const firstName = this.name[0] ?? ''
      const lastName = this.surname[0] ?? ''
      return firstName.toUpperCase() + lastName.toUpperCase()
    }
  }
}
</script>
