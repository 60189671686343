import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { CitiesState } from '@/store/modules/cities/types'
import { CityInterface } from '@/shared/interfaces/city.interface'

export const getters: GetterTree<CitiesState, RootState> = {
  getCities (state): CityInterface[] {
    return state.cities
  },
  getPagination (state): any {
    return state.pagination
  },
  getLoading (state): boolean {
    return state.loading
  }
}
