import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white w-full mb-2" }
const _hoisted_2 = { class: "font-bold text-white text-center text-xs" }
const _hoisted_3 = {
  key: 0,
  class: "p-2"
}
const _hoisted_4 = { class: "font-bold text-sm text-black" }
const _hoisted_5 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleOrder())),
      class: "bg-darkBlue rounded-lg py-2.5 px-2.5 cursor-pointer"
    }, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.order.orderId), 1)
    ]),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order.orderStatusTime, (statusItem) => {
            return (_openBlock(), _createElementBlock("div", {
              key: statusItem.status,
              class: "text-start mb-2"
            }, [
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t(`orders.statuses.${statusItem.status}`)), 1),
              _createElementVNode("p", _hoisted_5, _toDisplayString(statusItem.value ? statusItem.value : '-'), 1)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}