
import { defineAsyncComponent, defineComponent } from 'vue'
import router from '@/router'
import DriverSelect from '@/views/account/cache-settlements/component/DriverSelect.vue'

export default defineComponent({
  components: {
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    DynamicTable: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTable.vue')),
    DriverSelect
  },
  data () {
    return {
      showFilterModal: false,
      tableFields: [
        { id: 'driverFullName', name: 'Driver Full Name', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'driverFullName' },
        { id: 'financialLimitExceed', name: 'Financial Limit Exceed', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'financialLimitExceed' },
        { id: 'totalBalance', name: 'Total Balance', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'totalBalance' },
        { id: 'unpaidOrders', name: 'Unpaid Orders', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'unpaidOrders' },
        { id: 'unpaidOrdersAmount', name: 'Unpaid Orders Amount', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'unpaidOrdersAmount' },
        { id: 'settledOrders', name: 'Settled Orders', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'settledOrders' },
        { id: 'settledOrdersAmount', name: 'Settled Orders Amount', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'settledOrdersAmount' },
        { id: 'pendingSettlementOrders', name: 'Pending Settlement Orders', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'pendingSettlementOrders' },
        { id: 'pendingSettlementOrdersAmount', name: 'Pending Settlement Orders Amount', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'pendingSettlementOrdersAmount' }
      ],
      filters: [
        {
          name: this.$t('filters.isLimitExceed'),
          model: 'isLimitExceed',
          type: 'radio',
          data: [
            { id: '', label: this.$t('filters.default') },
            { id: '1', label: this.$t('filters.yes') },
            { id: '0', label: this.$t('filters.no') }
          ]
        },
        {
          name: this.$t('filters.hasPreviousDayNotPaidOrders'),
          model: 'hasPreviousDayNotPaidOrders',
          type: 'radio',
          data: [
            { id: '', label: this.$t('filters.default') },
            { id: '1', label: this.$t('filters.yes') },
            { id: '0', label: this.$t('filters.no') }
          ]
        }
      ],
      selectedDriver: null as any,
      selectedReport: this.reportType
    }
  },
  methods: {
    menuEvent (e: any): void {
      console.log(e)
    },
    clickSort (field: any): void {
      this.tableFields = this.tableFields?.map((el: any) => {
        if (el.id === field.id) {
          el.currentSort = true
          if (el.sort === null) {
            el.sort = 'asc'
          } else if (el.sort === 'asc') {
            el.sort = 'desc'
          } else if (el.sort === 'desc') {
            el.sort = null
          }
        } else {
          el.currentSort = false
          el.sort = null
        }
        return el
      })
      router.push({ query: { ...this.$route.query, orderBy: field.sortName, sort: field.sort } })
    },
    onExpand (eventName: string): void {
      console.log(eventName)
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (val: any) {
        if (val.query.orderBy) {
          this.tableFields = this.tableFields.map((el: any) => {
            if (el.id === val.query.orderBy) {
              el.currentSort = true
              el.sort = val.query.sort
            }
            return el
          })
        }
      }
    },
    selectedDriver (newVal) {
      const queries = this.$route.query
      if (newVal) {
        router.push({ query: { ...queries, driverId: newVal.id } })
      } else {
        router.push({ query: { ...queries, driverId: undefined } })
      }
    },
    selectedReport (val) {
      if (val) {
        router.push({ query: { reportName: val.name } })
      }
    }
  },
  props: {
    reportType: {
      type: String
    }
  },
  computed: {
    reports () {
      return this.$store.getters['reports/getDriverWalletReports'].map((item : any) => {
        return {
          ...item,
          totalBalance: item.totalBalance + ' IQD',
          unpaidOrdersAmount: item.unpaidOrdersAmount + ' IQD',
          settledOrdersAmount: item.settledOrdersAmount + ' IQD',
          pendingSettlementOrdersAmount: item.pendingSettlementOrdersAmount + ' IQD'
        }
      })
    },
    exportReports () {
      return this.$store.getters['reports/getDriverWalletReportsExport'].map((item : any) => {
        return {
          ...item,
          totalBalance: item.totalBalance + ' IQD',
          unpaidOrdersAmount: item.unpaidOrdersAmount + ' IQD',
          settledOrdersAmount: item.settledOrdersAmount + ' IQD',
          pendingSettlementOrdersAmount: item.pendingSettlementOrdersAmount + ' IQD'
        }
      })
    },
    pagination () {
      return this.$store.getters['reports/getDriverWalletPagination']
    },
    loading () {
      return this.$store.getters['reports/getLoading']
    }
  }
})
