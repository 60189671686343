
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Alert',
  props: {
    type: String,
    title: String
  },
  computed: {
    alertType: function (): string {
      const t = (this.type) ? this.type.toLowerCase().trim() : ''
      return ((['info', 'success', 'warning', 'danger'].includes(t)) ? t : 'info')
    }
  }
})
