
import { defineComponent } from 'vue'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@/customValidators'
import { WorkShiftActions } from '@/store/modules/work-shifts/actions'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'
import { WorkShiftInterface } from '@/shared/interfaces/work-shift.interface'
import Multiselect from '@vueform/multiselect'
import moment from 'moment'

export default defineComponent({
  components: { BaseIcon, Multiselect },
  props: [
    'isEdit', 'selectedShift'
  ],
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      name: '',
      value: '',
      darkStoreId: null as any,
      shiftStart: {} as any,
      shiftEnd: {} as any,
      description: ''
    }
  },
  validations: function () {
    return {
      name: { required },
      value: { required },
      darkStoreId: { required },
      shiftStart: { required },
      shiftEnd: { required },
      description: { required }
    }
  },
  mounted () {
    if (this.isEdit) {
      const shiftStartMap = this.selectedShift.shiftStart.split(':')
      const shiftEndMap = this.selectedShift.shiftEnd.split(':')
      this.darkStoreId = this.selectedShift.darkstoreId.id
      this.name = this.selectedShift.name
      this.value = this.selectedShift.value
      this.shiftStart = { hours: shiftStartMap[0], minutes: shiftStartMap[1], seconds: shiftStartMap[2] }
      this.shiftEnd = { hours: shiftEndMap[0], minutes: shiftEndMap[1], seconds: shiftEndMap[2] }
      this.description = this.selectedShift.description
    } else {
      this.shiftStart = null
      this.shiftEnd = null
    }
    if (!this.darkstores.length) {
      this.$store.dispatch(`darkstores/${DarkStoresActions.FETCH_DARKSTORES}`, { pageNumber: 1, pageSize: 100 })
    }
  },
  methods: {
    async submitForm (): Promise<any> {
      const formatTime = (time: { hours: number, minutes: number, seconds: number }) => {
        const t = moment(`${time.hours}:${time.minutes}:${time.seconds}`, 'H:m:s').format('HH:mm:ss')
        return t
      }
      const payload = {
        name: this.name,
        value: this.value,
        darkstore_id: this.darkStoreId,
        shift_start: formatTime(this.shiftStart),
        shift_end: formatTime(this.shiftEnd),
        description: this.description
      }
      const action = this.isEdit ? WorkShiftActions.UPDATE_SHIFT : WorkShiftActions.CREATE_SHIFT
      await this.$store.dispatch(`workShifts/${action}`, { payload, shiftId: this.isEdit ? this.selectedShift.id : null }).then((res: any) => {
        this.$emit('refresh')
        this.$emit('close')
      })
    },
    resetForm (): void {
      this.name = ''
      this.value = ''
      this.darkStoreId = null
      this.shiftStart = {}
      this.shiftEnd = {}
      this.description = ''
    }
  },
  computed: {
    darkstores (): any[] {
      return this.$store.getters['darkstores/getDarkStoresAsDictionary']
    }
  },
  beforeUnmount () {
    this.resetForm()
  }
})
