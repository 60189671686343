
import { defineComponent, defineAsyncComponent } from 'vue'
import moment from 'moment'
export default defineComponent({
  name: 'OrderDetails',
  components: {
    OrdersMap: defineAsyncComponent(() => import('@/views/account/orders/components/OrdersMap.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue'))
  },
  props: {
    collapsible: {
      type: Boolean,
      required: false,
      default: true
    },
    order: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      show: false,
      moment
    }
  },
  computed: {
    coordinates () {
      return {
        driver: this.order.driver.lastLocation,
        darkstore: { latitude: this.order.darkStore.latitude, longitude: this.order.darkStore.longitude },
        user: this.order
      }
    },
    tripDetails () {
      if (this.order.tripDetailsToDarkstoreInward && this.order.tripDetailsFromDarkstoreOutward) {
        return {
          toDarkstore: this.order.tripDetailsToDarkstoreInward,
          fromDarkstore: this.order.tripDetailsFromDarkstoreOutward,
          status: this.order.orderStatus.name,
          driverLocation: this.order.driver ? this.order.driver.lastLocation : null,
          orderData: this.order
        }
      } else {
        return {
          toDarkstore: { latitude: this.order.darkStore.latitude, longitude: this.order.darkStore.longitude },
          fromDarkstore: { latitude: this.order.destinationLatitude, longitude: this.order.destinationLongitude },
          status: this.order.orderStatus.name,
          driverLocation: this.order.driver ? this.order.driver.lastLocation : null,
          orderData: this.order
        }
      }
    }
  },
  methods: {
    toggleCollapse (): void {
      this.show = !this.show
    }
  }
})

