
import { defineComponent } from 'vue'
import { Driver } from '@/shared/interfaces/driver.interface'
const wdw: any = window

export default defineComponent({
  name: 'DriverMap',
  props: ['driver'],
  data () {
    return {
      openedMarkerID: 1,
      mapTypeControl: true,
      map: {} as any,
      activeInfoWindow: null as any
    }
  },
  mounted () {
    this.initMap()
  },
  watch: {
    map: {
      handler: function (dat: any) {
        if (dat && this.driver) {
          this.addMarker(this.driver)
        }
      }
    },
    driver (val: Driver): void {
      if (val && Object.keys(this.map).length) {
        this.addMarker(val)
      }
    }
  },
  methods: {
    initMap (): void {
      setTimeout(() => {
        if (wdw.google) {
          const latitude = 33.312805
          const longitude = 44.361488
          this.map = new wdw.google.maps.Map(
            document.getElementById('map'),
            {
              center: { lat: latitude, lng: longitude },
              zoom: 15,
              mapTypeControl: false,
              streetViewControl: false,
              disableDefaultUI: false
            }
          )
          this.map.addListener('click', () => {
            if (this.activeInfoWindow) {
              this.activeInfoWindow.close()
            }
          })
        } else {
          this.initMap()
        }
      }, 500)
    },
    addMarker (driver: Driver): void {
      const isBusy = ['occupied_outward', 'occupied_inward', 'occupied_inward_assigned'].includes(driver.driverStatus.name)
      const position = new wdw.google.maps.LatLng(driver.lastLocation?.latitude, driver.lastLocation?.longitude)
      const vehicleType = driver.vehicleId.vehicleType.id || 1
      let statusIcon
      const statusName = driver.driverStatus.name
      if (statusName === 'occupied_inward') {
        statusIcon = '_yellow.png' // yellow
      } else if (statusName === 'idle') {
        statusIcon = '_green.png' // green
      } else if (statusName === 'idle_assigned') {
        statusIcon = '_blue.png' // blue
      } else {
        statusIcon = '_red.png' // red
      }
      const image = {
        url: isBusy ? require(`../../../../assets/vehicle_${vehicleType}_yellow.png`)
          : require(`../../../../assets/vehicle_${vehicleType}${statusIcon}`),
        size: new wdw.google.maps.Size(55, 70),
        origin: new wdw.google.maps.Point(0, 0),
        anchor: new wdw.google.maps.Point(22, 50)
      }
      const marker = new wdw.google.maps.Marker({
        position,
        map: this.map,
        icon: image
      })
      const infowindow = new wdw.google.maps.InfoWindow({
        content: this.getContentData(driver)
      })

      marker.addListener('click', (event: any) => {
        if (this.activeInfoWindow) {
          this.activeInfoWindow.close()
        }
        this.activeInfoWindow = infowindow
        infowindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false
        })
      })
      const center = new wdw.google.maps.LatLng(driver.lastLocation?.latitude, driver.lastLocation?.longitude)
      this.map.panTo(center)
      if (this.activeInfoWindow) {
        this.activeInfoWindow.close()
      }
      this.$nextTick(() => {
        if (driver.lastLocation.latitude !== 0 && driver.lastLocation.longitude !== 0) {
          const bounds = new wdw.google.maps.LatLngBounds()
          const latLng = new wdw.google.maps.LatLng(driver.lastLocation.latitude, driver.lastLocation.longitude)
          bounds.extend(latLng)
          this.map.setCenter(bounds)
        }
      })
    },

    getContentData (driver: Driver): any {
      return `<table>
           <thead>
            <tr class="py-2">
               <th>${this.$t('drivers.driver')}</th>
               <th>${this.$t('drivers.vehicleType')}</th>
               <th>${this.$t('drivers.vehicleModel')}</th>
            </tr>
           </thead>
           <tbody>
            <tr>
                <td>${driver.driverInfo.userName} - ${driver.driverInfo.firstName} ${driver.driverInfo.lastName}</td>
                <td>${driver.vehicleId.name}</td>
                <td>${driver.vehicleId.model}</td>
            </tr>
           </tbody>
        </table>`
    }
  }
})
