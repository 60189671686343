
import { defineComponent, defineAsyncComponent } from 'vue'
import { OrderActions } from '@/store/modules/orders/actions'
import { ReturnedOrder } from '@/shared/interfaces/returned-order.interface'
import { OrderMutations } from '@/store/modules/orders/mutations'

export default defineComponent({
  name: 'returnedItems',
  components: {
    ReturnedOrderModal: defineAsyncComponent(() => import('@/views/account/returnedItems/components/ReturnedOrderModal.vue')),
    ReturnedItem: defineAsyncComponent(() => import('@/views/account/returnedItems/components/ReturnedItem.vue')),
    OrdersMap: defineAsyncComponent(() => import('@/views/account/orders/components/OrdersMap.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue'))
  },
  data () {
    return {
      showModal: false,
      selectedOrder: null,
      searchValue: '',
      orderType: 'all',
      pageNumber: 1
    }
  },
  mounted () {
    this.getReturnedItems()
  },
  methods: {
    editModal (event: any) {
      this.$store.commit(`orders/${OrderMutations.SET_RETURNED_ORDER_DETAILS}`, event)
      this.showModal = true
    },
    getReturnedItems (): void {
      const params = { pageNumber: this.pageNumber, pageSize: 20 }
      this.$store.dispatch(`orders/${OrderActions.GET_RETURNED_ITEMS}`, params)
    },
    loadMore (): void {
      this.pageNumber++
      this.getReturnedItems()
    }
  },
  computed: {
    orders (): ReturnedOrder[] {
      return this.$store.getters['orders/getReturnedItems']
    },
    driverLocations () {
      return this.$store.getters['orders/getReturnedItemsDriversLocations']
    },
    detailedOrder () {
      return this.$store.getters['orders/getDetailedReturnedOrder']
    },
    loading () {
      return this.$store.getters['orders/getLoading']
    },
    paginations () {
      return this.$store.getters['orders/getPagination']
    }
  }
})
