import { Method as apiMethod } from 'axios'

export const cacheSettlementsEndpoints = {
  orderCacheSettlements: {
    method: 'get' as apiMethod,
    url: '/cache_settlement/order_cache_settlement/page'
  },
  approveCacheSettlement: (id: number) => {
    return {
      method: 'post' as apiMethod,
      url: `/cache_settlement/order_cache_settlement/${id}/approve`
    }
  },
  rejectCacheSettlement: (id: number) => {
    return {
      method: 'post' as apiMethod,
      url: `/cache_settlement/order_cache_settlement/${id}/reject`
    }
  },
  orderCacheSettlement: (id: number) => {
    return {
      method: 'get' as apiMethod,
      url: `/cache_settlement/order_cache_settlement/${id}`
    }
  },
  retryErpSync: (id: number) => {
    return {
      method: 'post' as apiMethod,
      url: `/cache_settlement/order_cache_settlement/${id}/retry_erp_sync`
    }
  }
}
