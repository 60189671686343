import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { apiEndpoints } from '@/shared/endpoints/api'
import axiosInstance from '@/shared/utils/axiosInstance'
import { SettingsState } from '@/store/modules/settings/types'
import { SettingMutations } from '@/store/modules/settings/mutations'
import { useToast } from 'vue-toastification'
const toast = useToast()

export enum SettingsActions {
  FETCH_SETTINGS = 'FETCH_SETTINGS',
  SAVE_SETTINGS = 'SAVE_SETTINGS',
  FETCH_GENERAL_RETRY_ERP_SYNC = 'FETCH_GENERAL_RETRY_ERP_SYNC',
}

export const actions: ActionTree<SettingsState, RootState> = {
  [SettingsActions.FETCH_SETTINGS]: async (context) => {
    context.commit(SettingMutations.TRIGGER_LOADING, true)
    await axiosInstance({
      ...apiEndpoints.settings.getSettings
    }).then((resp: any) => {
      context.commit(SettingMutations.SET_SETTINGS, resp.data)
    }).catch((err: any) => {
      context.commit(SettingMutations.TRIGGER_LOADING, false)
      throw new Error(err)
    })
  },
  [SettingsActions.SAVE_SETTINGS]: async (context, payload) => {
    context.commit(SettingMutations.TRIGGER_LOADING, true)
    await axiosInstance({
      ...apiEndpoints.settings.updateSettings,
      data: payload
    }).then(() => {
      context.commit(SettingMutations.TRIGGER_LOADING, false)
      toast.success('General settings has been updated successfully', {
        timeout: 3000
      })
    }).catch((err: any) => {
      context.commit(SettingMutations.TRIGGER_LOADING, false)
      throw new Error(err)
    })
  },
  [SettingsActions.FETCH_GENERAL_RETRY_ERP_SYNC]: async (context) => {
    context.commit(SettingMutations.TRIGGER_LOADING, true)
    return await axiosInstance({
      ...apiEndpoints.general.reTryErpSync
    }).catch((err: any) => {
      context.commit(SettingMutations.TRIGGER_LOADING, false)
      throw new Error(err)
    }).finally(() => {
      context.commit(SettingMutations.TRIGGER_LOADING, false)
    })
  }
}
