
import { defineComponent } from 'vue'
import { debounce } from 'lodash'

export default defineComponent({
  name: 'MixMaxRange',
  emits: ['update:modelValue'],
  props: ['modelValue'],
  data () {
    return {
      localValue: { min: '', max: '' },
      onSetItem: debounce((index, input) => {
        this.inputHandler(index, input)
      }, 500)
    }
  },
  methods: {
    inputHandler (key: string, text: string) {
      this.localValue = { ...this.localValue, [key]: text }
    }
  },
  watch: {
    modelValue (newVal) {
      if (newVal !== null) {
        this.localValue = newVal
      } else {
        this.localValue = { min: '', max: '' }
      }
    },
    localValue: {
      handler (newVal) {
        if (newVal.min && newVal.max) {
          this.$emit('update:modelValue', newVal)
        }
      },
      deep: true
    }
  },
  mounted () {
    if (this.modelValue && this.modelValue !== null) {
      this.localValue = this.modelValue
    }
  }
})
