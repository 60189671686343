import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.sortField.sort)
      ? (_openBlock(), _createBlock(_component_BaseIcon, {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('sort', _ctx.sortField))),
          name: "orderempty"
        }))
      : _createCommentVNode("", true),
    (_ctx.sortField.sort === 'asc')
      ? (_openBlock(), _createBlock(_component_BaseIcon, {
          key: 1,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('sort', _ctx.sortField))),
          style: {"width":"18px"},
          name: "arrow"
        }))
      : _createCommentVNode("", true),
    (_ctx.sortField.sort === 'desc')
      ? (_openBlock(), _createBlock(_component_BaseIcon, {
          key: 2,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('sort', _ctx.sortField))),
          style: {"width":"18px","transform":"rotate(180deg)"},
          name: "arrow"
        }))
      : _createCommentVNode("", true)
  ], 64))
}