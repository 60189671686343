import { MutationTree } from 'vuex'
import { ZoneState } from '@/store/modules/zones/types'

export enum ZoneMutations {
  REQUEST_ZONES = 'REQUEST_ZONES',
  RECEIVE_ZONES = 'RECEIVE_ZONES',
  ERROR_ZONES = 'ERROR_ZONES',
}

export const mutations: MutationTree<ZoneState> = {
  [ZoneMutations.RECEIVE_ZONES] (state, payload) {
    state.zones = payload.data
    state.pagination = payload.pagination
    state.loading = false
  },
  [ZoneMutations.REQUEST_ZONES] (state, status = true) {
    state.loading = status
  },
  [ZoneMutations.ERROR_ZONES] (state, error) {
    // err handler
    state.loading = false
  }
}
