
import { defineAsyncComponent, defineComponent, toRaw } from 'vue'
import moment from 'moment'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'

const google = window.google
export default defineComponent({
  name: 'LTSPlayer',
  components: {
    BaseIcon,
    OrderStatusTime: defineAsyncComponent(() => import('@/views/account/lts-dashboard/components/OrderStatusTime.vue')),
    LTSStopPointList: defineAsyncComponent(() => import('@/views/account/lts-dashboard/components/LTSStopPointList.vue')),
    LTSSummaryView: defineAsyncComponent(() => import('@/views/account/lts-dashboard/components/LTSSummaryView.vue'))
  },
  props: {
    id: {
      type: String,
      default: 'lts-map'
    },
    zoom: {
      type: Number,
      default: 15
    },
    locations: {
      type: Array as any,
      default: () => []
    },
    stopPoints: {
      type: Array as any,
      default: () => []
    },
    orders: {
      type: Array as any,
      default: () => []
    },
    summary: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      map: {} as any,
      route: null as any,
      marker: null as any,
      currentLocationIndex: 0,
      animationInterval: null as any,
      currentLocationPlayingPercentage: 0,
      currentStep: 0,
      isPlaying: false,
      isSeeking: false,
      seekPercentage: 0 as any,
      step: 100,
      playSpeed: 1 as any,
      playSpeedSelection: false,
      markerGroups: {
        startPoint: null as any,
        stopPoints: [] as any,
        orders: [] as any
      },
      infoScreen: {
        speed: '--',
        accuracy: '--',
        distance: '--'
      },
      isStatusMenuOpen: false,
      mapHeight: null as any,
      activeInfoWindow: null as any
    }
  },
  methods: {
    initMap () {
      setTimeout(() => {
        if (google) {
          const _center = this.locations.length ? this.getStartPointCenter() : null
          // create a map
          this.map = new google.maps.Map(document.getElementById(this.id)!, {
            center: _center || { lat: 33.312805, lng: 44.361488 },
            zoom: this.zoom,
            disableDefaultUI: true
          })

          this.$nextTick(() => {
            if (this.map) {
              this.initMapObjects()
            } else {
              this.initMap()
            }
          })
        } else {
          this.initMap()
        }
      }, 500)
    },
    initMapObjects () {
      if (this.locations.length) {
        this.drawRoute()
        this.marker = this.addMarker(this.locations[0].latitude, this.locations[0].longitude)
        this.addStartPointMarker(this.locations[0].latitude, this.locations[0].longitude)
        this.addStopPointsMarker()
      }

      this.map.addListener('click', () => {
        if (this.activeInfoWindow) {
          this.activeInfoWindow.close()
        }
      })

      this.addOrdersMarker()
      // this.startAnimation()
    },
    getLocationsCenter () {
      if (!this.locations.length) return false
      const bounds = new google.maps.LatLngBounds()
      for (const coordinate of this.locations) {
        const latLng = new google.maps.LatLng(coordinate.latitude, coordinate.longitude)
        bounds.extend(latLng)
      }
      return bounds.getCenter()
    },
    getStartPointCenter () {
      if (!this.locations.length) return false
      const bounds = new google.maps.LatLngBounds()
      bounds.extend(new google.maps.LatLng(this.locations[0].latitude, this.locations[0].longitude))
      return bounds.getCenter()
    },
    drawRoute () {
      if (this.route) {
        toRaw(this.route).setMap(null)
      }

      const routeCoordinates = this.locations.map((location: any) => ({
        lat: location.latitude,
        lng: location.longitude
      }))

      this.route = new google.maps.Polyline({
        path: routeCoordinates,
        geodesic: true,
        strokeColor: '#000',
        strokeOpacity: 1.0,
        strokeWeight: 3
      })

      this.route.setMap(this.map)
    },
    addMarker (latitude: any, longitude:any, title = '') {
      if (this.marker) {
        toRaw(this.marker).setMap(null)
      }
      const icon = {
        url: require('../../../../assets/lts-driver-icon.svg'),
        size: new google.maps.Size(36, 54),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 48),
        scaledSize: new google.maps.Size(36, 54)
      }
      return new google.maps.Marker({
        position: new google.maps.LatLng(latitude, longitude),
        map: this.map,
        title: title,
        icon,
        zIndex: 9999
      })
    },
    startAnimation () {
      this.currentLocationIndex = 0
      if (this.currentLocationIndex < this.locations.length - 1) {
        this.animateMarker()
      }
    },
    pauseAnimation () {
      clearTimeout(this.animationInterval)
      this.isPlaying = false
    },
    stopAnimation () {
      this.pauseAnimation()
      this.currentLocationIndex = 0
      this.currentStep = 0
      this.currentLocationPlayingPercentage = 0
      this.seekPercentage = 0
      if (this.locations.length && this.marker) {
        this.marker.setPosition(new google.maps.LatLng(this.locations[0].latitude, this.locations[0].longitude))
      }
    },
    resumeAnimation () {
      this.animateMarker(true)
    },
    animateMarker (isResume = false) {
      const currentLocation = this.locations[this.currentLocationIndex]
      const nextLocation = this.locations[this.currentLocationIndex + 1]
      this.infoScreen = {
        speed: currentLocation.speed,
        distance: currentLocation.distance,
        accuracy: currentLocation.accuracy
      }
      if (nextLocation) {
        let startLatLng = new google.maps.LatLng(currentLocation.latitude, currentLocation.longitude)
        const endLatLng = new google.maps.LatLng(nextLocation.latitude, nextLocation.longitude)
        let duration = moment(nextLocation.timestamp).diff(currentLocation.timestamp)
        let numSteps = (duration / this.step) / this.playSpeed
        if (isResume) {
          startLatLng = this.marker.getPosition()
          duration = duration - ((duration * this.currentLocationPlayingPercentage) / 100)
          numSteps = (duration / this.step) / this.playSpeed
        } else {
          this.currentStep = 0
        }
        const stepLat = (endLatLng.lat() - startLatLng.lat()) / numSteps
        const stepLng = (endLatLng.lng() - startLatLng.lng()) / numSteps
        this.isPlaying = true
        const animateStep = () => {
          this.calculateProgressPercentage()
          if (this.currentStep < numSteps) {
            const newLatLng = new google.maps.LatLng(
              startLatLng.lat() + stepLat * this.currentStep,
              startLatLng.lng() + stepLng * this.currentStep
            )
            this.marker.setPosition(newLatLng)
            this.currentStep++
            this.animationInterval = setTimeout(animateStep, this.step / this.playSpeed)
            this.currentLocationPlayingPercentage = (this.currentStep / numSteps) * 100
            // console.log(`Location Step Step: ${this.currentLocationIndex}, Percentage: ${this.currentLocationPlayingPercentage}%`)
          } else {
            this.currentLocationIndex++
            this.animateMarker()
          }
        }
        animateStep()
      } else {
        this.isPlaying = false
      }
    },
    formatTime (seconds:number) {
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      seconds = seconds % 60

      if (hours > 0) {
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
      } else {
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
      }
    },
    onSeek () {
      this.isSeeking = true
      // seek value of user
      const seekValue = parseFloat(this.seekPercentage)

      if (!isNaN(seekValue) && seekValue >= 0 && seekValue <= 100) {
        const totalDurationInSeconds = this.totalAnimationDuration.split(':').reduce((acc, val) => acc * 60 + +val, 0)
        const seekTimeInSeconds = (seekValue / 100) * totalDurationInSeconds

        // find the location
        let currentDurationInSeconds = 0
        for (let i = 0; i < this.locations.length - 1; i++) {
          const currentLocation = this.locations[i]
          const nextLocation = this.locations[i + 1]
          const duration = moment(nextLocation.timestamp).diff(currentLocation.timestamp) / 1000

          this.infoScreen = {
            speed: currentLocation.speed,
            distance: currentLocation.distance,
            accuracy: currentLocation.accuracy
          }

          const endLatLng = new google.maps.LatLng(nextLocation.latitude, nextLocation.longitude)
          const startLatLng = new google.maps.LatLng(currentLocation.latitude, currentLocation.longitude)
          const numSteps = (duration / this.step) * 1000
          if (currentDurationInSeconds + duration >= seekTimeInSeconds) {
            this.currentLocationIndex = i
            const startPointDuration = moment(this.locations[this.currentLocationIndex].timestamp)
              .diff(this.locations[0].timestamp) / 1000
            if (seekTimeInSeconds - startPointDuration > 0) {
              const stepDiff = Math.round(seekTimeInSeconds - startPointDuration)
              this.currentLocationPlayingPercentage = (stepDiff / duration) * 100
              const _duration = duration - ((duration * this.currentLocationPlayingPercentage) / 100)
              this.currentStep = (duration - _duration) * 10
              const stepLat = (endLatLng.lat() - startLatLng.lat()) / numSteps
              const stepLng = (endLatLng.lng() - startLatLng.lng()) / numSteps
              const newLatLng = new google.maps.LatLng(
                startLatLng.lat() + stepLat * this.currentStep,
                startLatLng.lng() + stepLng * this.currentStep
              )
              this.marker.setPosition(newLatLng)
            } else {
              // set new location
              const startLatLng = new google.maps.LatLng(currentLocation.latitude, currentLocation.longitude)
              this.marker.setPosition(startLatLng)
            }
            // this.resumeAnimation()
            break
          }

          currentDurationInSeconds += duration
        }
      }
    },
    calculateProgressPercentage () {
      if (this.locations.length < 2) {
        return 0
      }
      const totalDurationInSeconds = this.totalAnimationDuration.split(':').reduce((acc, val) => acc * 60 + +val, 0)
      const currentDurationInSeconds = this.currentAnimationTime.split(':').reduce((acc, val) => acc * 60 + +val, 0)
      this.seekPercentage = ((currentDurationInSeconds / totalDurationInSeconds) * 100)
    },
    mouseOutHandler () {
      if (!this.isPlaying && this.isSeeking) {
        this.resumeAnimation()
      }
    },
    updatePlaySpeed (speed:any) {
      this.pauseAnimation()
      this.playSpeed = speed
      this.playSpeedSelection = false
      this.resumeAnimation()
    },
    addStartPointMarker (latitude: any, longitude:any, title = 'Start point') {
      if (this.markerGroups.startPoint) {
        toRaw(this.markerGroups.startPoint).setMap(null)
      }
      const icon = {
        url: require('../../../../assets/lts-start-point-marker.svg'),
        size: new google.maps.Size(36, 54),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 48),
        scaledSize: new google.maps.Size(36, 54) // scaled size
      }
      this.markerGroups.startPoint = new google.maps.Marker({
        position: new google.maps.LatLng(latitude, longitude),
        map: this.map,
        icon,
        label: {
          text: 'Start point',
          className: 'playback-start-point-label rounded-full',
          color: '#fff',
          fontWeight: 'semibold',
          fontSize: '12px'
        },
        zIndex: 99
      })
    },
    addStopPointsMarker () {
      this.markerGroups.stopPoints.map((spm:any) => {
        toRaw(spm).setMap(null)
      })
      const icon = {
        url: require('../../../../assets/lts-stop-point-marker.svg'),
        size: new google.maps.Size(36, 54),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 48),
        scaledSize: new google.maps.Size(36, 54) // scaled size
      }
      this.stopPoints.map((sp:any) => {
        const infoWindow = new google.maps.InfoWindow({
          content: this.getStopPointContentData(sp)
        })
        const spMarker = new google.maps.Marker({
          position: new google.maps.LatLng(sp.latitude, sp.longitude),
          map: this.map,
          icon,
          zIndex: 98
        })

        spMarker.addListener('click', (event: any) => {
          if (this.activeInfoWindow) {
            this.activeInfoWindow.close()
          }
          this.activeInfoWindow = infoWindow
          infoWindow.open({
            anchor: spMarker,
            map: this.map,
            shouldFocus: false
          })
        })
        const center = new google.maps.LatLng(sp.latitude, sp.longitude)
        this.map.panTo(center)
        if (this.activeInfoWindow) {
          this.activeInfoWindow.close()
        }

        this.markerGroups.stopPoints.push(spMarker)
      })
    },
    getStopPointContentData (sp:any) {
      return `<div>
                <div class="text-center font-bold text-black">${this.$t('lts.stop_point')}</div>
                <div class="text-left mt-3">
                    <div class="mt-1">
                        <span class="font-bold text-black">${this.$t('lts.date')}:</span>
                        ${moment(sp.stop_datetime).format('DD/MM/YYYY hh:mm A')}
                    </div>
                    <div class="mt-1">
                        <span class="font-bold text-black">${this.$t('lts.duration')}:</span>
                        ${this.formatTime(sp.duration)}
                    </div>
                    <div class="mt-1">
                        <span class="font-bold text-black">${this.$t('lts.lat')}:</span>
                        ${sp.latitude}</div>
                    <div class="mt-1">
                        <span class="font-bold text-black">${this.$t('lts.lng')}:</span>
                        ${sp.longitude}
                    </div>
                </div>
             </div>`
    },
    addOrdersMarker () {
      this.markerGroups.orders.map((spm:any) => {
        toRaw(spm).setMap(null)
      })
      const icon = {
        url: require('../../../../assets/lts-order-marker.svg'),
        size: new google.maps.Size(24, 36),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(10, 22),
        scaledSize: new google.maps.Size(24, 36) // scaled size
      }
      this.orders.map((o:any) => {
        if (o.tripDetailsFromDarkstoreOutward) {
          const { endLocation } = o.tripDetailsFromDarkstoreOutward[0]
          const orderMarker = new google.maps.Marker({
            position: new google.maps.LatLng(endLocation.lat, endLocation.lng),
            map: this.map,
            icon,
            zIndex: 98,
            label: {
              text: o.orderId,
              className: 'playback-order-label rounded-full',
              color: '#fff',
              fontWeight: 'semibold',
              fontSize: '12px'
            }
          })
          this.markerGroups.orders.push(orderMarker)
        }
      })
    },
    toggleStatusMenu (): void {
      const mapReference: any = this.$refs?.mapRef
      this.mapHeight = mapReference.clientHeight
      this.isStatusMenuOpen = !this.isStatusMenuOpen
    },
    createStopPointTimelines () {
      const el = this.$refs.visualizedPlayer as HTMLElement
      el.innerHTML = ''
      this.stopPoints.map((sp: any) => {
        const startDatetime = moment(sp.stop_datetime)
        const firstLocation = moment(this.locations[0].timestamp)
        const lastLocation = moment(this.locations[this.locations.length - 1].timestamp)
        if (startDatetime.unix() <= lastLocation.unix() && startDatetime.unix() >= firstLocation.unix()) {
          const startPercentage = (startDatetime.diff(firstLocation) / this.totalAnimationTimeInSecond) / 10
          const timeline = document.createElement('div')
          timeline.style.backgroundColor = '#DD504BFF'
          timeline.style.height = '100%'
          timeline.style.top = '0'
          timeline.style.position = 'absolute'
          timeline.style.left = startPercentage + '%'
          timeline.style.width = (sp.duration / this.totalAnimationTimeInSecond) * 100 + '%'
          el.appendChild(timeline)
        }
      })
    },
    init () {
      if (this.locations.length) {
        this.drawRoute()
        this.marker = this.addMarker(this.locations[0].latitude, this.locations[0].longitude)
        this.addStartPointMarker(this.locations[0].latitude, this.locations[0].longitude)
        this.addStopPointsMarker()
        this.createStopPointTimelines()
      }
    }
  },
  watch: {
    isPlaying (newVal) {
      this.$emit('playStatus', newVal)
    },
    locations: {
      handler () {
        if (this.marker) {
          toRaw(this.marker).setMap(null)
        }
        this.stopAnimation()
        this.init()
      },
      deep: true
    },
    orders: {
      handler () {
        this.addOrdersMarker()
      },
      deep: true
    },
    currentAnimationTimeInSecond (newVal) {
      // fix
      if (this.playSpeed > 1) {
        const totalDurationInSeconds = this.totalAnimationDuration.split(':').reduce((acc, val) => acc * 60 + +val, 0)
        const currentDurationInSeconds = this.currentAnimationTime.split(':').reduce((acc, val) => acc * 60 + +val, 0)
        this.seekPercentage = ((currentDurationInSeconds / totalDurationInSeconds) * 100)
      }
      if (newVal >= this.totalAnimationTimeInSecond) {
        setTimeout(() => {
          this.stopAnimation()
        }, 3000)
      }
    }

  },
  computed: {
    totalAnimationDuration () {
      if (this.locations.length < 2) {
        return '00:00:00'
      }

      const firstLocation = this.locations[0]
      const lastLocation = this.locations[this.locations.length - 1]
      const totalDurationInSeconds = moment(lastLocation.timestamp).diff(firstLocation.timestamp) / 1000

      return this.formatTime(totalDurationInSeconds)
    },
    currentAnimationTime () {
      if (this.currentLocationIndex < 0) {
        return '00:00:00' // start value
      }

      if (this.currentLocationIndex >= this.locations.length - 1) {
        const totalDurationInSeconds = this.totalAnimationDuration.split(':').reduce((acc, val) => acc * 60 + +val, 0)
        return this.totalAnimationDuration
      }
      const currentLocation = this.locations[this.currentLocationIndex]
      const nextLocation = this.locations[this.currentLocationIndex + 1]
      const currentStep = this.currentStep

      // time of passed from beginning to right now
      const elapsedMilliseconds = currentStep * this.step
      const elapsedSeconds = elapsedMilliseconds / 1000

      // date time info of current location item
      const currentLocationTime = moment(currentLocation.timestamp)

      // elapsed time calculation
      const advancedTime = currentLocationTime.clone().add(elapsedSeconds, 'seconds')

      // elapsed time since first location item
      const elapsedTimeFromStart = advancedTime.diff(moment(this.locations[0].timestamp), 'seconds')

      return this.formatTime(elapsedTimeFromStart)
    },
    totalAnimationTimeInSecond () {
      return this.totalAnimationDuration.split(':').reduce((acc, val) => acc * 60 + +val, 0)
    },
    currentAnimationTimeInSecond () {
      return this.currentAnimationTime.split(':').reduce((acc, val) => acc * 60 + +val, 0)
    },
    completed () {
      const total = this.totalAnimationDuration.split(':').reduce((acc, val) => acc * 60 + +val, 0)
      const current = this.currentAnimationTime.split(':').reduce((acc, val) => acc * 60 + +val, 0)
      return current >= total
    }
  },
  mounted () {
    this.initMap()
  }
})
