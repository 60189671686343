
import { defineComponent, defineAsyncComponent } from 'vue'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import { VehiclesActions } from '@/store/modules/vehicles/actions'
import { VehicleInterface } from '@/shared/interfaces/vehicle.interface'
import { DriverActions } from '@/store/modules/drivers/actions'
import { useToast } from 'vue-toastification'
import { WorkShiftActions } from '@/store/modules/work-shifts/actions'
import router from '@/router'

export default defineComponent({
  components: {
    SearchInput: defineAsyncComponent(() => import('@/shared/components/SearchInput.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    TableGhost: defineAsyncComponent(() => import('@/shared/components/ghosts/TableGhost.vue')),
    AssignVehicle: defineAsyncComponent(() => import('@/views/account/vehicles/components/AssignVehicle.vue')),
    Pagination: defineAsyncComponent(() => import('@/shared/components/Pagination.vue')),
    BaseModal: defineAsyncComponent(() => import('@/shared/components/modals/BaseModal.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    EditVehicle: defineAsyncComponent(() => import('@/views/account/vehicles/components/EditVehicle.vue'))
  },
  data () {
    return {
      showModal: false,
      showAssignModal: false,
      editedVehicle: {} as VehicleInterface,
      driverIds: null as any,
      selectedVehicle: null as any,
      showFilterModal: false,
      searchValue: null as any,
      current: 1,
      isFree: false,
      isEdit: false,
      value: null,
      height: 400,
      filters: [
        {
          name: this.$t('filters.isActivated'),
          model: 'isActivated',
          type: 'radio',
          data: [
            { id: 1, label: this.$t('filters.yes') },
            { id: 0, label: this.$t('filters.no') }
          ]
        },
        {
          name: this.$t('filters.owner'),
          model: 'owner',
          type: 'radio',
          data: [
            { id: 'driver', label: this.$t('vehicles.vehicleOwners.driver') },
            { id: 'Tasleem', label: this.$t('vehicles.vehicleOwners.tasleem') }
          ]
        },
        {
          name: this.$t('filters.isFree'),
          model: 'isFree',
          type: 'radio',
          data: [
            { id: 1, label: this.$t('filters.yes') },
            { id: 0, label: this.$t('filters.no') }
          ]
        },
        {
          name: this.$t('filters.assignedShift'),
          model: 'shiftId',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'shifts',
          type: 'radio',
          data: []
        },
        {
          name: this.$t('filters.vehicleType'),
          model: 'vehicleTypeId',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'vehicleTypes',
          type: 'radio',
          data: []
        }
      ]
    }
  },
  mounted () {
    this.height = document.documentElement.clientHeight - 280
    addEventListener('resize', () => {
      this.height = document.documentElement.clientHeight - 280
    })
  },
  computed: {
    vehicles (): VehicleInterface[] {
      return this.$store.getters['vehicles/getVehicles']
    },
    pagination (): PaginationInterface {
      return this.$store.getters['vehicles/getPagination']
    },
    workShifts (): any[] {
      return this.$store.getters['workShifts/getWorkShiftsForSelect']
        .map((el: any) => {
          return {
            id: el.id,
            label: el.value
          }
        })
    },
    vehicleTypes (): any[] {
      return this.$store.getters['vehicles/getVehicleTypes']
        .map((el: any) => {
          return {
            id: el.id,
            label: el.value
          }
        })
    },
    loading (): boolean {
      return this.$store.getters['vehicles/getLoading']
    }
  },
  watch: {
    current (val) {
      router.push({ query: { ...this.$route.query, pageNumber: val } })
    },
    searchValue (val) {
      const queries = this.$route.query
      queries.pageNumber = '1'
      router.push({ query: { ...queries, search: this.searchValue } })
    },
    workShifts: {
      immediate: true,
      handler (val) {
        if (!val.length) return
        const target = this.filters[3]
        target.data = val
        target.dataLoading = false
      }
    },
    vehicleTypes: {
      immediate: true,
      handler (val) {
        if (!val.length) return
        const target = this.filters[4]
        target.dataRequested = true
        target.data = val
        target.dataLoading = false
      }
    },
    $route: {
      immediate: true,
      handler (val) {
        if (val.name === 'Vehicles') {
          this.current = this.$route.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
          this.getVehicles(val)
        }
      }
    }
  },
  methods: {
    getVehicles (route: any): void {
      if (route.query.search) {
        this.searchValue = route.query.search
      }
      const params = { pageSize: 10, pageNumber: route.query.pageNumber ? route.query.pageNumber : 1, ...route.query }
      this.$store.dispatch(`vehicles/${VehiclesActions.FETCH_VEHICLES}`, params)
    },
    assignToDriver (vehicle: any): void {
      this.driverIds = vehicle.driverInfo ? vehicle.driverInfo.map((el: any) => el.id) : []
      this.selectedVehicle = vehicle
      this.showAssignModal = true
    },
    editVehicle (vehicle: VehicleInterface): void {
      this.showModal = true
      this.isEdit = true
      this.editedVehicle = vehicle
    },
    deleteVehicle (vehicle: VehicleInterface): void {
      this.$store.dispatch(`vehicles/${VehiclesActions.DELETE_VEHICLE}`, { vehicleId: vehicle.id }).then(() => {
        this.getVehicles(this.$route)
        const toast = useToast()
        toast.success(this.$t('messages.deleteVehicle'), {
          timeout: 3000
        })
      })
    },
    addVehicle (): void {
      this.showModal = true
      this.isEdit = false
      this.driverIds = null
      this.selectedVehicle = null
      this.editedVehicle = {} as VehicleInterface
    },
    toggleActivate (id: number, type: 'activate' | 'deactivate'): void {
      if (type === 'activate') {
        this.$store.dispatch(`vehicles/${VehiclesActions.ACTIVATE_VEHICLE}`, { vehicleId: id }).catch(() => {
          const inputRef = this.$refs['activationInput' + id] as any
          inputRef.checked = false
        })
      } else {
        this.$store.dispatch(`vehicles/${VehiclesActions.DEACTIVATE_VEHICLE}`, { vehicleId: id }).catch(() => {
          const inputRef = this.$refs['activationInput' + id] as any
          inputRef.checked = true
        })
      }
    },
    unassignDriver (vehicleId: number, driverId: number): void {
      const payload = {
        vehicleId,
        driverId
      }
      this.$store.dispatch(`drivers/${[DriverActions.UNASSIGN_VEHICLE]}`, payload).then(() => {
        this.getVehicles(this.$route)
      })
    },
    onExpandPanel (actionName: string): void {
      if (actionName === 'shifts') {
        this.$store.dispatch(`workShifts/${WorkShiftActions.FETCH_WORK_SHIFTS}`, { pageNumber: 1, pageSize: 100 })
      } else if (actionName === 'vehicleTypes') {
        this.$store.dispatch(`vehicles/${[VehiclesActions.FETCH_VEHICLE_TYPES]}`)
      }
    }
  }
})
