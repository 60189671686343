import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex gap-x-3" }
const _hoisted_2 = { class: "relative w-1/2" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "relative w-1/2" }
const _hoisted_5 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        value: _ctx.localValue.min,
        onInput: _cache[0] || (_cache[0] = (e) => _ctx.onSetItem('min', e.target.value)),
        type: "number",
        class: "form-control h-42",
        placeholder: "Min"
      }, null, 40, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("input", {
        value: _ctx.localValue.max,
        onInput: _cache[1] || (_cache[1] = (e) => _ctx.onSetItem('max', e.target.value)),
        type: "number",
        class: "form-control h-42",
        placeholder: "Max"
      }, null, 40, _hoisted_5)
    ])
  ]))
}