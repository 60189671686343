import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { ZoneInterface } from '@/shared/interfaces/zone.interface'

export class ZoneModel extends CommonModel {
  public data: ZoneInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...ZoneModel.setData(item) }]
    })
  }

  private static setData (data: any): ZoneInterface {
    return {
      id: data.id,
      name: data.name,
      darkstores: data.darkstores,
      polygon: data.polygon
    }
  }

  public static convertToMapPolygonFormat (polygons: []) {
    return polygons.map(p => ({ lat: p[0], lng: p[1] }))
  }
}
