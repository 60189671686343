<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.16397 16.3489C3.97197 16.3489 3.78034 16.2758 3.63372 16.1291C3.34084 15.8363 3.34084 15.3615 3.63372 15.0686L15.354 3.34838C15.6465 3.0555 16.1216 3.0555 16.4145 3.34838C16.7073 3.64125 16.7073 4.116 16.4145 4.40888L4.69422 16.1291C4.54797 16.2754 4.35597 16.3489 4.16397 16.3489Z" fill="black"/>
    <path d="M3.00661 21.75C2.95448 21.75 2.90161 21.7444 2.84873 21.7331C2.44373 21.6461 2.18573 21.2475 2.27273 20.8425L3.43148 15.4414C3.51848 15.0364 3.91898 14.7791 4.32211 14.8654C4.72711 14.9524 4.98511 15.351 4.89811 15.756L3.73936 21.1571C3.66398 21.5093 3.35273 21.75 3.00661 21.75Z" fill="black"/>
    <path d="M8.40713 20.5912C8.21513 20.5912 8.02351 20.5181 7.87688 20.3715C7.58401 20.0786 7.58401 19.6039 7.87688 19.311L19.5971 7.59112C19.8896 7.29824 20.3648 7.29824 20.6576 7.59112C20.9505 7.88399 20.9505 8.35874 20.6576 8.65162L8.93776 20.3715C8.79113 20.5181 8.59913 20.5912 8.40713 20.5912Z" fill="black"/>
    <path d="M3.0044 21.75C2.65827 21.75 2.3474 21.5092 2.27165 21.1571C2.18502 20.7521 2.44265 20.3535 2.84765 20.2665L8.24877 19.1077C8.65415 19.0219 9.05277 19.2791 9.1394 19.6837C9.22602 20.0887 8.9684 20.4874 8.5634 20.5744L3.16227 21.7331C3.1094 21.7447 3.05652 21.75 3.0044 21.75Z" fill="black"/>
    <path d="M18.006 10.9924C17.814 10.9924 17.622 10.9192 17.4757 10.7726L13.2333 6.53025C12.9405 6.23737 12.9405 5.76262 13.2333 5.46975C13.5258 5.17687 14.0013 5.17687 14.2938 5.46975L18.5362 9.71212C18.8291 10.005 18.8291 10.4797 18.5362 10.7726C18.39 10.9192 18.198 10.9924 18.006 10.9924Z" fill="black"/>
    <path d="M20.1268 8.87138C19.9348 8.87138 19.7428 8.79825 19.5962 8.65163C19.3033 8.35875 19.3033 7.884 19.5962 7.59075C20.0136 7.17338 20.2434 6.60825 20.2434 6C20.2434 5.39175 20.0136 4.82663 19.5962 4.40925C19.1784 3.9915 18.6133 3.76163 18.0051 3.76163C17.3968 3.76163 16.8317 3.9915 16.4143 4.40925C16.1218 4.70213 15.6471 4.7025 15.3534 4.40925C15.0606 4.11638 15.0606 3.64163 15.3534 3.34838C16.0539 2.6475 16.9956 2.26163 18.0051 2.26163C19.0142 2.26163 19.9562 2.6475 20.6567 3.34838C21.3576 4.04888 21.7434 4.9905 21.7434 6C21.7434 7.0095 21.3576 7.95113 20.6567 8.65163C20.5108 8.79788 20.3188 8.87138 20.1268 8.87138Z" fill="black"/>
  </svg>
</template>
