import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c0e217f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-6" }
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = { class: "flex gap-8" }
const _hoisted_4 = {
  key: 0,
  class: "w-full position-relative"
}
const _hoisted_5 = { class: "flex border-b pb-3 mt-6" }
const _hoisted_6 = { class: "font-bold w-full" }
const _hoisted_7 = { class: "sort-item flex items-center justify-between border-b my-0 p-3 related-order-item" }
const _hoisted_8 = { class: "text-black flex items-center" }
const _hoisted_9 = { class: "rounded px-2 border inline-flex mx-2 text-gray" }
const _hoisted_10 = { class: "w-full position-relative" }
const _hoisted_11 = { class: "flex justify-end mt-6" }
const _hoisted_12 = { class: "text-textColor font-bold text-md mb-" }
const _hoisted_13 = { class: "flex items-center gap-x-1 mt-5" }
const _hoisted_14 = {
  key: 0,
  class: "flex items-center gap-x-1 mt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_draggableComponent = _resolveComponent("draggableComponent")!
  const _component_AssignmentMap = _resolveComponent("AssignmentMap")!
  const _component_ReasonForm = _resolveComponent("ReasonForm")!
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('orders.relatedOrdersDesc')), 1),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.relatedOrders.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('orders.orderID')), 1)
              ]),
              _createVNode(_component_draggableComponent, {
                modelValue: _ctx.relatedOrders,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.relatedOrders) = $event)),
                group: "people",
                "ghost-class": "ghost"
              }, {
                item: _withCtx(({element}) => [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createTextVNode(_toDisplayString(element.order_id) + " ", 1),
                      (element.order_id === _ctx.orderData.orderId)
                        ? (_openBlock(), _createBlock(_component_BaseIcon, {
                            key: 0,
                            name: "eye",
                            class: "ml-1 w-3"
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t(`orders.statuses.${element.order_status.name}`)), 1)
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_AssignmentMap, {
            coordinates: _ctx.coordinates,
            "store-coordinates": _ctx.storeCoordinates,
            style: {"width":"100%","height":"300px"}
          }, null, 8, ["coordinates", "store-coordinates"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
        class: "tertiary px-6 py-3 me-2"
      }, _toDisplayString(_ctx.$t('buttons.close')), 1),
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showConfirmation = true)),
        class: "primary px-6 py-3"
      }, _toDisplayString(_ctx.$t('buttons.saveChanges')), 1)
    ]),
    (_ctx.showConfirmation)
      ? (_openBlock(), _createBlock(_component_ConfirmationModal, {
          key: 0,
          header: _ctx.$t('orders.unassign.header'),
          hasIcon: false,
          cancelText: _ctx.$t('buttons.no'),
          confirmText: _ctx.$t('buttons.yesUnassign'),
          isValid: _ctx.unAssignReason && _ctx.unAssignReason.reason_id && _ctx.unAssignReason.note.length,
          onConfirm: _ctx.unAssignOrder,
          onCancel: _cache[8] || (_cache[8] = ($event: any) => (_ctx.rejectUnAssignDriver()))
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.unassignTitle), 1),
            _createElementVNode("div", _hoisted_13, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.awaitFurtherOrders) = $event))
              }, null, 512), [
                [_vModelCheckbox, _ctx.awaitFurtherOrders]
              ]),
              _createElementVNode("span", {
                role: "button",
                class: "text-black font-bold",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.awaitFurtherOrders = !_ctx.awaitFurtherOrders))
              }, _toDisplayString(_ctx.$t('orders.unassign.make_driver_wait_for_more_orders')), 1)
            ]),
            (_ctx.showManualAssignmentItems)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isManual) = $event))
                  }, null, 512), [
                    [_vModelCheckbox, _ctx.isManual]
                  ]),
                  _createElementVNode("span", {
                    role: "button",
                    class: "text-black font-bold",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isManual = !_ctx.isManual))
                  }, _toDisplayString(_ctx.$t('orders.manual')), 1)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_ReasonForm, {
              class: "mt-5",
              "feedback-type": _ctx.PredefinedFeedbackTypes.MANUAL_UNASSIGNMENT,
              onReason: _cache[7] || (_cache[7] = (r) => _ctx.unAssignReason = r)
            }, null, 8, ["feedback-type"])
          ]),
          _: 1
        }, 8, ["header", "cancelText", "confirmText", "isValid", "onConfirm"]))
      : _createCommentVNode("", true)
  ]))
}