
import { defineComponent, PropType, toRaw } from 'vue'
const google = window.google

export default defineComponent({
  name: 'AssignmentMap',
  props: {
    coordinates: {
      type: Array as PropType<Array<{ lat: number; lng: number }>>,
      default: () => []
    },
    storeCoordinates: {
      type: Object as PropType<Record<any, any>>,
      default: null
    }
  },
  data () {
    return {
      map: {} as any,
      markers: [] as any,
      storeMarker: null as any
    }
  },
  methods: {
    initMap () {
      const centerCoords = this.coordinates.length > 0 ? this.coordinates[0] : { lat: 33.312805, lng: 44.361488 }
      this.map = new google.maps.Map(document.getElementById('assignment-map')!, {
        center: centerCoords,
        zoom: 8,
        mapTypeControl: false,
        streetViewControl: false,
        disableDefaultUI: false
      })
      this.addMarkers()
      this.addStoreMarker()
    },
    addMarkers () {
      this.coordinates.forEach((markerCoords, index) => {
        const marker = new google.maps.Marker({
          position: markerCoords,
          map: this.map,
          label: {
            text: `${index + 1}`,
            color: '#fff',
            fontWeight: 'bold'
          }
        })
        this.markers.push(marker)
        this.centerMap()
      })
    },
    addStoreMarker () {
      if (this.storeMarker) {
        toRaw(this.storeMarker).setMap(null)
        this.storeMarker.setMap(null)
      }
      const image = {
        url: require('../../../../assets/markers/darkstore-dark-marker.png'),
        size: new google.maps.Size(55, 70),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(22, 67)
      }
      this.storeMarker = new google.maps.Marker({
        position: this.storeCoordinates as any,
        icon: image,
        map: this.map
      })
      this.centerMap()
    },
    removeMarkers () {
      this.markers.forEach((m:any) => {
        toRaw(m).setMap(null)
        m.setMap(null)
      })
    },
    centerMap () {
      const bounds = new google.maps.LatLngBounds()
      for (const coordinate of this.coordinates) {
        const latLng = new google.maps.LatLng(coordinate.lat, coordinate.lng)
        bounds.extend(latLng)
      }
      this.map.setCenter(bounds.getCenter())
    }
  },
  watch: {
    coordinates: {
      handler () {
        this.removeMarkers()
        this.addMarkers()
      },
      deep: true
    },
    storeCoordinates: {
      handler () {
        this.addStoreMarker()
      }
    }
  },
  mounted () {
    this.initMap()
  }
})
