import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pt-14 pb-5 px-5" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "flex justify-start" }
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_FilterPanel = _resolveComponent("FilterPanel")!
  const _component_DynamicTable = _resolveComponent("DynamicTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_PageTitle, { title: "reports.Drivers_activity_per_darkstore_per_shift" })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFilterModal = true)),
          class: "border border-inputBorder w-42 h-42"
        }, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_BaseIcon, { name: "filter" })
          ])
        ]),
        (_ctx.showFilterModal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_FilterPanel, {
                filters: _ctx.filters,
                onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showFilterModal = false)),
                onFetchData: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onExpand($event)))
              }, null, 8, ["filters"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_DynamicTable, {
        tableData: _ctx.reports,
        exportData: _ctx.exportReports,
        loading: _ctx.loading,
        reportType: _ctx.reportType,
        pagination: _ctx.pagination,
        tableFields: _ctx.tableFields,
        scrollable: true,
        exportFields: [
        { id: 'driverId', name: 'Driver ID' },
        { id: 'username', name: 'Driver Username' },
        { id: 'driverFullName', name: 'Driver Full Name' },
        { id: 'creationDate', name: 'Date' },
        { id: 'darkstoreName', name: 'Darkstore' },
        { id: 'shiftName', name: 'Shift Name' },
        { id: 'shiftTimeFrom', name: 'Shift Time From' },
        { id: 'shiftTimeTo', name: 'Shift Time To' },
        { id: 'overtime', name: 'Overtime' },
        { id: 'belowTime', name: 'Below time' },
        { id: 'deliveredOrders', name: 'Delivered' },
        { id: 'returnedOrders', name: 'Returned' },
        { id: 'totalOrders', name: 'Total' },
        { id: 'trackingId', name: 'Tracking Id' }
      ],
        buttons: { edit: true, delete: true, export: true},
        onSort: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clickSort($event))),
        onMenuEvent: _cache[4] || (_cache[4] = ($event: any) => (_ctx.menuEvent($event)))
      }, null, 8, ["tableData", "exportData", "loading", "reportType", "pagination", "tableFields"])
    ])
  ], 64))
}