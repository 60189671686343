
import { defineComponent } from 'vue'
export default defineComponent({
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    isSuperAdmin (): boolean {
      return this.$store.getters.isSuperAdmin
    }
  }
})
