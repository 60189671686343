<template>
  <div class="text-xs dark:text-white text-left">{{ title }}</div>
  <div class="flex">
    <div class="w-full bg-gray-200 rounded-full h-1.5 mb-1 dark:bg-gray-700 mt-1">
      <div class="h-1.5 rounded-full" :style="percentValue" :class="color" />
    </div>
    <div class="w-25 text-right">{{ orderCount }}/{{totalOrderCount}}</div>
  </div>
</template>

<script>
export default {
  name: 'DarkstoreInfoWindowOrderInfoBar',
  props: {
    totalOrderCount: {
      type: Number,
      default: 0
    },
    orderCount: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'dark:bg-blue-500'
    }
  },
  computed: {
    percentValue () {
      const percentage = (this.orderCount / this.totalOrderCount) * 100
      return `width: ${Math.round(percentage)}%`
    }
  }
}
</script>
