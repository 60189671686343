import { ActionTree } from 'vuex'
import { DriversState } from '@/store/modules/drivers/types'
import { RootState } from '@/store/types'
import { apiEndpoints, locationsApiBase } from '@/shared/endpoints/api'
import { DriversMutations } from '@/store/modules/drivers/mutations'
import { DriversModel } from '@/shared/models/drivers.model'
import { DriverInfoModel } from '@/shared/models/driver-info.model'
import { OrderModel } from '@/shared/models/order.model'
import { useToast } from 'vue-toastification'
import moment from 'moment'
import { AvailableDriversModel } from '@/shared/models/available-drivers.model'
import axiosInstance from '@/shared/utils/axiosInstance'
import { DriversAutocompleteModel } from '@/shared/models/DriversAutocomplete.model'

export enum DriverActions {
  FETCH_DRIVERS = 'FETCH_DRIVERS',
  FETCH_DRIVERS_AUTOCOMPLETE = 'FETCH_DRIVERS_AUTOCOMPLETE',
  FETCH_DRIVERS_LOCATIONS = 'FETCH_DRIVERS_LOCATIONS',
  FETCH_AVAILABLE_DRIVERS = 'FETCH_AVAILABLE_DRIVERS',
  FETCH_SINGLE_DRIVER = 'FETCH_SINGLE_DRIVER',
  ASSIGN_VEHICLE = 'ASSIGN_VEHICLE',
  UPDATE_DRIVER = 'UPDATE_DRIVER',
  UNASSIGN_VEHICLE = 'UNASSIGN_VEHICLE',
  INVITE_DRIVER = 'INVITE_DRIVER',
  FETCH_CURRENT_ORDER = 'FETCH_CURRENT_ORDER',
  FETCH_TODAYS_ORDERS = 'FETCH_TODAYS_ORDERS',
  ASSIGN_SHIFT = 'ASSIGN_SHIFT',
  FETCH_DRIVER_STATUSES = 'FETCH_DRIVER_STATUSES',
  UPDATE_STATUS = 'UPDATE_STATUS',
  FETCH_LAST_LOCATION = 'FETCH_LAST_LOCATION',
  FETCH_DRIVER_CURRENT_LOCATION = 'FETCH_DRIVER_CURRENT_LOCATION',
  CLOCKED_IN_LOCATIONS = 'CLOCKED_IN_LOCATIONS',
  FORCE_CLOCKOUT = 'FORCE_CLOCKOUT',
  DRIVER_ORDERS = 'DRIVER_ORDERS',
  FORCE_TO_START_DELIVERY = 'FORCE_TO_START_DELIVERY',
  FETCH_DARKSTORE_CLOCKED_IN_DRIVERS = 'FETCH_DARKSTORE_CLOCKED_IN_DRIVERS',
  FETCH_TRIP_LOCATIONS = 'FETCH_TRIP_LOCATIONS',
  SET_DRIVERS_LOCATIONS_CACHE = 'SET_DRIVERS_LOCATIONS_CACHE',
  RE_TRY_ERP_SYNC = 'RE_TRY_ERP_SYNC'
}

export const actions: ActionTree<DriversState, RootState> = {
  [DriverActions.FETCH_DRIVER_CURRENT_LOCATION]: async (context, payload) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.driver.currentLocation.method,
        url: apiEndpoints.driver.currentLocation.url + payload.driverId
      }).then(resp => {
        if (resp.data?.data) {
          resolve(resp.data.data)
        }
      }).catch(err => {
        throw new Error(err)
        reject(err)
      })
    })
  },
  [DriverActions.CLOCKED_IN_LOCATIONS]: async (context, payload) => {
    const params = new URLSearchParams()
    if (payload.search && payload.search !== '') {
      params.append('search', payload.search)
    }
    if (payload.driverStatusIds && payload.driverStatusIds.split(':')[1] !== '') {
      payload.driverStatusIds.split(':')[1].split(',').forEach((el: any) => {
        params.append('driver_status_ids', el)
      })
    }
    if (payload.vehicleTypeId) {
      params.append('vehicle_type_id', payload.vehicleTypeId)
    }
    if (payload.darkstoreIds && payload.darkstoreIds.split(':')[1] !== '') {
      payload.darkstoreIds.split(':')[1].split(',').forEach((el: any) => {
        params.append('darkstore_ids', el)
      })
    }
    if (payload.isClockedIn === '1') {
      params.append('is_clocked_in', '1')
    }
    if (payload.isClockedIn === '2') {
      params.append('is_clocked_in', '0')
    }
    if (payload.isActive !== null && payload.isActive !== undefined) {
      params.append('is_active', payload.isActive)
    }
    if (payload.statusId) {
      if (Array.isArray(payload.statusId)) {
        payload.statusId.forEach((el: number) => {
          params.append('driver_status_ids', el.toString())
        })
      } else {
        params.append('driver_status_ids', payload.statusId)
      }
    }
    await axiosInstance({
      method: apiEndpoints.driver.listLocations.method,
      url: apiEndpoints.driver.listLocations.url,
      params
    }).then(resp => {
      if (resp?.data?.data) {
        context.commit(DriversMutations.SET_CLOCKED_IN_LOCATIONS, resp.data.data.page_records)
      }
    }).catch(err => {
      throw new Error(err)
    })
  },
  [DriverActions.FETCH_DRIVERS]: (context, payload) => {
    context.commit(DriversMutations.LOADING_EVENT, true)
    const params = new URLSearchParams()
    params.append('page_number', payload.pageNumber)
    params.append('per_page', payload.pageSize)

    if (payload.isActive !== null && payload.isActive !== undefined) {
      params.append('is_active', payload.isActive)
    }
    if (payload.joiningDate_from) {
      params.append('from_date', moment(payload.joiningDate_from).format('YYYY-MM-DD hh:mm:ss'))
      params.append('to_date', moment(payload.joiningDate_to).format('YYYY-MM-DD hh:mm:ss'))
    }
    if (payload.sort) {
      // params.append('sort', payload.sort)
      params.append('order_by', `${payload.orderBy}_${payload.sort}`)
    } else {
      params.append('order_by', 'created_at_asc')
    }
    // eslint-disable-next-line no-prototype-builtins
    if (payload.hasOwnProperty('hasVehicle')) {
      params.append('has_vehicle', payload.hasVehicle)
    }
    if (payload.isClockedIn === '1') {
      params.append('is_clocked_in', '1')
    }
    // workaround solution for is_clocked_in = 0 since backend doesn't work for 0 value
    if (payload.isClockedIn === '2') {
      params.append('is_clocked_out', '1')
    }
    if (payload.darkstoreIds && payload.darkstoreIds.split(':')[1] !== '') {
      payload.darkstoreIds.split(':')[1].split(',').forEach((el: any) => {
        params.append('dark_store_ids', el)
      })
    }
    if (payload.driverStatusIds && payload.driverStatusIds.split(':')[1] !== '') {
      payload.driverStatusIds.split(':')[1].split(',').forEach((el: any) => {
        params.append('driver_status_ids', el)
      })
    }
    if (payload.vehicleTypeId) {
      params.append('vehicle_type_id', payload.vehicleTypeId)
    }
    if (payload.statusId) {
      if (Array.isArray(payload.statusId)) {
        payload.statusId.forEach((el: number) => {
          params.append('driver_status_ids', el.toString())
        })
      } else {
        params.append('driver_status_ids', payload.statusId)
      }
    }
    if (payload.search && payload.search !== '') {
      params.append('search', payload.search)
    }
    if (payload.firstName && payload.firstName !== '') {
      params.append('first_name', payload.firstName)
    }
    if (payload.lastName && payload.lastName !== '') {
      params.append('last_name', payload.lastName)
    }
    if (payload.shiftId) {
      params.append('shift_ids', payload.shiftId)
    }
    if (payload.notInShiftIds) {
      payload.notInShiftIds.forEach((el: number) => {
        params.append('not_in_shift_ids', el.toString())
      })
    }
    if (payload.balance) {
      const conditionObject = JSON.parse(decodeURIComponent(payload.balance))
      if (conditionObject.condition === 'lowerThan') {
        params.append('balance_lt', conditionObject.value)
      }
      if (conditionObject.condition === 'greaterThan') {
        params.append('balance_gt', conditionObject.value)
      }
    }
    if (payload.orderBy) {
      params.append('order_by', payload.orderBy)
    }
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.driver.list.method,
        url: apiEndpoints.driver.list.url,
        params
      }).then(resp => {
        context.commit(DriversMutations.LOADING_EVENT, false)
        resolve(resp)
        const driverModel = new DriversModel(resp.data)
        const payload = {
          data: driverModel.data,
          pagination: driverModel.paginationData
        }
        context.commit(DriversMutations.INSERT_DRIVERS, payload)
      }).catch(err => {
        reject(err)
        context.commit(DriversMutations.LOADING_EVENT, false)
        throw new Error(err)
      })
    })
  },
  [DriverActions.FETCH_DRIVERS_AUTOCOMPLETE]: (context, payload) => {
    context.commit(DriversMutations.LOADING_EVENT, true)
    const params = new URLSearchParams()
    params.append('page_number', payload.pageNumber)
    params.append('per_page', payload.pageSize)
    if (payload.search && payload.search !== '') {
      params.append('query', payload.search)
    }
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.driver.listAutocomplete.method,
        url: apiEndpoints.driver.listAutocomplete.url,
        params
      }).then(resp => {
        context.commit(DriversMutations.LOADING_EVENT, false)
        resolve(resp)
        const driverModel = new DriversAutocompleteModel(resp.data)
        const payload = {
          data: driverModel.data,
          pagination: driverModel.paginationData
        }
        context.commit(DriversMutations.INSERT_DRIVERS_AUTOCOMPLETE, payload)
      }).catch(err => {
        reject(err)
        context.commit(DriversMutations.LOADING_EVENT, false)
        throw new Error(err)
      })
    })
  },

  [DriverActions.UPDATE_DRIVER]: (context, payload) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.driver.updateDriver.method,
        url: apiEndpoints.driver.updateDriver.url + payload.driverId,
        data: payload.data
      }).then(resp => {
        resolve(resp)
        context.commit(DriversMutations.UPDATE_DRIVER_DETAILS, payload.data)
      }).catch(err => {
        reject(err)
        throw new Error(err)
      })
    })
  },

  [DriverActions.FETCH_AVAILABLE_DRIVERS]: async (context, payload) => {
    const params = new URLSearchParams()
    params.append('order_id', payload.orderId)
    await axiosInstance({
      method: apiEndpoints.driver.availableDrivers.method,
      url: apiEndpoints.driver.availableDrivers.url,
      params
    }).then(resp => {
      const driverModel = new AvailableDriversModel(resp.data)
      const payload = {
        data: driverModel.data,
        pagination: driverModel.paginationData
      }
      context.commit(DriversMutations.INSERT_AVAILABLE_DRIVERS, payload)
    }).catch(err => {
      throw new Error(err)
    })
  },

  [DriverActions.FETCH_SINGLE_DRIVER]: (context, payload) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.driver.singleDriver.method,
        url: apiEndpoints.driver.singleDriver.url + payload
      }).then(resp => {
        const driverModel = new DriverInfoModel(resp.data)
        resolve(driverModel.data)
        context.commit(DriversMutations.SET_DRIVER, driverModel.data)
      }).catch(err => {
        throw new Error(err)
      })
    })
  },

  [DriverActions.FETCH_DRIVER_STATUSES]: async (context, payload) => {
    await axiosInstance({
      method: apiEndpoints.driver.statuses.method,
      url: apiEndpoints.driver.statuses.url
    }).then(resp => {
      context.commit(DriversMutations.SET_STATUSES, resp.data.data)
    }).catch(err => {
      throw new Error(err)
    })
  },

  [DriverActions.ASSIGN_VEHICLE]: (context, payload) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.driver.assignVehicle.method,
        url: apiEndpoints.driver.assignVehicle.url + payload.driverId,
        data: { vehicle_id: payload.vehicleId }
      }).then((res: any) => {
        const toast = useToast()
        toast.success('success', {
          timeout: 3000
        })
        resolve(res)
      }).catch(err => {
        throw new Error(err)
      })
    })
  },

  [DriverActions.UNASSIGN_VEHICLE]: async (context, payload) => {
    await axiosInstance({
      method: apiEndpoints.vehicle.unassignVehicle.method,
      url: apiEndpoints.vehicle.unassignVehicle.url + payload.vehicleId,
      data: { driver_id: payload.driverId }
    }).then(() => {
      const toast = useToast()
      toast.success('success', {
        timeout: 3000
      })
    }).catch(err => {
      throw new Error(err)
    })
  },

  [DriverActions.ASSIGN_SHIFT]: async (context, payload) => {
    context.commit(DriversMutations.LOADING_EVENT, true)
    await axiosInstance({
      method: apiEndpoints.driver.assignShift.method,
      url: `${apiEndpoints.driver.assignShift.url}${payload.driverId}`,
      data: {
        shift_id: payload.shiftId,
        dark_store_id: payload.darkstoreId
      }
    }).then(resp => {
      context.commit(DriversMutations.LOADING_EVENT, false)
    }).catch(err => {
      context.commit(DriversMutations.LOADING_EVENT, false)
      throw new Error(err)
    })
  },

  [DriverActions.INVITE_DRIVER]: (context, payload) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.users.invite.method,
        url: apiEndpoints.users.invite.url,
        data: payload
      }).then((res: any) => {
        resolve(res.data.data.invite_link)
        // const toast = useToast()
        // toast.success(res.data?.info?.message, {
        //   timeout: 3000
        // })
      }).catch(err => {
        throw new Error(err)
      })
    })
  },
  [DriverActions.FETCH_CURRENT_ORDER]: async (context, payload) => {
    const params = { driver_ids: payload.driverId, current_assigned: 1 }
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.order.list.method,
        url: apiEndpoints.order.list.url,
        params
      }).then(res => {
        const order = new OrderModel(res.data)
        context.commit(DriversMutations.SET_CURRENT_ORDER, { order: order.data })
        resolve(order.data)
      }).catch(err => {
        throw new Error(err)
      })
    })
  },

  [DriverActions.UPDATE_STATUS]: async (context, payload) => {
    await axiosInstance({
      method: apiEndpoints.driver.updateStatus.method,
      url: apiEndpoints.driver.updateStatus.url + payload.driverId,
      data: { driver_status_id: payload.statusId }
    }).then((res: any) => {
      const toast = useToast()
      toast.success(res.data?.info?.message, {
        timeout: 3000
      })
    }).catch(err => {
      throw new Error(err)
    })
  },

  [DriverActions.FORCE_CLOCKOUT]: async (context, payload) => {
    await axiosInstance({
      method: apiEndpoints.driver.forceClockout.method,
      url: apiEndpoints.driver.forceClockout.url + payload.driverId,
      params: { is_manual: payload.is_manual }
    }).then((res: any) => {
      const toast = useToast()
      toast.success(res.data?.info?.message, {
        timeout: 3000
      })
    }).catch(err => {
      throw new Error(err)
    })
  },

  [DriverActions.FETCH_LAST_LOCATION]: async (context, payload) => {
    context.commit(DriversMutations.LAST_LOCATION_LOADING, true)
    await axiosInstance({
      method: apiEndpoints.driver.lastLocation.method,
      url: apiEndpoints.driver.lastLocation.url,
      params: { driver_ids: payload.driverId }
    }).then((res: any) => {
      context.commit(DriversMutations.LAST_LOCATION_LOADING, false)
      context.commit(DriversMutations.SET_LAST_LOCATION, res)
    }).catch(err => {
      context.commit(DriversMutations.LAST_LOCATION_LOADING, false)
      throw new Error(err)
    })
  },

  [DriverActions.DRIVER_ORDERS]: async (context, driverId) => {
    return new Promise((resolve) => {
      axiosInstance({
        ...apiEndpoints.driver.driverOrders(driverId)
      }).then(resp => {
        if (resp.data?.data) {
          resolve(resp.data.data)
        }
      }).catch(err => {
        throw new Error(err)
      })
    })
  },

  [DriverActions.FORCE_TO_START_DELIVERY]: async (context, driverId) => {
    return new Promise((resolve) => {
      axiosInstance({
        ...apiEndpoints.driver.forceToDelivery(driverId)
      }).then(resp => {
        if (resp.data?.data) {
          const toast = useToast()
          toast.success('Delivery request has been sent successfully', {
            timeout: 3000
          })
          resolve(resp.data.data)
        }
      }).catch(err => {
        throw new Error(err)
      })
    })
  },

  [DriverActions.FETCH_DARKSTORE_CLOCKED_IN_DRIVERS]: async (context, darkstoreId) => {
    const params = new URLSearchParams()
    params.append('dark_store_ids', darkstoreId)
    params.append('per_page', '10000')
    params.append('page', '1')
    params.append('is_clocked_in', '1')
    return new Promise((resolve) => {
      axiosInstance({
        ...apiEndpoints.driver.list,
        params
      }).then(resp => {
        if (resp) {
          resolve(resp)
        }
      }).catch(err => {
        throw new Error(err)
      })
    })
  },

  [DriverActions.FETCH_TRIP_LOCATIONS]: async (context, payload) => {
    context.commit(DriversMutations.TRIP_LOCATION_LOADING, true)
    await axiosInstance({
      baseURL: locationsApiBase,
      method: apiEndpoints.driver.tripLocations.method,
      url: apiEndpoints.driver.tripLocations.url,
      params: {
        driver_id: payload.driverId,
        from_datetime: moment(payload.dateRange[0]).format('YYYY-MM-DDTHH:mm:ss'),
        to_datetime: moment(payload.dateRange[1]).format('YYYY-MM-DDTHH:mm:ss')
      }
    }).then((res: any) => {
      context.commit(DriversMutations.TRIP_LOCATION_LOADING, false)
      context.commit(DriversMutations.SET_TRIP_LOCATION, res.data.data)
    }).catch(err => {
      context.commit(DriversMutations.TRIP_LOCATION_LOADING, false)
      throw new Error(err)
    })
  },

  [DriverActions.SET_DRIVERS_LOCATIONS_CACHE]: async (context, payload) => {
    context.commit(DriversMutations.SET_DRIVERS_MARKERS_CACHE, payload)
  },

  [DriverActions.RE_TRY_ERP_SYNC]: (context, { driverId }) => {
    return new Promise((resolve, reject) => {
      const toast = useToast()
      axiosInstance({
        ...apiEndpoints.driver.reTryErpSync(driverId)
      }).then((res: any) => {
        toast.success('success', {
          timeout: 3000
        })
        resolve(res)
      }).catch(err => {
        reject(err)
        throw new Error(err)
      })
    })
  }
}
