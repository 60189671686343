
import { defineComponent } from 'vue'
const wdw: any = window

export default defineComponent({
  name: 'CreateOrdersMap',
  emits: ['onLocationSelect'],
  data () {
    return {
      id: 'create-order-location-select-map',
      map: {} as any,
      marker: null as any
    }
  },
  mounted () {
    this.initMap()
  },
  methods: {
    initMap () {
      if (wdw.google) {
        const latitude = 33.312805
        const longitude = 44.361488
        this.map = new wdw.google.maps.Map(document.getElementById(this.id), {
          center: { lat: latitude, lng: longitude },
          zoom: 13,
          mapTypeControl: false,
          streetViewControl: false,
          disableDefaultUI: false
        })

        this.map.addListener('idle', () => {
          if (!this.marker) {
            this.placeMarker(this.map.getCenter())
          }
        })
      } else {
        setTimeout(() => {
          this.initMap()
        }, 500)
      }
    },
    placeMarker (location: any) {
      if (this.marker) {
        this.marker.setPosition(location)
      } else {
        this.marker = new wdw.google.maps.Marker({
          position: location,
          map: this.map,
          draggable: true
        })

        this.marker.addListener('dragend', (event: any) => {
          this.emitLocation(event.latLng)
        })
      }
      this.emitLocation(location)
    },
    emitLocation (location: any) {
      this.$emit('onLocationSelect', {
        lat: location.lat(),
        lng: location.lng()
      })
    }
  }
})
