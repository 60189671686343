import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { SettingsState } from '@/store/modules/settings/types'

export const getters: GetterTree<SettingsState, RootState> = {
  getSettings (state): any[] {
    return state.settings
  },
  getPagination (state): any {
    return state.pagination
  },
  getLoading (state): boolean {
    return state.loading
  }
}
