import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { DriverProductivityInterface } from '@/shared/interfaces/reports/driver-productivity.interface'

export class DriverProductivityModel extends CommonModel {
  public data: DriverProductivityInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): DriverProductivityInterface {
    return {
      driverId: data.driver_id,
      driverUsername: data.driver_username,
      orderStatus: data.order_status,
      orderCount: data.order_count,
      trackingId: data.tracking_id,
      driverFullName: data.driver_full_name
    }
  }
}
