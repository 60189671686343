import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center mb-4 font-bold text-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_echarts = _resolveComponent("vue-echarts")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('reports.darkStoreOrderPeakHours.subTitle')), 1),
    _createVNode(_component_vue_echarts, {
      style: {"height":"400px"},
      option: _ctx.chartOption
    }, null, 8, ["option"])
  ]))
}