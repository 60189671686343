import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { DarkStoreInterface } from '@/shared/interfaces/darkstore.interface'
import { DarkStoresState } from '@/store/modules/darkstores/types'

export const getters: GetterTree<DarkStoresState, RootState> = {
  getDarkStores (state): DarkStoreInterface[] {
    return state.darkstores
  },
  getPagination (state): any {
    return state.pagination
  },
  getDarkStoresAsDictionary (state): any[] {
    return state.darkstores?.map(el => {
      return { id: el.id, value: el.name }
    })
  },
  getLoading (state): boolean {
    return state.loading
  }
}
