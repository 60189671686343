<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#1c1c1c" height="800px" width="800px" version="1.1" id="Capa_1" viewBox="0 0 488.437 488.437" xml:space="preserve">
  <g>
    <path d="M453.664,416.988V192.532h-0.016c-12.711,0-24.756-4.15-34.783-11.155c-5.221,3.674-11.188,6.355-17.551,8.268V323.08   v9.917c0,10.434-8.457,18.891-18.883,18.891H106.006c-10.425,0-18.883-8.457-18.883-18.891v-9.917V189.645   c-6.378-1.912-12.328-4.586-17.549-8.259c-10.029,6.997-22.072,11.147-34.785,11.147h-0.014v224.456   c-8.982,0-16.25,7.275-16.25,16.249c0,8.973,7.268,16.249,16.25,16.249h52.348v18.55c0,10.425,8.458,18.883,18.883,18.883h14.6   c10.426,0,18.884-8.458,18.884-18.883v-18.55h209.46v18.55c0,10.425,8.457,18.883,18.883,18.883h14.6   c10.426,0,18.883-8.458,18.883-18.883v-18.55h52.35c8.982,0,16.25-7.276,16.25-16.249   C469.914,424.263,462.645,416.988,453.664,416.988z M401.314,399.62H87.123c-4.489,0-8.125-3.634-8.125-8.125   c0-4.489,3.636-8.124,8.125-8.124h314.191c4.49,0,8.125,3.635,8.125,8.124C409.439,395.986,405.805,399.62,401.314,399.62z"/>
    <path d="M488.065,126.433H0.372c-2.476,16.86,7.601,33.428,24.405,38.45c3.332,0.991,6.695,1.467,10.012,1.467   c15.027,0,28.912-9.791,33.437-24.929l1.824-6.11c1.253,11.124,7.759,21.01,17.455,26.5c3.126,1.77,6.537,3.118,10.25,3.856   c2.301,0.46,4.602,0.683,6.855,0.683c16.313,0,30.896-11.497,34.196-28.103l0.856-4.348c0.841,11.878,7.712,22.255,17.789,27.913   c4.046,2.276,8.552,3.864,13.486,4.356c1.175,0.119,2.35,0.174,3.509,0.174c17.708,0,32.895-13.441,34.688-31.452l0.254-2.609   c0.317,12.718,7.474,23.636,17.883,29.49c5.03,2.826,10.758,4.571,16.947,4.571c6.189,0,11.917-1.745,16.948-4.577   c10.409-5.848,17.566-16.766,17.883-29.484l0.254,2.609c1.792,18.01,16.963,31.452,34.688,31.452c1.157,0,2.332-0.055,3.507-0.174   c4.918-0.492,9.44-2.072,13.488-4.348c10.076-5.658,16.946-16.042,17.788-27.921l0.855,4.348   c3.301,16.606,17.885,28.103,34.197,28.103c2.253,0,4.555-0.223,6.855-0.683c3.713-0.738,7.125-2.086,10.234-3.848   c9.711-5.499,16.217-15.385,17.471-26.509l1.825,6.11c4.523,15.138,18.407,24.929,33.435,24.929c3.316,0,6.68-0.476,10.012-1.467   C480.466,159.86,490.541,143.292,488.065,126.433z"/>
    <path d="M460.344,31.803c-5.363-17.97-21.898-30.285-40.654-30.285H68.748c-18.756,0-35.291,12.315-40.654,30.285L4.688,110.183   H483.75L460.344,31.803z"/>
    <path d="M379.31,278.721v-89.076H109.127v89.076h129.592V290.6c-9.479,2.552-16.478,11.218-16.478,21.492   c0,12.272,9.984,22.257,22.257,22.257c12.272,0,22.257-9.985,22.257-22.257c0-10.475-7.275-19.276-17.036-21.633v-11.738H379.31z    M257.175,312.092c0,6.99-5.687,12.677-12.677,12.677s-12.677-5.687-12.677-12.677c0-6.99,5.687-12.677,12.677-12.677   S257.175,305.102,257.175,312.092z"/>
  </g>
  </svg>
</template>
