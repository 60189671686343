
import { defineAsyncComponent, defineComponent } from 'vue'
import { DriverActions } from '@/store/modules/drivers/actions'
import { Order } from '@/shared/interfaces/order.interface'
import { Driver } from '@/shared/interfaces/driver.interface'
import { OrderActions } from '@/store/modules/orders/actions'

export default defineComponent({
  name: 'CurrentStatus',
  data () {
    return {
      driverId: this.$route.params.id
    }
  },
  components: {
    DriverMap: defineAsyncComponent(() => import('@/views/account/drivers/components/DriverMap.vue')),
    OrderDetails: defineAsyncComponent(() => import('@/views/account/drivers/components/OrderDetails.vue'))
  },
  mounted () {
    this.$store.dispatch(`drivers/${[DriverActions.FETCH_CURRENT_ORDER]}`, { driverId: this.driverId })
    this.$store.dispatch(`orders/${OrderActions.FETCH_ACCEPTED_ORDERS}`, this.$route.params.id)
  },
  computed: {
    currentOrder (): Order | null {
      return this.$store.getters['drivers/getCurrentOrder']
    },
    driver (): Driver {
      const dr = this.$store.getters['drivers/getDriver']
      return dr
    },
    acceptedOrdersCount (): number {
      return this.$store.getters['orders/getAcceptedOrdersCount']
    }
    // lastClockIn (): string {
    //   return `${formatDate(this.driver.driverInfo.lastLogin, 'DD MMM YYYY')} - ${formatDate(this.driver.driverInfo.lastLogin, 'hh:mm A')}`
    // }
  }
})
