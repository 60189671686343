
import { defineComponent, defineAsyncComponent } from 'vue'
import router from '@/router'
import { OrderActions } from '@/store/modules/orders/actions'
import { VehiclesActions } from '@/store/modules/vehicles/actions'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
const wdw: any = window
export default defineComponent({
  components: {
    BaseModal: defineAsyncComponent(() => import('@/shared/components/modals/BaseModal.vue')),
    SearchInput: defineAsyncComponent(() => import('@/shared/components/SearchInput.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    DynamicTable: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTable.vue'))
  },
  data () {
    // deliveryDuration: number

    return {
      map: {} as any,
      showLocationModal: false,
      showFilterModal: false,
      tableFields: [
        { id: 'driverId', name: 'Driver ID', width: '18%', sortable: false },
        { id: 'driverName', name: 'Driver Name', width: '18%', sortable: false },
        { id: 'driverFullName', name: 'Driver Full Name', width: '18%', sortable: false },
        { id: 'createdAt', name: 'Date and time', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'created_at' },
        { id: 'darkstoreName', name: 'Darkstore', width: '18%', sortable: false },
        { id: 'shiftName', name: 'Shift Name', width: '18%', sortable: false },
        { id: 'shiftStartTime', name: 'Shift Time From', width: '18%', sortable: false },
        { id: 'shiftEndTime', name: 'Shift Time To', width: '18%', sortable: false },
        { id: 'orderId', name: 'Order', width: '18%', sortable: false },
        { id: 'orderTotal', name: 'Order Total', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'order_total' },
        { id: 'distance', name: 'Distance', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'distance' },
        { id: 'assignedTime', name: 'Assigned time', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'assigned_time' },
        { id: 'orderStatus', name: 'order Status', width: '18%', sortable: false },
        { id: 'readyForPickupTime', name: 'Time (Ready to Pickup)', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'ready_for_pickup_time' },
        { id: 'deliveryDuration', name: 'Time (Delivery Duration)', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'delivery_duration' },
        { id: 'driverReturnDatetime', name: 'Driver Return Datetime', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'driver_return_datetime' },
        { id: 'driverReturnDuration', name: 'Driver Return Duration', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'driver_return_duration' },
        { id: 'locations', name: 'Location', width: '18%', sortable: false, type: 'location' },
        { id: 'trackingId', name: 'Tracking Id', width: '18%', sortable: false }
      ],
      filters: [
        { name: this.$t('filters.orderId'), model: 'orderId', type: 'text' },
        {
          name: this.$t('filters.orderStatus'),
          model: 'orderStatusId',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'orderStatus',
          type: 'radio',
          data: []
        },
        {
          name: this.$t('filters.darkstores'),
          model: 'darkstore_Ids',
          type: 'checkbox',
          data: []
        },
        { name: this.$t('filters.createdAt'), model: 'createdAt', type: 'date', calendarType: 'range' },
        { name: this.$t('filters.shiftStart'), model: 'shiftStartTime', type: 'date', calendarType: 'time' },
        { name: this.$t('filters.shiftEnd'), model: 'shiftEndTime', type: 'date', calendarType: 'time' },
        {
          name: this.$t('filters.minDistance'),
          model: 'minDistance',
          type: 'number'
        },
        {
          name: this.$t('filters.maxDistance'),
          model: 'maxDistance',
          type: 'number'
        },
        {
          name: this.$t('filters.minOrderTotal'),
          model: 'minOrderTotal',
          type: 'number'
        },
        {
          name: this.$t('filters.maxOrderTotal'),
          model: 'maxOrderTotal',
          type: 'number'
        },
        {
          name: this.$t('filters.vehicleType'),
          model: 'vehicleTypeId',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'vehicleTypes',
          type: 'radio',
          data: []
        },
        { name: this.$t('filters.trackingId'), model: 'trackingId', type: 'number' }
      ],
      selectedReport: this.reportType,
      searchValue: null as any
    }
  },
  mounted (): void {
    this.$store.dispatch(`darkstores/${DarkStoresActions.FETCH_DARKSTORES}`, { pageNUmber: 1, pageSize: 100 })
  },
  methods: {
    locationClick (event: any): void {
      const darkstore = this.darkstores.find((el: any) => el.name === event.darkstoreName)
      this.showLocationModal = true
      setTimeout(() => {
        const latitude = 33.312805
        const longitude = 44.361488
        const markers = []
        this.map = new wdw.google.maps.Map(
          document.getElementById('report-location-map'),
          {
            center: { lat: latitude, lng: longitude },
            zoom: 15,
            mapTypeControl: false,
            streetViewControl: false,
            disableDefaultUI: false
          }
        )
        const position = new wdw.google.maps.LatLng(event.locations.lat, event.locations.long)
        const image = {
          url: require('../../../../assets/vehicle_1_green.png'),
          size: new wdw.google.maps.Size(55, 70),
          origin: new wdw.google.maps.Point(0, 0),
          anchor: new wdw.google.maps.Point(22, 50)
        }
        const marker = new wdw.google.maps.Marker({
          position,
          map: this.map,
          icon: image
        })
        const center = new wdw.google.maps.LatLng(event.locations.lat, event.locations.long)
        this.map.setCenter(center)
        markers.push(marker)

        if (darkstore && darkstore.latitude && darkstore.longitude) {
          const dsPosition = new wdw.google.maps.LatLng(darkstore.latitude, darkstore.longitude)

          const dsImage = {
            url: require('../../../../assets/markers/darkstore-dark-marker.png'),
            size: new wdw.google.maps.Size(55, 70),
            origin: new wdw.google.maps.Point(0, 0),
            anchor: new wdw.google.maps.Point(22, 67)
          }
          const dsMarker = new wdw.google.maps.Marker({
            position: dsPosition,
            map: this.map,
            icon: dsImage
          })
          markers.push(dsMarker)
        }
        const markerCluster = new MarkerClusterer({ map: this.map, markers })
      }, 1000)
    },
    onExpandPanelClick (eventName: string): void {
      if (eventName === 'orderStatus') {
        this.$store.dispatch(`orders/${OrderActions.FETCH_ORDER_STATUSES}`)
      }
      if (eventName === 'vehicleTypes') {
        this.$store.dispatch(`vehicles/${[VehiclesActions.FETCH_VEHICLE_TYPES]}`)
      }
    },
    menuEvent (e: any): void {
      console.log(e)
    },
    clickSort (field: any): void {
      this.tableFields = this.tableFields?.map((el: any) => {
        if (el.id === field.id) {
          el.currentSort = true
          if (el.sort === null) {
            el.sort = 'asc'
          } else if (el.sort === 'asc') {
            el.sort = 'desc'
          } else if (el.sort === 'desc') {
            el.sort = null
          }
        } else {
          el.currentSort = false
          el.sort = null
        }
        return el
      })
      router.push({ query: { ...this.$route.query, orderBy: field.sortName, sort: field.sort } })
    }
  },
  watch: {
    darkstoresAsDictionary: {
      immediate: true,
      handler (val) {
        this.filters[2].data = val
      }
    },
    $route: {
      immediate: true,
      handler (val: any) {
        if (val.query.orderBy) {
          this.tableFields = this.tableFields.map((el: any) => {
            if (el.id === val.query.orderBy) {
              el.currentSort = true
              el.sort = val.query.sort
            }
            return el
          })
        }
      }
    },
    vehicleTypes: {
      immediate: true,
      handler (val) {
        if (!val.length) return
        const target = this.filters[10]
        target.dataRequested = true
        target.data = val
        target.dataLoading = false
      }
    },
    statuses (val: any) {
      if (!val.length) return
      const filterItems = val.map((el: any) => {
        return {
          id: el.id,
          label: el.value
        }
      })
      const target = this.filters[1]
      target.data = filterItems
      target.dataRequested = true
      target.dataLoading = false
    },
    selectedReport (val) {
      if (val) {
        router.push({ query: { reportName: val.name } })
      }
    },
    searchValue () {
      const queries = this.$route.query
      queries.pageNumber = '1'
      router.push({ query: { ...queries, username: this.searchValue } })
    }
  },
  props: {
    reportType: {
      type: String
    }
  },
  computed: {
    darkstores () {
      return this.$store.getters['darkstores/getDarkStores']
    },
    darkstoresAsDictionary () {
      return this.$store.getters['darkstores/getDarkStoresAsDictionary']
    },
    vehicleTypes (): any[] {
      return this.$store.getters['vehicles/getVehicleTypes']
        .map((el: any) => {
          return {
            id: el.id,
            label: el.value
          }
        })
    },
    reports () {
      return this.$store.getters['reports/getDriversDeliveryReports']
    },
    exportReports () {
      return this.$store.getters['reports/getDriversDeliveryExportReports']
    },
    pagination () {
      return this.$store.getters['reports/getDriversDeliveryPagination']
    },
    loading () {
      return this.$store.getters['reports/getLoading']
    },
    statuses (): any {
      return this.$store.getters['orders/getOrderStatuses']
    }
  }
})
