import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { WorkShiftsState } from '@/store/modules/work-shifts/types'

const state: WorkShiftsState = {
  workShifts: [],
  userRequests: [],
  userExportRequests: [],
  pagination: null,
  loading: false,
  requestLoading: false
}

export const workShifts: Module<WorkShiftsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
