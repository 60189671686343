import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "relative mt-24" }
const _hoisted_2 = { class: "bg-white rounded mt-2 px-6 pb-6 pt-4 shadow-mainShadow text-start overflow-hidden w-412 mx-220 relative" }
const _hoisted_3 = { class: "flex justify-between items-center border-b pb-3" }
const _hoisted_4 = { class: "text-black font-bold text-lg" }
const _hoisted_5 = {
  key: 0,
  class: "relative w-full"
}
const _hoisted_6 = {
  key: 0,
  class: "form-error"
}
const _hoisted_7 = {
  key: 1,
  class: "relative w-full"
}
const _hoisted_8 = {
  key: 0,
  class: "form-error"
}
const _hoisted_9 = { class: "relative w-full" }
const _hoisted_10 = {
  key: 0,
  class: "form-error"
}
const _hoisted_11 = { class: "relative w-full mt-4" }
const _hoisted_12 = {
  key: 0,
  class: "form-error"
}
const _hoisted_13 = { class: "flex justify-end items-center gap-x-6 mt-4" }
const _hoisted_14 = {
  type: "submit",
  class: "primary py-3 px-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_DarkstoreSelect = _resolveComponent("DarkstoreSelect")!
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('bulkOrderCreateModal.title')), 1),
        _createElementVNode("div", {
          role: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createVNode(_component_BaseIcon, { name: "close" })
        ])
      ]),
      _createElementVNode("form", {
        onSubmit: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.submitForm(_ctx.v$)), ["prevent"])),
        class: "w-full mt-6 flex flex-col gap-6"
      }, [
        (!_ctx.isClientAdmin)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("label", {
                class: _normalizeClass([{error: _ctx.v$.form.dark_store.$error}, "text-xs mb-1"])
              }, _toDisplayString(_ctx.$t('orderCreateModal.darkstore')), 3),
              _createVNode(_component_DarkstoreSelect, {
                modelValue: _ctx.form.dark_store,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.dark_store) = $event)),
                class: _normalizeClass({error: _ctx.v$.form.dark_store.$error})
              }, null, 8, ["modelValue", "class"]),
              (_ctx.v$.form.dark_store.$error)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('validation.required')), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isClientAdmin)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("label", {
                class: _normalizeClass([{error: _ctx.v$.form.erpnext_merchant.$error}, "text-xs mb-1"])
              }, _toDisplayString(_ctx.$t('orderCreateModal.erpnextMerchant')), 3),
              _createVNode(_component_Multiselect, {
                class: _normalizeClass(["w-full", {'error' : _ctx.v$.form.erpnext_merchant.$error}]),
                canDeselect: false,
                modelValue: _ctx.form.erpnext_merchant,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.erpnext_merchant) = $event)),
                options: _ctx.merchants,
                canClear: false,
                noOptionsText: _ctx.$t('validation.noItemsToShow')
              }, null, 8, ["modelValue", "options", "noOptionsText", "class"]),
              (_ctx.v$.form.erpnext_merchant.$error)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('validation.required')), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", {
            class: _normalizeClass([{error: _ctx.v$.form.order_status.$error}, "text-xs mb-1"])
          }, _toDisplayString(_ctx.$t('filters.orderStatus')), 3),
          _createVNode(_component_Multiselect, {
            class: _normalizeClass(["w-full", {'error' : _ctx.v$.form.order_status.$error}]),
            canDeselect: false,
            modelValue: _ctx.form.order_status,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.order_status) = $event)),
            options: _ctx.statuses,
            canClear: false,
            label: "label",
            valueProp: "value",
            object: "true",
            noOptionsText: _ctx.$t('validation.noItemsToShow')
          }, null, 8, ["modelValue", "options", "noOptionsText", "class"]),
          (_ctx.v$.form.order_status.$error)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t('validation.required')), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("label", {
            for: "file",
            class: _normalizeClass([{error: _ctx.v$.form.file.$error}, "text-xs mb-1 border p-3 bg-gray-100 border-dashed border-gray-400 w-full flex flex-1 justify-center rounded-md"])
          }, _toDisplayString(_ctx.form.file ? _ctx.form.file.name : _ctx.$t('bulkOrderCreateModal.file')), 3),
          _createElementVNode("input", {
            name: "file",
            id: "file",
            type: "file",
            class: "h-42 form-control hidden",
            accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
            onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.v$.file.$touch && _ctx.v$.file.$touch(...args))),
            onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onFileSelected && _ctx.onFileSelected(...args)))
          }, null, 32),
          (_ctx.v$.form.file.$error)
            ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t('validation.required')), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('close'))),
            class: "danger-btn py-3 px-6"
          }, _toDisplayString(_ctx.$t('buttons.close')), 1),
          _createElementVNode("button", _hoisted_14, _toDisplayString(_ctx.$t('create')), 1)
        ])
      ], 32)
    ])
  ]))
}