import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { ViewDriversTimingPerDayInterface } from '@/shared/interfaces/reports/view-drivers-timing-per-day.interface'
import moment from 'moment'

export class ViewDriversTimingPerDayModel extends CommonModel {
  public data: ViewDriversTimingPerDayInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      Object.assign(item, { date: moment({ year: item.Y, month: item.M - 1, day: item.D }).format('DD.MM.yyyy') })
      delete item.Y
      delete item.M
      delete item.D
      this.data = [...this.data, { ...item }]
    })
  }
}
