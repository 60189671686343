import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bdd41a24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "absolute inset-0 h-screen" }
const _hoisted_2 = { class: "bg-opacityBackground backdrop-filter backdrop-blur-6px z-10 fixed p-4 shadow rounded" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "flex items-center gap-x-1 py-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ZoneMap = _resolveComponent("ZoneMap")!
  const _component_PageTitle = _resolveComponent("PageTitle")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ZoneMap, {
        onDrawCompleted: _ctx.setPolygon,
        onPolygonDragged: _ctx.setPolygon,
        showAllZones: _ctx.showAllZones
      }, null, 8, ["onDrawCompleted", "onPolygonDragged", "showAllZones"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(_component_PageTitle, {
          title: "zones.add_new",
          class: "w-full"
        }),
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
            placeholder: _ctx.$t('zones.name_placeholder'),
            class: "h-42 bg-white input px-2"
          }, null, 8, _hoisted_4), [
            [_vModelText, _ctx.form.name]
          ]),
          _createElementVNode("button", {
            class: "primary h-10 px-5 ml-4",
            disabled: !_ctx.isValid,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onCreate && _ctx.onCreate(...args)))
          }, _toDisplayString(_ctx.$t('buttons.create')), 9, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showAllZones) = $event))
          }, null, 512), [
            [_vModelCheckbox, _ctx.showAllZones]
          ]),
          _createElementVNode("div", {
            role: "button",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showAllZones = !_ctx.showAllZones))
          }, _toDisplayString(_ctx.showAllZones ? _ctx.$t('zones.hide_zones') : _ctx.$t('zones.show_zones')), 1)
        ])
      ])
    ])
  ], 64))
}