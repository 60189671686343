
import draggableComponent from 'vuedraggable'
import { defineAsyncComponent, defineComponent } from 'vue'
import { Order } from '@/shared/interfaces/order.interface'
import { cloneDeep } from 'lodash'
import { OrderActions } from '@/store/modules/orders/actions'
import AssignmentMap from '@/views/account/orders/components/AssignmentMap.vue'
import { PredefinedFeedbackTypes } from '@/shared/interfaces/predefined-reasons.interface'

export default defineComponent({
  name: 'OrderManuelAssignModal',
  components: {
    AssignmentMap,
    draggableComponent,
    ConfirmationModal: defineAsyncComponent(() => import('@/shared/components/modals/ConfirmationModal.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    ReasonForm: defineAsyncComponent(() => import('@/views/account/orders/components/ReasonForm.vue'))
  },
  props: {
    orderData: {
      type: Object as () => Order,
      required: true
    }
  },
  data () {
    return {
      relatedOrders: [] as any,
      showConfirmation: false,
      awaitFurtherOrders: false,
      isManual: false,
      unAssignReason: null as any,
      PredefinedFeedbackTypes
    }
  },
  methods: {
    unAssignOrder () {
      const orderId = this.orderData.id
      const payload = {
        await_further_orders: this.awaitFurtherOrders ? 1 : 0,
        order_sequence: this.relatedOrders.map((o:any) => o.id),
        is_manual: this.isManual ? 1 : 0,
        ...this.unAssignReason
      }
      this.$store.dispatch(`orders/${[OrderActions.MANUEL_ORDER_UN_ASSIGN]}`, { orderId, payload }).then(() => {
        this.$emit('resetDriverData', { orderId: this.orderData.id })
        this.$emit('close')
      }).finally(() => {
        this.showConfirmation = false
      })
    },
    rejectUnAssignDriver (val: boolean): void {
      this.showConfirmation = false
    }
  },
  computed: {
    coordinates () {
      return this.relatedOrders.map((o: any) => ({ lng: o.destination_longitude, lat: o.destination_latitude }))
    },
    storeCoordinates () {
      return { lat: this.orderData.darkStore.latitude, lng: this.orderData.darkStore.longitude }
    },
    unassignTitle (): string {
      const driver = `${this.orderData.driver?.driverInfo.firstName} ${this.orderData.driver?.driverInfo.lastName}`
      return this.$t('orders.unassign.title', { driver, darkstore: this.orderData.darkStore.name, orderNumber: this.orderData.orderId })
    },
    showManualAssignmentItems () {
      return ['preparing', 'ready_for_pick_up', 'assigned', 'picked_up'].includes(this.orderData.orderStatus.name)
    }
  },
  mounted () {
    if (this.orderData.linkedOrders && this.orderData.linkedOrders.length) {
      this.relatedOrders = cloneDeep(this.orderData.linkedOrders)
    }
    this.isManual = this.orderData.isManual ? this.orderData.isManual : false
  }
})
