<template>
  <Transition>
    <div class="flex justify-between flex-nowrap pt-4 px-4 pb-4">
      <router-view></router-view>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'lts-dashboard'
}
</script>
