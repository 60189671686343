import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { VehicleInterface } from '@/shared/interfaces/vehicle.interface'
import { formatDate } from '@/shared/utils/formatDate'

export class VehiclesModel extends CommonModel {
  public data: VehicleInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any, i: number) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): VehicleInterface {
    return {
      id: data.id,
      licenseNo: data.license_no,
      make: data.make,
      model: data.model,
      owner: data.owner,
      vehicleType: {
        value: data.vehicle_type.value,
        id: data.vehicle_type.id,
        name: data.vehicle_type.name // ?
      },
      name: data.name,
      year: data.year,
      isAssigned: data.is_assigned,
      isActivated: data.is_activated,
      driverInfo: Array.isArray(data.driver_info)
        ? data.driver_info.map((el: any) => {
          return {
            driverInfo: {
              id: el.driver_info.id,
              firstName: el.driver_info.first_name,
              lastName: el.driver_info.last_name,
              userName: el.driver_info.username
            },
            id: el.id
          }
        }) : []
    }
  }
}
