
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    settings: {
      type: Array,
      required: true
    }
  }
})
