
import { defineAsyncComponent, defineComponent } from 'vue'
import { UsersActions } from '@/store/modules/users/actions'
import router from '@/router'
import { User } from '@/shared/interfaces/user.interface'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, sameAs } from '@/customValidators'
import isValidUsername from '@/shared/utils/usernameRegex'
import { useToast } from 'vue-toastification'
const toast = useToast()
export default defineComponent({
  components: {
    SearchInput: defineAsyncComponent(() => import('@/shared/components/SearchInput.vue')),
    OrderByArrow: defineAsyncComponent(() => import('@/shared/OrderByArrow.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    TableGhost: defineAsyncComponent(() => import('@/shared/components/ghosts/TableGhost.vue')),
    InviteUser: defineAsyncComponent(() => import('@/views/shared/InviteUser.vue')),
    Pagination: defineAsyncComponent(() => import('@/shared/components/Pagination.vue')),
    BaseModal: defineAsyncComponent(() => import('@/shared/components/modals/BaseModal.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue'))
  },
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      showPassword: false,
      // showOldPassword: false,
      showConfirmPassword: false,
      sortFields: [
        { id: 'created_at', currentSort: false, sort: null },
        { id: 'last_login', currentSort: false, sort: null }
      ],
      userInfo: {
        userPhoneNumber: null as any,
        firstName: null as any,
        lastName: null as any,
        birthDate: null as any,
        userName: null as any,
        userEmail: null as any
      },
      changePass: {
        // oldPass: null as any,
        pass: null as any,
        confirm_pass: null as any
      },
      showModal: false,
      height: 400,
      current: 1,
      superAdmin: false,
      showFilterModal: false,
      searchValue: null as any,
      admin: false,
      driver: false,
      showUserEditModal: false,
      selectedUser: null as any,
      activeEditTab: 'userInfo',
      filters: [
        // {
        //   name: this.$t('filters.orderBy'),
        //   model: '',
        //   type: 'order',
        //   default: { id: 'created_at', value: this.$t('filters.createdAt') },
        //   data: [
        //     { id: 'created_at', value: this.$t('filters.createdAt') },
        //     { id: 'modified_at', value: this.$t('filters.modifiedAt') },
        //     { id: 'username', value: this.$t('filters.username') },
        //     { id: 'last_login', value: this.$t('filters.lastLogin') }
        //   ]
        // },
        { name: this.$t('filters.joiningDate'), model: 'joiningDate', type: 'date', calendarType: 'range' },
        {
          name: this.$t('filters.role'),
          model: 'role',
          type: 'radio',
          data: [
            { id: 'is_admin', label: 'Admin' },
            { id: 'is_super_admin', label: 'SuperAdmin' },
            { id: 'is_driver', label: 'Driver' },
            { id: 'is_viewer', label: 'Viewer' }
          ]
        },
        {
          name: this.$t('filters.status'),
          model: 'active',
          type: 'radio',
          data: [
            { id: null, label: 'All Users' },
            { id: 'true', label: 'Active Users' },
            { id: 'false', label: 'Deactivated Users' }
          ]
        }
      ],
      exportColumn: [
        { field: 'username', label: 'Username' },
        { field: 'userEmail', label: 'Email' },
        { field: 'birthDateFormatted', label: 'Birth Date' },
        { field: 'userPhoneNumber', label: 'Mobile' },
        { field: 'role', label: 'Role' },
        { field: 'createdAtFormatted', label: 'Joining Date' },
        { field: 'lastLoginFormatted', label: 'Last Login Date' },
        { field: 'activated', label: 'Is Active' }
      ],
      exportData: [] as any,
      exportLoading: false
    }
  },
  validations: function () {
    return {
      userInfo: {
        userPhoneNumber: { required, minLength: minLength(13) },
        firstName: { required },
        lastName: { required },
        birthDate: { required },
        userName: { required },
        userEmail: { required, email }
      },
      changePass: {
        // oldPass: { required, minLength: minLength(8) },
        pass: { required, minLength: minLength(8) },
        confirm_pass: { required, sameAs: sameAs(this.changePass.pass, this.$t('auth.password')) }
      }
    }
  },
  mounted () {
    this.height = document.documentElement.clientHeight - 280
    addEventListener('resize', () => {
      this.height = document.documentElement.clientHeight - 280
    })
  },
  computed: {
    users (): User[] {
      return this.$store.getters['users/getUsers']
    },
    pagination (): PaginationInterface {
      return this.$store.getters['users/getPagination']
    },
    loading (): boolean {
      return this.$store.getters['users/getLoading']
    },
    getExportUsers (): User[] {
      return this.$store.getters['users/getExportUsers']
    },
    isSuperAdmin (): boolean {
      return this.$store.getters.isSuperAdmin
    }
  },
  methods: {
    clickSort (field: any, currentSortName: string): void {
      this.sortFields.map((el: any) => {
        if (el.id === currentSortName) {
          el.currentSort = true
          if (el.sort === null) {
            el.sort = 'asc'
          } else if (el.sort === 'asc') {
            el.sort = 'desc'
          } else if (el.sort === 'desc') {
            el.sort = null
          }
        } else {
          el.currentSort = false
          el.sort = null
        }
        return el
      })
      const queries = this.$route.query
      router.push({ query: { ...queries, orderBy: field.id, sort: field.sort } })
    },
    togglePassword () {
      this.showPassword = !this.showPassword
    },
    changeActive (tabName: string): void {
      this.activeEditTab = tabName
    },
    toggleActivate (id: number, type: 'activate' | 'deactivate'): void {
      if (type === 'activate') {
        this.$store.dispatch(`users/${UsersActions.ACTIVATE_USER}`, { userId: id })
      } else {
        this.$store.dispatch(`users/${UsersActions.DEACTIVATE_USER}`, { userId: id })
      }
    },
    changePassword (event: any): void {
      event.preventDefault()
      const payload = {
        // old_password: this.changePass.oldPass,
        new_password: this.changePass.pass,
        confirm_new_password: this.changePass.confirm_pass
      }
      this.$store.dispatch(`users/${UsersActions.CHANGE_PASSWORD}`, { data: payload, userId: this.selectedUser.id }).then((res: any) => {
        toast.success('User password has been updated successfully', {
          timeout: 3000
        })
        this.closeEditUserModal()
        // let params = JSON.parse(JSON.stringify(this.$route.query))
        // params = { pageSize: 10, pageNumber: this.$route.query.pageNumber ? this.$route.query.pageNumber : 1, ...params }
        // this.$store.dispatch(`users/${UsersActions.FETCH_USERS}`, params)
      })
        .catch(() => {
          toast.error('Something went wrong, please try again!', {
            timeout: 3000
          })
        })
    },
    editUserInfo (event: any): void {
      event.preventDefault()
      const payload = {}
      if (this.userInfo.userName !== this.selectedUser.username) {
        Object.assign(payload, { username: this.userInfo.userName })
      }
      if (this.userInfo.firstName !== this.selectedUser.firstName) {
        Object.assign(payload, { first_name: this.userInfo.firstName })
      }
      if (this.userInfo.lastName !== this.selectedUser.lastName) {
        Object.assign(payload, { last_name: this.userInfo.lastName })
      }
      if (this.userInfo.userEmail !== this.selectedUser.userEmail) {
        Object.assign(payload, { user_email: this.userInfo.userEmail })
      }
      if (this.userInfo.userPhoneNumber !== this.selectedUser.userPhoneNumber) {
        Object.assign(payload, { user_phone_number: this.userInfo.userPhoneNumber })
      }
      if (this.userInfo.birthDate !== this.selectedUser.birthdate) {
        Object.assign(payload, { birthdate: this.userInfo.birthDate })
      }
      if (Object.keys(payload).length) {
        const toast = useToast()
        this.$store.dispatch(`users/${UsersActions.UPDATE_USER}`, { data: payload, userId: this.selectedUser.id }).then((res: any) => {
          this.closeEditUserModal()
          toast.success('User has been updated successfully', {
            timeout: 3000
          })
          let params = JSON.parse(JSON.stringify(this.$route.query))
          params = { pageSize: 10, pageNumber: this.$route.query.pageNumber ? this.$route.query.pageNumber : 1, ...params }
          this.$store.dispatch(`users/${UsersActions.FETCH_USERS}`, params)
        }).catch(() => {
          toast.error('Something went wrong, please try again!', {
            timeout: 3000
          })
          this.closeEditUserModal()
        })
      }
    },
    closeEditUserModal (): void {
      this.resetForm()
      this.selectedUser = null
      this.showUserEditModal = false
    },
    resetForm (): void {
      this.userInfo.userName = null
      this.userInfo.firstName = null
      this.userInfo.lastName = null
      this.userInfo.birthDate = null
      this.userInfo.userPhoneNumber = null
      this.userInfo.userEmail = null
      this.changePass.pass = null
      this.changePass.confirm_pass = null
      // this.changePass.oldPass = null
      this.v$.$reset()
    },
    openEditUserModal (user: User): void {
      this.showUserEditModal = true
      this.userInfo.userName = user.username
      this.userInfo.firstName = user.firstName
      this.userInfo.lastName = user.lastName
      this.userInfo.userEmail = user.userEmail
      this.userInfo.userPhoneNumber = user.userPhoneNumber
      this.userInfo.birthDate = user.birthdate
      this.selectedUser = user
    },
    getUserList (): void {
      router.push({ query: { ...this.$route.query, pageNumber: 1 } })
    },
    async exportUsers () {
      this.exportLoading = true
      let params = JSON.parse(JSON.stringify(this.$route.query))
      params = { ...params, pageSize: this.pagination.totalItems, pageNumber: 1, export: true }
      await this.$store.dispatch(`users/${UsersActions.FETCH_USERS}`, params)
      this.exportData = this.getExportUsers.map((user: User) => {
        return { ...user, role: user.role.value }
      })
      const button = document.getElementById('excel-export')
      if (button) {
        this.$nextTick(() => {
          button.click()
          this.exportData = []
          this.exportLoading = false
        })
      }
    }
  },
  watch: {
    current (val) {
      router.push({ query: { ...this.$route.query, pageNumber: val } })
    },
    searchValue (val) {
      const queries = this.$route.query
      queries.pageNumber = '1'
      router.push({ query: { ...queries, search: this.searchValue } })
    },
    $route: {
      immediate: true,
      handler (val) {
        if (val.name === 'Users') {
          this.current = this.$route.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
          if (val.query.search) {
            this.searchValue = val.query.search
          }
          let params = JSON.parse(JSON.stringify(val.query))
          params = { pageSize: 10, pageNumber: val.query.pageNumber ? val.query.pageNumber : 1, ...params }
          this.$store.dispatch(`users/${UsersActions.FETCH_USERS}`, params)
        }
      }
    },
    'userInfo.userName' (val) {
      if (!isValidUsername(val)) {
        if (this.userInfo.userName.split(' ').length > 1) {
          this.userInfo.userName = this.userInfo.userName.split(' ').join('_')
        } else {
          const strArry = this.userInfo.userName.split('')
          strArry.pop()
          this.userInfo.userName = strArry.join('')
        }
      }
    }
  }
})
