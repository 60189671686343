
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Toggle',
  props: {
    modelValue: Boolean
  },
  emits: ['update:modelValue']
})
