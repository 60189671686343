
import { defineComponent, defineAsyncComponent } from 'vue'
import { OrderActions } from '@/store/modules/orders/actions'
import { Order } from '@/shared/interfaces/order.interface'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'

export default defineComponent({
  name: 'OrderHistory',
  components: {
    DateRange: defineAsyncComponent(() => import('@/shared/components/datepicker/DateRange.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    OrderDetails: defineAsyncComponent(() => import('@/views/account/drivers/components/OrderDetails.vue'))
  },
  data () {
    return {
      date: '',
      searchValue: '',
      pageNumber: 1
    }
  },
  mounted () {
    this.getOrderHistories(1, true)
  },
  computed: {
    orders (): Order[] {
      return this.$store.getters['orders/getOrderHistories']
    },
    pagination (): PaginationInterface {
      return this.$store.getters['orders/getOrderHistoryPagination']
    }
  },
  methods: {
    loadMore () {
      this.pageNumber++
      this.getOrderHistories(this.pageNumber, false)
    },
    getOrderHistories (pageNumber: number, refresh: boolean) {
      this.$store.dispatch(`orders/${[OrderActions.FETCH_ORDERS_HISTORY]}`,
        {
          driverId: this.$route.params.id,
          from_date: this.date !== null && this.date !== '' ? new Date(this.date[0]).toISOString().slice(0, 19).replace('T', ' ') : null,
          to_date: this.date !== null && this.date !== '' ? new Date(this.date[1]).toISOString().slice(0, 19).replace('T', ' ') : null,
          search: this.searchValue,
          pageNumber: pageNumber,
          refresh
        }
      )
    }
  },
  watch: {
    date (val) {
      this.getOrderHistories(1, true)
    },
    searchValue () {
      this.getOrderHistories(1, true)
    }
  }
})
