import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { ErpNextState } from '@/store/modules/erp-next/types'

export const getters: GetterTree<ErpNextState, RootState> = {
  getMerchants (state): any[] {
    return state.merchants
  },
  getPagination (state): any {
    return state.pagination
  },
  getLoading (state): boolean {
    return state.loading
  }
}
