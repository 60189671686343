import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-end gap-8 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isSuperAdmin)
      ? (_openBlock(), _createBlock(_component_Popper, {
          key: 0,
          arrow: "",
          hover: "",
          placement: "auto"
        }, {
          content: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('darkstoreSettings.update_label')), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("a", {
              role: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('edit-darkstore-settings', _ctx.data)))
            }, [
              _createVNode(_component_BaseIcon, { name: "settings" })
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_Popper, {
      arrow: "",
      hover: "",
      placement: "auto"
    }, {
      content: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('zones.assign_un_assign_zone')), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("a", {
          role: "button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('zone-management', _ctx.data)))
        }, [
          _createVNode(_component_BaseIcon, { name: "mappin" })
        ])
      ]),
      _: 1
    })
  ]))
}