import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { NotificationInterface } from '@/shared/interfaces/notification.interface'

export class NotificationsModel extends CommonModel {
  public data: NotificationInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): NotificationInterface {
    return {
      id: data.id,
      message: data.name,
      createdAt: data.created_at
    }
  }
}
