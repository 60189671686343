
import { defineComponent } from 'vue'
const wdw: any = window
const driverMarkers: any = []

export default defineComponent({
  name: 'DriverMap',
  props: ['location'],
  data () {
    return {
      mapTypeControl: true,
      map: {} as any
    }
  },
  mounted () {
    const latitude = 33.312805
    const longitude = 44.361488
    this.map = new wdw.google.maps.Map(
      document.getElementById('map'),
      {
        center: { lat: latitude, lng: longitude },
        zoom: 15,
        mapTypeControl: false,
        streetViewControl: false,
        disableDefaultUI: false
      }
    )
  },
  watch: {
    location: {
      immediate: true,
      handler (newVal) {
        setTimeout(() => {
          for (let i = 0; i < driverMarkers.length; i++) {
            driverMarkers[i].setMap(null)
          }
          if (newVal && newVal.latitude && newVal.longitude) {
            this.addMarker(newVal.latitude, newVal.longitude)
          }
        }, 0)
      }
    }
  },
  methods: {
    addMarker (lat: number, lng: number): void {
      const position = new wdw.google.maps.LatLng(lat, lng)
      const image = {
        url: require('../../../../assets/images/car_marker.png'),
        size: new wdw.google.maps.Size(50, 50),
        origin: new wdw.google.maps.Point(0, 0),
        anchor: new wdw.google.maps.Point(0, 32)
      }
      const marker = new wdw.google.maps.Marker({
        position,
        map: this.map,
        icon: image
      })
      const center = new wdw.google.maps.LatLng(lat, lng)
      this.map.panTo(center)
      driverMarkers.push(marker)
    }
  }
})
