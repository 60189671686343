import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap p-8" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = {
  key: 0,
  class: "form-error"
}
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = {
  key: 0,
  class: "form-error"
}
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = {
  key: 0,
  class: "form-error"
}
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = {
  key: 0,
  class: "form-error"
}
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = {
  key: 0,
  class: "form-error"
}
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = {
  key: 0,
  class: "form-error"
}
const _hoisted_14 = { class: "flex flex-wrap items-center justify-end w-full" }
const _hoisted_15 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args))),
      class: "w-full"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass([{error: _ctx.v$.name.$error}, "h-42 form-control"]),
          type: "text",
          placeholder: " ",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
          onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.v$.name.$touch && _ctx.v$.name.$touch(...args)))
        }, null, 34), [
          [_vModelText, _ctx.name]
        ]),
        _createElementVNode("label", {
          class: _normalizeClass([{error: _ctx.v$.name.$error}, "floating-label"])
        }, _toDisplayString(_ctx.$t('workShifts.name')), 3),
        (_ctx.v$.name.$error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _createVNode(_component_BaseIcon, {
                class: "me-1",
                name: "alert"
              }),
              _createTextVNode(_toDisplayString(_ctx.$t('validation.required')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass([{error: _ctx.v$.value.$error}, "h-42 form-control"]),
          type: "text",
          placeholder: " ",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
          onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.v$.value.$touch && _ctx.v$.value.$touch(...args)))
        }, null, 34), [
          [_vModelText, _ctx.value]
        ]),
        _createElementVNode("label", {
          class: _normalizeClass([{error: _ctx.v$.value.$error}, "floating-label"])
        }, _toDisplayString(_ctx.$t('workShifts.value')), 3),
        (_ctx.v$.value.$error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _createVNode(_component_BaseIcon, {
                class: "me-1",
                name: "alert"
              }),
              _createTextVNode(_toDisplayString(_ctx.$t('validation.required')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Multiselect, {
          modelValue: _ctx.darkStoreId,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.darkStoreId) = $event)),
          options: _ctx.darkstores,
          label: "value",
          valueProp: "id",
          noOptionsText: _ctx.$t('validation.noItemsToShow'),
          placeholder: _ctx.$t('workShifts.darkstore')
        }, null, 8, ["modelValue", "options", "noOptionsText", "placeholder"]),
        (_ctx.v$.darkStoreId.$error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
              _createVNode(_component_BaseIcon, {
                class: "me-1",
                name: "alert"
              }),
              _createTextVNode(_toDisplayString(_ctx.$t('validation.required')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_Datepicker, {
          modelValue: _ctx.shiftStart,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.shiftStart) = $event)),
          placeholder: _ctx.$t('workShifts.shiftStart'),
          timePicker: ""
        }, null, 8, ["modelValue", "placeholder"]),
        (_ctx.v$.shiftStart.$error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
              _createVNode(_component_BaseIcon, {
                class: "me-1",
                name: "alert"
              }),
              _createTextVNode(_toDisplayString(_ctx.$t('validation.required')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_Datepicker, {
          modelValue: _ctx.shiftEnd,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.shiftEnd) = $event)),
          placeholder: _ctx.$t('workShifts.shiftEnd'),
          timePicker: ""
        }, null, 8, ["modelValue", "placeholder"]),
        (_ctx.v$.shiftEnd.$error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
              _createVNode(_component_BaseIcon, {
                class: "me-1",
                name: "alert"
              }),
              _createTextVNode(_toDisplayString(_ctx.$t('validation.required')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass([{error: _ctx.v$.description.$error}, "h-42 form-control"]),
          type: "text",
          placeholder: " ",
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.description) = $event)),
          onBlur: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.v$.description.$touch && _ctx.v$.description.$touch(...args)))
        }, null, 34), [
          [_vModelText, _ctx.description]
        ]),
        _createElementVNode("label", {
          class: _normalizeClass([{error: _ctx.v$.description.$error}, "floating-label"])
        }, _toDisplayString(_ctx.$t('workShifts.description')), 3),
        (_ctx.v$.description.$error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
              _createVNode(_component_BaseIcon, {
                class: "me-1",
                name: "alert"
              }),
              _createTextVNode(_toDisplayString(_ctx.$t('validation.required')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[9] || (_cache[9] = ($event: any) => {_ctx.$emit('close'); _ctx.resetForm()}),
          class: "secondary m-1 w-32 px-4 py-2"
        }, _toDisplayString(_ctx.$t('buttons.close')), 1),
        _createElementVNode("button", {
          disabled: _ctx.v$.$invalid,
          type: "submit",
          class: "primary m-1 w-32 px-4 py-2"
        }, _toDisplayString(this.isEdit ? _ctx.$t('buttons.update') : _ctx.$t('buttons.create')), 9, _hoisted_15)
      ])
    ], 32)
  ]))
}