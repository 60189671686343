import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap max-w-6xl px-6 py-8" }
const _hoisted_2 = {
  key: 0,
  class: "w-full pb-10"
}
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = {
  key: 0,
  class: "text-textColor font-bold text-lg mb-2"
}
const _hoisted_5 = {
  key: 1,
  class: "text-textColor text-sm"
}
const _hoisted_6 = { class: "flex items-center justify-end w-full pt-6" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    ref: "modal",
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.confirmOnClose ? _ctx.handleConfirm() : _ctx.handleCancel())),
    title: _ctx.header,
    width: '400'
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.hasIcon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_BaseIcon, {
                name: _ctx.iconName,
                classNames: "text-8xl w-24 h-24",
                class: _normalizeClass(_ctx.classColor)
              }, null, 8, ["name", "class"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true),
            (_ctx.description)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.description), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.cancelText)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCancel && _ctx.handleCancel(...args))),
                class: "tertiary m-1 px-7 py-3"
              }, _toDisplayString(_ctx.cancelText), 1))
            : _createCommentVNode("", true),
          (_ctx.confirmText)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                disabled: !_ctx.isValid,
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleConfirm && _ctx.handleConfirm(...args))),
                class: "primary m-1 px-7 py-3"
              }, _toDisplayString(_ctx.confirmText), 9, _hoisted_7))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 3
  }, 8, ["title"]))
}