import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { HourlyDeliveryTimeAnalysisInterface } from '@/shared/interfaces/reports/hourly-delivery-time-analysis.interface'

export class HourlyDeliveryTimeAnalysisModel extends CommonModel {
  public data: HourlyDeliveryTimeAnalysisInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    const keys = Object.keys(this.baseData)
    keys.forEach((k: any) => {
      const items = this.baseData[k]
      items.map((item: any) => {
        this.data = [
          ...this.data,
          { ...this.setData(item, k) }
        ]
      })
    })
  }

  private setData (data: any, hour: number): HourlyDeliveryTimeAnalysisInterface {
    return {
      darkstoreId: data.dark_store_id,
      darkstoreName: data.dark_store_name,
      avgDeliveryTime: Math.floor(data.avg_delivery_time / 60),
      hour: hour
    }
  }
}
