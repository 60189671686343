<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1017_3285)">
      <path d="M12.0009 2.25H0.750854V12H12.0009V2.25Z" stroke="#484848" stroke-width="1.68" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.0009 6H15.0009L17.2509 8.25V12H12.0009V6Z" stroke="#484848" stroke-width="1.68" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.12585 15.75C5.16139 15.75 6.00085 14.9105 6.00085 13.875C6.00085 12.8395 5.16139 12 4.12585 12C3.09032 12 2.25085 12.8395 2.25085 13.875C2.25085 14.9105 3.09032 15.75 4.12585 15.75Z" stroke="#484848" stroke-width="1.68" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.8759 15.75C14.9114 15.75 15.7509 14.9105 15.7509 13.875C15.7509 12.8395 14.9114 12 13.8759 12C12.8403 12 12.0009 12.8395 12.0009 13.875C12.0009 14.9105 12.8403 15.75 13.8759 15.75Z" stroke="#484848" stroke-width="1.68" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1017_3285">
        <rect width="18" height="18" fill="white" transform="translate(0.000854492)"/>
      </clipPath>
    </defs>
  </svg>
</template>
