import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { PredefinedReasonsState } from '@/store/modules/predefined-reasons/types'
import { getters } from '@/store/modules/predefined-reasons/getters'
import { mutations } from '@/store/modules/predefined-reasons/mutations'
import { actions } from '@/store/modules/predefined-reasons/actions'

const state: PredefinedReasonsState = {
  predefinedReasons: [],
  pagination: {
    pageSize: 1,
    pageNumber: 10,
    totalItems: null,
    totalPage: null
  },
  loading: false
}

export const predefinedReasons: Module<PredefinedReasonsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
