
import { computed, defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'PageTitle',
  props: {
    title: String,
    subtitle: String
  },
  setup (props) {
    const subtitleText = ref(props.subtitle || '')

    const pageTitleText = computed(() => {
      return props.title || useRoute().name
    })

    return { pageTitleText, subtitleText }
  }
})
