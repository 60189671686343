import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { NotificationsState } from '@/store/modules/notifications/types'
import { NotificationInterface } from '@/shared/interfaces/notification.interface'

export const getters: GetterTree<NotificationsState, RootState> = {
  getNotifications (state): NotificationInterface[] {
    return state.notifications
  },
  getPagination (state): any {
    return state.pagination
  },
  getLoading (state): boolean {
    return state.loading
  }
}
