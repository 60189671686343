
import { defineComponent, defineAsyncComponent } from 'vue'
import { CacheSettlementsActions } from '@/store/modules/cache-settlements/actions'

export default defineComponent({
  name: 'CacheSettlementDetails',
  components: {
    DynamicTable: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTable.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    ConfirmationModal: defineAsyncComponent(() => import('@/shared/components/modals/ConfirmationModal.vue')),
    DynamicTableCacheSettlementsAction: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTableCacheSettlementsAction.vue'))
  },
  data () {
    return {
      confirmObject: null as any,
      tableFields: [
        { id: 'orderId', name: 'Order ID', width: '9%', sortable: false },
        { id: 'amount', name: 'Amount', width: '9%', sortable: false },
        {
          id: 'confirmText',
          name: 'Status',
          width: '18%',
          sortable: false
        },
        { id: 'customerCode', name: 'Customer', width: '18%', sortable: false },
        { id: 'createdAt', name: 'Date', width: '18%', sortable: false }
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.$store.dispatch(`cacheSettlements/${[CacheSettlementsActions.FETCH_ORDER_CACHE_SETTLEMENT]}`, this.$route.params.id)
    },
    onReject (data: any) {
      this.confirmObject = {
        title: this.$t('cacheSettlements.confirms.reject.title'),
        message: this.$t('cacheSettlements.confirms.reject.message', [data.settlementNo]),
        handler: async () => {
          await this.$store.dispatch(`cacheSettlements/${[CacheSettlementsActions.REJECT_CACHE_SETTLEMENT]}`, data.id)
          this.init()
          this.confirmObject = null
        }
      }
    },
    onApprove (data: any) {
      this.confirmObject = {
        title: this.$t('cacheSettlements.confirms.approve.title'),
        message: this.$t('cacheSettlements.confirms.approve.message', [data.settlementNo]),
        handler: async () => {
          await this.$store.dispatch(`cacheSettlements/${[CacheSettlementsActions.APPROVE_CACHE_SETTLEMENT]}`, data.id)
          this.init()
          this.confirmObject = null
        }
      }
    },
    onRetryErpSync (data: any) {
      this.confirmObject = {
        title: this.$t('cacheSettlements.confirms.retryErpSync.title'),
        message: this.$t('cacheSettlements.confirms.retryErpSync.message', [data.settlementNo]),
        handler: async () => {
          await this.$store.dispatch(`cacheSettlements/${[CacheSettlementsActions.RETRY_ERP_SYNC]}`, data.id)
          this.init()
          this.confirmObject = null
        }
      }
    }
  },
  computed: {
    type () {
      return this.$route.params?.type ?? 'all'
    },
    cacheSettlementsData () {
      return this.$store.getters['cacheSettlements/getCacheSettlementOrders']
    },
    loading () {
      return this.$store.getters['cacheSettlements/getLoading']
    },
    records () {
      const orderList = this.$store.getters['cacheSettlements/getCacheSettlementOrders']
      return orderList?.cacheSettlementsOrder.map((o:any) => ({
        ...o,
        confirmText: o.isConfirmed ? 'Approved' : 'Unapproved'
      })) ?? []
    }
  }
})
