import { Method as apiMethod } from 'axios'

export const settingsEndpoints = {
  getSettings: {
    method: 'get' as apiMethod,
    url: '/general_on_demand_settings'
  },
  updateSettings: {
    method: 'put' as apiMethod,
    url: '/general_on_demand_settings'
  }
}
