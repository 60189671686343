
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'Tab',
  props: {
    tabItems: Array as PropType<Array<string>>
  },
  setup (props, { emit }) {
    const items = ref(props.tabItems || [])
    const activeItem = ref(props.tabItems?.length ? props.tabItems.filter((el: any) => el.isActive)[0] : null)

    const changeActive = (indexOf: number): void => {
      activeItem.value = items.value[indexOf]
      emit('tabChanged', items.value[indexOf])
    }

    return {
      items,
      changeActive,
      activeItem
    }
  }
})
