import { MutationTree } from 'vuex'
import { CacheSettlementsState } from '@/store/modules/cache-settlements/types'

export enum CacheSettlementsMutations {
  SET_CACHE_SETTLEMENTS = 'SET_CACHE_SETTLEMENTS',
  SET_CACHE_SETTLEMENT_ORDERS = 'SET_CACHE_SETTLEMENT_ORDERS',
  TRIGGER_LOADING = 'TRIGGER_LOADING'
}

export const mutations: MutationTree<CacheSettlementsState> = {
  [CacheSettlementsMutations.SET_CACHE_SETTLEMENTS] (state, payload) {
    state.cacheSettlements = payload.data
    state.pagination = payload.pagination
    state.loading = false
  },
  [CacheSettlementsMutations.TRIGGER_LOADING] (state, bool: boolean) {
    state.loading = bool
  },
  [CacheSettlementsMutations.SET_CACHE_SETTLEMENT_ORDERS] (state, payload) {
    state.cacheSettlementOrders = payload
  }
}
