import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { apiEndpoints } from '@/shared/endpoints/api'
import { CacheSettlementsMutations } from '@/store/modules/cache-settlements/mutations'
import axiosInstance from '@/shared/utils/axiosInstance'
import { CacheSettlementsState } from '@/store/modules/cache-settlements/types'
import { CacheSettlementsModel } from '@/shared/models/cache-settlements.model'
import { OrderCacheSettlementsModel } from '@/shared/models/order-cache-settlements.model'
import moment from 'moment'

export enum CacheSettlementsActions {
  FETCH_CACHE_SETTLEMENTS = 'FETCH_CACHE_SETTLEMENTS',
  APPROVE_CACHE_SETTLEMENT = 'APPROVE_CACHE_SETTLEMENT',
  REJECT_CACHE_SETTLEMENT = 'REJECT_CACHE_SETTLEMENT',
  RETRY_ERP_SYNC = 'RETRY_ERP_SYNC',
  FETCH_ORDER_CACHE_SETTLEMENT = 'FETCH_ORDER_CACHE_SETTLEMENT'
}

export const actions: ActionTree<CacheSettlementsState, RootState> = {
  [CacheSettlementsActions.FETCH_CACHE_SETTLEMENTS]: async (context, payload) => {
    const params = new URLSearchParams()
    params.append('page_number', payload.pageNumber)
    params.append('per_page', payload.pageSize)

    if (payload.isSettled) {
      params.append('is_settled', payload.isSettled)
    }

    if (payload.export) {
      params.append('export', '1')
    }

    if (payload.createdAt_from && payload.createdAt_to) {
      params.append('from_date', moment(payload.createdAt_from).format('YYYY-MM-DD HH:mm:ss'))
      params.append('to_date', moment(payload.createdAt_to).format('YYYY-MM-DD HH:mm:ss'))
    }

    if (payload.driverIds) {
      payload.driverIds.split(':')[1].split(',').forEach((el: any) => {
        params.append('driver_ids', el)
      })
    }

    if (payload.driverId) {
      params.append('driver_ids', payload.driverId)
    }

    if (payload.status && payload.status !== 'all') {
      params.append('status', payload.status)
    }

    if (payload.adminUsername) {
      params.append('admin_username', payload.adminUsername)
    }

    if (payload.export) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        await axiosInstance({
          ...apiEndpoints.cacheSettlements.orderCacheSettlements,
          params
        }).then((res) => {
          const cacheSettlementsModel = new CacheSettlementsModel(res.data)
          resolve(cacheSettlementsModel.data)
        }).catch((err) => reject(err))
      })
    }

    context.commit(CacheSettlementsMutations.TRIGGER_LOADING, true)
    await axiosInstance({
      ...apiEndpoints.cacheSettlements.orderCacheSettlements,
      params
    }).then((resp: any) => {
      const cacheSettlementsModel = new CacheSettlementsModel(resp.data)
      const payload = {
        data: cacheSettlementsModel.data,
        pagination: cacheSettlementsModel.paginationData
      }
      context.commit(CacheSettlementsMutations.SET_CACHE_SETTLEMENTS, payload)
    }).catch((err: any) => {
      context.commit(CacheSettlementsMutations.TRIGGER_LOADING, false)
      throw new Error(err)
    })
  },
  [CacheSettlementsActions.APPROVE_CACHE_SETTLEMENT]: async (context, id) => {
    return axiosInstance({
      ...apiEndpoints.cacheSettlements.approveCacheSettlement(id)
    })
  },
  [CacheSettlementsActions.REJECT_CACHE_SETTLEMENT]: async (context, id) => {
    await axiosInstance({
      ...apiEndpoints.cacheSettlements.rejectCacheSettlement(id)
    })
  },
  [CacheSettlementsActions.FETCH_ORDER_CACHE_SETTLEMENT]: async (context, id) => {
    context.commit(CacheSettlementsMutations.TRIGGER_LOADING, true)
    await axiosInstance({
      ...apiEndpoints.cacheSettlements.orderCacheSettlement(id)
    }).then((resp: any) => {
      const orderCacheSettlements = new OrderCacheSettlementsModel(resp.data)
      context.commit(CacheSettlementsMutations.SET_CACHE_SETTLEMENT_ORDERS, orderCacheSettlements.data)
    }).catch((err: any) => {
      throw new Error(err)
    }).finally(() => {
      context.commit(CacheSettlementsMutations.TRIGGER_LOADING, false)
    })
  },
  [CacheSettlementsActions.RETRY_ERP_SYNC]: async (context, id) => {
    await axiosInstance({
      ...apiEndpoints.cacheSettlements.retryErpSync(id)
    })
  }
}
