import { CommonModel } from '@/shared/models/common.model'
import { OrderDetailsModel } from '@/shared/models/order-details.model'
import { ReturnedOrder } from '@/shared/interfaces/returned-order.interface'

export class ReturnedOrderDetailsModel extends CommonModel {
  public data!: ReturnedOrder;

  constructor (data: any) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.data = this.setData(this.baseData)
  }

  private setData (data: any): ReturnedOrder {
    return {
      id: data.id,
      orderId: data.order_id,
      orderStatus: {
        id: data.order_status.id,
        name: data.order_status.name,
        value: data.order_status.value
      },
      driver: data.driver ? {
        createdAt: data.driver.created_at,
        isClockIn: data.driver.is_clock_in,
        driverInfo: {
          activated: data.driver.driver_info.activated,
          birthDate: data.driver.driver_info.birthdate,
          createdAt: data.driver.driver_info.created_at,
          firstName: data.driver.driver_info.first_name,
          lastName: data.driver.driver_info.last_name,
          lastLogin: data.driver.driver_info.last_login,
          modifiedAt: data.driver.driver_info.modified_at,
          userName: data.driver.driver_info.username,
          userEmail: data.driver.driver_info.user_email,
          userPhone: data.driver.driver_info.user_phone_number
        },
        shift: {
          darkstoreId: {
            closeTime: data.driver.shift.darkstore_id.close_time,
            id: data.driver.shift.darkstore_id.id,
            latitude: data.driver.shift.darkstore_id.latitude,
            longitude: data.driver.shift.darkstore_id.longitude,
            openTime: data.driver.shift.darkstore_id.open_time,
            name: data.driver.shift.darkstore_id.name,
            city: {
              countryName: data.driver.shift.darkstore_id.city.countryName,
              id: data.driver.shift.darkstore_id.city.id,
              name: data.driver.shift.darkstore_id.city.name
            }
          },
          description: data.driver.shift.description,
          id: data.driver.shift.id,
          shiftEnd: data.driver.shift.shift_end,
          shiftStart: data.driver.shift.shift_start,
          name: data.driver.shift.name,
          value: data.driver.shift.value
        },
        driverLicenseNo: data.driver.driver_license_no,
        id: data.driver.id,
        license: data.driver.license,
        vehicleInfo: data.driver.vehicle_info ? {
          name: data.driver.vehicle_info.name,
          model: data.driver.vehicle_info.model
        } : null,
        driverStatus: {
          id: data.driver.driver_status.id,
          name: data.driver.driver_status.name,
          value: data.driver.driver_status.value
        }
      } : null,
      tripDetailsFromDarkstoreOutward: data.trip_details_from_darkstore_outward ? this.setTripOutwardInfo(data.trip_details_from_darkstore_outward) : null,
      tripDetailsToDarkstoreInward: data.trip_details_to_darkstore_inward ? {
        endAddress: data.trip_details_to_darkstore_inward.end_address,
        endLocation: {
          lat: data.trip_details_to_darkstore_inward.end_location.lat,
          lng: data.trip_details_to_darkstore_inward.end_location.lng
        },
        startAddress: data.trip_details_to_darkstore_inward.start_address,
        startLocation: {
          lat: data.trip_details_to_darkstore_inward.start_location.lat,
          lng: data.trip_details_to_darkstore_inward.start_location.lng
        },
        totalTravelDuration: data.trip_details_to_darkstore_inward.total_travel_duration,
        travelDistance: data.trip_details_to_darkstore_inward.travel_distance,
        travelDuration: data.trip_details_to_darkstore_inward.travel_duration,
        overviewPolyline: data.trip_details_to_darkstore_inward.overview_polyline
      } : null,
      amountMoneyToReturn: data.amount_money_to_return,
      originalOrder: new OrderDetailsModel({ data: data.original_order }).data,
      returnedOrderCacheSettlementDetails: data.returned_order_cache_settlement_details,
      returnedOrderItems: data.returned_order_items
    }
  }

  private setTripOutwardInfo (data: any) {
    if (data.length) {
      return data.map((el: any) => {
        return {
          endAddress: el.end_address,
          endLocation: {
            lat: el.end_location.lat,
            lng: el.end_location.lng
          },
          startAddress: el.start_address,
          startLocation: {
            lat: el.start_location.lat,
            lng: el.start_location.lng
          },
          totalTravelDuration: el.total_travel_duration,
          travelDistance: el.travel_distance,
          travelDuration: el.travel_duration,
          overviewPolyline: el.overview_polyline
        }
      })
    } else {
      return [
        {
          endAddress: data.end_address,
          endLocation: {
            lat: data.end_location.lat,
            lng: data.end_location.lng
          },
          startAddress: data.start_address,
          startLocation: {
            lat: data.start_location.lat,
            lng: data.start_location.lng
          },
          totalTravelDuration: data.total_travel_duration,
          travelDistance: data.travel_distance,
          travelDuration: data.travel_duration
        }
      ]
    }
  }
}
