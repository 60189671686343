import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Multiselect, {
      modelValue: _ctx.selectedDriver,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDriver) = $event)),
      options: _ctx.drivers,
      label: "name",
      valueProp: "id",
      object: "true",
      loading: _ctx.driverLoading,
      noOptionsText: '',
      placeholder: _ctx.$t('placeholders.driversSearch'),
      canClear: true,
      searchable: _ctx.driverSearchable,
      onSearchChange: _ctx.handleSearch ,
      onOpen: _cache[1] || (_cache[1] = () => _ctx.driverSearchable = true),
      onClose: _cache[2] || (_cache[2] = () => _ctx.driverSearchable = false)
    }, null, 8, ["modelValue", "options", "loading", "placeholder", "searchable", "onSearchChange"])
  ]))
}