
import { defineComponent } from 'vue'
import CloseIcon from '@/shared/components/base-icon/icons/CloseIcon.vue'

export default defineComponent({
  components: { CloseIcon },
  props: {
    title: {
      type: String,
      required: false
    },
    width: {
      type: String,
      required: false,
      default: '490px'
    },
    loading: {
      type: Boolean,
      required: false
    }
  },
  emits: ['close'],
  setup (_, { emit }) {
    const closeModal = ($event: any, out = true) => {
      if (out && !$event.inner) {
        emit('close', false)
      } else $event.inner = true
    }
    return {
      closeModal
    }
  }
})
