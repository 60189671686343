
import moment from 'moment'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LTSStopPointList',
  components: { BaseIcon },
  props: {
    stopPoints: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      moment
    }
  },
  methods: {
    formatTime (seconds: number) {
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      seconds = seconds % 60

      if (hours > 0) {
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
      } else {
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
      }
    }
  },
  computed: {
    totalDuration () {
      const total = this.stopPoints.reduce((sum:any, b:any) => sum + b.duration, 0) as number
      return total > 60 ? `${this.formatTime(total)}` : `${total} sec.`
    }
  }
})
