import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tbody", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array.from(Array(_ctx.row).keys()), (row) => {
      return (_openBlock(), _createElementBlock("tr", {
        key: row + 'ghost-row'
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array.from(Array(_ctx.col).keys()), (col) => {
          return (_openBlock(), _createElementBlock("td", {
            key: col + 'ghost-col'
          }, [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.colMargin, "td-ghost rounded h-6 rounded-lg"])
            }, null, 2)
          ]))
        }), 128))
      ]))
    }), 128))
  ]))
}