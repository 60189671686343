
import { defineAsyncComponent, defineComponent } from 'vue'
import { SettingsActions } from '@/store/modules/settings/actions'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'Settings',
  components: {
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue'))
  },
  data () {
    return {
      darkStoreSearchable: false,
      settings: {
        preparing_max_delay_time: 0,
        ready_for_pick_up_max_delay_time: 0,
        assigned_max_delay_time: 0,
        picked_up_max_delay_time: 0,
        on_the_way_max_delay_time: 0,
        at_the_address_max_delay_time: 0,
        to_return_max_delay_time: 0,
        to_return_assigned_max_delay_time: 0,
        check_unpaid_orders_from_the_previous_day: true
      }
    }
  },
  methods: {
    async updateSettings () {
      await this.$store.dispatch(`settings/${[SettingsActions.SAVE_SETTINGS]}`, this.settings)
    },
    async retryERPSync () {
      await this.$store.dispatch(`settings/${[SettingsActions.FETCH_GENERAL_RETRY_ERP_SYNC]}`)
      const toast = useToast()
      toast.success('General ERP SYNC has been started successfully', {
        timeout: 3000
      })
    }
  },
  computed: {
    darkstores (): any[] {
      return this.$store.getters['darkstores/getDarkStoresAsDictionary']
    },
    getSettings (): any[] {
      return this.$store.getters['settings/getSettings']
    },
    loading (): any[] {
      return this.$store.getters['settings/getLoading']
    }
  },
  watch: {
    getSettings (newVal) {
      this.settings = newVal as any
    }
  },
  mounted () {
    this.$store.dispatch(`settings/${[SettingsActions.FETCH_SETTINGS]}`)
  }
})
