import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { PredefinedReasonsState } from '@/store/modules/predefined-reasons/types'
import { apiEndpoints } from '@/shared/endpoints/api'
import { PredefinedReasonMutations } from '@/store/modules/predefined-reasons/mutations'
import axiosInstance from '@/shared/utils/axiosInstance'
import { PredefinedReasonModel } from '@/shared/models/predefinedReason.model'

export enum PredefinedReasons {
  FETCH_REASONS = 'FETCH_REASONS',
}

export const actions: ActionTree<PredefinedReasonsState, RootState> = {
  [PredefinedReasons.FETCH_REASONS]: async (context) => {
    context.commit(PredefinedReasonMutations.REQUEST_PREDEFINED_REASONS, true)
    await axiosInstance({
      ...apiEndpoints.predefinedReasons.list
    }).then(resp => {
      const data = new PredefinedReasonModel(resp.data)
      context.commit(PredefinedReasonMutations.SET_PREDEFINED_REASONS, data.data)
    }).catch(err => {
      throw new Error(err)
    })
      .finally(() => {
        context.commit(PredefinedReasonMutations.REQUEST_PREDEFINED_REASONS, false)
      })
  }
}
