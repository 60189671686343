import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { CacheSettlementsInterface } from '@/shared/interfaces/cache-settlements.interface'
import moment from 'moment'
export class CacheSettlementsModel extends CommonModel {
  public data: CacheSettlementsInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): CacheSettlementsInterface {
    return {
      id: data.id,
      driver: {
        driverId: data?.driver?.driver_info?.id ?? null,
        fullName: (data?.driver?.driver_info?.first_name ?? '') + ' ' + (data?.driver?.driver_info?.last_name ?? '')
      },
      adminActionDatetime: data.admin_action_datetime ? moment(data.admin_action_datetime).format('YYYY-MM-DD HH:mm') : undefined,
      adminId: data?.admin_id ?? undefined,
      createdAt: moment(data.created_at).format('YYYY-MM-DD HH:mm'),
      status: data.status,
      totalAmount: data.total_amount,
      erpSyncStatus: data.erp_sync_status,
      admin: data?.admin?.username ?? '',
      settlementNo: data?.settlement_no ?? ''
    }
  }
}
