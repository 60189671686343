import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { ManualChangesState } from '@/store/modules/manual-changes/types'

const state: ManualChangesState = {
  manualChanges: [],
  manualChangesExport: [],
  pagination: null,
  loading: false,
  entityLoading: false,
  entityTypes: []
}

export const manualChanges: Module<ManualChangesState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
