
import { defineAsyncComponent, defineComponent } from 'vue'
import { debounce } from 'lodash'
import { CitiesActions } from '@/store/modules/cities/actions'

export default defineComponent({
  name: 'CitySelect',
  props: ['modelValue'],
  components: {
    Multiselect: defineAsyncComponent(() => import('@vueform/multiselect'))
  },
  data () {
    return {
      selectedCity: null as any,
      handleSearch: debounce((searchText: string) => {
        if (!searchText.length) return
        this.$store.dispatch(`cities/${CitiesActions.SEARCH_CITY_AUTOCOMPLETE}`,
          {
            pageNumber: 1,
            pageSize: 1000, // we need to get all the data
            search: searchText
          }
        )
      }, 1000),
      searchable: false
    }
  },
  computed: {
    drivers (vm: any) {
      const cities = this.$store.getters['cities/getCities']
        .map((city: any) => ({ name: city.name, id: city.id }))
      const selected = vm.selectedCity as any
      if (selected) {
        const hasSelected = cities.findIndex((d:any) => d.id === selected.id)
        return hasSelected > -1 ? cities : [selected, ...cities]
      }
      return cities
    },
    driverLoading (): boolean {
      return this.$store.getters['cities/getLoading']
    }
  },
  watch: {
    selectedCity (newValue) {
      this.$emit('update:modelValue', newValue)
    },
    modelValue (newValue) {
      this.selectedCity = newValue
    }
  },
  mounted () {
    this.$store.dispatch(`cities/${CitiesActions.SEARCH_CITY_AUTOCOMPLETE}`, {
      pageNumber: 1,
      pageSize: 100 // we need to get all the data
    })
  }
})
