import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-14 pb-5 px-5" }
const _hoisted_2 = { class: "flex justify-between mb-7" }
const _hoisted_3 = { class: "flex justify-start w-full" }
const _hoisted_4 = { class: "flex justify-between w-full" }
const _hoisted_5 = { class: "relative h-42 w-288 me-3" }
const _hoisted_6 = { class: "px-5" }
const _hoisted_7 = { class: "text-textColor font-bold text-md mb-" }
const _hoisted_8 = { class: "p-6" }
const _hoisted_9 = { class: "font-bold" }
const _hoisted_10 = { class: "flex gap-4 mt-2" }
const _hoisted_11 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_DynamicTable = _resolveComponent("DynamicTable")!
  const _component_ClientForm = _resolveComponent("ClientForm")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PageTitle, { title: "clients.title" }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_SearchInput, {
                placeholder: _ctx.$t('clients.search'),
                modelValue: _ctx.searchValue,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event))
              }, null, 8, ["placeholder", "modelValue"])
            ]),
            _createElementVNode("button", {
              class: "primary flex items-center rounded-md px-3",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showCreateClientPopup = true))
            }, _toDisplayString(_ctx.$t('clients.createModal.create')), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_DynamicTable, {
        tableData: _ctx.clients,
        loading: _ctx.loading,
        pagination: _ctx.pagination,
        tableFields: _ctx.tableFields,
        scrollable: true,
        buttons: { edit: false, delete: false, export: false},
        onSort: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clickSort($event))),
        onMenuEvent: _cache[3] || (_cache[3] = ($event: any) => (_ctx.menuEvent($event)))
      }, null, 8, ["tableData", "loading", "pagination", "tableFields"])
    ]),
    (_ctx.showCreateClientPopup)
      ? (_openBlock(), _createBlock(_component_BaseModal, {
          key: 0,
          title: _ctx.selectedClient ? _ctx.$t('clients.createModal.edit') : _ctx.$t('clients.createModal.create'),
          width: '675',
          onClose: _ctx.onCancel
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ClientForm, {
              client: _ctx.selectedClient,
              onOnCompleted: _ctx.onCompleteHandler,
              onClose: _ctx.onCancel
            }, null, 8, ["client", "onOnCompleted", "onClose"])
          ]),
          _: 1
        }, 8, ["title", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.confirmObject)
      ? (_openBlock(), _createBlock(_component_ConfirmationModal, {
          key: 1,
          header: _ctx.confirmObject.title,
          hasIcon: false,
          cancelText: _ctx.$t('buttons.no'),
          confirmText: _ctx.$t('buttons.yes'),
          onConfirm: _ctx.confirmObject?.handler,
          onCancel: _cache[4] || (_cache[4] = () => { _ctx.confirmObject = null; _ctx.onCancel() })
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.confirmObject.message), 1)
          ]),
          _: 1
        }, 8, ["header", "cancelText", "confirmText", "onConfirm"]))
      : _createCommentVNode("", true),
    (_ctx.showSecretKeyPopup)
      ? (_openBlock(), _createBlock(_component_BaseModal, {
          key: 2,
          title: _ctx.selectedClient.name,
          width: '420',
          onClose: _ctx.onCancel
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('clients.secretKey')), 1),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("input", {
                  disabled: "",
                  type: "secretKey",
                  class: "w-full px-2 border-gray-400",
                  value: _ctx.selectedClient.secretKey
                }, null, 8, _hoisted_11),
                _createElementVNode("button", {
                  class: "border border-gray-400 px-2 hover:bg-gray-100",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.copySecretKey(_ctx.selectedClient.secretKey)))
                }, _toDisplayString(_ctx.$t('copy')), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["title", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}