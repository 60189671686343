import { MutationTree } from 'vuex'
import { ErpNextState } from '@/store/modules/erp-next/types'

export enum ErpNextMutations {
  SET_MERCHANTS = 'SET_MERCHANTS',
  TRIGGER_LOADING = 'TRIGGER_LOADING'
}

export const mutations: MutationTree<ErpNextState> = {
  [ErpNextMutations.SET_MERCHANTS] (state, payload) {
    state.merchants = payload.data
    state.pagination = payload.pagination
  },
  [ErpNextMutations.TRIGGER_LOADING] (state, bool: boolean) {
    state.loading = bool
  }
}
