import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap p-8" }
const _hoisted_2 = { class: "text-lg text-center w-full mb-4 text-darkPrimary" }
const _hoisted_3 = { class: "border border-l-2 bg-backgroundGray p-2 basis-20 flex-grow-0 flex-shrink-0" }
const _hoisted_4 = { class: "p-2 text text-darkPrimary text-sm text-ellipsis truncate" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = {
  key: 0,
  class: "form-group"
}
const _hoisted_7 = {
  key: 1,
  class: "form-group"
}
const _hoisted_8 = {
  key: 2,
  class: "form-group"
}
const _hoisted_9 = {
  key: 3,
  class: "form-group"
}
const _hoisted_10 = {
  key: 4,
  class: "form-group"
}
const _hoisted_11 = { class: "flex flex-wrap items-center justify-end w-full" }
const _hoisted_12 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputError = _resolveComponent("InputError")!
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.invitationLink)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('addDriver.invitationTitle')), 1),
          _createElementVNode("div", {
            class: "w-full border border-2 border-gray-200 rounded rounded-md flex items-center justify-start overflow-hidden cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyUrl(this.invitationLink)))
          }, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('addDriver.copyUrl')), 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(this.invitationLink), 1)
          ])
        ], 64))
      : (_openBlock(), _createElementBlock("form", {
          key: 1,
          onSubmit: _cache[13] || (_cache[13] = ($event: any) => (_ctx.submitForm($event))),
          class: "w-full"
        }, [
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass([{error: _ctx.v$.email.$error}, "h-42 form-control"]),
              type: "email",
              placeholder: " ",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
              onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.v$.email.$touch && _ctx.v$.email.$touch(...args)))
            }, null, 34), [
              [_vModelText, _ctx.email]
            ]),
            _createElementVNode("label", {
              class: _normalizeClass([{error: _ctx.v$.email.$error}, "floating-label"])
            }, _toDisplayString(_ctx.$t('addDriver.email')), 3),
            (_ctx.v$.email.$error)
              ? (_openBlock(), _createBlock(_component_InputError, {
                  key: 0,
                  error: _ctx.v$.email.email.$invalid ? _ctx.$t('validation.email') : _ctx.$t('validation.required')
                }, null, 8, ["error"]))
              : _createCommentVNode("", true)
          ]),
          (!_ctx.driversOnly)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_Multiselect, {
                  modelValue: _ctx.role,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.role) = $event)),
                  options: _ctx.roles,
                  label: "value",
                  valueProp: "id",
                  object: "true",
                  noOptionsText: _ctx.$t('validation.noItemsToShow'),
                  placeholder: _ctx.$t('placeholders.role')
                }, null, 8, ["modelValue", "options", "noOptionsText", "placeholder"]),
                (_ctx.v$.role.$error)
                  ? (_openBlock(), _createBlock(_component_InputError, {
                      key: 0,
                      error: _ctx.$t('validation.required')
                    }, null, 8, ["error"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isDriver)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_Multiselect, {
                  modelValue: _ctx.darkStore,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.darkStore) = $event)),
                  options: _ctx.darkstores,
                  label: "value",
                  valueProp: "id",
                  object: "true",
                  noOptionsText: _ctx.$t('validation.noItemsToShow'),
                  placeholder: _ctx.$t('placeholders.darkstore'),
                  searchable: _ctx.darkStoreSearchable,
                  onOpen: _cache[5] || (_cache[5] = () => _ctx.darkStoreSearchable = true),
                  onClose: _cache[6] || (_cache[6] = () => _ctx.darkStoreSearchable = false)
                }, null, 8, ["modelValue", "options", "noOptionsText", "placeholder", "searchable"]),
                (_ctx.v$.darkStore.$error)
                  ? (_openBlock(), _createBlock(_component_InputError, {
                      key: 0,
                      error: _ctx.$t('validation.required')
                    }, null, 8, ["error"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isDriver)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_Multiselect, {
                  modelValue: _ctx.shift,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.shift) = $event)),
                  options: _ctx.shifts,
                  label: "value",
                  valueProp: "id",
                  object: "true",
                  noOptionsText: _ctx.$t('validation.noItemsToShow'),
                  placeholder: _ctx.$t('placeholders.shift')
                }, null, 8, ["modelValue", "options", "noOptionsText", "placeholder"]),
                (_ctx.v$.shift.$error)
                  ? (_openBlock(), _createBlock(_component_InputError, {
                      key: 0,
                      error: _ctx.$t('validation.required')
                    }, null, 8, ["error"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isDriver)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_Multiselect, {
                  modelValue: _ctx.vehicle,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.vehicle) = $event)),
                  options: _ctx.vehicles,
                  label: "value",
                  valueProp: "id",
                  object: "true",
                  noOptionsText: _ctx.$t('validation.noItemsToShow'),
                  placeholder: _ctx.$t('placeholders.vehicle'),
                  searchable: _ctx.driverSearchable,
                  onOpen: _cache[9] || (_cache[9] = () => _ctx.driverSearchable = true),
                  onClose: _cache[10] || (_cache[10] = () => _ctx.driverSearchable = false)
                }, null, 8, ["modelValue", "options", "noOptionsText", "placeholder", "searchable"]),
                (_ctx.v$.vehicle.$error)
                  ? (_openBlock(), _createBlock(_component_InputError, {
                      key: 0,
                      error: _ctx.$t('validation.required')
                    }, null, 8, ["error"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isClientAdmin)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_Multiselect, {
                  modelValue: _ctx.client,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.client) = $event)),
                  options: _ctx.clients,
                  label: "name",
                  valueProp: "id",
                  object: "true",
                  noOptionsText: _ctx.$t('validation.noItemsToShow'),
                  placeholder: _ctx.$t('placeholders.client')
                }, null, 8, ["modelValue", "options", "noOptionsText", "placeholder"]),
                (_ctx.v$.client.$error)
                  ? (_openBlock(), _createBlock(_component_InputError, {
                      key: 0,
                      error: _ctx.$t('validation.required')
                    }, null, 8, ["error"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", {
              onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args))),
              class: "secondary m-1 w-32 px-4 py-2"
            }, _toDisplayString(_ctx.$t('buttons.close')), 1),
            _createElementVNode("button", {
              type: "submit",
              class: "primary m-1 w-32 px-4 py-2",
              disabled: _ctx.v$.$invalid
            }, _toDisplayString(_ctx.$t('buttons.inviteUser')), 9, _hoisted_12)
          ])
        ], 32))
  ]))
}