
import { defineAsyncComponent, defineComponent } from 'vue'
import { debounce } from 'lodash'
import { DriverActions } from '@/store/modules/drivers/actions'

export default defineComponent({
  name: 'DriverSelect',
  props: ['modelValue'],
  components: {
    Multiselect: defineAsyncComponent(() => import('@vueform/multiselect'))
  },
  data () {
    return {
      selectedDriver: null as any,
      handleSearch: debounce((searchText: string) => {
        if (!searchText.length) return
        this.$store.dispatch(`drivers/${DriverActions.FETCH_DRIVERS_AUTOCOMPLETE}`,
          {
            pageNumber: 1,
            pageSize: 1000, // we need to get all the data
            search: searchText
          }
        )
      }, 1000),
      driverSearchable: false
    }
  },
  computed: {
    drivers (vm: any) {
      const drivers = this.$store.getters['drivers/getDriversAutocomplete']
        .map((driver: any) => ({ name: driver.userName, id: driver.driverId }))
      const selected = vm.selectedDriver as any
      if (selected) {
        const hasSelected = drivers.findIndex((d:any) => d.id === selected.id)
        return hasSelected > -1 ? drivers : [selected, ...drivers]
      }
      return drivers
    },
    driverLoading (): boolean {
      return this.$store.getters['drivers/getLoading']
    }
  },
  watch: {
    selectedDriver (newValue) {
      this.$emit('update:modelValue', newValue)
    },
    modelValue (newValue) {
      this.selectedDriver = newValue
    }
  }
})
