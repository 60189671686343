import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import { UsersState } from '@/store/modules/users/types'
import { User } from '@/shared/interfaces/user.interface'

export const getters: GetterTree<UsersState, RootState> = {
  getUsers (state): User[] {
    return state.userList
  },
  getPagination (state): PaginationInterface | null {
    return state.pagination
  },
  getLoading (state): boolean {
    return state.loading
  },
  getExportUsers (state): User[] {
    return state.exportUserList
  }
}
