import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { formatDate } from '@/shared/utils/formatDate'
import { AvailableDriver } from '@/shared/interfaces/available-driver.interface'

export class AvailableDriversModel extends CommonModel {
  public data: AvailableDriver[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }
  // lastLogin: formatDate(data.driver_info.last_login, 'dd.MM.YYYY HH:mm'),

  private setData (data: any): AvailableDriver {
    return {
      cityInfo: {
        id: data.city.id,
        name: data.city.name,
        countryName: data.city.country_name
      },
      createdAt: formatDate(data.created_at, 'DD.MM.YYYY HH:mm'),
      vehicleId: data.vehicle_id,
      driverOrdersCount: data.driver_orders_count,
      darkStoreId: data.darkstore_id,
      isClockIn: data.is_clock_in,
      driverInfo: {
        firstName: data.driver_info.first_name,
        lastName: data.driver_info.last_name,
        userName: data.driver_info.username,
        userPhone: data.driver_info.user_phone_number
      },
      driverLicenseNo: data.driver_license_no,
      id: data.id,
      license: data.license,
      driverStatus: {
        id: data.driver_status.id,
        name: data.driver_status.name,
        value: data.driver_status.value
      },
      shift: {
        id: data.shift.id,
        shiftEnd: data.shift.shift_end,
        shiftStart: data.shift.shift_start
      }
    }
  }
}
