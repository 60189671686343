import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { CacheSettlementInterface } from '@/shared/interfaces/cache-settlement.interface'

export class CacheSettlementModel extends CommonModel {
  public data: CacheSettlementInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): CacheSettlementInterface {
    return {
      id: data.id,
      name: data.name
    }
  }
}
