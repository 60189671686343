
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'DateRange',
  setup () {
    const date = ref()
    return {
      date
    }
  },
  mounted () {
    const dpMenu = this.$refs.dpMenuRef
  },
  data () {
    return {
      asd: 'asd'
    }
  }
})
