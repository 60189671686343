
import { defineComponent, PropType } from 'vue'
import { DarkStoreInterface } from '@/shared/interfaces/darkstore.interface'
import { OrderActions } from '@/store/modules/orders/actions'
import { DriverActions } from '@/store/modules/drivers/actions'
import DarkstoreInfoWindowOrderInfoBar from './DarkstoreInfoWindowOrderInfoBar.vue'
import DarkstoreInfoWindowDriver from './DarkstoreInfoWindowDriver.vue'
import moment from 'moment'

export default defineComponent({
  name: 'DarkStoreInfoWindow',
  components: {
    DarkstoreInfoWindowOrderInfoBar,
    DarkstoreInfoWindowDriver
  },
  props: {
    darkstore: {
      type: Object as PropType<DarkStoreInterface>,
      required: true
    },
    vm: {
      type: Object as any,
      required: true
    }
  },
  data () {
    return {
      driverLoading: false,
      orderLoading: false,
      orderStatuses: ['preparing', 'ready_for_pick_up', 'assigned', 'picked_up', 'on_the_way', 'at_the_address'],
      orderInfo: {
        total: 0
      } as any,
      colors: {
        preparing: 'bg-yellow-200',
        ready_for_pick_up: 'bg-yellow-300',
        assigned: 'bg-blue-200',
        picked_up: 'bg-blue-500',
        on_the_way: 'bg-green-300',
        at_the_address: 'bg-pink-300',
        canceled: 'bg-red-500',
        returned: 'bg-red-300',
        delivered: 'bg-green-500'
      },
      drivers: [] as any,
      showAllDrivers: false
    }
  },
  methods: {
    async getOrders () {
      const payload = {
        darkstoreId: this.darkstore.id,
        perPage: 10,
        page: 1,
        fromDate: moment().add(-1, 'day').format('YYYY-MM-DD') + ' ' + moment().format('HH:mm:ss'),
        toDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        orderStatusIds: [1, 2, 3, 4, 5, 6]
      }
      this.orderLoading = true
      const response = await this.vm.$store.dispatch(`orders/${OrderActions.FETCH_DARKSTORE_ORDERS}`, payload)
      if (response.data.data.page_records) {
        const orders = response.data.data.page_records
        this.orderInfo = {
          total: orders.length
        }
        this.orderStatuses.forEach((k) => {
          this.orderInfo[k] = orders.filter((f: any) => f.order_status.name === k).length as any
        })
      }
      this.orderLoading = false
    },
    async getDrivers () {
      this.driverLoading = true
      const response = await this.vm.$store.dispatch(`drivers/${DriverActions.FETCH_DARKSTORE_CLOCKED_IN_DRIVERS}`, this.darkstore.id)
      if (response.data.data.page_records) {
        this.drivers = response.data.data.page_records.map((d:any) => (d.driver_info))
      }
      this.driverLoading = false
    },
    showDrivers () {
      this.showAllDrivers = true
    }
  },
  computed: {
    driverAvatars () {
      return this.drivers.length <= 7 ? this.drivers : this.drivers.slice(0, 7)
    }
  },
  mounted () {
    this.getOrders()
    this.getDrivers()
  }
})
