import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { UsersState } from '@/store/modules/users/types'

const state: UsersState = {
  userList: [],
  pagination: null,
  loading: false,
  exportUserList: []
}

export const users: Module<UsersState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
