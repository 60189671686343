<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1671_386)">
      <path d="M17.6719 11.2969H15.5625V9.1875C15.5625 8.79919 15.2477 8.48438 14.8594 8.48438C14.4711 8.48438 14.1562 8.79919 14.1562 9.1875V12C14.1562 12.3883 14.4711 12.7031 14.8594 12.7031H17.6719C18.0602 12.7031 18.375 12.3883 18.375 12C18.375 11.6117 18.0602 11.2969 17.6719 11.2969ZM14.8594 2.85938C13.5521 2.85938 12.3 3.12647 11.1417 3.65152L3.32292 7.13906C3.07458 7.24983 2.91239 7.49386 2.90639 7.76573C2.90044 8.03756 3.0517 8.28853 3.29494 8.41012L4.83923 9.1823L0.392812 11.3691C0.152344 11.4873 0 11.732 0 12C0 12.268 0.152344 12.5127 0.392812 12.6309L4.83919 14.8177L3.29489 15.5899C3.0517 15.7115 2.90039 15.9624 2.90634 16.2343C2.91234 16.5061 3.07453 16.7501 3.32288 16.8609L11.1377 20.3467C12.2999 20.8735 13.552 21.1406 14.8594 21.1406C19.8995 21.1406 24 17.0401 24 12C24 6.95986 19.8995 2.85938 14.8594 2.85938ZM14.8594 19.7344C13.7537 19.7344 12.6969 19.5095 11.7145 19.0641L5.25412 16.1825L6.73631 15.4414C6.97528 15.3219 7.12589 15.0773 7.125 14.8102C7.12411 14.543 6.97191 14.2994 6.73219 14.1815L2.29636 12L6.73219 9.81844C6.97191 9.70055 7.12411 9.45698 7.125 9.1898C7.12589 8.92266 6.97528 8.67806 6.73631 8.55858L5.25412 7.81748L11.7184 4.93406C12.697 4.49053 13.7537 4.26562 14.8594 4.26562C19.1241 4.26562 22.5938 7.73527 22.5938 12C22.5938 16.2647 19.1241 19.7344 14.8594 19.7344ZM14.8594 5.67188C11.37 5.67188 8.53125 8.51067 8.53125 12C8.53125 15.4893 11.37 18.3281 14.8594 18.3281C18.3487 18.3281 21.1875 15.4893 21.1875 12C21.1875 8.51067 18.3487 5.67188 14.8594 5.67188ZM14.8594 16.9219C12.1455 16.9219 9.9375 14.7139 9.9375 12C9.9375 9.28608 12.1455 7.07812 14.8594 7.07812C17.5733 7.07812 19.7812 9.28608 19.7812 12C19.7812 14.7139 17.5733 16.9219 14.8594 16.9219Z" fill="url(#paint0_linear_1671_386)"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_1671_386" x1="27" y1="16" x2="-3.5" y2="13" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DD504B"/>
        <stop offset="1" stop-color="#F4EDEC"/>
      </linearGradient>
      <clipPath id="clip0_1671_386">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
