import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { apiEndpoints } from '@/shared/endpoints/api'
import axiosInstance from '@/shared/utils/axiosInstance'
import { ErpNextState } from '@/store/modules/erp-next/types'
import { ErpNextMutations } from '@/store/modules/erp-next/mutations'

export enum ErpNextActions {
  FETCH_ERP_NEXT_MERCHANTS = 'FETCH_ERP_NEXT_MERCHANTS',
  TRIGGER_LOADING = 'TRIGGER_LOADING',
}

export const actions: ActionTree<ErpNextState, RootState> = {
  [ErpNextActions.FETCH_ERP_NEXT_MERCHANTS]: async (context, payload) => {
    context.commit(ErpNextMutations.TRIGGER_LOADING, true)
    await axiosInstance({
      ...apiEndpoints.erpNext.merchants
    }).then((resp: any) => {
      context.commit(ErpNextActions.TRIGGER_LOADING, false)
      context.commit(ErpNextMutations.SET_MERCHANTS, resp.data)
    }).catch((err: any) => {
      context.commit(ErpNextMutations.TRIGGER_LOADING, false)
      throw new Error(err)
    })
  }
}
