import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import client from 'axios'
import { apiEndpoints } from '@/shared/endpoints/api'
import { WorkShiftModel } from '@/shared/models/work-shift.model'
import { WorkShiftMutations } from '@/store/modules/work-shifts/mutations'
import { WorkShiftsState } from '@/store/modules/work-shifts/types'
import { UserRequestsModel } from '@/shared/models/user-requests.model'
import moment from 'moment'
import axiosInstance from '@/shared/utils/axiosInstance'

export enum WorkShiftActions {
  FETCH_WORK_SHIFTS = 'FETCH_WORK_SHIFTS',
  FETCH_USER_REQUESTS = 'FETCH_USER_REQUESTS',
  CREATE_SHIFT = 'CREATE_SHIFT',
  UPDATE_SHIFT = 'UPDATE_SHIFT',
  APPROVE_REQUEST = 'APPROVE_REQUEST',
  APPROVE_CLOCKOUT = 'APPROVE_CLOCKOUT'
}

export const actions: ActionTree<WorkShiftsState, RootState> = {
  [WorkShiftActions.FETCH_WORK_SHIFTS]: async (context, payload) => {
    context.commit(WorkShiftMutations.TRIGGER_LOADING, true)
    const queryParams = new URLSearchParams()
    if (payload.search && payload.search !== '') {
      queryParams.append('search', payload.search)
    }
    if (payload.darkstoreIds && payload.darkstoreIds.split(':')[1] !== '') {
      payload.darkstoreIds.split(':')[1].split(',').forEach((el: any) => {
        queryParams.append('dark_store_ids', el)
      })
    }
    if (payload && payload.pageNumber) {
      queryParams.append('page_number', payload.pageNumber)
    }
    if (payload && payload.pageSize) {
      queryParams.append('per_page', payload.pageSize)
    }
    if (payload && payload.darkstoreId) {
      queryParams.append('dark_store_ids', payload.darkstoreId)
    }
    await axiosInstance({
      method: apiEndpoints.workShifts.list.method,
      url: apiEndpoints.workShifts.list.url,
      params: queryParams
    }).then(resp => {
      context.commit(WorkShiftMutations.TRIGGER_LOADING, false)
      const workShiftModel = new WorkShiftModel(resp.data)
      const payload = {
        data: workShiftModel.data,
        pagination: workShiftModel.paginationData
      }
      context.commit(WorkShiftMutations.INSERT_WORK_SHIFTS, payload)
    }).catch(err => {
      context.commit(WorkShiftMutations.TRIGGER_LOADING, false)
      throw new Error(err)
    })
  },

  [WorkShiftActions.CREATE_SHIFT]: async (context, data): Promise<any> => {
    context.commit(WorkShiftMutations.TRIGGER_LOADING, true)
    return await axiosInstance({
      method: apiEndpoints.workShifts.create.method,
      url: apiEndpoints.workShifts.create.url,
      data: data.payload
    }).then(resp => {
      context.commit(WorkShiftMutations.TRIGGER_LOADING, false)
      return resp
    }).catch(err => {
      context.commit(WorkShiftMutations.TRIGGER_LOADING, false)
      throw new Error(err)
    })
  },

  [WorkShiftActions.UPDATE_SHIFT]: async (context, data): Promise<any> => {
    context.commit(WorkShiftMutations.TRIGGER_LOADING, true)
    return await axiosInstance({
      method: apiEndpoints.workShifts.update.method,
      url: `${apiEndpoints.workShifts.update.url}${data.shiftId}`,
      data: data.payload
    }).then(resp => {
      context.commit(WorkShiftMutations.TRIGGER_LOADING, false)
      return resp
    }).catch(err => {
      context.commit(WorkShiftMutations.TRIGGER_LOADING, false)
      throw new Error(err)
    })
  },

  [WorkShiftActions.FETCH_USER_REQUESTS]: (context, payload) => {
    if (!payload.export) {
      context.commit(WorkShiftMutations.TRIGGER_REQUEST_LOADING, true)
    }
    const params = new URLSearchParams()
    params.append('page_number', payload.pageNumber)
    params.append('per_page', payload.pageSize)
    if (payload.name && payload.name !== '') {
      params.append('search', payload.name)
    }
    if (payload.isClockIn) {
      params.append('is_clock_in', payload.isClockIn)
    }
    if (payload.sort) {
      params.append('order_by', `${payload.orderBy}_${payload.sort === 'desc' ? 'desc' : 'asc'}`)
    } else {
      params.append('order_by', 'created_at_desc')
    }
    if (payload.isClockOut) {
      params.append('is_clock_out', payload.isClockOut)
    }
    if (payload.isClockOutApproved) {
      params.append('is_clock_out_approved', payload.isClockOutApproved)
    }
    if (payload.surname && payload.surname !== '') {
      params.append('last_name', payload.surname)
    }
    if (payload.requestDate_from) {
      params.append('from_date', moment(payload.requestDate_from).format('YYYY-MM-DD hh:mm:ss'))
      params.append('to_date', moment(payload.requestDate_to).format('YYYY-MM-DD hh:mm:ss'))
    }
    if (payload.darkstoreIds && payload.darkstoreIds.split(':')[1] !== '') {
      payload.darkstoreIds.split(':')[1].split(',').forEach((el: any) => {
        params.append('dark_store_ids', el)
      })
    }
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.workShifts.requests.method,
        url: apiEndpoints.workShifts.requests.url,
        params
      }).then(resp => {
        context.commit(WorkShiftMutations.TRIGGER_REQUEST_LOADING, false)
        const requeststModel = new UserRequestsModel(resp.data)
        const res = {
          data: requeststModel.data,
          pagination: requeststModel.paginationData
        }
        payload.export ? context.commit(WorkShiftMutations.INSERT_USER_EXPORT_REQUESTS, res)
          : context.commit(WorkShiftMutations.INSERT_USER_REQUESTS, res)
        resolve(res)
      }).catch(err => {
        context.commit(WorkShiftMutations.TRIGGER_REQUEST_LOADING, false)
        throw new Error(err)
      })
    })
  },

  [WorkShiftActions.APPROVE_REQUEST]: async (context, payload) => {
    await axiosInstance({
      method: apiEndpoints.workShifts.approve.method,
      url: apiEndpoints.workShifts.approve.url + payload.requestId,
      data: payload.data,
      params: { is_overtime_requested: true }
    }).catch(err => {
      throw new Error(err)
    })
  },

  [WorkShiftActions.APPROVE_CLOCKOUT]: (context, payload) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.workShifts.approveClockout.method,
        url: apiEndpoints.workShifts.approveClockout.url + payload.requestId,
        data: payload.data
      }).then((res) => {
        resolve(res)
      }).catch(err => {
        reject(err)
        throw new Error(err)
      })
    })
  }
}
