
import { defineComponent } from 'vue'
import { locales, changeLocale } from '@/i18n'

export default defineComponent({
  name: 'LocaleSwitcher',
  data: function () {
    return {
      locales: locales
    }
  },
  methods: {
    setLocale: function (locale: string): void {
      changeLocale(locale)
    }
  }
})
