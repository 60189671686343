import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { apiEndpoints } from '@/shared/endpoints/api'
import axiosInstance from '@/shared/utils/axiosInstance'
import { CitiesState } from '@/store/modules/cities/types'
import { CitiesMutations } from '@/store/modules/cities/mutations'
import { CitiesModel } from '@/shared/models/cities.model'

export enum CitiesActions {
  SEARCH_CITY_AUTOCOMPLETE = 'SEARCH_CITY_AUTOCOMPLETE',
  TRIGGER_LOADING = 'TRIGGER_LOADING',
}

export const actions: ActionTree<CitiesState, RootState> = {
  [CitiesActions.SEARCH_CITY_AUTOCOMPLETE]: async (context, payload) => {
    context.commit(CitiesMutations.TRIGGER_LOADING, true)
    const params = new URLSearchParams()
    if (payload.pageNumber) {
      params.append('page_number', payload.pageNumber)
    }
    if (payload.pageSize) {
      params.append('per_page', payload.pageSize)
    }
    if (payload.query && payload.query !== '') {
      params.append('query', payload.query)
    }
    await axiosInstance({
      ...apiEndpoints.cities.searchAutocomplete,
      params
    }).then((resp: any) => {
      context.commit(CitiesMutations.TRIGGER_LOADING, false)
      const citiesModel = new CitiesModel(resp.data)
      const payload = {
        data: citiesModel.data,
        pagination: citiesModel.paginationData
      }
      context.commit(CitiesMutations.SET_CITIES, payload)
    }).catch((err: any) => {
      context.commit(CitiesMutations.TRIGGER_LOADING, false)
      throw new Error(err)
    })
  }
}
