
import { defineAsyncComponent, defineComponent } from 'vue'
import router from '@/router'
import { Driver } from '@/shared/interfaces/driver.interface'
import { OrderActions } from '@/store/modules/orders/actions'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'
import { DarkstoreDriverRevenueInterface } from '@/shared/interfaces/reports/darkstore-driver-revenue.interface'
import { ReportActions } from '@/store/modules/reports/actions'

export default defineComponent({
  components: {
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    DynamicTable: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTable.vue')),
    Multiselect: defineAsyncComponent(() => import('@vueform/multiselect'))
  },
  data () {
    return {
      darkstoreId: null as any,
      showFilterModal: false,
      tableFields: [
        { id: 'driverId', name: 'Driver Id', width: '10%', sortable: false, currentSort: false, sort: null, sortName: 'order_id', type: 'link' },
        { id: 'username', name: 'Driver', width: '15%', sortable: false, currentSort: false, sort: null },
        { id: 'driverFullName', name: 'Driver Full Name', width: '15%', sortable: false, currentSort: false, sort: null },
        { id: 'orderStatus', name: 'Order Status', width: '20%' },
        { id: 'totalCollectedOrderAmount', name: 'Total Collected Order Amount', width: '20%', sortable: false, currentSort: false, sort: null },
        { id: 'totalSettledOrder', name: 'Total Settled Order', width: '20%', sortable: false, currentSort: false, sort: null },
        { id: 'totalSettledOrderAmount', name: 'Total Settled Order Amount', width: '20%', sortable: false, currentSort: false, sort: null },
        { id: 'totalOrders', name: 'Total Orders', width: '20%', sortable: false, currentSort: false, sort: null },
        { id: 'trackingId', name: 'Tracking Id', width: '15%', sortable: false, currentSort: false, sort: null }
      ],
      filters: [
        { name: this.$t('filters.date'), model: 'orderCreatedAt', type: 'date', calendarType: 'range' },
        {
          name: this.$t('filters.orderStatus'),
          model: 'status',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'orderStatus',
          type: 'radio',
          data: []
        },
        { name: this.$t('filters.trackingId'), model: 'trackingId', type: 'number' }
      ],
      selectedReport: this.reportType,
      exportData: [] as any,
      exportFields: [
        { label: 'Driver ID', field: 'driverId' },
        { label: 'Username', field: 'username' },
        { label: 'Driver Full Name', field: 'driverFullName' },
        { label: 'Order Status', field: 'orderStatusId' },
        { label: 'Total Orders', field: 'totalOrders' },
        { label: 'Total Collected Order Amount', field: 'totalCollectedOrderAmount' },
        { label: 'Order ID', field: 'orderId' },
        { label: 'Order Status', field: 'orderStatus' },
        { label: 'Order Total', field: 'orderTotal' },
        { label: 'Order Created At', field: 'orderCreatedAtDate' },
        { label: 'Total Settled Order', field: 'totalSettledOrder' },
        { label: 'Total Settled Order Amount', field: 'totalSettledOrderAmount' },
        { label: 'Settlement Date', field: 'settlementDate' },
        { label: 'Settlement No (Smart ID)', field: 'settlementNo' },
        { label: 'Settlement Status', field: 'settlementStatus' },
        { label: 'Admin', field: 'adminUsername' },
        { label: 'Tracking Id', field: 'trackingId' }
      ] as any,
      exportLoading: false
    }
  },
  methods: {
    menuEvent (e: any): void {
      console.log(e)
    },
    onExpandPanelClick (eventName: string): void {
      if (eventName === 'orderStatus') {
        this.$store.dispatch(`orders/${OrderActions.FETCH_ORDER_STATUSES}`)
      }
    },
    clickSort (field: any): void {
      this.tableFields = this.tableFields?.map((el: any) => {
        if (el.id === field.id) {
          el.currentSort = true
          if (el.sort === null) {
            el.sort = 'asc'
          } else if (el.sort === 'asc') {
            el.sort = 'desc'
          } else if (el.sort === 'desc') {
            el.sort = null
          }
        } else {
          el.currentSort = false
          el.sort = null
        }
        return el
      })
      router.push({ query: { ...this.$route.query, orderBy: field.sortName, sort: field.sort } })
    },
    onRedirectToDriver (item: any): void {
      router.push(`/profile/${item.driverId}`)
    },
    exportTableToExcel (event: any) {
      event.stopPropagation()
      this.exportLoading = true
      this.$store.dispatch(`reports/${[ReportActions.FETCH_REPORTS]}`, { reportType: this.reportType, export: true, pageNumber: 1, pageSize: this.pagination?.totalItems || 100, ...this.$route.query })
        .then((res: any) => {
          const button = document.getElementById('excel-export')
          res.data.forEach((item: any) => {
            const obj = {
              driverId: item.driverId,
              username: item.username,
              driverFullName: item.driverFullName,
              orderStatusId: item.orderStatus,
              totalOrders: item.totalOrders,
              totalCollectedOrderAmount: item.totalCollectedOrderAmount,
              totalSettledOrder: item.totalSettledOrder,
              totalSettledOrderAmount: item.totalSettledOrderAmount,
              trackingId: item.trackingId,
              orderId: '',
              orderStatus: '',
              orderTotal: '',
              orderCreatedAtDate: '',
              orderDeliveredAt: '',
              settlementDate: '',
              settlementNo: '',
              settlementStatus: '',
              adminUsername: ''
            }
            this.exportData.push({ ...obj })
            item.items.map((child: any) => {
              obj.driverId = ''
              obj.username = ''
              obj.driverFullName = ''
              obj.orderStatusId = ''
              obj.totalOrders = ''
              obj.totalCollectedOrderAmount = '+'
              obj.totalSettledOrder = item.totalSettledOrder
              obj.totalSettledOrderAmount = item.totalSettledOrderAmount
              obj.trackingId = ''
              obj.orderId = child.order_id
              obj.orderStatus = child.order_status
              obj.orderTotal = child.order_total
              obj.orderCreatedAtDate = child.order_created_at
              obj.orderDeliveredAt = child.order_delivered_at
              obj.settlementDate = child.settlement_date
              obj.settlementNo = child.settlement_no
              obj.settlementStatus = child.settlement_status
              obj.adminUsername = child.admin_username
              this.exportData.push({ ...obj })
            })
            obj.driverId = ''
            obj.username = ''
            obj.driverFullName = ''
            obj.orderStatusId = ''
            obj.totalOrders = ''
            obj.totalCollectedOrderAmount = ''
            obj.trackingId = ''
            obj.totalSettledOrder = ''
            obj.totalSettledOrderAmount = ''
            obj.orderId = ''
            obj.orderStatus = ''
            obj.orderTotal = ''
            obj.orderCreatedAtDate = ''
            obj.orderDeliveredAt = ''
            obj.settlementDate = ''
            obj.settlementNo = ''
            obj.settlementStatus = ''
            obj.adminUsername = ''
            this.exportData.push({ ...obj })
          })
          if (button) {
            setTimeout(() => {
              button.click()
              this.exportLoading = false
            }, 200)
          }
        })
        .finall(() => {
          this.exportLoading = false
        })
    }
  },
  watch: {
    darkstoreId (val) {
      let queries = this.$route.query
      queries.pageNumber = '1'
      if (val === null) {
        queries = {
          pageNumber: '1'
        }
        this.showFilterModal = false
      }
      router.push({ query: { ...queries, darkstoreId: val ? val as any : undefined } })
    },
    $route: {
      immediate: true,
      handler (val: any) {
        if (val.query.orderBy) {
          this.tableFields = this.tableFields.map((el: any) => {
            if (el.id === val.query.orderBy) {
              el.currentSort = true
              el.sort = val.query.sort
            }
            return el
          })
        }
      }
    },
    selectedReport (val) {
      if (val) {
        router.push({ query: { reportName: val.name } })
      }
    },
    statuses (val: any) {
      if (!val.length) return
      const filterItems = val.map((el: any) => {
        return {
          id: el.id,
          label: el.value
        }
      })
      const target = this.filters[1]
      target.dataRequested = true
      target.data = filterItems
      target.dataLoading = false
    }
  },
  props: {
    reportType: {
      type: String
    }
  },
  computed: {
    reports (): DarkstoreDriverRevenueInterface[] {
      return this.$store.getters['reports/getDarkstoreDriverRevenueReports']
    },
    exportReports () {
      return this.$store.getters['reports/getDarkstoreDriverRevenueExportReports']
    },
    pagination () {
      return this.$store.getters['reports/getDarkstoreDriverRevenuePagination']
    },
    loading (): boolean {
      return this.$store.getters['reports/getLoading']
    },
    statuses (): any {
      return this.$store.getters['orders/getOrderStatuses']
    },
    darkstores (): any[] {
      return this.$store.getters['darkstores/getDarkStoresAsDictionary']
    }
  },
  mounted () {
    if (!this.darkstores.length) {
      this.$store.dispatch(`darkstores/${DarkStoresActions.FETCH_DARKSTORES}`, { pageNumber: 1, pageSize: 100 })
    }
  }
})
