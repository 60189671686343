import { GetterTree } from 'vuex'
import { DriversState } from '@/store/modules/drivers/types'
import { RootState } from '@/store/types'
import { Driver } from '@/shared/interfaces/driver.interface'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import { Order } from '@/shared/interfaces/order.interface'

export const getters: GetterTree<DriversState, RootState> = {
  getDrivers (state): Driver[] {
    return state.driversList
  },
  getDriversAutocomplete (state): Driver[] {
    return state.driversListAutocomplete
  },
  getClockedInLocations (state): Driver[] {
    return state.clockedInLocations
  },
  getDriverStatuses (state): any {
    return state.statuses
  },
  getDriversAsDictation (state): any {
    return state.driversList.map(el => {
      return {
        id: el.id,
        value: el.driverInfo.firstName && el.driverInfo.lastName ? `${el.driverInfo.userName} - ${el.driverInfo.firstName} ${el.driverInfo.lastName}`
          : el.driverInfo.userName
      }
    })
  },
  getDriversWithCount (state): any {
    return state.availableDrivers.map(el => {
      return {
        id: el.id,
        value: el.driverInfo.firstName && el.driverInfo.lastName ? `(${el.driverOrdersCount}) ${el.driverInfo.userName} - ${el.driverInfo.firstName} ${el.driverInfo.lastName}`
          : el.driverInfo.userName
      }
    })
  },
  getDriver (state): Driver {
    return state.driver
  },
  getPagination (state): PaginationInterface | null {
    return state.pagination
  },
  getLoading (state): boolean {
    return state.loading
  },
  getCurrentOrder (state): Order[] | null {
    if (state.currentOrder.length) {
      return state.currentOrder
    } else {
      return null
    }
  },
  getDriverStatus: (state) => (id: number) => {
    let status = ''
    state.driversList.forEach(el => {
      if (el.id === id) {
        status = el.driverStatus.name
      }
    })
    return status
  },
  getTripLocations: (state) => {
    return state.tripLocations
  },
  getTripLocationsLoading: (state) => {
    return state.tripLocationLoading
  },
  getDriversMarkers: (state) => {
    return state.driversMarkers
  }
}
