<template>
  <div class="flex h-screen items-center px-4">
    <div class="w-550 m-auto">
      <div v-if="!sent" class="forgot flex flex-col bg-white">
        <Alert v-if="isError" type="danger" title="error"><p>{{ errorMessage }}</p></Alert>
        <h1 class="font-bold text-3xl text-gray-600 text-start mb-8">{{ $t('auth.verification_header') }}</h1>
        <p class="text-base text-start text-gray-600 mb-2">{{ $t('auth.verification_description') }}</p>
        <p class="text-base text-start text-gray-600 mb-2">{{ $t('auth.verification_message') }} <span class="text-blue-500">{{time}}.</span></p>
        <div class="relative h-42 mt-10 flex justify-center">
          <v-otp-input
            ref="otpInput"
            :model="code"
            input-classes="otp-input"
            separator="-"
            :num-inputs="4"
            :should-auto-focus="true"
            :is-input-num="true"
            :conditionalClass="['one', 'two', 'three', 'four']"
            :placeholder="['', '', '', '']"
            @on-change="handleOnChange"
            @on-complete="handleOnComplete"
          />
        </div>
        <form action="" class="mt-8">
          <div class="relative h-42 mb-8">
            <input v-bind:class="{error: v$.pass.$error}"  :type="this.showPassword ? 'text' : 'password'" placeholder=" " class="h-42 absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent" v-model="pass" @blur="v$.pass.$touch"/>
            <label v-bind:class="{error: v$.pass.$error}" class="absolute text-placeholder top-2 start-4 text-base bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text">Password</label>
            <BaseIcon v-if="showPassword" @click="togglePassword" class="input-icon absolute end-3.5 top-3 cursor-pointer z-30" name="eye"/>
            <BaseIcon v-if="!showPassword" @click="togglePassword" class="input-icon absolute end-3.5 top-3 cursor-pointer z-30" name="eyeoff"/>
            <span v-if="v$.pass.$error" class="flex items-center absolute start-0 -bottom-6 text-10px text-textError">
              <BaseIcon class="me-1" name="alert"/>
              {{ $t('validation.required') }}
          </span>
          </div>
          <div class="relative h-42 mb-7">
            <input v-bind:class="{error: v$.confirm_pass.$error}"  :type="this.showConfirmPassword ? 'text' : 'password'" placeholder=" " class="h-42 absolute p-3.5 top-0 start-0 w-full h-full block appearance-none bg-transparent" v-model="confirm_pass" @blur="v$.confirm_pass.$touch"/>
            <label v-bind:class="{error: v$.confirm_pass.$error}" class="absolute text-placeholder top-2 start-4 text-base bg-white py-0 px-1 -z-1 duration-300 origin-0 cursor-text">Confirm Password</label>
            <BaseIcon v-if="showConfirmPassword" @click="toggleConfirmPassword" class="input-icon absolute end-3.5 top-3 cursor-pointer z-30" name="eye"/>
            <BaseIcon v-if="!showConfirmPassword" @click="toggleConfirmPassword" class="input-icon absolute end-3.5 top-3 cursor-pointer z-30" name="eyeoff"/>
            <span v-if="v$.confirm_pass.$error" class="flex items-center absolute start-0 -bottom-6 text-10px text-textError">
              <BaseIcon class="me-1" name="alert"/>
              {{ $t('validation.same_as') }}
          </span>
          </div>
        </form>
        <div class="mt-8 flex justify-center">
          <button
            :disabled="v$.$invalid"
            class="primary px-20 rounded-full py-2 text-base"
            @click="send">{{ $t('auth.send') }}
          </button>
        </div>
        <div class="relative">
          <router-link to="/auth" class="inline-block text-black font-bold text-blue-500 hover:underline mt-6">{{ $t('auth.back') }}</router-link>
        </div>
      </div>
      <div v-else class="forgot-sent flex flex-col bg-white p-9 rounded-lg shadow border">
        <h1 class="font-normal text-lg text-center">{{ $t('auth.forget_message') }}</h1>
        <div class="relative">
          <router-link to="/auth" class="inline-block text-blue-500 font-bold hover:underline mt-6">{{ $t('auth.back') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, minLength, sameAs } from '@/customValidators'
import Alert from '@/shared/components/Alert.vue'
import client from 'axios'
import VOtpInput from 'vue3-otp-input'
import { apiEndpoints } from '@/shared/endpoints/api'
import BaseIcon from '../../shared/components/base-icon/BaseIcon'
import axiosInstance from '../../shared/utils/axiosInstance'

export default defineComponent({
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      code: '',
      isError: false,
      errorMessage: '',
      sent: false,
      timer: 60,
      time: null,
      interval: '',
      pass: '',
      confirm_pass: '',
      showPassword: false,
      showConfirmPassword: false
    }
  },
  mounted () {
    if (!this.userCredentials) {
      this.$router.push('/auth')
    }
    this.startTimer()
  },
  validations: function () {
    return {
      code: { required },
      pass: { required, minLength: minLength(8) },
      confirm_pass: { required, sameAs: sameAs(this.pass, this.$i18n.t('auth.password')) }
    }
  },
  components: {
    BaseIcon,
    Alert,
    VOtpInput
  },
  computed: {
    userCredentials () {
      return this.$store.getters.getUserCredentials
    }
  },
  methods: {
    send: async function () {
      // check form validation
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      await axiosInstance[apiEndpoints.reset.method](apiEndpoints.reset.url,
        {
          user_email: this.userCredentials.user_email,
          new_password: this.pass,
          code: this.code
        }
      ).then((response) => {
        this.email = ''
        this.sent = true
        this.isError = false
        this.v$.$reset()
        this.errorMessage = ''
        this.$router.push('/auth')
      }, (err) => {
        this.isError = true
        this.errorMessage = err
      })
    },
    togglePassword () {
      this.showPassword = !this.showPassword
    },
    toggleConfirmPassword () {
      this.showConfirmPassword = !this.showConfirmPassword
    },
    handleOnComplete (value) {
      this.code = value
    },

    handleOnChange (value) {
      this.code = null
    },
    startTimer () {
      this.interval = setInterval(() => {
        if (this.timer === 0) {
          clearInterval(this.interval)
          this.$router.push('/auth')
        }
        this.timer--
        this.time = this.convertHMS(this.timer)
      }, 1000)
    },
    convertHMS (value) {
      const sec = parseInt(value, 10) // convert value to number if it's string
      let hours = Math.floor(sec / 3600) // get hours
      let minutes = Math.floor((sec - (hours * 3600)) / 60) // get minutes
      let seconds = sec - (hours * 3600) - (minutes * 60) //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      if (hours < 10) { hours = '0' + hours }
      if (minutes < 10) { minutes = '0' + minutes }
      if (seconds < 10) { seconds = '0' + seconds }
      return minutes + ':' + seconds // Return is HH : MM : SS
    }
  }
})
</script>
<style>
.otp-input{
  width: 40px;
  padding: 14px;
  font-size: 16px;
}
</style>

<style lang="scss" scoped>
input[type='text'], input[type='password'] {
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-radius: 0px;
}
</style>
