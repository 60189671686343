import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import routesAccount from '@/views/account/router'
import routesAuth from '@/views/auth/router'
import routesOrder from '@/views/order/router'
import store from '@/store/index'
import moment from 'moment'
import Reports from '@/views/account/reports/Reports.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Account',
    component: () => import('../views/account/Account.vue'),
    children: routesAccount,
    beforeEnter: function (to, from, next) {
      if (store.getters.isAuthenticated) next()
      else next({ path: 'auth', query: { redirect: to.path } })
    }
  }, {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/auth/Auth.vue'),
    children: routesAuth,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isAuthenticated) next()
      else next({ path: from.path })
    }
  }, {
    path: '/order',
    name: 'Order',
    component: () => import('../views/order/Order.vue'),
    children: routesOrder
  }, {
    path: '/user-invitation',
    name: 'UserInvitation',
    component: () => import('../views/auth/UserInvitation.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated) {
        store.dispatch('logout', false).then(() => {
          next()
        })
      } else {
        next()
      }
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const defaultValues = {
  view_drivers_productivity_per_day: {
    values: [
      { field: 'productivityDate', value: moment().format('YYYY-MM-DDT00:00') }
    ]
  },
  view_orders_and_drivers: {
    values: [
      { field: 'createdAt_from', value: moment().format('YYYY-MM-DDT00:00') },
      { field: 'createdAt_to', value: moment().format('YYYY-MM-DDTHH:mm') }
    ]
  },
  view_drivers_productivity_per_month: {
    values: [
      { field: 'dates_month', value: moment().format('MM') },
      { field: 'dates_year', value: moment().format('YYYY') }
    ]
  },
  view_orders_by_status_per_day: {
    values: [
      { field: 'datetime', value: moment().format('YYYY-MM-DDT00:00:00') }
    ]
  },
  view_orders_by_status_per_month: {
    values: [
      { field: 'dates_month', value: moment().format('MM') },
      { field: 'dates_year', value: moment().format('YYYY') }
    ]
  },
  view_orders_timing: {
    values: [
      { field: 'createdAt_from', value: moment().format('YYYY-MM-DDT00:00') },
      { field: 'createdAt_to', value: moment().format('YYYY-MM-DDTHH:mm') }
    ]
  },
  view_drivers_timing_per_month: {
    values: [
      { field: 'dates_month', value: moment().format('MM') },
      { field: 'dates_year', value: moment().format('YYYY') }
    ]
  },
  view_drivers_timing_per_day: {
    values: [
      { field: 'datetime', value: moment().format('YYYY-MM-DDT00:00:00') }
    ]
  },
  Drivers_activity_per_darkstore_per_shift: {
    values: [
      { field: 'activity_from', value: moment().format('YYYY-MM-DDT00:00:00') },
      { field: 'activity_to', value: moment().format('YYYY-MM-DDTHH:mm') }
    ]
  },
  Drivers_productivity: {
    values: [
      { field: 'createdAt_from', value: moment().format('YYYY-MM-DDT00:00') },
      { field: 'createdAt_to', value: moment().format('YYYY-MM-DDTHH:mm') }
    ]
  },
  Driver_delivery: {
    values: [
      { field: 'createdAt_from', value: moment().format('YYYY-MM-DDT00:00') },
      { field: 'createdAt_to', value: moment().format('YYYY-MM-DDTHH:mm') }
    ]
  },
  Driver_revenue: {
    values: [
      { field: 'orderCreatedAt_from', value: moment().format('YYYY-MM-DDT00:00') },
      { field: 'orderCreatedAt_to', value: moment().format('YYYY-MM-DDTHH:mm') }
    ]
  },
  Darkstore_driver_revenue: {
    values: [
      { field: 'orderCreatedAt_from', value: moment().format('YYYY-MM-DDT00:00') },
      { field: 'orderCreatedAt_to', value: moment().format('YYYY-MM-DDTHH:mm') }
    ]
  },
  Aggregate_darkstore_revenue: {
    values: [
      { field: 'orderCreatedAt_from', value: moment().format('YYYY-MM-DDT00:00') },
      { field: 'orderCreatedAt_to', value: moment().format('YYYY-MM-DDTHH:mm') }
    ]
  },
  Darkstore_delivery_metrics: {
    values: [
      { field: 'createdAt_from', value: moment().format('YYYY-MM-DDT00:00') },
      { field: 'createdAt_to', value: moment().format('YYYY-MM-DDTHH:mm') }
    ]
  },
  Zone_delivery_metrics: {
    values: [
      { field: 'createdAt_from', value: moment().format('YYYY-MM-DDT00:00') },
      { field: 'createdAt_to', value: moment().format('YYYY-MM-DDTHH:mm') }
    ]
  },
  Driver_performance: {
    values: [
      { field: 'locationDate_from', value: moment().format('YYYY-MM-DDT00:00') },
      { field: 'locationDate_to', value: moment().format('YYYY-MM-DDTHH:mm') }
    ]
  },
  Orders_settlement: {
    values: [
      { field: 'locationDate_from', value: moment().format('YYYY-MM-DDT00:00') },
      { field: 'locationDate_to', value: moment().format('YYYY-MM-DDTHH:mm') }
    ]
  },
  Admin_wallet: {
    values: [
      { field: 'locationDate_from', value: moment().format('YYYY-MM-DDT00:00') },
      { field: 'locationDate_to', value: moment().format('YYYY-MM-DDTHH:mm') }
    ]
  }
} as any

const getDefaultValues = (reportName: string, query: any) => {
  const config = defaultValues[reportName]
  let defaultQueries = { ...query }
  if (config && config.values) {
    config.values.map((v: any) => {
      const { field, value } = v
      defaultQueries = {
        ...defaultQueries,
        [field]: value
      }
    })
  }
  return defaultQueries
}
const hasDefaultValues = (reportName: string, query: any) => {
  const config = defaultValues[reportName]
  if (config && config.values) {
    for (let i = 0; i < config.values.length; i++) {
      const field = config.values[i].field
      if (query[field] === undefined) {
        return false
      }
    }
  }
  return true
}

const isClientAdmin = store?.getters.isClientAdmin

router.beforeEach((to, from, next) => {
  if (isClientAdmin && to.name !== 'ClientOrders') {
    next('/client-orders')
  }
  if (to.name === 'Drivers' && to.query.isClockedIn === undefined) {
    next('/drivers?isClockedIn=1')
  }
  if (to.name === 'Reports' && to.params.reportName && !hasDefaultValues(to.params.reportName as string, to.query)) {
    const defaultQuery = getDefaultValues(to.params.reportName as string, to.query)
    to.query = defaultQuery
    next({ path: to.path, query: defaultQuery, force: true })
    return
  }
  next()
})

export default router
