

import { defineComponent, defineAsyncComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@/customValidators'
import { ClientActions } from '@/store/modules/clients/actions'
import { ErpNextActions } from '@/store/modules/erp-next/actions'

export default defineComponent({
  name: 'CreateOrderDarkstore',
  components: {
    Multiselect: defineAsyncComponent(() => import('@vueform/multiselect')),
    DarkstoreSelect: defineAsyncComponent(() => import('@/views/account/orders/components/DarkstoreSelect.vue'))
  },
  props: {
    client: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      statuses: ['SUBMITTED', 'ACTIVE', 'INACTIVE'],
      form: {
        name: '',
        description: '',
        status: '',
        erpnext_merchant: '',
        darkstore: undefined as any
      }
    }
  },
  validations: function () {
    return {
      form: {
        name: { required },
        status: { required },
        erpnext_merchant: { required },
        darkstore: { required }
      }
    }
  },
  setup (_, { emit }) {
    const closeModal = ($event: any, out = true) => {
      if (out && !$event.inner) {
        emit('close', false)
      } else $event.inner = true
    }
    return {
      closeModal,
      v$: useVuelidate()
    }
  },
  methods: {
    async submitForm (validator: any): Promise<any> {
      validator.$touch()

      if (validator.$invalid) return
      if (!validator.$invalid) {
        if (this.client) {
          this.$store.dispatch(`clients/${[ClientActions.UPDATE_CLIENT]}`, {
            id: this.client.id,
            name: this.form.name,
            description: this.form.description,
            erpnext_merchant: this.form.erpnext_merchant,
            status: this.form.status,
            darkstore_id: this.form.darkstore.id
          }).then(() => {
            this.$emit('onCompleted')
          })
        } else {
          this.$store.dispatch(`clients/${[ClientActions.CREATE_CLIENT]}`, {
            name: this.form.name,
            description: this.form.description,
            erpnext_merchant: this.form.erpnext_merchant,
            status: this.form.status,
            darkstore_id: this.form.darkstore.id
          }).then(() => {
            this.$emit('onCompleted')
          })
        }
      }
    }
  },
  computed: {
    merchants (): any[] {
      return this.$store.getters['erpNext/getMerchants']
    }
  },
  created () {
    this.$store.dispatch(`erpNext/${[ErpNextActions.FETCH_ERP_NEXT_MERCHANTS]}`)
  },
  mounted () {
    if (this.client) {
      this.form = {
        name: this.client?.name ?? '',
        description: this.client?.description ?? '',
        status: this.client?.status ?? '',
        erpnext_merchant: this.client?.erpnextMerchant ?? '',
        darkstore: this.client.darkstore ?? undefined as any
      }
    }
  }
})
