import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { apiEndpoints } from '@/shared/endpoints/api'
import axiosInstance from '@/shared/utils/axiosInstance'
import { NotificationsState } from '@/store/modules/notifications/types'
import { NotificationsMutations } from '@/store/modules/notifications/mutations'
import { NotificationsModel } from '@/shared/models/notifications.model'

export enum NotificationActions {
  FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS',
  MARK_AS_READ = 'MARK_AS_READ'
}

export const actions: ActionTree<NotificationsState, RootState> = {
  [NotificationActions.MARK_AS_READ]: async (context, payload) => {
    return 'ok'
  },
  [NotificationActions.FETCH_NOTIFICATIONS]: async (context, payload) => {
    context.commit(NotificationsMutations.TRIGGER_LOADING, true)
    const params = new URLSearchParams()
    if (payload.pageNumber) {
      params.append('page_number', payload.pageNumber)
    }
    if (payload.pageSize) {
      params.append('per_page', payload.pageSize)
    }
    await axiosInstance({
      method: apiEndpoints.notifications.list.method,
      url: apiEndpoints.notifications.list.url,
      params
    }).then((resp: any) => {
      context.commit(NotificationsMutations.TRIGGER_LOADING, false)
      const notificationsModel = new NotificationsModel(resp.data)
      const payload = {
        data: notificationsModel.data,
        pagination: notificationsModel.paginationData
      }
      context.commit(NotificationsMutations.SET_NOTIFICATIONS, payload)
    }).catch((err: any) => {
      context.commit(NotificationsMutations.TRIGGER_LOADING, false)
      throw new Error(err)
    })
  }
}
