import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c9098ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full text-left text-xs" }
const _hoisted_2 = { class: "text-black font-bold" }
const _hoisted_3 = { class: "bg-gray-100 p-3 rounded-lg text-gray-500 font-bold mt-2" }
const _hoisted_4 = { class: "flex flex-col gap-1 content scrollable-content" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "px-2" }
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "px-2" }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = { class: "px-2" }
const _hoisted_11 = { class: "flex" }
const _hoisted_12 = { class: "px-2" }
const _hoisted_13 = { class: "flex" }
const _hoisted_14 = { class: "px-2" }
const _hoisted_15 = { class: "flex" }
const _hoisted_16 = { class: "px-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('lts.tripSummary.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('lts.tripSummary.totalDistance')) + ": ", 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.totalDistance), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('lts.tripSummary.totalTime')) + ": ", 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.summary && _ctx.summary.total_time ? _ctx.formatTime(_ctx.summary.total_time)  : '-'), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('lts.tripSummary.maxSpeed')) + ": ", 1),
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.summary && _ctx.summary.max_speed ? `${_ctx.summary.max_speed} km/h` : '-'), 1)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('lts.tripSummary.minSpeed')) + ": ", 1),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.summary && _ctx.summary.min_speed > -1 ? `${_ctx.summary?.min_speed} km/h` : '-'), 1)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('lts.tripSummary.averageSpeed')) + ": ", 1),
          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.summary && _ctx.summary.average_speed ? `${Number(_ctx.summary?.average_speed).toFixed(1)} km/h` : '-'), 1)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('lts.tripSummary.totalWaypoints')) + ": ", 1),
          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.summary?.total_waypoints ?? '-'), 1)
        ])
      ])
    ])
  ]))
}