import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4" }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-end gap-4 items-center"
}
const _hoisted_3 = {
  key: 1,
  class: "flex justify-center gap-4 items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.data.status === 'requested')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "bg-green-500 text-white hover:bg-green-600 flex items-center px-5 py-1 text-xs",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('approve', _ctx.data)), ["prevent","stop"]))
          }, _toDisplayString(_ctx.$t('cacheSettlements.approve')), 1),
          _createElementVNode("button", {
            class: "bg-red-500 text-white hover:bg-red-700 border flex items-center px-5 py-1 text-xs",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('reject', _ctx.data)), ["prevent","stop"]))
          }, _toDisplayString(_ctx.$t('cacheSettlements.reject')), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.data.erpSyncStatus === 'failed' ||
            (_ctx.data.status === 'approved' && _ctx.data.erpSyncStatus === 'init'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "bg-blue-500 text-white hover:bg-blue-600 flex items-center px-5 py-1 text-xs",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('retryErpSync', _ctx.data)), ["prevent","stop"]))
          }, _toDisplayString(_ctx.$t('cacheSettlements.retryErpSync')), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}