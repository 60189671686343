
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OrderStatusTime',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleOrder () {
      this.isOpen = !this.isOpen
    }
  }
})
