import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pt-14 pb-5 px-5" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "flex pb-4" }
const _hoisted_4 = { class: "relative h-42 w-288 me-3" }
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "px-5"
}
const _hoisted_8 = {
  key: 1,
  class: "flex items-center justify-center p-10"
}
const _hoisted_9 = { class: "text-xl font-bold text-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_FilterPanel = _resolveComponent("FilterPanel")!
  const _component_DynamicTable = _resolveComponent("DynamicTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_PageTitle, { title: "reports.aggregateDarkstoreRevenue.title" })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Multiselect, {
            modelValue: _ctx.darkstoreId,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.darkstoreId) = $event)),
            options: _ctx.darkstores,
            label: "value",
            valueProp: "id",
            noOptionsText: _ctx.$t('validation.noItemsToShow'),
            placeholder: _ctx.$t('workShifts.darkstore')
          }, null, 8, ["modelValue", "options", "noOptionsText", "placeholder"])
        ]),
        (_ctx.darkstoreId)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showFilterModal = true)),
              class: "border border-inputBorder w-42 h-42"
            }, [
              _createElementVNode("span", _hoisted_5, [
                _createVNode(_component_BaseIcon, { name: "filter" })
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.showFilterModal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_FilterPanel, {
                filters: _ctx.filters,
                onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showFilterModal = false)),
                onFetchData: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onExpandPanelClick($event)))
              }, null, 8, ["filters"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.darkstoreId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_DynamicTable, {
            tableData: _ctx.reports,
            loading: _ctx.loading,
            reportType: _ctx.reportType,
            pagination: _ctx.pagination,
            tableFields: _ctx.tableFields,
            exportData: _ctx.exportReports,
            exportFields: _ctx.exportFields,
            scrollable: true,
            onSort: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clickSort($event))),
            buttons: { edit: true, delete: true, export: true},
            onMenuEvent: _cache[5] || (_cache[5] = ($event: any) => (_ctx.menuEvent($event))),
            onGetReports: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('getReports', $event)))
          }, null, 8, ["tableData", "loading", "reportType", "pagination", "tableFields", "exportData", "exportFields"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('reports.darkstoreDriverRevenue.selectDarkstoreMessage')), 1)
        ]))
  ], 64))
}