import { RouteRecordRaw } from 'vue-router'

const routesOrder: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'OrderTracker',
    component: () => import('./Track.vue')
  }
]

export default routesOrder
