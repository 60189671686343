import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { locationsApiBase } from '@/shared/endpoints/api'
import { driverEndpoints } from '@/shared/endpoints/driver'
import axiosInstance from '@/shared/utils/axiosInstance'
import { LocationState } from '@/store/modules/locations/types'
export enum LocationActions {
  FETCH_DRIVER_LAST_LOCATION = 'FETCH_DRIVER_LAST_LOCATION',
}

export const actions: ActionTree<LocationState, RootState> = {
  [LocationActions.FETCH_DRIVER_LAST_LOCATION]: async (context, driverId) => {
    return new Promise((resolve) => {
      axiosInstance({
        baseURL: locationsApiBase,
        ...driverEndpoints.getLastLocations,
        params: {
          driver_id: driverId
        }
      }).then(resp => {
        if (resp.data?.data) {
          resolve(resp.data.data)
        }
      }).catch(err => {
        throw new Error(err)
      })
    })
  }
}
