
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
import { defineComponent } from 'vue'
export default defineComponent({
  components: {
    BaseIcon
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
})
