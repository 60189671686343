
import { defineComponent } from 'vue'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'

export default defineComponent({
  name: 'InputError',
  components: {
    BaseIcon
  },
  props: {
    error: {
      type: String,
      required: true
    }
  }
})
