import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import { ManualChangesState } from '@/store/modules/manual-changes/types'
import { ManualChangesInterface } from '@/shared/interfaces/manual-changes.interface'

export const getters: GetterTree<ManualChangesState, RootState> = {
  getManualChanges (state): ManualChangesInterface[] {
    return state.manualChanges
  },
  getEntityTypes (state): any[] {
    return state.entityTypes
  },
  getPagination (state): PaginationInterface | null {
    return state.pagination
  },
  getLoading (state): boolean {
    return state.loading
  },
  getEntityTypesLoading (state): boolean {
    return state.entityLoading
  }
}
