import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-end gap-8 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      role: "button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('edit', _ctx.data)))
    }, [
      _createVNode(_component_BaseIcon, { name: "edit" })
    ]),
    _createElementVNode("a", {
      role: "button",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('delete', _ctx.data)))
    }, [
      _createVNode(_component_BaseIcon, { name: "trash" })
    ])
  ]))
}