import { MutationTree } from 'vuex'
import { VehiclesState } from '@/store/modules/vehicles/types'

export enum VehiclesMutations {
  INSERT_VEHICLES = 'INSERT_VEHICLES',
  INSERT_VEHICLE_TYPES = 'INSERT_VEHICLE_TYPES',
  TRIGGER_LOADING = 'TRIGGER_LOADING',
  UPDATE_VEHICLES_ACTIVATED = 'UPDATE_VEHICLES_ACTIVATED',
  INSERT_AVAILABLE_VEHICLES = 'INSERT_AVAILABLE_VEHICLES',
  INSERT_VEHICLE_MODELS = 'INSERT_VEHICLE_MODELS',
  TRIGGER_VEHICLE_MODELS_LOADING = 'TRIGGER_VEHICLE_MODELS_LOADING',
  TRIGGER_VEHICLE_MAKE_LOADING = 'TRIGGER_VEHICLE_MAKE_LOADING',
  INSERT_VEHICLES_MAKE = 'INSERT_VEHICLES_MAKE'
}

export const mutations: MutationTree<VehiclesState> = {
  [VehiclesMutations.INSERT_VEHICLES] (state, payload) {
    state.vehicleList = payload.data
    state.pagination = payload.pagination
  },
  [VehiclesMutations.INSERT_AVAILABLE_VEHICLES] (state, payload) {
    state.availableVehicleList = payload.data
  },
  [VehiclesMutations.INSERT_VEHICLE_TYPES] (state, payload) {
    state.vehicleTypes = payload.data
  },
  [VehiclesMutations.TRIGGER_LOADING] (state, bool: boolean) {
    state.loading = bool
  },
  [VehiclesMutations.TRIGGER_VEHICLE_MODELS_LOADING] (state, bool: boolean) {
    state.vehicleModelLoading = bool
  },
  [VehiclesMutations.TRIGGER_VEHICLE_MAKE_LOADING] (state, bool: boolean) {
    state.vehiclesMakeLoading = bool
  },
  [VehiclesMutations.INSERT_VEHICLE_MODELS] (state, payload) {
    state.vehicleModels = payload
  },
  [VehiclesMutations.INSERT_VEHICLES_MAKE] (state, payload) {
    state.vehiclesMake = payload
  },
  [VehiclesMutations.UPDATE_VEHICLES_ACTIVATED] (state, payload) {
    state.vehicleList = state.vehicleList.map(vehicle => {
      if (vehicle.id === payload.vehicleId) {
        vehicle.isActivated = payload.isActivate
      }
      return vehicle
    })
  }
}
