import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { DriverActivityInterface } from '@/shared/interfaces/reports/driver-activity.interface'
import { formatDate } from '@/shared/utils/formatDate'

export class DriverActivityModel extends CommonModel {
  public data: DriverActivityInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): DriverActivityInterface {
    return {
      belowTime: data.below_time,
      creationDate: formatDate(data.creation_date, 'DD.MM.YYYY HH:mm'),
      darkstoreName: data.darkstore_name,
      deliveredOrders: data.delivered_orders,
      driverId: data.driver_id,
      overtime: data.overtime,
      returnedOrders: data.returned_orders,
      shiftName: data.shift_name,
      shiftTimeFrom: data.shift_time_from,
      shiftTimeTo: data.shift_time_to,
      totalOrders: data.total_orders,
      username: data.username,
      trackingId: data?.tracking_id,
      driverFullName: data.driver_full_name
    }
  }
}
