import { App, defineAsyncComponent } from 'vue'
export const injectComponents = (app:App) => {
  app.component('DynamicTableDarkstoreSetting', defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTableDarkstoreSetting.vue')))
  app.component('DynamicTableDarkstoreActionsCell', defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTableDarkstoreActionsCell.vue')))
  app.component('DynamicTableCacheSettlementAction', defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTableCacheSettlementsAction.vue')))
  app.component('DynamicTableCacheSettlementStatus', defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTableCacheSettlementsStatus.vue')))
  app.component('DynamicTableErpSyncStatus', defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTableErpSyncStatus.vue')))
  app.component('DynamicTableActionsCell', defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTableActionsCell.vue')))
  app.component('DynamicTableClientActionsCell', defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTableClientActionsCell.vue')))
}
