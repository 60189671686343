
import _ from 'lodash'
import { defineAsyncComponent, defineComponent } from 'vue'
import { VueEcharts } from 'vue3-echarts'
import moment from 'moment'
import { ReportActions } from '@/store/modules/reports/actions'
import { HourlyDeliveryTimeAnalysisInterface } from '@/shared/interfaces/reports/hourly-delivery-time-analysis.interface'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'
import { DarkstoreOrderPeakHoursInterface } from '@/shared/interfaces/reports/darkstore-order-peak-hours.interface'
export default defineComponent({
  components: {
    PeakHoursChart: defineAsyncComponent(() => import('@/views/account/reports/components/DarkstoreOrderPeakHours/PeakHoursChart.vue')),
    NoChartDataPlaceholder: defineAsyncComponent(() => import('@/views/account/reports/components/DarkstoreOrderPeakHours/NoChartDataPlaceholder.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    Popper: defineAsyncComponent(() => import('vue3-popper')),
    Multiselect: defineAsyncComponent(() => import('@vueform/multiselect')),
    VueEcharts
  },
  data () {
    return {
      dateRange: [moment().add(-7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      startHour: 8,
      endHour: 18,
      deliveryTimeApply: 1,
      searchValue: null as any,
      optionTemplate: {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: []
        },
        xAxis: {
          name: this.$t('reports.darkStoreOrderPeakHours.hours'),
          type: 'category',
          data: []
        },
        yAxis: [{
          name: this.$t('reports.darkStoreOrderPeakHours.totalOrders'),
          nameRotate: 0,
          type: 'value'
        }],
        series: [] as any
      },
      exportColumn: [] as any,
      exportData: [] as any,
      selectedDarkstores: [] as any,
      exportName: 'darkstore_hourly_delivery_analysis',
      deliveryTimeApplyItems: [
        { id: 1, label: this.$t('reports.hourlyDeliveryTimeAnalysis.deliveryTimeOptions.proportionalAllocation') },
        { id: 2, label: this.$t('reports.hourlyDeliveryTimeAnalysis.deliveryTimeOptions.keyTimePointDeliveryStarted') },
        { id: 3, label: this.$t('reports.hourlyDeliveryTimeAnalysis.deliveryTimeOptions.keyTimePointDeliveryCompleted') }
      ]
    }
  },
  methods: {
    async getPeakHoursReport () {
      const queryParams = {
        reportType: { name: 'Darkstore_order_peak_hours' },
        pageNumber: 1,
        pageSize: 10000,
        startDate: moment(this.dateRange[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dateRange[1]).format('YYYY-MM-DD'),
        startHour: this.startHour,
        endHour: this.endHour,
        darkstoreIdList: this.selectedDarkstores.map((ds: any) => ds.id)
      }
      await this.$store.dispatch(`reports/${[ReportActions.FETCH_REPORTS]}`, queryParams)
    },
    async getDeliveryTimeAnalysisReport () {
      const queryParams = {
        reportType: this.reportType,
        pageNumber: 1,
        pageSize: 10000,
        startDate: moment(this.dateRange[0]).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(this.dateRange[1]).format('YYYY-MM-DD HH:mm:ss'),
        startHour: this.startHour,
        endHour: this.endHour,
        deliveryTimeApply: this.deliveryTimeApply,
        darkstoreIdList: this.selectedDarkstores.map((ds: any) => ds.id)
      }
      console.log(queryParams)
      await this.$store.dispatch(`reports/${[ReportActions.FETCH_REPORTS]}`, queryParams)
    },
    exportTableToExcel (event: any) {
      event.stopPropagation()
      this.exportData = []
      this.exportColumn = [
        { label: 'Store', field: 'name' }
      ]
      for (let i = this.startHour; i <= this.endHour; i++) {
        this.exportColumn.push({ label: i < 10 ? `0${i}:00` : `${i}:00`, field: `field_${i}` })
      }

      this.exportName = 'darkstore_hourly_delivery_analysis_' + moment(this.dateRange[0]).format('YYYY-MM-DD') + '-' +
        moment(this.dateRange[1]).format('YYYY-MM-DD') + '_' + this.startHour + '-' + this.endHour
      this.chartOptionTimeAnalysis.series.forEach((s:any) => {
        const obj = {
          name: s.name
        } as any

        for (let i = this.startHour; i <= this.endHour; i++) {
          const dataItem = this.deliveryTimeAnalysisReport.filter((x: HourlyDeliveryTimeAnalysisInterface) => Number(x.hour) === i && x.darkstoreName === s.name)
          obj[`field_${i}`] = dataItem.length ? dataItem[0].avgDeliveryTime : 0
        }
        this.exportData.push(obj)
      })
      const button = document.getElementById('excel-export')
      // eslint-disable-next-line no-unused-expressions
      if (button) {
        this.$nextTick(() => button.click())
      }
    },
    exportPeakHoursTableToExcel (event: any) {
      if (this.chartOptionPeakHours === null) return
      event.stopPropagation()
      this.exportData = []
      this.exportColumn = [
        { label: 'Store', field: 'name' }
      ]
      this.exportName = 'darkstore_order_peak_hours_' + moment(this.dateRange[0]).format('YYYY-MM-DD') + '-' +
        moment(this.dateRange[1]).format('YYYY-MM-DD') + '_' + this.startHour + '-' + this.endHour

      for (let i = this.startHour; i <= this.endHour; i++) {
        this.exportColumn.push({ label: i < 10 ? `0${i}:00` : `${i}:00`, field: `field_${i}` })
      }

      this.chartOptionPeakHours.series.forEach((s:any) => {
        const obj = {
          name: s.name
        } as any

        for (let i = this.startHour; i <= this.endHour; i++) {
          const dataItem = this.deliveryPeakHoursReport.filter((x: DarkstoreOrderPeakHoursInterface) => Number(x.orderHour) === i && x.darkstoreName === s.name)
          obj[`field_${i}`] = dataItem.length ? dataItem[0].totalOrders : 0
        }

        s.data.forEach((data:any, index: number) => {
          const key = this.chartOptionPeakHours.xAxis.data[index]
          obj[key] = data
        })
        this.exportData.push(obj)
      })
      const button = document.getElementById('excel-export-2')
      // eslint-disable-next-line no-unused-expressions
      if (button) {
        this.$nextTick(() => button.click())
      }
    },
    fetchReports () {
      this.getDeliveryTimeAnalysisReport()
      this.getPeakHoursReport()
    },
    getDarkstores () {
      const params = { pageSize: 1000, pageNumber: 1 }
      this.$store.dispatch(`darkstores/${[DarkStoresActions.FETCH_DARKSTORES]}`, params)
    }
  },
  props: {
    reportType: {
      type: String,
      default: 'Darkstore_order_peak_hours'
    }
  },
  computed: {
    loading () {
      return this.$store.getters['reports/getLoading']
    },
    peakHoursReports () {
      return this.$store.getters['reports/getDarkstorePeakHoursReports']
    },
    deliveryTimeAnalysisReport () {
      return this.$store.getters['reports/getHourlyDeliveryTimeAnalysisExportReports']
    },
    deliveryTimeAnalysisReportForMoreThan1Mins () {
      return this.$store.getters['reports/getHourlyDeliveryTimeAnalysisExportReports']
        .filter((r: HourlyDeliveryTimeAnalysisInterface) => r.avgDeliveryTime > 1)
    },
    deliveryPeakHoursReport () {
      return this.$store.getters['reports/getDarkstorePeakHoursReports']
    },
    darkstores () {
      return this.$store.getters['darkstores/getDarkStores'].map((ds: any) => ({ id: ds.id, name: ds.name }))
    },
    chartOptionTimeAnalysis () {
      const options = _.cloneDeep(this.optionTemplate)
      options.yAxis = {
        ...options.yAxis,
        name: this.$t('reports.hourlyDeliveryTimeAnalysis.deliveryTime')
      } as any
      options.legend.data = [...new Set(this.deliveryTimeAnalysisReportForMoreThan1Mins.map((r: HourlyDeliveryTimeAnalysisInterface) => r.darkstoreName))] as any
      options.legend.data = [...options.legend.data] as any
      options.xAxis.data = [...new Set(this.deliveryTimeAnalysisReportForMoreThan1Mins.map((r: HourlyDeliveryTimeAnalysisInterface) => r.hour))] as any
      const darkStoreIds = [...new Set(this.deliveryTimeAnalysisReportForMoreThan1Mins.map((r: HourlyDeliveryTimeAnalysisInterface) => r.darkstoreId))] as number[]
      darkStoreIds.forEach((darkstoreId: number) => {
        const darkstore = this.deliveryTimeAnalysisReportForMoreThan1Mins
          .filter((r: HourlyDeliveryTimeAnalysisInterface) => (r.darkstoreId === darkstoreId))
        const dsObject = {
          name: darkstore.length ? darkstore[0].darkstoreName : 'N/A',
          data: [],
          type: 'bar',
          barGap: '0',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          },
          itemStyle: {
            normal: {
              barBorderRadius: [50, 50, 0, 0]
            }
          }
        } as any
        options.xAxis.data.forEach((hour: number) => {
          const item = this.deliveryTimeAnalysisReportForMoreThan1Mins
            .filter((r:HourlyDeliveryTimeAnalysisInterface) => r.hour === hour && r.darkstoreId === darkstoreId) as HourlyDeliveryTimeAnalysisInterface[]
          if (item.length > 0) {
            dsObject.data.push(item[0].avgDeliveryTime)
          } else {
            dsObject.data.push(0)
          }
        })
        options.series.push(dsObject)
      })
      options.xAxis.data = options.xAxis.data.map((value: number) => (value < 10 ? `0${value}:00` : `${value}:00`)) as any
      return options
    },
    hours () {
      return Array.from(Array(24).keys()).map((k) => ({ label: k > 9 ? `${k}:00` : `0${k}:00`, value: k }))
    },
    chartOptionPeakHours (vm:any) {
      return vm.$refs.peakHoursChart.chartOption ?? {}
    }
  },
  mounted () {
    this.getPeakHoursReport()
    this.getDarkstores()
  }
})
