import { RouteRecordRaw } from 'vue-router'
const LTSDashboardRoutes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'LTSDashboardIndex',
    component: () => import('./LTSDashboardIndex.vue')
  },
  {
    path: 'trip-playback',
    name: 'TripPlayback',
    component: () => import('./TripPlayback.vue')
  }
]

export default LTSDashboardRoutes
