
import { defineComponent, createApp } from 'vue'
import { DarkStoreInterface } from '@/shared/interfaces/darkstore.interface'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'
import { decode } from '@googlemaps/polyline-codec'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import DarkStoreInfoWindow from '@/views/account/orders/components/DarkStoreInfoWindow.vue'
import { cloneDeep } from 'lodash'

const wdw: any = window
let driverMarker: any = null
const driverMarkers: any = {}
// let customerMarker: any = null
const customerMarkers: any = []
let darkstoreMarker: any = null
export default defineComponent({
  name: 'OrdersMap',
  props: ['id', 'tripDetails', 'showDarkstores', 'drivers', 'zoom', 'mapRedirection'],
  emits: ['redirectToGoogleMap'],
  data () {
    return {
      openedMarkerID: 1,
      map: {} as any,
      activeInfoWindow: null as any,
      // markers: null as any,
      isInitial: true,
      darkstoresState: null as any,
      isRefresh: 0
    }
  },
  mounted () {
    this.initMap()
    if (this.showDarkstores) {
      this.$store.dispatch(`darkstores/${DarkStoresActions.FETCH_DARKSTORES}`, { pageNUmber: null, pageSize: null })
    }
    this.$nextTick(() => {
      this.setItemsOnMap()
    })
  },
  computed: {
    darkstores () {
      if (this.showDarkstores) {
        return this.$store.getters['darkstores/getDarkStores']
      } else {
        return []
      }
    }
  },
  watch: {
    map: {
      handler: function (dat: any) {
        if (this.darkstoresState) {
          this.setDarkstores(this.darkstoresState)
          this.darkstoresState = null
        }
      }
    },
    darkstores (val) {
      if (wdw.google) {
        this.setDarkstores(val)
      } else {
        this.darkstoresState = val
      }
    },
    tripDetails (val) {
      this.isInitial = false
      this.setItemsOnMap()
    },
    drivers: {
      handler (val) {
        this.setDrivers(val)
      },
      deep: true
    }
  },
  methods: {
    setDarkstores (val: any): void {
      const markers = val.map((darkStore: DarkStoreInterface) => {
        const marker = this.addMarker(darkStore.latitude, darkStore.longitude, 'darkstore')
        marker.addListener('click', () => {
          const content = this.createDarkstoreInfoWindow(darkStore)
          const storeDetailsInfo = this.generateInfoWindow(content)
          if (this.activeInfoWindow) {
            this.activeInfoWindow.close()
          }
          this.activeInfoWindow = storeDetailsInfo
          storeDetailsInfo.open({
            anchor: marker,
            map: this.map
          })
        })

        return marker
      })
      const markerCluster = new MarkerClusterer({ map: this.map, markers })
      // var mcStyles = markerCluster.getStyles();
      // mcStyles.push({
      //   url: "http://www.geocodezip.com/mapIcons/markerclusterer/heart50.png",
      //   width: 50,
      //   height: 44,
      //   anchorIcon: [44, 25],
      //   textSize: 10,
      //   textColor: "black",
      //   textDecoration: "none",
      //   fontStyle: "normal",
      //   fontFamily: "Arial,sans-serif",
      //   backgroundPosition: "0 0",
      // });
    },
    initMap () {
      if (wdw.google) {
        const latitude = 33.312805
        const longitude = 44.361488
        this.map = new wdw.google.maps.Map(
          document.getElementById(this.id),
          {
            center: { lat: latitude, lng: longitude },
            zoom: 15,
            mapTypeControl: false,
            streetViewControl: false,
            disableDefaultUI: false
          }
        )
        this.map.addListener('zoom_changed', () => {
          setTimeout(() => {
            this.updateMapCenter()
          }, 100)
        })
      } else {
        setTimeout(() => {
          this.initMap()
        }, 500)
      }
    },
    setItemsOnMap (): void {
      if (this.drivers && this.drivers.length) {
        this.setDrivers(this.drivers)
      }
      if (!this.tripDetails) {
        return
      }
      if (this.isInitial && this.tripDetails.toDarkstore && this.tripDetails.fromDarkstore) {
        this.genPly()
      }
      if (!(this.tripDetails.toDarkstore || this.tripDetails.fromDarkstore) && this.tripDetails.orderData?.destinationLatitude) {
        const lat = this.tripDetails.orderData.destinationLatitude
        const long = this.tripDetails.orderData.destinationLongitude
        const darkstoreLat = this.tripDetails.orderData.darkStore.latitude
        const darkstoreLong = this.tripDetails.orderData.darkStore.longitude

        if (customerMarkers.length) {
          customerMarkers.forEach((cm: any) => cm.setMap(null))
        }
        if (darkstoreMarker) {
          darkstoreMarker.setMap(null)
        }
        const latlngCustomer = new wdw.google.maps.LatLng(lat, long)
        const latlngDarkstore = new wdw.google.maps.LatLng(darkstoreLat, darkstoreLong)
        const latLngArry = []
        latLngArry.push(latlngCustomer)
        latLngArry.push(latlngDarkstore)
        const latlngbounds = new wdw.google.maps.LatLngBounds()
        const marker = this.addMarker(lat, long, 'customer-first', true)
        const dsMarker = this.addMarker(darkstoreLat, darkstoreLong, 'darkstore')
        latlngbounds.extend(marker.position)
        latlngbounds.extend(dsMarker.position)
        const infowindowCustomer = this.generateInfoWindow(this.tripDetails.orderData.userAddress)
        marker.addListener('click', () => {
          if (this.activeInfoWindow) {
            this.activeInfoWindow.close()
          }
          this.activeInfoWindow = infowindowCustomer
          infowindowCustomer.open({
            anchor: marker,
            map: this.map
          })
        })
        const infowindowDs = this.generateInfoWindow(this.tripDetails.orderData.darkStore.name)
        dsMarker.addListener('click', () => {
          if (this.activeInfoWindow) {
            this.activeInfoWindow.close()
          }
          this.activeInfoWindow = infowindowDs
          infowindowDs.open({
            anchor: dsMarker,
            map: this.map
          })
        })
        customerMarkers.push(marker)
        darkstoreMarker = dsMarker

        // Show linked orders
        if (this.tripDetails.orderData.linkedOrders) {
          this.tripDetails.orderData.linkedOrders.map((el:any) => {
            const lat = el.destination_latitude
            const long = el.destination_longitude
            const latlngCustomerNext = new wdw.google.maps.LatLng(lat, long)
            latLngArry.push(latlngCustomerNext)
            const markerNext = this.addMarker(lat, long, 'customer-first', true)
            latlngbounds.extend(markerNext.position)

            const infowindowCustomerNext = this.generateInfoWindow(el.user_address)
            markerNext.addListener('click', () => {
              if (this.activeInfoWindow) {
                this.activeInfoWindow.close()
              }
              this.activeInfoWindow = infowindowCustomerNext
              infowindowCustomerNext.open({
                anchor: markerNext,
                map: this.map
              })
            })
            customerMarkers.push(markerNext)
          })
        }

        if (!this.isRefresh) {
          this.map.setCenter(latlngbounds.getCenter())
          this.map.fitBounds(latlngbounds)
          this.isRefresh = 1
        }
      }
      const getDeliveredStatusItem = (statuses: any) => {
        return statuses.find((el: any) => el.status === 'delivered')
      }
      let showDriverLocationForDeliveredOrder = false
      if (this.tripDetails.orderData?.orderStatusTime) {
        const deliveredStatusTime = getDeliveredStatusItem(this.tripDetails.orderData.orderStatusTime)
        if (deliveredStatusTime?.value) {
          showDriverLocationForDeliveredOrder = (new Date().getTime() - new Date(deliveredStatusTime.value).getTime()) / 60000 < 45
        }
      }
      if (this.tripDetails.status !== 'cancelled' &&
          (this.tripDetails.status !== 'delivered' ||
            (this.tripDetails.status === 'delivered' && showDriverLocationForDeliveredOrder))) {
        if (this.tripDetails.driverLocation) {
          if (driverMarker) {
            driverMarker.setMap(null)
          }
          const tooltipData =
            `<table>
                <tbody class="">
                    <tr class="border-0">
                        <td class="p-2 text-xs">Driver</td>
                        <td class="p-2 text-xs">${this.tripDetails.orderData.driver.driverInfo.firstName} ${this.tripDetails.orderData.driver.driverInfo.lastName}</td>
                    </tr>
                     <tr  class="border-0">
                        <td class="p-2 text-xs">Vehicle</td>
                        <td class="p-2 text-xs">${this.tripDetails.orderData.driver?.vehicleInfo?.model} ${this.tripDetails.orderData.driver?.vehicleInfo?.make}</td>
                    </tr>
                    <tr  class="border-0">
                      <td class="p-2 text-xs">License No</td>
                      <td class="p-2 text-xs">${this.tripDetails.orderData.driver.vehicleInfo?.licenseNo}</td>
                  </tr>
                </tbody>
            </table>`
          const infowindow = this.generateInfoWindow(tooltipData)
          const marker = this.addMarker(this.tripDetails.driverLocation.latitude, this.tripDetails.driverLocation.longitude, 'driver', true)
          marker.addListener('click', () => {
            if (this.activeInfoWindow) {
              this.activeInfoWindow.close()
            }
            this.activeInfoWindow = infowindow
            infowindow.open({
              anchor: marker,
              map: this.map
            })
          })
          driverMarker = marker
        }
      }
    },
    addMarker (lat: number, lng: number, markerType: string, isToCustomer = false, isBusyDriver = false, vehicleType = 1): any {
      const position = new wdw.google.maps.LatLng(lat, lng)
      let markerImg
      if (markerType === 'darkstore') {
        markerImg = require('../../../../assets/markers/darkstore-dark-marker.png')
      } else if (markerType === 'main-darkstore') {
        markerImg = require('../../../../assets/backstore.png')
      } else if (markerType === 'customer-first') {
        markerImg = require('../../../../assets/markers/double-order-dark-marker.png')
      } else if (markerType === 'customer-second') {
        markerImg = require('../../../../assets/markers/double-order-light-marker.png')
      } else if (markerType === 'driver') {
        if (isToCustomer) {
          markerImg = require('../../../../assets/red.png')
        } else {
          markerImg = require('../../../../assets/g.png')
        }
      }
      let image = {
        url: markerImg,
        size: new wdw.google.maps.Size(55, 70),
        origin: new wdw.google.maps.Point(0, 0),
        anchor: new wdw.google.maps.Point(22, 67)
      }

      if (markerType === 'driver') {
        image = {
          url: isBusyDriver ? require(`../../../../assets/vehicle_${vehicleType}_green.png`)
            : require(`../../../../assets/vehicle_${vehicleType}_red.png`),
          size: new wdw.google.maps.Size(55, 70),
          origin: new wdw.google.maps.Point(0, 0),
          anchor: new wdw.google.maps.Point(22, 50)
        }
      }

      const marker = new wdw.google.maps.Marker({
        position,
        map: this.map,
        icon: image
      })
      if (markerType === 'main-darkstore') {
        marker.setLabel({ text: 'DS', color: 'white' })
      }
      if (this.isInitial) {
        // this.map.setCenter(position)
      }
      return marker
    },
    genPly () {
      const markers = []
      markers.push(
        {
          latitude: this.tripDetails.toDarkstore.endLocation.lat,
          longitude: this.tripDetails.toDarkstore.endLocation.lng,
          tooltip: {
            name: this.tripDetails.orderData.darkStore.name
          },
          markerType: 'darkstore'
        }
      )
      this.tripDetails.fromDarkstore.forEach((el: any, i: number) => {
        markers.push(
          {
            latitude: el.endLocation.lat,
            longitude: el.endLocation.lng,
            tooltip: {
              name: el.endAddress
            },
            markerType: i === 0 ? 'customer-first' : 'customer-second'
          }
        )
      })
      const latLngArry = []
      const latlngbounds = new wdw.google.maps.LatLngBounds()
      for (let i = 0; i < markers.length; i++) {
        const data = markers[i]
        const infowindow = this.generateInfoWindow(data.tooltip.name)
        const myLatlng = new wdw.google.maps.LatLng(data.latitude, data.longitude)
        latLngArry.push(myLatlng)
        const marker = this.addMarker(data.latitude, data.longitude, data.markerType)
        latlngbounds.extend(marker.position)
        marker.addListener('click', () => {
          if (this.activeInfoWindow) {
            this.activeInfoWindow.close()
          }
          this.activeInfoWindow = infowindow
          infowindow.open({
            anchor: marker,
            map: this.map
          })
        })
      }
      this.map.setCenter(latlngbounds.getCenter())
      this.map.fitBounds(latlngbounds)
      if (this.tripDetails.fromDarkstore) {
        this.tripDetails.fromDarkstore.forEach((el: any) => {
          if (el.overviewPolyline?.points) {
            const decodedPoints = decode(el.overviewPolyline.points)
            const decodedLatLangs = decodedPoints.map(el => {
              return {
                lat: el[0],
                lng: el[1]
              }
            })
            const direction = new wdw.google.maps.Polyline({
              path: decodedLatLangs,
              geodesic: true,
              strokeColor: '#2B2D42'
              // strokeOpacity: 1.0,
              // strokeWeight: 2
            })
            direction.setMap(this.map)
          }
        })
      }
    },
    generateInfoWindow (data: any) {
      return new wdw.google.maps.InfoWindow({
        content: data
      })
    },
    updateMapCenter () {
      const visibleBounds = this.map.getBounds()
      const bounds = new wdw.google.maps.LatLngBounds()
      for (let i = 0; i < customerMarkers.length; i++) {
        if (visibleBounds.contains(customerMarkers[i].getPosition())) {
          bounds.extend(customerMarkers[i].getPosition())
        }
      }
      bounds.extend(darkstoreMarker.getPosition())
      this.map.setCenter(bounds.getCenter())
    },
    animateDriverMarker (marker:any, newPosition:any) {
      const animationDuration = 15000
      const startPosition = marker.getPosition()
      let startTime = 0

      const animateStep = (timestamp:any) => {
        if (!startTime) startTime = timestamp
        const elapsedTime = timestamp - startTime
        if (elapsedTime >= animationDuration) {
          marker.setPosition(newPosition)
          this.map.setCenter(newPosition)
          return
        }
        const fraction = elapsedTime / animationDuration
        const interpolatedPosition = wdw.google.maps.geometry.spherical.interpolate(
          startPosition,
          newPosition,
          fraction
        )
        marker.setPosition(interpolatedPosition)
        requestAnimationFrame(animateStep)
      }
      requestAnimationFrame(animateStep)
    },
    createDarkstoreInfoWindow (darkstore: DarkStoreInterface) {
      const app = createApp(DarkStoreInfoWindow, { darkstore, vm: this })
      const content = document.createElement('div')
      app.mount(content)
      return content
    },
    setDrivers (val: any) {
      val.map((driverLocation: any) => {
        const key = driverLocation.id
        if (driverMarkers[key]) {
          const newLat = driverLocation.latitude
          const newLong = driverLocation.longitude
          if (driverMarkers[key].driverInfo.latitude !== newLat ||
            driverMarkers[key].driverInfo.longitude !== newLong) {
            const newPosition = new wdw.google.maps.LatLng(newLat, newLong)
            this.animateDriverMarker(driverMarkers[key].marker, newPosition)
            driverMarkers[key].driverInfo = cloneDeep(driverLocation)
          }
        } else {
          const isBusy = ['idle', 'occupied_inward', 'occupied_inward_assigned'].includes(driverLocation.driverStatus.name)
          const vehicleType = driverLocation.vehicleId.vehicleType.id
          const marker = this.addMarker(driverLocation.latitude, driverLocation.longitude, 'driver', driverLocation.isToCustomer, isBusy, vehicleType)
          const driverDetails = driverLocation.driver.driverInfo
          const driverFullName = driverDetails.firstName && driverDetails.lastName ? `${driverDetails.userName} - ${driverDetails.firstName} ${driverDetails.lastName}`
            : driverDetails.userName
          const tooltipData =
            `<table>
              <tbody>
                  <tr class="border-0">
                      <td class="p-2">Driver</td>
                      <td class="p-2">${driverFullName}</td>
                  </tr>
                   <tr  class="border-0">
                      <td class="p-2">Vehicle</td>
                      <td class="p-2">${driverLocation.driver.vehicleInfo.model} ${driverLocation.driver.vehicleInfo.make}</td>
                  </tr>
                   <tr  class="border-0">
                      <td class="p-2">License No</td>
                      <td class="p-2">${driverLocation.driver.vehicleInfo.licenseNo}</td>
                  </tr>
              </tbody>
          </table>`
          const infowindow = this.generateInfoWindow(tooltipData)
          marker.addListener('click', () => {
            if (this.activeInfoWindow) {
              this.activeInfoWindow.close()
            }
            this.activeInfoWindow = infowindow
            infowindow.open({
              anchor: marker,
              map: this.map
            })
          })
          driverMarkers[key] = {
            driverInfo: cloneDeep(driverLocation),
            marker
          }
        }
      })
    }
  }
})
