
import { defineComponent, defineAsyncComponent } from 'vue'
import router from '@/router'
import { VehiclesActions } from '@/store/modules/vehicles/actions'

export default defineComponent({
  components: {
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    DynamicTable: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTable.vue'))
  },
  data () {
    return {
      showFilterModal: false,
      tableFields: [
        { id: 'driverId', name: 'Driver ID', width: '18%', sortable: false },
        { id: 'username', name: 'Driver Username', width: '18%', sortable: false },
        { id: 'driverFullName', name: 'Driver Full Name', width: '18%', sortable: false },
        { id: 'creationDate', name: 'Date', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'creation_date' },
        { id: 'darkstoreName', name: 'Darkstore', width: '18%', sortable: false },
        { id: 'shiftName', name: 'Shift Name', width: '18%', sortable: false },
        { id: 'shiftTimeFrom', name: 'Shift Time From', width: '18%', sortable: false },
        { id: 'shiftTimeTo', name: 'Shift Time To', width: '18%', sortable: false },
        { id: 'overtime', name: 'Overtime', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'overtime' },
        { id: 'belowTime', name: 'Below time', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'below_time' },
        { id: 'deliveredOrders', name: 'Delivered', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'delivered_orders' },
        { id: 'returnedOrders', name: 'Returned', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'returned_orders' },
        { id: 'totalOrders', name: 'Total', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'total_orders' },
        { id: 'trackingId', name: 'Tracking Id', width: '18%', sortable: false, currentSort: false, sort: null, sortName: 'tracking_id' }
        // { id: 'ordersCount', name: 'Orders Count', width: '18%', sortable: true, currentSort: false, sort: null, sortName: 'orders_count' }
      ],
      filters: [
        { name: this.$t('filters.activity'), model: 'activity', type: 'date', calendarType: 'range' },
        { name: this.$t('filters.shiftStart'), model: 'shiftStartTime', type: 'date', calendarType: 'time' },
        { name: this.$t('filters.shiftEnd'), model: 'shiftEndTime', type: 'date', calendarType: 'time' },
        {
          name: this.$t('filters.vehicleType'),
          model: 'vehicleTypeId',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'vehicleTypes',
          type: 'radio',
          data: []
        },
        { name: this.$t('filters.trackingId'), model: 'trackingId', type: 'number' }
      ],
      selectedReport: this.reportType
    }
  },
  methods: {
    menuEvent (e: any): void {
      console.log(e)
    },
    clickSort (field: any): void {
      this.tableFields = this.tableFields?.map((el: any) => {
        if (el.id === field.id) {
          el.currentSort = true
          if (el.sort === null) {
            el.sort = 'asc'
          } else if (el.sort === 'asc') {
            el.sort = 'desc'
          } else if (el.sort === 'desc') {
            el.sort = null
          }
        } else {
          el.currentSort = false
          el.sort = null
        }
        return el
      })
      router.push({ query: { ...this.$route.query, orderBy: field.sortName, sort: field.sort } })
    },
    onExpand (event: any): void {
      if (event === 'vehicleTypes') {
        this.$store.dispatch(`vehicles/${[VehiclesActions.FETCH_VEHICLE_TYPES]}`)
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (val: any) {
        if (val.query.orderBy) {
          this.tableFields = this.tableFields.map((el: any) => {
            if (el.id === val.query.orderBy) {
              el.currentSort = true
              el.sort = val.query.sort
            }
            return el
          })
        }
      }
    },
    vehicleTypes: {
      immediate: true,
      handler (val) {
        if (!val.length) return
        const target = this.filters[3]
        target.dataRequested = true
        target.data = val
        target.dataLoading = false
      }
    },
    selectedReport (val) {
      if (val) {
        router.push({ query: { reportName: val.name } })
      }
    }
  },
  props: {
    reportType: {
      type: String
    }
  },
  computed: {
    vehicleTypes (): any[] {
      return this.$store.getters['vehicles/getVehicleTypes']
        .map((el: any) => {
          return {
            id: el.id,
            label: el.value
          }
        })
    },
    reports () {
      return this.$store.getters['reports/getDriversActivityReports']
    },
    exportReports () {
      return this.$store.getters['reports/getDriversActivityExportReports']
    },
    pagination () {
      return this.$store.getters['reports/getDriversActivityPagination']
    },
    loading () {
      return this.$store.getters['reports/getLoading']
    }
  }
})
