import { MutationTree } from 'vuex'
import { DarkStoresState } from '@/store/modules/darkstores/types'

export enum DarkStoresMutations {
  SET_DARKSTORES = 'SET_DARKSTORES',
  TRIGGER_LOADING = 'TRIGGER_LOADING'
}

export const mutations: MutationTree<DarkStoresState> = {
  [DarkStoresMutations.SET_DARKSTORES] (state, payload) {
    state.darkstores = payload.data
    state.pagination = payload.pagination
  },
  [DarkStoresMutations.TRIGGER_LOADING] (state, bool: boolean) {
    state.loading = bool
  }
}
